import styled from 'styled-components'

import { Label } from '@/atoms/Typography'

export const StyledSubParagraph = styled.div`
   > * + * {
    margin-block-start: ${({ theme }) => theme.rem(40)};
  }

  ${Label}{
    color: ${({ theme }) => theme.colors.general.light()};
  }
`

export const StyledSubParagraphContent = styled.div`
  color: ${({ theme }) => theme.colors.general.light()};

  ul{
    list-style-type: disc;
    list-style-position: inside;
    margin-left: 15px;
  }
`
