import styled from 'styled-components'

export const StyledSteps = styled.div`
  color: ${({ theme }) => theme.colors.general.light()};
`

export const StyledStepsHeader = styled.header`
  margin-bottom: ${({ theme }) => theme.rem(40)};
`

export const StyledStepsContent = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.rem(40)};

  > a {
    ${({ theme }) => theme.mediaQuery.md(`
      display: none;
    `)}
  }
`

export const StyledStepsList = styled.ol`
  display: grid;
  gap: ${({ theme }) => theme.rem(40)};

  ${({ theme }) => theme.mediaQuery.md(`
    gap: ${theme.rem(80)};
  `)}
`

export const StyledStepsListItem = styled.li`
  display: inline-flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(24)};

  ${({ theme }) => theme.mediaQuery.md(`
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  `)};
`

export const StyledStepsListItemNumber = styled.div`
  display: grid;
  place-items: center;
  flex-shrink: 0;
  border-radius: 50%;

  ${({ theme }) => theme.size(48)};
  color: ${({ theme }) => theme.colors.general.light()};
  background-color: ${({ theme }) => theme.colors.accent()};
`

export const StyledStepsListItemContent = styled.div`
width: 100%;
  > * + *{
    margin-block-start:  ${({ theme }) => theme.rem(24)};
  }
`
