import React from 'react'
import Link from 'next/link'

import {
  Heading,
  Label
} from '@/atoms/Typography'

import {
  StyledProductCard,
  StyledProductCardMedia,
  StyledProductCardName,
  StyledProductCardLines,
  StyledProductCardContainer,
} from './styles'


const ProductCard = ({
  item,
  grid,
}) => {
  const {
    title,
    link: href,
    image,
    lines
  } = item
  return (
    <StyledProductCard
      as={!!href ? Link : 'div'}
      href={!!href ? href : false}
      grid={grid}
    >
      <StyledProductCardMedia
        image={image}
        withAnimation
      />

      <StyledProductCardContainer>
        {
          lines &&
          !!lines.length &&
          <StyledProductCardLines>
            {
              lines.map(line => <Label key={line} as="span" typo="labelSmall">{line}</Label>)
            }
          </StyledProductCardLines>
        }
        <StyledProductCardName>
          <Heading typo="heading4">{title}</Heading>
        </StyledProductCardName>
      </StyledProductCardContainer>

    </StyledProductCard>
  )
}

export default ProductCard
