import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.2067 18.4063C14.2067 18.4063 13.0329 15.7384 16.9684 12.5099C17.497 12.0271 19.5311 9.33864 16.6412 7.01852C13.9413 5.6022 12.4106 7.93376 11.9484 9.14873C11.6532 9.84202 9.25531 14.6516 14.2067 18.4063Z" fill="#194074"/>
    <path d="M11.7607 18.9326C11.7607 18.9326 8.42695 17.2806 9.25752 9.6384C9.38108 8.51495 9.07677 3.30269 3.79815 4.07835C-0.439385 5.61137 -0.0526983 10.0823 0.59483 11.9219C0.901434 13.0042 2.90809 20.4931 11.7607 18.9326Z" fill="#194074"/>
    <path d="M15.809 18.4498C15.809 18.4498 17.3168 16.9831 20.1998 19.5C20.6185 19.8272 22.5908 20.9484 23.7212 18.1615C24.2268 15.5439 22.2957 14.8186 21.4079 14.6928C20.9 14.5807 17.2848 13.7661 15.809 18.4498Z" fill="#194074"/>
  </svg>

)
