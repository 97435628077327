import React from 'react'
import BoxedImage from '@/molecules/BoxedImage'

import { StyledBrandCard } from './styles'

const BrandCard = ({ item }) => (
  <StyledBrandCard>
    <BoxedImage {...item} />
  </StyledBrandCard>
)

export default BrandCard
