import React from 'react'

import GridMolecule from '@/molecules/Grid'

const Grid = (props) => (
  <GridMolecule {...props} />
)


export default Grid
