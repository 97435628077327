import React from 'react'

import Graphics from '@/atoms/Graphics'

import {
  StyledHeroBackground,
  StyledHeroBackgroundImage,
} from './styles'

const HeroBackground = ({
  brand,
  graphicIds = [],
  children,
  backgroundProps = {},
  full = false,
  ...props
}) => {
  return (
    <StyledHeroBackground {...props}>
      {
        graphicIds &&
        !!graphicIds.length &&
        <StyledHeroBackgroundImage full={full} length={graphicIds.length}>
          {
            graphicIds.map((graphicId) => (
              <Graphics key={graphicId} id={graphicId} alt={`${brand} graphic`} {...backgroundProps}/>
            ))
          }
        </StyledHeroBackgroundImage>
      }

      {children}

    </StyledHeroBackground>
  )
}

export default HeroBackground
