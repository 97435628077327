import React from 'react'

import Richtext from '@/atoms/RichText'
import Table from '@/atoms/Table'

import SubParagraph from '@/molecules/SubParagraph'
import RoundedIcon from '@/molecules/RoundedIcon'

import {
  StyledSpecs,
  StyledSpecsList,
  StyledSpecsItem,
  StyledSpecsItemIcon
} from './styles'

const Specs = ({
  title,
  items,
  data
}) => (
  <StyledSpecs>
    <SubParagraph title={title}>
      {
        items &&
        items.length &&
        <StyledSpecsList>
          {
            items.map(({
              icon,
              label
            }) => (
              <StyledSpecsItem key={label}>
                <StyledSpecsItemIcon>
                  <RoundedIcon name={icon} size={48} unlockedProportions useIconColor />
                </StyledSpecsItemIcon>

                <Richtext typo="paragraphLarge">{label}</Richtext>
              </StyledSpecsItem>
            ))
          }
        </StyledSpecsList>
      }

      <Table {...data} />
    </SubParagraph>
  </StyledSpecs>
)

export default Specs
