import styled from 'styled-components'

import Richtext from '@/atoms/RichText'
import HeadingWithMark from '@/molecules/HeadingWithMark'
export const StyledEditorialMedia = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: ${({ theme }) =>theme.rem(40)};

  ${({
    theme, imagePosition
  }) => theme.mediaQuery.md(`
    gap: 0;
    flex-direction: ${imagePosition === 'sx' ? 'row' : 'row-reverse'};
  `)}
`

export const StyledEditorialMediaMedia = styled.div`
  position: relative;
  overflow: hidden;
  aspect-ratio: 540/720;
  background-color: ${({ theme }) => theme.colors.background()};
  border-radius: ${({ theme }) =>theme.rem(24)};
  width: 100%;
  ${({ theme }) => theme.mediaQuery.md(`
    width: 45%
  `)}

  img{
    object-fit: cover;
  }
`


export const StyledEditorialButtons = styled.div`
  --margin-top: ${({ theme }) => theme.rem(40)};
  margin-top: var(--margin-top);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) =>theme.rem(24)};

  ${({ theme }) => theme.mediaQuery.lg(`
    gap: ${theme.rem(40)};
    flex-direction: row;
  `)};

  > a:first-child{
    width: 100%;
    ${({ theme }) => theme.mediaQuery.lg(`
      width: auto;
    `)}
  }
`

export const StyledEditorialMediaLogo = styled.div`
  --margin-bottom: ${({ theme }) => theme.rem(24)};
  display: inline-block;
  margin-bottom: var(--margin-bottom);

  ${({ theme }) => theme.mediaQuery.md(`
    --margin-bottom: ${theme.rem(40)};
  `)}

  img{
    max-height: 56px;

    width: auto;
  }
`

export const StyledEditorialMediaEditorialParagraph = styled(Richtext)`
  color: ${({ theme }) => theme.colors.general.light()};
`

export const StyledEditorialMediaEditorialHeading = styled(HeadingWithMark)`
  --margin-bottom: ${({ theme }) => theme.rem(32)};
  color: ${({ theme }) => theme.colors.general.light()};
  margin-top: var(--margin-bottom);

  ${({ theme }) => theme.mediaQuery.md(`
    --margin-bottom: ${theme.rem(80)};
  `)}
`

export const StyledEditorialMediaEditorial = styled.article`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQuery.md(`
    width: 45%
  `)}


  ${({ position, theme }) => position === 'top' && `
    row-gap: ${theme.rem(24)};
    ${StyledEditorialMediaLogo},
    ${StyledEditorialMediaEditorialHeading}{
      --margin-bottom: 0;
      --margin-top: 0;
    }

    ${StyledEditorialButtons}{
      --margin-top: ${(theme.rem(16))};
    }

  `}
`
