import React, { useRef } from 'react'
import Carousel from '@/molecules/Carousel'

import {
  StyledHeroCarousel,
  StyledHeroCarouselWave,
  StyledHeroCarouselCta
} from './styles'

const HeroCarousel = ({ list }) => {
  const ref = useRef(null)
  return (
    <StyledHeroCarousel ref={ref}>
      <Carousel list={list} />

      <StyledHeroCarouselWave>
        <StyledHeroCarouselCta container={ref} />
      </StyledHeroCarouselWave>
    </StyledHeroCarousel>
  )
}

export default HeroCarousel
