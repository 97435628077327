import React from 'react'
import Script from 'next/script'

import { StyledCookiebot } from './styles'

const Cookiebot = () => (
  <StyledCookiebot>
    <div id="CookieDeclaration"></div>
    <Script src={`https://consent.cookiebot.com/${process.env.NEXT_PUBLIC_COOKIEBOT_ID}/cd.js`} type="text/javascript"/>
  </StyledCookiebot>
)

export default Cookiebot
