import React from 'react'

import Contact from '@/molecules/Contact'

import { StyledContacts } from './styles'

const Contacts = ({ items }) => (
  items &&
  !!items.length &&
  <StyledContacts>
    {
      items.map(item => <Contact key={item.title} {...item} />)
    }
  </StyledContacts>
)

export default Contacts
