import styled from 'styled-components'

import { Label } from '@/atoms/Typography'

export const StyledInfo = styled(Label)`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.rem(8)};

  i{
    font-size: ${({
    theme, iconSize
  }) => theme.rem(iconSize)};
  }
`
