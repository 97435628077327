import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12275_21337)">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 12.0015C0 17.9415 4.32 22.8615 10.02 23.8815L10.0921 23.8224C10.088 23.8217 10.0838 23.8209 10.0796 23.8202V15.3602H7.07959V12.0002H10.0796V9.36018C10.0796 6.36018 11.9996 4.68018 14.7596 4.68018C15.5996 4.68018 16.5596 4.80018 17.3996 4.92018V7.98018H15.8396C14.3996 7.98018 14.0396 8.70018 14.0396 9.66018V12.0002H17.2196L16.6796 15.3602H14.0396V23.8202C14.0027 23.8269 13.9659 23.8334 13.929 23.8397L13.98 23.8815C19.68 22.8615 24 17.9415 24 12.0015C24 5.40146 18.6 0.00146484 12 0.00146484C5.4 0.00146484 0 5.40146 0 12.0015Z" fill="#194074"/>
    </g>
    <defs>
      <clipPath id="clip0_12275_21337">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>

)
