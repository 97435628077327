import React from 'react'
import Icon from '@/atoms/Icon'

import { StyledMediaIcon } from './styles'

const MediaIcon = ({
  name,
  ...rest
}) => {
  return (
    <StyledMediaIcon icon={name} {...rest}>
      <Icon name={name} unlockedProportions />
    </StyledMediaIcon>
  )
}

export default MediaIcon
