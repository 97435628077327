import React, {
  useContext,
  useRef
} from 'react'

import { GeneralContext } from 'contexts/General'
import useIsMobile from 'hooks/useIsMobile'
import { allowedBrands } from '@/utils'

import Breadcrumbs from '@/molecules/Breadcrumbs'

import StyledHeroWave from '../HeroWave'
import HeroContent from '../HeroContent'
import HeroBackground from '../HeroBackground'

import StickyElement from './StickyElement'

import {
  StyledHeroContainer,
  StyledHeroPart
} from '../styles'

import { StyledHeroProduct, } from './styles'

const HeroProduct = ({
  breadcrumbs,
  title,
  description,
  mainImage,
  cta = null,
}) => {
  const { brand } = useContext(GeneralContext)

  const brandSelected = allowedBrands.find(i => i === brand) || 'parmalat'
  const graphicIds = [`${brandSelected}-products-illustration-1`, `${brandSelected}-products-illustration-2`]

  const isMobile = useIsMobile()
  const containerRef = useRef(null)
  return (
    <StyledHeroProduct ref={containerRef}>
      <Breadcrumbs items={breadcrumbs}/>
      <StyledHeroContainer>
        <StyledHeroPart>
          <HeroContent
            title={title}
            description={description}
            cta={cta}
          />
        </StyledHeroPart>

        <StyledHeroPart>
          <StickyElement
            productName={title}
            cta={cta}
            containerRef={containerRef}
            image={mainImage}
            imageProps={{ className: 'main-image' }}/>
        </StyledHeroPart>
      </StyledHeroContainer>

      {
        !isMobile &&
        <HeroBackground
          brand={brand}
          backgroundProps={{
            fill: false,
            width: 320, height: 320
          }}
          graphicIds={graphicIds}
          cols={5}
          right
          absolute
        />
      }
      <StyledHeroWave />
    </StyledHeroProduct>
  )
}

export default HeroProduct
