import React, { useState } from 'react'

import {
  AnimatePresence, motion
} from 'framer-motion'

import { Heading } from '@/atoms/Typography'
import Richtext from '@/atoms/RichText'

import LineDecorationButton from '@/molecules/Buttons/LineDecorationButton'

import {
  StyledSeoParagraph,
  StyledSeoParagraphContent,
  StyledSeoParagraphContentExpanded,
  StyledSeoParagraphContentExcerpt,
} from './styles'

const SeoParagraph = ({
  title,
  excerpt,
  content,
  cta,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const onClick = () => {
    setIsExpanded(status => !status)
  }
  return (
    <StyledSeoParagraph>
      <StyledSeoParagraphContent as={motion.div}>
        <StyledSeoParagraphContentExcerpt>
          <Heading bold as="h3" typo="heading3">{title}</Heading>
          <Richtext as="div" typo="paragraphLarge">{excerpt}</Richtext>
        </StyledSeoParagraphContentExcerpt>
        <AnimatePresence>
          {
            isExpanded  &&
            <StyledSeoParagraphContentExpanded
              initial={{
                height: 0,
                opacity: 0,
                marginBottom: 0
              }}
              exit={{
                height: 0,
                opacity: 0,
                marginBottom: 0
              }}
              animate={{
                display: 'block',
                height: 'auto',
                marginBottom: 40,
                opacity: 1
              }}
              as={motion.div}>
              <Richtext as="div" typo="paragraphLarge">
                {content}
              </Richtext>
            </StyledSeoParagraphContentExpanded>
          }
        </AnimatePresence>
        <LineDecorationButton cta={{
          ...cta,
          label: !isExpanded ? cta.label : cta.labelClose
        }} isExpanded={isExpanded} onClick={onClick}/>
      </StyledSeoParagraphContent>
    </StyledSeoParagraph>
  )
}

export default SeoParagraph
