import styled from 'styled-components'

import { StyledBoxedImage } from '@/molecules/BoxedImage/styles'
import styledMap from 'styled-map'

export const StyledTabFilterTitle = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: ${({ theme }) =>theme.rem(16)};

  > span{
    white-space: nowrap;
    ${({ theme }) => theme.mediaQuery.md(`
      display: block;
      white-space: normal;
      width: 50%;
    `)}
  }

  ${({ theme }) => theme.mediaQuery.md(`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    ${theme.size('100%')}
    padding: ${theme.rem(24)};
  `)}
`

export const StyledTabFilter = styled.div`
  cursor: pointer;
  flex-shrink: 0;
  width: auto;

  --size: ${styledMap`
    default: 240px;
    small: 130px;
  `};

  ${({ theme }) => theme.mediaQuery.md(`
    width: var(--size);
    height: auto;
  `)}

  ${StyledBoxedImage}{
    display: block;
    height: 100%;
    position: relative;
    max-height: 80px;
    transition: border-color ease-in-out .4s;
    border-radius: ${({ theme }) => theme.rem(8)};
    border: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};
    aspect-ratio: ${styledMap('hideImg', { default: 'auto', false: '16 / 9' })};
    &:hover{
      border-color: ${({ theme }) => theme.colors.general.light()};

      ${({ theme, current }) => !current && theme.mediaQuery.md(`
        border-color: ${theme.colors.grayscale.gray200};
      `)}
    }

    ${({ theme, current }) => current ? `border-color: ${theme.colors.general.light()};` : ''}

    ${({ theme }) => theme.mediaQuery.md(`
      border-radius: ${theme.rem(16)};
      aspect-ratio: 16 / 9;
      max-height: 100%;
    `)}

    img{
      position: relative;
      z-index: 0;
      display: ${styledMap('hideImg', { default: 'none', false: 'block', })};

      ${({ theme }) => theme.mediaQuery.md(`
        display: block;
      `)}
    }
  }

  ${StyledTabFilterTitle}{
    color: ${({ theme }) => theme.colors.general.light()};

    ${({ theme, current }) => current && `
      background: ${theme.colors.general.light()} ;
      color: ${theme.colors.grayscale.white};
    `}

    ${({ theme, current }) =>!current && theme.mediaQuery.md(`
      transition: background-color ease-in-out .6s;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 50%, rgba(0, 0, 0, 0.00) 100%);
      color: ${theme.colors.grayscale.white};

      &:hover{
        border-color: ${theme.colors.general.light()};
        background: linear-gradient(90deg, rgba(25,64,116, 0.70) 0%, rgba(25,64,116, 0.7) 100%);
      }
    `)}
  }
`
export const StyledTabFilterList = styled.div`
  .swiper{
    ${({ theme }) => theme.paddingX(24)};
  }


  ${({ theme }) => theme.mediaQuery.md(`
    .swiper{
      --col-min: calc(1 / 12 * 100vw);
      --col: calc((100vw - var(--max-width)) / 2);
      padding-left: clamp(var(--col-min),var(--col),var(--col));
      padding-right: clamp(var(--col-min),var(--col),var(--col));
    }
  `)}

.swiper-slide{
    width: fit-content;
  }
`
