import React from 'react'

import { StyledFieldError } from './styles'

const FieldError = ({
  error, touched
}) => (
  error && touched
    ? (
      <StyledFieldError className="error">
        <span>{error}</span>
      </StyledFieldError>
    )
    : null
)

export default FieldError
