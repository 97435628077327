import styled from 'styled-components'

import {
  Heading, Label
} from '@/atoms/Typography'

import {
  StyledCard,
  StyledCardContainer,
  commonRoundedCardStyle
} from '@/molecules/Card/Cards/styles'
import CardMedia from '@/molecules/Card/CardMedia'
import { StyledGraphicAnimated } from '@/atoms/GraphicAnimated/styles'

export const StyledProductCardMedia = styled(CardMedia)`
  aspect-ratio: 1;
  overflow: hidden;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};
  transition: box-shadow ${({ theme }) => theme.timing.normal} ${({ theme }) => theme.ease}, border-color ${({ theme }) => theme.timing.normal} ${({ theme }) => theme.ease};

  img{
    object-fit: contain;
    max-width: 72%;
    max-height: 72%;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    transition: filter ${({ theme }) => theme.timing.normal} ${({ theme }) => theme.ease};
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
  }
`


export const StyledProductCardName = styled.div`
  padding: 0 ${({ theme }) =>theme.rem(8)};
  color: ${({ theme }) => theme.colors.general.light()};

  ${({ theme }) => theme.mediaQuery.md(`
    text-align: center;
  `)}
`

export const StyledProductCard = styled(StyledCard)`

  ${({
    theme, grid
  }) => grid && `
    flex-direction: row;
    ${StyledProductCardMedia}{
      flex-shrink: 0;
      ${theme.size(120)};
    }

    ${StyledCardContainer}{
      padding: ${theme.rem(8)}
    }
    ${StyledProductCardName}{
      padding:0;
    }
  `}
  ${({
    theme, grid
  }) => grid && theme.mediaQuery.md(`
    flex-direction: column;
    ${StyledProductCardMedia}{
      ${theme.size('auto')};
    }
  `)};

  ${commonRoundedCardStyle};

  .swiper-slide-active &,
  &:focus{
    ${StyledProductCardMedia}{
      border-color: transparent;
      box-shadow: ${({ theme }) => theme.shadows.hover};
    }
    ${Heading}{
      text-decoration: underline;
    }
  }

  ${({ theme }) => theme.mediaQuery.lg(`
    &:hover {
      ${StyledGraphicAnimated}{
        svg{
          opacity: 1;
          transform: scale(1);
        }
      }

      ${StyledProductCardMedia}{
        border-color: transparent;
        box-shadow: ${theme.shadows.hover};

        img{
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.35));
        }
      }

      ${Heading}{
        text-decoration: underline;
      }
    }
  `)}
`

export const StyledProductCardLines = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.rem(8)} ${({ theme }) => theme.rem(16)};

  ${({ theme }) => theme.mediaQuery.md(`
    justify-content: center;
  `)}

  ${Label}:not(:last-child){
    position: relative;
    &::after{
      content: '';
      position: absolute;
      right: -8px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.general.light()};
      ${({ theme }) => theme.size(2)};
    }
  }
`

export const StyledProductCardContainer = styled.div`
  ${({ theme }) => theme.paddingY(8)}
  ${({ theme }) => theme.mediaQuery.md(`
    padding: 0;
  `)}
  > * +*{
    margin-block-start: ${({ theme }) => theme.rem(8)};
  }
`
