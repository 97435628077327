import styled from 'styled-components'
import Button from '../Button'
export const StyledVideoPlayer = styled.div`
  overflow: hidden;
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius:  ${({ theme }) => theme.rem(24)};
  border: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};
  position: relative;

`

export const StyledVideoPlayerPreview = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;

  img{
    object-fit: cover;
  }
`

export const StyledVideoPlayerContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  left: 0;

 > div {
    position: relative;
    width: 100%;
    height: 100%;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

`

export const StyledVideoPlayerPreviewIcon = styled(Button)`
  aspect-ratio: 1 / 1;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ theme }) => theme.size(100)};
  ${({ theme }) => theme.mediaQuery.md(`
    ${theme.size(140)};
  `)};

  i{
    font-size: ${({ theme }) => theme.rem(40)} !important;

    ${({ theme }) => theme.mediaQuery.md(`
      font-size: ${theme.rem(56)} !important;
    `)};
  }
`
