import styled from 'styled-components'
import Link from 'next/link'

export const StyledLogo = styled.div`
  height: 100%;
  width: 80px;
  flex-shrink: 0;

  img{
    width: 100%;
    height: 100%;
  }
`

export const StyledLogoWrapper = styled(Link)`
  position: relative;
  display: block;
  ${({ theme }) => theme.size('100%')};
`
