import React from 'react'
import { useField  } from 'formik'

import CustomField from './index'

const Textarea = ({
  label,
  dependsOn,
  hideLabel,
  ...props
}) => {
  const [field, meta] = useField(props)
  return (
    <CustomField
      id={props.id}
      type={props.type}
      label={label}
      field={field}
      meta={meta}
      dependsOn={dependsOn}
      hideLabel={hideLabel}
    >
      <textarea id={field.name} {...field} {...props} />
    </CustomField>
  )
}

export default Textarea
