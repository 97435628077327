import React from 'react'

export default () => (
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M41.8437 26.2813L25.9375 10.375Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M25.0536 9.49117C25.5418 9.00301 26.3332 9.00301 26.8214 9.49117L42.7276 25.3974C43.2157 25.8855 43.2157 26.677 42.7276 27.1651C42.2394 27.6533 41.448 27.6533 40.9598 27.1651L25.0536 11.2589C24.5655 10.7708 24.5655 9.97932 25.0536 9.49117Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M26.7188 10L26.7188 42C26.7188 42.6904 26.1591 43.25 25.4688 43.25C24.7784 43.25 24.2188 42.6904 24.2188 42L24.2188 10C24.2188 9.30964 24.7784 8.75 25.4688 8.75C26.1591 8.75 26.7188 9.30964 26.7188 10Z" fill="#194074"/>
    <path d="M19.5312 16.7501L10 26.2812Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.11612 27.1651C8.62796 26.677 8.62796 25.8855 9.11612 25.3974L18.6473 15.8662C19.1355 15.378 19.927 15.378 20.4151 15.8662C20.9033 16.3543 20.9033 17.1458 20.4151 17.6339L10.8839 27.1651C10.3957 27.6533 9.60427 27.6533 9.11612 27.1651Z" fill="#194074"/>
  </svg>

)
