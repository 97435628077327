import React, { useState } from 'react'
import { StyledAccordionList } from './styles'
import Accordion from './Accordion'

const AccordionList = ({
  items,
  isFaq = false
}) => {
  const [expanded, setExpanded] = useState(null)

  const accordionListClassName = isFaq ? 'FaqList' : false

  return (
    items &&
    !!items.length &&
    <StyledAccordionList className={accordionListClassName}>
      {
        items.map((item, index) => (
          <Accordion
            key={index}
            i={index}
            expanded={expanded}
            setExpanded={setExpanded}

            data={item}
          />
        ))
      }
    </StyledAccordionList>
  )
}

export default AccordionList
