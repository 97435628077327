import styled from 'styled-components'

export const StyledTimer = styled.button`
  ${({ theme }) => theme.size(56)};

  position: relative;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;

  &::before, &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    width: 50%;
    height: 1;
    background-color: ${({ theme }) => theme.colors.grayscale.white};
  }

  &::before { transform: translate(-50%, -50%) rotate(45deg); }
  &::after { transform: translate(-50%, -50%) rotate(-45deg); }

  svg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  i{
    font-size:  ${({ theme }) => theme.rem(24)};
    color: ${({ theme }) => theme.colors.grayscale.white};
  }
`
