import React, { useMemo } from 'react'
import { StyledImage } from './styles'

const CustomImage = ({
  data = {},
  format = null,
  fill = false,
  lazyload = true,
  ...rest
} = {}) => {
  const {
    alt,
    sizes,
    width,
    height,
    url,
  } = data

  const imageUrl = useMemo(() => {
    return sizes && sizes[format] ? sizes && sizes[format] : { url }
  }, [format, data])

  const imageProps = useMemo(() => {
    let props = {
      width,
      height,
      loading: 'lazy',
    }
    if (lazyload === false) {
      delete props.loading
      props.priority = true
    }

    if (fill) props = {}

    return props
  }, [lazyload, fill])


  return (
    <StyledImage
      src={imageUrl.url}
      alt={alt}
      fill={fill}
      {...imageProps}
      {...rest}
    />
  )
}


export default CustomImage
