import React from 'react'

import CustomImage from '@/atoms/CustomImage'

import {
  StyledLogo,
  StyledLogoWrapper
} from './styles'

const Logo = ({
  className = null,
  image,
  href = '',
  fill = false,
  brand = '',
}) => {
  return (
    image &&
    <StyledLogo className={className}>
      <StyledLogoWrapper href={href} aria-label={brand}>
        <CustomImage data={image} fill={fill}/>
      </StyledLogoWrapper>
    </StyledLogo>
  )
}

export default Logo
