export default () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.6626 5.25H18.2251V12.8438L13.1626 15.9375L8.6626 13.4062V5.25Z" fill="#CFD4DB" className="branded-content"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.03062 9.07947L7.48217 9.858L4.8939 11.3592V20.2687H20.8689V11.3592L18.2806 9.858L18.7322 9.07947L21.7689 10.8408V21.1687H3.9939V10.8408L7.03062 9.07947Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.8064 3.39374H18.9564V12.6187H18.0564V4.29374H7.7064V12.675H6.8064V3.39374Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M21.0961 21.1097L4.22107 11.4909L4.66675 10.709L21.5418 20.3278L21.0961 21.1097Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.4429 15.3726L21.1054 10.7039L21.5324 11.4961L12.8699 16.1649L12.4429 15.3726Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.6189 6.76874H16.1439V7.66874H9.6189V6.76874Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.6189 9.58124H16.1439V10.4812H9.6189V9.58124Z" fill="#194074"/>
  </svg>
)
