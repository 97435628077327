import styled from 'styled-components'

import {
  StyledCardMedia,
  StyledMediaIcon,
} from '@/molecules/Card/CardMedia/styles'
import { StyledRoundedIcon } from '@/molecules/RoundedIcon/styles'
import { StyledCard } from '@/molecules/Card/Cards/styles'

import styledMap from 'styled-map'
import { Heading } from '@/atoms/Typography'

export const StyledDefaultCardName = styled.span`
  color: ${({ theme }) => theme.colors.general.light()};
`

const activeCardStatus = (theme) => `
  ${StyledCardMedia}{
    border-radius: ${theme.rem(16)} ${theme.rem(16)} ${theme.rem(100)};

    ${theme.mediaQuery.md(`
      border-radius: ${theme.rem(24)} ${theme.rem(24)} ${theme.rem(100)};
    `)}

    ${StyledMediaIcon}{
      transform: translate(-15%, 10%);
    }
  }

  ${StyledDefaultCardName}{
    > ${Heading}{
      text-decoration: underline;
    }
  }
`


export const StyledDefaultCard = styled(StyledCard)`
  display: flex;
  flex-direction: ${styledMap`
    default: column;
    row: row;
  `};

  &:hover,
  &:focus {
    ${StyledDefaultCardName}{
      > ${Heading}{
        text-decoration: underline;
      }
    }
  }

  ${({
    row,
    theme
  }) => row && `
    align-items: center;
    gap: ${theme.rem(16)};

    ${theme.mediaQuery.md(`
      gap: ${theme.rem(24)};
    `)}

    ${StyledCardMedia}{
      ${theme.size(122)};
      ${theme.mediaQuery.md(`
        ${theme.size(180)};
      `)}
    }
  `}


  ${({
    row,
    theme
  })=> !row && `
    ${StyledCardMedia}{
      will-change: box-shadow, border-radius;
      transition: box-shadow ease-in-out .6s, border-radius ease-in-out .6s,  transform ease-in-out .6s;

      ${StyledMediaIcon}{
        will-change: transform;
        transition: transform ease-in-out .6s;
      }
    }


    &:hover,
    &:focus {
      ${activeCardStatus(theme)}

      ${StyledCardMedia}{
        transform: scale(1.01);
        box-shadow: ${theme.shadows.hover};
      }
    }
  `}
`

export const StyledDefaultCardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) =>theme.rem(16)};
  ${({
    theme,
    icon
  }) => !!icon && `
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.rem(16)};
  `}

  > ${StyledRoundedIcon}{
    i{
      font-size: ${({ theme }) => theme.rem(40)}
    }
  }
`

export const StyledDefaultCardContent = styled.div`

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(8)};
`
export const StyledDefaultCardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(16)};
`

