import React from 'react'
import { useRouter } from 'next/router'

import Icon from '@/atoms/Icon'
import { Label } from '@/atoms/Typography'

import { isCurrent } from '../utils'
import {
  StyledSingleNavigation,
  StyledSingleNavigationList,
  StyledSingleNavigationListItem,
  StyledSingleNavigationLink,
} from './styles'

const SingleNavigation = ({
  title,
  list,
}) => {
  const router = useRouter()

  return (
    <StyledSingleNavigation>
      <Label as="strong" typo="labelSmall">{title}</Label>

      {
        list &&
        !!list.length &&
        <StyledSingleNavigationList>
          {
            list.map(({
              id,
              title: label,
              href
            }) => (
              <StyledSingleNavigationListItem
                key={id}
              >
                <StyledSingleNavigationLink
                  typo="link2"
                  href={href}
                  bold
                  aria-label={label}
                  current={isCurrent(router.asPath, href)}
                >
                  {label}
                  <Icon name="chevron-right" />
                </StyledSingleNavigationLink>
              </StyledSingleNavigationListItem>
            ))
          }
        </StyledSingleNavigationList>
      }
    </StyledSingleNavigation>
  )
}

export default SingleNavigation
