import React from 'react'

import { StyledSectionBuilder } from './styles'

import Section from '@/organisms/Section'
import Components from '@/organisms'

const SectionBuilder = ({ data }) => (
  <StyledSectionBuilder
    id='main-content'
  >
    {
      data &&
      !!data.length &&
      data.map(props=> {
        const componentName = props.type
        const fallBack = process.env.NODE_ENV === 'development' ? <>{componentName} not found</> : <></>
        const Component = Components[componentName]

        return (
          Component ?
            <Section
              key={`${componentName}_${props.uuid}`}
              type={props.type}
              bgp={props.bgp}
              style={props.style}
              fill={props.fill}
              withBgColor={props.withBgColor}
              isLast={props.isLast}
              decoration={props?.data?.decoration}
            >
              <Component {...props.data} />
            </Section>
            : fallBack
        )
      })
    }
  </StyledSectionBuilder>
)

export default SectionBuilder
