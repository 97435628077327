import styled from 'styled-components'

import { Heading } from '@/atoms/Typography'

export const StyledSeoParagraph = styled.article`
  ${({ theme }) => theme.mediaQuery.md(`
    display: block;
    margin: 0 auto;
    max-width: ${theme.col(6)};
  `)}
`

export const StyledSeoParagraphContent = styled.div`
  > * + * {

  }
`

export const StyledSeoParagraphContentExcerpt = styled.div`
  color: ${({ theme }) => theme.colors.general.light()};
  margin-bottom:  ${({ theme }) => theme.rem(40)};

  ${Heading}{
    text-align: center;
  }

  > *+*{
    margin-block-start:  ${({ theme }) => theme.rem(24)};
  }
`

export const StyledSeoParagraphContentExpanded = styled.div`
  color: ${({ theme }) => theme.colors.general.light()};

`

