const isValidUrl = urlString=> {
  try {
    return Boolean(new URL(urlString))
  } catch (e) {
    return false
  }
}

export const isCurrent = (currentHref, linkHref) => {
  if (!linkHref || linkHref === '#' || !currentHref) return false

  if (!isValidUrl(linkHref)) {
    return currentHref === linkHref
  }

  const url = new URL(linkHref)
  let linkHrefToCheck = url.pathname.replace(/\/$/, '')

  if (!!url.search) linkHrefToCheck = `${linkHrefToCheck}${url.search}`

  return currentHref === linkHrefToCheck
}
