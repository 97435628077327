import React from 'react'
import Link from 'next/link'

import CustomImage from '@/atoms/CustomImage'
import ChefPeopleLogo from '@/atoms/ChefPeopleLogo'

import MediaIcon from './MediaIcon'
import GraphicAnimated from '@/atoms/GraphicAnimated'

import { StyledCardMedia, } from './styles'

const CardMedia = ({
  image,
  ratio,
  withHover,
  icon = null,
  href,
  withAnimation = false,
  chefPeople,
  ...props
}) =>(
  <StyledCardMedia
    as={href ? Link : 'div'}
    href={href}
    ratio={ratio}
    withHover={withHover}
    {...props}
  >
    {
      image &&
      <CustomImage fill data={image} />
    }
    {
      chefPeople &&
      <ChefPeopleLogo />
    }

    {
      withAnimation &&
      <GraphicAnimated />
    }

    {
      icon &&
      <MediaIcon {...icon} />
    }
  </StyledCardMedia>
)

export default CardMedia
