import styled from 'styled-components'
import styledMap from 'styled-map'

import Button from '@/atoms/Button'

import {
  openedStatus,
  openedGraphic,
  currentGraphic,
} from '@/organisms/Header/styles'

export const StyledSingleItem = styled.li`
  > *{
    width: 100%;
    justify-content: space-between;
    i{
      font-size: ${({ theme }) =>theme.rem(16)} !important;
    }
  }

  ${({ theme }) => theme.mediaQuery.lg(`
    position: relative;
    height: 100%;
  `)}
`

export const StyledSingleItemLink = styled(Button)`
  position: relative;
  background-color: transparent;
  padding:0;
  border: none;
  height: 100%;
  padding-left: ${({ theme }) => styledMap`
    default: 0;
    current: ${theme.rem(8)};
  `};

  ${({ current }) => current ? currentGraphic : ''};

  ${openedGraphic};
  ${({
    theme,
    opened
  }) => theme.mediaQuery.lg(`
    display: grid;
    place-items: center;
    margin-right: 0;

    &:hover{
      ${openedStatus};
    }

    ${opened ? openedStatus : ''};
    i{
      display: none;
    }
  `)}
`
