import React from 'react'
import { StyledRichtext } from './styles'

const Richtext = ({
  children,
  ...props
}) => (
  <StyledRichtext
    {...props}
    data-richtext-wrapper
    dangerouslySetInnerHTML={{ __html: children }}
  />
)


export default Richtext
