import dynamic from 'next/dynamic'
import React, { useState, } from 'react'

import { fetchAlgoliaData } from 'api'
import { GridAlgolia } from 'models/Grid'

import CheckboxFilterHeader from './Header'

const FiltersResults = dynamic(import('@/molecules/Filters/Results'))

const CheckboxFilter = ({
  items,
  checkPrefill = false,
  async,
  cta = {},
  showResults = false,
  contentType = null,
}) => {
  const [results, setResults] = useState([])
  const [error, setError] = useState(null)
  const [totalItems, setTotalItems] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(0)
  const [fetchBody, setFetchBody] = useState({})

  const getData = async ({
    additionalBodyData = {},
    page = 0,
    replace = true,
  }) => {
    const body = {
      ...fetchBody,
      ...additionalBodyData
    }

    body.page = page

    const query = new URLSearchParams(window.location.search).get('s')
    const algoliaFetchParams = {
      body,
      type: contentType,
    }

    if (query) {
      body.query = query
    }

    const { data } = await fetchAlgoliaData(algoliaFetchParams)

    const { list, } = GridAlgolia(data)

    if (!replace) {
      setResults(oldItems =>[
        ...oldItems,
        ...list
      ]
      )
    } else {
      setResults(list)
    }

    setCurrentPage(data.page)
    setFetchBody(body)

    return data
  }

  return (
    <>
      <CheckboxFilterHeader
        items={items}
        checkPrefill={checkPrefill}
        contentType={contentType}
        async={async}
        cta={cta}
        handlePayload={getData}
        totalPages={totalPages}
        setTotalPages={setTotalPages}
        setTotalItems={setTotalItems}
        setResults={setResults}
        setError={setError}
      />

      {
        showResults &&
        <FiltersResults
          results={results}
          error={error}
          getData={getData}
          currentPage={currentPage}
          totalItems={totalItems}
          totalPages={totalPages}
        />
      }

    </>
  )
}

export default CheckboxFilter
