import styled, { css } from 'styled-components'
import styledMap from 'styled-map'

export const helpers = css`
  font-weight: ${styledMap`
    bold: 600;
    default: 300;
  `};
  text-align: ${styledMap`
    center: center;
    right: right;
    default: inherit;
  `};
  font-family: ${({ theme }) => styledMap`
    default: ${theme.fonts.main};
  `};

  ${({ uppercase }) => uppercase && 'text-transform: uppercase;' };
`

const base = css`
  ${helpers}
  font-style: normal;
`

export const ButtonLabel = styled.span`
  ${base};
  z-index: 1;

  margin-left: ${styledMap`
    default: 0;
    iconStart: ${({ theme }) => theme.rem(8)};
  `};

  margin-right: ${styledMap`
    default: 0;
    iconEnd: ${({ theme }) => theme.rem(8)};
  `};

  ${({
    theme,
    small,
    typo
  }) =>{
    if (typo) return null
    if (small) return theme.typo('buttonRegular')
    return theme.typo('buttonBig')
  }};

  ${({
    theme, typo
  }) => theme.typo(typo || 'button1')};
`

export const LinkLabel = styled.span`
  ${base};
  ${({
    theme, typo
  }) => theme.typo(typo || 'link1')};
`

export const Heading = styled.div`
  ${base};
  ${({
    theme, as
  }) => as && theme.typo(as)};
  ${({
    theme, typo
  }) => theme.typo(typo || 'displaySM')};
`

export const Paragraph = styled.p`
  ${base};
  ${({
    theme, typo
  }) => theme.typo(typo || 'paragraphLarge')};

  strong {
    font-weight: 700;
  }
`

export const StyledLink = styled.a`
  ${base};
  ${({
    theme, typo
  }) => theme.typo(typo || 'link1')};
`

export const Label = styled.div`
  ${base};
  ${({
    theme, typo
  }) => theme.typo(typo || 'labelBig')};
`
