import React from 'react'

export default () => (
  <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M179.647 68.2C189.519 96.3195 181.563 132.536 155.926 153.147C130.289 173.758 86.9719 178.911 56.6203 158.742C26.2687 138.425 8.73555 92.7861 20.3752 62.1639C32.1622 31.3944 73.1221 15.4944 107.599 17.7027C142.076 19.7638 169.776 39.9333 179.647 68.2Z" fill="#FAF7ED"/>
    <path d="M138.928 179.951C106.165 194.194 53.0365 179.951 33.2608 149.41C13.4851 118.869 26.9149 72.029 51.8558 41.4877C76.7969 10.9464 113.249 -3.00278 139.223 6.39454C165.345 15.645 180.988 48.242 182.612 85.8313C184.235 123.274 171.691 165.855 138.928 179.951Z" fill="#FAF7ED"/>
    <g style={{ 'mix-blend-mode': 'multiply' }} opacity="0.41">
      <path d="M149.312 83.2306C147.778 73.7024 143.183 64.6305 135.651 57.6719C135.064 57.1316 134.465 56.5972 133.842 56.0809C114.998 40.4347 86.9633 43.0764 71.355 61.9587C70.8398 62.5831 70.3484 63.2196 69.8691 63.862C63.7636 72.1114 60.9356 81.8798 61.1992 91.5281C61.5407 103.908 66.9811 116.084 77.2268 124.58C78.0896 125.294 78.9764 125.961 79.8752 126.597C97.7183 139.289 122.32 136.978 137.491 121.169C138.252 120.371 139.001 119.554 139.72 118.69C148.204 108.429 151.283 95.4486 149.318 83.2246L149.312 83.2306Z" fill="#FAF7ED"/>
    </g>
    <g style={{ 'mix-blend-mode': 'multiply' }} opacity="0.21">
      <path d="M149.312 83.2317C147.778 73.7034 143.183 64.6315 135.651 57.673C135.064 57.1326 134.465 56.5982 133.842 56.0819C123.003 47.088 109.126 44.1461 96.3162 46.8599C103.099 48.3008 109.642 51.3508 115.346 56.0819C115.969 56.5982 116.568 57.1266 117.155 57.673C124.687 64.6375 129.282 73.7034 130.816 83.2317C132.781 95.4557 129.702 108.436 121.217 118.697C120.504 119.561 119.756 120.378 118.989 121.176C112.655 127.775 104.675 132.014 96.2324 133.797C110.852 136.913 126.592 132.518 137.485 121.176C138.246 120.378 138.995 119.561 139.714 118.697C148.198 108.436 151.278 95.4557 149.312 83.2317Z" fill="#98D1F1"/>
    </g>
    <path d="M143.824 122.1C161.337 100.906 158.396 69.5058 137.245 51.9504C116.095 34.4009 84.7584 37.3488 67.2388 58.5427C49.7252 79.7365 52.6671 111.137 73.8176 128.693C94.9681 146.242 126.304 143.294 143.824 122.1ZM79.8752 126.597C78.9764 125.961 78.0897 125.294 77.2269 124.58C66.9872 116.078 61.5408 103.902 61.1992 91.5282C60.9356 81.8799 63.7637 72.1115 69.8691 63.8621C70.3425 63.2197 70.8398 62.5833 71.3551 61.9589C86.9633 43.0766 114.998 40.4348 133.842 56.0751C134.465 56.5914 135.064 57.1197 135.651 57.6661C143.183 64.6306 147.778 73.6966 149.312 83.2248C151.278 95.4488 148.198 108.429 139.714 118.69C139.001 119.555 138.252 120.371 137.485 121.17C122.314 136.972 97.7123 139.289 79.8692 126.597H79.8752Z" fill="#194074"/>
    <path d="M78.2494 129.034L72.6657 124.4L36.9373 167.631L42.521 172.265L78.2494 129.034Z" fill="#194074"/>
    <path d="M69.2124 148.219C72.3403 144.434 71.8141 138.825 68.0371 135.691C64.2601 132.557 58.6625 133.084 55.5346 136.869L18.2928 181.93C15.1649 185.715 15.691 191.324 19.468 194.459C23.2451 197.593 28.8426 197.066 31.9706 193.281L69.2124 148.219Z" fill="#98D1F1"/>
  </svg>

)
