import React from 'react'

import Button from '@/atoms/Button'

const ShareButton = (props) => {
  const onClick = () => {
    navigator.share({
      url: document.URL,
      title: document.title,
      text: document.title
    })
  }

  return (
    <Button {...props} handleClick={onClick} />)
}

export default ShareButton
