import styled from 'styled-components'

import { StyledSingleItem } from '../SingleItem/styles'

export const StyledSecondaryNavigation = styled.div`
  --offset: 65px;
  position: fixed;
  display: none;
  top: var(--offset);
  left: 0;
  z-index: 2;
  overflow: auto;
  ${({ theme }) => theme.size(['100%', 'calc(100% - var(--offset))'])};
  background-color: ${({ theme }) => theme.colors.grayscale.white};

  ${({ theme, }) => theme.mediaQuery.md(`
    --offset: 146px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  `)}

  ${({ theme, }) => theme.mediaQuery.lg(`
    padding: ${theme.rem(24)};
    ${theme.size('auto')};
    top: 100%;
    left: -24px;
    border-top: 1px solid ${theme.colors.grayscale.gray200};
    border-bottom-right-radius: ${theme.rem(16)};
    border-bottom-left-radius: ${theme.rem(16)};
    box-shadow: ${theme.shadows.low(8)};
  `)};
`

export const StyledSecondaryNavigationList = styled.ul`
  display: grid;
  gap: ${({ theme }) => theme.rem(24)};
  padding: ${({ theme }) => theme.rem(24)};

  ${({
    theme,
    cols,
    x
  }) => theme.mediaQuery.lg(`
    gap: ${theme.rem(24)} ${theme.rem(40)};
    padding: 0;
    grid-template-columns: ${cols < 6 ? `repeat(${cols}, 1fr);` : 'repeat(auto-fill, minmax(130px, 1fr));'};
    width: ${cols < 6 ? 'auto' : `clamp(500px, ${x}px,60vw )`};
    max-width: calc(170px * ${cols});
  `)};

  ${StyledSingleItem}:not(:last-child){
    padding-bottom: ${({ theme })=> theme.rem(24)};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};

    ${({ theme }) => theme.mediaQuery.lg(`
      padding-bottom: 0;
      border-bottom: none;
    `)}
  }
`

export const StyledSecondaryNavigationCta = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  border-top: 1px solid  ${({ theme }) => theme.colors.grayscale.gray200};
  ${({ theme }) => theme.paddingY([16, 24])};
  ${({ theme }) => theme.paddingX(24)};

  a{
    width: 100%;
  }

  ${({ theme }) => theme.mediaQuery.lg(`
    margin-top:  ${theme.rem(24)};
    ${theme.paddingY([24, 0])};
    ${theme.paddingX(0)};
    position: static;

    a{
      width: auto;
    }
  `)}
`

export const StyledSecondaryNavigationHeader = styled.div`
  display: grid;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.rem(16)};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  padding: ${({ theme }) => theme.rem(24)} ${({ theme }) => theme.rem(24)} 0;
  color: ${({ theme }) => theme.colors.general.light()};

  i{
    font-size: ${({ theme }) => theme.rem(16)} !important;
  }
`
