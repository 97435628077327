import React from 'react'
import useIsMobile from 'hooks/useIsMobile'

import {
  Swiper,
  SwiperSlide,
} from 'swiper/react'


import RecipeCard from '@/molecules/Card/Cards/RecipeCard'
import WithDecorationButton from '@/molecules/Buttons/WithDecorationButton'

import {
  StyledMosaicRecipe,
  StyledMosaicRecipeWrapper,
  StyledMosaicRecipeCta,
  StyledMosaicRecipeCols
} from './styles'

const MosaicRecipe = ({
  list,
  columnList,
  cta
}) => {
  const isMobile = useIsMobile()

  return (
    <StyledMosaicRecipe>
      {
        list &&
        list.length &&
        <StyledMosaicRecipeWrapper>
          {
            !isMobile
              ? (
                columnList.map((col, cIdx) => (
                  !!col.length &&
                  <StyledMosaicRecipeCols key={cIdx}>
                    {col.map(item=> <RecipeCard key={item.id} item={item} imageRatio={1} />)}
                  </StyledMosaicRecipeCols>
                ))
              )
              : (
                <Swiper
                  spaceBetween={24}
                  slidesOffsetBefore={24}
                  slidesOffsetAfter={24}
                  slidesPerView={'auto'}
                >
                  {
                    list.map((item, i) => (
                      <SwiperSlide key={i}>
                        <RecipeCard key={item.id} item={item} imageRatio={1} />
                      </SwiperSlide>
                    ))
                  }
                </Swiper>
              )
          }
        </StyledMosaicRecipeWrapper>
      }
      {
        !!cta.link &&
        <StyledMosaicRecipeCta>
          <WithDecorationButton keyword={cta.keyword} href={cta.link.href} label={cta.link.title} />
        </StyledMosaicRecipeCta>
      }

    </StyledMosaicRecipe>
  )
}

export default MosaicRecipe
