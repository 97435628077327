import styled from 'styled-components'

import { StyledBoxedImage } from '@/molecules/BoxedImage/styles'
import {
  StyledHero,
  StyledHeroContainer,
  StyledHeroPart,
} from '../styles'

export const StyledHeroRecipe = styled(StyledHero)`

  > nav{
    margin-bottom:  ${({ theme }) => theme.rem(24)};
  }

  ${StyledBoxedImage}{
    margin-left: auto;
    width: 100%;
    ${({ theme }) => theme.mediaQuery.md(`
      ${theme.size(540, true)}
    `)}
  }

  ${StyledHeroContainer}{
    flex-direction: column-reverse;

    ${({ theme }) => theme.mediaQuery.md(`
      margin-bottom:  ${theme.rem(128)};
      ${StyledHeroPart}:first-of-type{
        margin-top:  ${theme.rem(16)};
      }
    `)};

  }

  ${({ theme }) => theme.mediaQuery.md(`
    > nav{
      margin-bottom:  ${theme.rem(24)};
    }
  `)}
`

export const StyledHeroRecipeProduct = styled.div`
  margin-top: ${({ theme }) => theme.rem(40)};
  ${({ theme }) => theme.mediaQuery.md(`
    margin-top:${theme.rem(70)}
  `)};
`
