import React from 'react'

import SectionBackground from '@/molecules/SectionBackground'

import {
  StyledSection,
  StyledSectionContent,
} from './styles'

const Section = ({
  type,
  bgp = false,
  style,
  fill = false,
  withBgColor = true,
  isLast,
  children,
  decoration = false,
}) => {
  const props = {
    style,
    isLast,
    'data-bg-lastSection': isLast
  }

  if (bgp && !!bgp.length) {
    props['data-bg'] = true
    bgp.forEach(bg => {
      props[`data-bg-${bg}`] = true
    })
  }


  return (
    <StyledSection
      data-section={type}
      data-fill={fill}
      {...props}
    >

      <StyledSectionContent
        fill={fill}
      >
        {children}
      </StyledSectionContent>

      <SectionBackground decoration={decoration} isLast={isLast} bgp={bgp} withBgColor={withBgColor}/>
    </StyledSection>
  )
}

export default Section
