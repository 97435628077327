import styled from 'styled-components'
import styledMap from 'styled-map'
import Link from 'next/link'

import { helpers } from '@/atoms/Typography'


export const StyledLink = styled(Link)`
  ${helpers};

  color:${({ theme }) => styledMap`
    default: ${theme.colors.general.light()};
    white: ${theme.colors.grayscale.white};
    accent:${theme.colors.accent()};
    background:${theme.colors.background()};
    medium:${theme.colors.medium()};
  `};
`
