import React from 'react'

import Richtext from '@/atoms/RichText'
import Button from '@/atoms/Button'

import HeadingWithMark from '@/molecules/HeadingWithMark'

import { StyledHeroContent } from './styles'

const HeroContent = ({
  title,
  description,
  cta
}) => {
  return (
    <StyledHeroContent>
      {
        title &&
        <HeadingWithMark as="h1" bold typo="heading1" content={title} />
      }

      {
        description &&
        <Richtext as="div" typo="paragraphLarge">
          {description}
        </Richtext>
      }
      {
        cta &&
        !!cta.link &&
        <Button general big
          className={cta.className}
          keyword={cta.keyword}
          {...cta.icon}
          {...cta.link}
          label={cta.link.title}/>
      }
    </StyledHeroContent>
  )
}

export default HeroContent
