import styled from 'styled-components'

export const StyledEditorial = styled.div`
  &[data-position="dx"],
  &[data-position="sx"] {
    display: flex;
    gap: ${({ theme }) => theme.rem(16)};
    flex-direction: column;
    ${({ theme }) => theme.mediaQuery.md(`
      gap: calc(100vw / 12 * 1);
      align-items: flex-start;
      > div {
        width: 50%;
      }
    `)}
  }

  ${({ theme }) => theme.mediaQuery.md(`
    &[data-position="dx"]{
      flex-direction: row-reverse;
    }

    &[data-position="sx"] {
      flex-direction: row;
    }
  `)}


  /* &[data-position="sx"] {
    display: flex;
    gap: calc(100vw / 12 * 1);
    flex-direction: column;
    ${({ theme }) => theme.mediaQuery.md(`
      align-items: flex-end;
      flex-direction: row;
      > div {
        width: 50%;
      }
    `)}
  }

  &[data-position="dx"] {
    display: flex;
    gap: calc(100vw / 12 * 1);
    flex-direction: column;
    ${({ theme }) => theme.mediaQuery.md(`
      align-items: flex-end;
      flex-direction: row-reverse;
      > div {
        width: 50%;
      }
    `)}
  } */

  &[data-position="cntr"] {
    display: flex;
    gap: ${({ theme }) =>theme.rem(24)};
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 auto;

    ${({ theme }) => theme.mediaQuery.md(`
      max-width: ${theme.col(8)}
    `)}

    ${({ theme }) => theme.mediaQuery.lg(`
      max-width: ${theme.col(6)}
    `)}
  }
`

export const StyledEditorialTitle = styled.div`
  color: ${({ theme }) => theme.colors.general.light()};
`


export const StyledEditorialCta = styled.div`
  margin-top: ${({ theme }) =>theme.rem(16)};

  ${({ theme }) => theme.mediaQuery.md(`
    margin-top: ${theme.rem(24)};
  `)}
`
