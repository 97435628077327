import React from 'react'

import TabFilter from './Tab'
import LineFilter from './Line'
import CheckboxFilter from './Checkbox'
import Cluster from './Cluster'

const allowedFilterContentElement = {
  Tab: TabFilter,
  Line: LineFilter,
  Checkbox: CheckboxFilter,
}

import {
  StyledFilters,
  StyledFiltersItems,
} from './styles'

const Filters = ({
  items,
  actions = {}
}) =>  (
  items &&
  !!items.length &&
  <StyledFilters>
    <StyledFiltersItems>
      {
        items.map(({
          label,
          type,
          items: filterItems,
          checkPrefill,
          async = false,
          cta,
          showResults,
          contentType,
        }, idx) => {
          const FilterContentElement = allowedFilterContentElement[type] || `No filter type ${type} found`
          let functions = false
          if (FilterContentElement && actions[type]) {
            functions = actions[type]
          }

          return (
            <Cluster
              key={idx + label + type}
              label={label}
              type={type}
              withLine
              isLast={idx === items.length - 1}
            >
              <FilterContentElement
                type={type}
                items={filterItems}
                checkPrefill={checkPrefill}
                contentType={contentType}
                showResults={showResults}
                cta={cta}
                async={async}
                actions={functions}
              />
            </Cluster>
          )
        })
      }
    </StyledFiltersItems>
  </StyledFilters>
)

export default Filters
