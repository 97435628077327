/**
 * Accessibility specifications
 *
 * @url Accessibility https://www.w3.org/WAI/ARIA/apg/patterns/breadcrumb/
 */

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'

import Icon from '@/atoms/Icon'

import {
  StyledBreadcrumbsList,
  StyledBreadcrumbsListItem,
  StyledBreadcrumbsItem,
  StyledBreadcrumbsBack
} from './styles'

const Breadcrumbs = ({ items }) => {
  const previousPageLink = useMemo(() => {
    if (!items || items.length === 0) return false
    const lastItemIdx = items.length - 1
    const prevItemBeforeLast = lastItemIdx - 1
    return items[prevItemBeforeLast]
  }, [items])

  return (
    items &&
    !!items.length &&
    <nav aria-label="Breadcrumbs">
      <StyledBreadcrumbsList>
        {
          items.map(({
            href,
            label,
            current
          }) => {
            const itemProps = {
              as: Link,
              href,
              current,
            }

            if (current) Object.assign(itemProps, { 'aria-current': 'page' })

            return (
              <StyledBreadcrumbsListItem
                key={label}
              >
                <StyledBreadcrumbsItem
                  {...itemProps}
                  typo="link3"
                >
                  {label}
                </StyledBreadcrumbsItem>
                {
                  !current &&
                <Icon name="chevron-right" />
                }
              </StyledBreadcrumbsListItem>
            )
          })
        }
      </StyledBreadcrumbsList>

      {
        previousPageLink.href &&
        <StyledBreadcrumbsBack
          typo="link3"
          bold
          href={previousPageLink.href}>
          <Icon name="chevron-left" />
          Torna indietro
        </StyledBreadcrumbsBack>
      }
    </nav>
  )
}

Breadcrumbs.propTypes = { items: PropTypes.array.isRequired, }

export default Breadcrumbs
