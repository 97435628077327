import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { HeaderContext } from 'contexts/Header'
import { getFirstFocusableElement } from '@/utils'

import Form from '@/molecules/Form'

import {
  StyledSearch,
  StyledSearchContainer,
  StyledSearchClose,
} from './styles'

const Search = () => {
  const containerRef = useRef(null)
  const { state, dispatch, searchForm, brand } = useContext(HeaderContext)

  searchForm.form.initialValues.brand = brand.slug

  const onClick = () => {
    dispatch({ type: 'close' })
  }

  const isOpen = useMemo(
    () => state.position === 'search' && state.opened,
    [state]
  )

  useEffect(() => {
    if (isOpen) getFirstFocusableElement(containerRef.current)
  }, [state, isOpen])

  return (
    <AnimatePresence>
      {state.position === 'search' && state.opened && (
        <StyledSearch
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: 0.2 },
          }}
          exit={{ opacity: 0 }}
        >
          <StyledSearchContainer ref={containerRef}>
            <Form {...searchForm} />
            <StyledSearchClose onClick={onClick} />
          </StyledSearchContainer>
        </StyledSearch>
      )}
    </AnimatePresence>
  )
}

export default Search
