import React from 'react'

export default () => (
  <svg width="129" height="129" viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_12759_24316" style={{ 'maskType': 'alpha' }} maskUnits="userSpaceOnUse" x="8" y="53" width="41" height="63">
      <path d="M8.69801 87.013C7.2733 97.0173 16.086 109.365 21.434 115.167C21.7603 115.521 22.372 115.28 22.4473 114.805C23.4535 108.451 31.7454 101.449 36.0403 98.5824C38.2058 97.1372 44.9195 93.9721 48.046 84.2675C51.1037 74.7767 42.801 60.4567 37.9753 54.0649C37.7977 53.8296 37.4682 53.789 37.2347 53.969C25.9021 62.7024 10.5615 73.9276 8.69801 87.013Z" fill="#88D46A"/>
    </mask>
    <g mask="url(#mask0_12759_24316)">
      <path d="M8.62331 87.0447C7.17142 97.2399 16.3512 109.869 21.6615 115.524C21.8812 115.758 22.2811 115.596 22.3146 115.277C22.9925 108.809 31.5712 101.547 35.9656 98.6141C38.1311 97.1689 44.8448 94.0039 47.9713 84.2993C51.0519 74.7372 42.601 60.273 37.7925 53.9539C37.6735 53.7975 37.456 53.7725 37.3004 53.8925C25.9546 62.6413 10.4945 73.9052 8.62331 87.0447Z" fill="#88D46A"/>
      <path d="M21.8701 116.074C21.9194 116.19 22.0536 116.244 22.1698 116.195C22.286 116.146 22.3403 116.011 22.291 115.895L21.8701 116.074ZM22.1579 89.3088L22.3525 89.4286L22.1579 89.3088ZM22.291 115.895C19.9672 110.414 15.6765 100.28 22.3525 89.4286L21.9632 89.189C15.1542 100.257 19.5486 110.598 21.8701 116.074L22.291 115.895ZM22.3525 89.4286C24.446 86.0258 26.8905 83.3515 29.2761 80.9334C31.6557 78.5214 33.9932 76.3494 35.8347 73.9769C37.6825 71.5962 39.0448 68.9982 39.4809 65.7145C39.9165 62.4343 39.4249 58.4912 37.6033 53.4227L37.1731 53.5773C38.9788 58.6016 39.4508 62.4685 39.0277 65.6544C38.6051 68.8367 37.2867 71.3607 35.4736 73.6966C33.6541 76.0406 31.3506 78.1798 28.9507 80.6123C26.5569 83.0387 24.0838 85.7421 21.9632 89.189L22.3525 89.4286Z" fill="#5D9A5C"/>
    </g>
    <mask id="mask1_12759_24316" style={{ 'maskType': 'alpha' }} maskUnits="userSpaceOnUse" x="8" y="24" width="73" height="57">
      <path d="M49.5107 26.4266C37.3095 20.9171 18.2709 27.6957 9.12073 32.3115C8.66249 32.5427 8.73231 33.2329 9.20287 33.4378C16.9799 36.8243 22.8864 50.0764 24.9503 56.6143C25.9778 59.8687 27.5071 69.4769 38.5798 76.9608C49.4542 84.3106 70.6475 79.0203 80.4035 75.2798C80.6969 75.1673 80.8341 74.8435 80.7193 74.551C73.8613 57.08 65.3375 33.5733 49.5107 26.4266Z" fill="#88D46A"/>
    </mask>
    <g mask="url(#mask1_12759_24316)">
      <path d="M49.5034 26.3222C37.1149 20.7281 17.6775 27.8023 8.69908 32.418C8.39478 32.5745 8.43919 33.0316 8.75861 33.1543C16.751 36.2233 22.8406 49.85 24.943 56.5098C25.9704 59.7642 27.4998 69.3724 38.5725 76.8563C49.513 84.2508 70.8983 78.851 80.5729 75.1073C80.7683 75.0317 80.8576 74.8172 80.781 74.6222C73.9111 57.1335 65.3833 33.4928 49.5034 26.3222Z" fill="#5D9A5C"/>
      <path d="M7.96178 32.6246C7.83643 32.6395 7.74689 32.7532 7.7618 32.8785C7.7767 33.0039 7.8904 33.0934 8.01575 33.0785L7.96178 32.6246ZM47.023 46.0279L46.8536 46.1814L47.023 46.0279ZM8.01575 33.0785C11.859 32.6216 18.757 32.7897 26.1057 34.6308C33.4543 36.4717 41.2294 39.9793 46.8536 46.1814L47.1923 45.8744C41.4862 39.5821 33.6166 36.0411 26.2168 34.1873C18.8173 32.3336 11.8633 32.1607 7.96178 32.6246L8.01575 33.0785ZM46.8536 46.1814C50.3736 50.063 52.5049 53.9113 54.1604 57.4809C54.9896 59.2689 55.6966 60.9806 56.4032 62.6009C57.1082 64.2175 57.8109 65.7389 58.625 67.1265C60.2573 69.9088 62.3397 72.157 65.7949 73.5913C69.2398 75.0212 74.0288 75.6335 81.0597 75.1946L81.0312 74.7384C74.0312 75.1753 69.3224 74.5605 65.9702 73.169C62.6284 71.7819 60.6145 69.6143 59.0193 66.8952C58.2196 65.5322 57.5258 64.0315 56.8222 62.4182C56.1204 60.8086 55.4071 59.0826 54.5751 57.2886C52.9082 53.6943 50.7538 49.8018 47.1923 45.8744L46.8536 46.1814Z" fill="#88D46A"/>
    </g>
    <mask id="mask2_12759_24316" style={{ 'maskType': 'alpha' }} maskUnits="userSpaceOnUse" x="66" y="15" width="54" height="71">
      <path d="M77.6052 26.577C87.0875 16.3546 108.126 15.18 118.731 15.9177C119.239 15.953 119.44 16.6096 119.087 16.976C112.96 23.3297 112.648 38.4648 113.311 45.5776C113.64 49.1092 116.053 58.9288 108.468 70.5652C101.013 82.0027 78.549 85.4852 67.7058 85.835C67.3943 85.8451 67.1447 85.6021 67.137 85.2905C66.6551 65.7868 65.3254 39.8151 77.6052 26.577Z" fill="#88D46A"/>
    </mask>
    <g mask="url(#mask2_12759_24316)">
      <path d="M77.571 26.4754C87.1928 16.1027 108.713 15.0458 119.158 15.8499C119.496 15.8759 119.63 16.3105 119.384 16.5446C112.937 22.6884 112.602 38.237 113.277 45.4761C113.606 49.0077 116.019 58.8272 108.434 70.4637C100.936 81.9675 78.2538 85.4237 67.4842 85.7392C67.2765 85.7453 67.1125 85.5837 67.1075 85.376C66.6296 65.8516 65.2519 39.7559 77.571 26.4754Z" fill="#88D46A"/>
      <path d="M119.708 15.856C119.83 15.8211 119.956 15.8911 119.991 16.0124C120.026 16.1337 119.956 16.2604 119.835 16.2953L119.708 15.856ZM91.6138 39.0502L91.8295 39.1259L91.6138 39.0502ZM119.835 16.2953C112.043 18.5398 97.5968 22.7063 91.8295 39.1259L91.3982 38.9744C97.2496 22.3155 111.922 18.0989 119.708 15.856L119.835 16.2953ZM91.8295 39.1259C90.0227 44.27 89.2901 49.1589 88.7788 53.7602C88.2683 58.353 87.977 62.6817 87.0486 66.6598C86.1179 70.648 84.5436 74.3043 81.4563 77.5702C78.3713 80.8336 73.7905 83.6916 66.8724 86.1111L66.7215 85.6796C73.5951 83.2756 78.1045 80.4504 81.1241 77.2562C84.1412 74.0645 85.6856 70.4889 86.6034 66.5559C87.5236 62.6129 87.8108 58.3313 88.3244 53.7098C88.8371 49.0968 89.5742 44.1674 91.3982 38.9744L91.8295 39.1259Z" fill="#5D9A5C"/>
    </g>
    <mask id="mask3_12759_24316" style={{ 'maskType': 'alpha' }} maskUnits="userSpaceOnUse" x="63" y="51" width="49" height="56">
      <path d="M66.7662 85.0588C61.2271 75.5891 65.1171 59.4888 68.0914 51.6398C68.2395 51.2492 68.804 51.2468 69.0097 51.6104C72.4328 57.6592 83.7433 61.3344 89.2492 62.4543C91.9889 63.0116 99.9482 63.4317 106.997 71.8095C113.921 80.0381 111.431 97.7624 109.222 106.031C109.156 106.278 108.906 106.417 108.659 106.349C93.8355 102.263 73.9496 97.3395 66.7662 85.0588Z" fill="#88D46A"/>
    </mask>
    <g mask="url(#mask3_12759_24316)">
      <path d="M66.7638 85.0715C61.2247 75.6018 65.1147 59.5015 68.089 51.6525C68.237 51.2619 68.8015 51.2595 69.0073 51.6231C72.4304 57.6719 83.7409 61.3471 89.2467 62.467C91.9864 63.0243 99.9458 63.4444 106.995 71.8222C113.918 80.0508 111.429 97.7751 109.22 106.044C109.154 106.291 108.903 106.43 108.657 106.362C93.8331 102.276 73.9471 97.3522 66.7638 85.0715Z" fill="#5D9A5C"/>
      <path d="M68.0473 50.8183C68.049 50.692 68.1527 50.5911 68.2789 50.5927C68.4051 50.5944 68.5061 50.6981 68.5044 50.8243L68.0473 50.8183ZM83.1822 73.8827L83.0472 74.0672L83.1822 73.8827ZM68.5044 50.8243C68.4213 57.1161 72.202 65.5692 83.3171 73.6982L83.0472 74.0672C71.8558 65.8823 67.9616 57.3043 68.0473 50.8183L68.5044 50.8243ZM83.3171 73.6982C86.9388 76.3469 89.7654 77.669 93.5112 81.7719C97.2565 85.8741 101.933 92.7618 109.344 106.581L108.941 106.797C101.533 92.9848 96.879 86.1386 93.1736 82.0801C89.4688 78.0222 86.7247 76.7567 83.0472 74.0672L83.3171 73.6982Z" fill="#88D46A"/>
    </g>
  </svg>

)
