import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0099 19.9219L4.05679 11.9688Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.42042 11.3324C3.06894 11.6838 3.06894 12.2537 3.42042 12.6052L11.3735 20.5583C11.725 20.9097 12.2948 20.9097 12.6463 20.5583C12.9978 20.2068 12.9978 19.6369 12.6463 19.2855L4.69321 11.3324C4.34174 10.9809 3.77189 10.9809 3.42042 11.3324Z" fill="#194074"/>
    <path d="M20 11.6151L4 11.615Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.00002 12.515L20 12.5151C20.4971 12.5151 20.9 12.1121 20.9 11.6151C20.9 11.118 20.4971 10.7151 20 10.7151L4.00003 10.715C3.50297 10.715 3.10003 11.118 3.10003 11.615C3.10002 12.1121 3.50297 12.515 4.00002 12.515Z" fill="#194074"/>
    <path d="M7.24429 7.76561L12.0099 4Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.716 3.44203C12.4079 3.05203 11.8419 2.98569 11.4519 3.29386L6.68629 7.05947C6.29629 7.36763 6.22995 7.93361 6.53812 8.32361C6.84628 8.71361 7.41226 8.77995 7.80226 8.47178L12.5679 4.70617C12.9579 4.398 13.0242 3.83203 12.716 3.44203Z" fill="#194074"/>
  </svg>

)
