import styled from 'styled-components'

import {
  StyledHero,
  StyledHeroContainer
} from '../styles'

import { StyledHeroBackground } from '../HeroBackground/styles'
import { StyledButton } from '@/atoms/Button/styles'
import { StyledGraphicAnimated } from '@/atoms/GraphicAnimated/styles'

export const StyledHeroProduct = styled(StyledHero)`
  position: relative;
  z-index: 10;

  ${StyledHeroContainer}{
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaQuery.md(`
    min-height: 0;
    ${StyledHeroBackground}{
      ${theme.paddingY([0, 40])};
      --col-min: ${theme.col(0.5)};
      --col: calc((100vw - var(--max-width)) / 2);
      padding-right: clamp(var(--col-min), var(--col),var(--col));
    }

    ${StyledHeroContainer}{
      margin-bottom:  ${theme.rem(34)};
    }
  `)}

  ${({ theme }) => theme.mediaQuery.lg(`
      ${StyledHeroBackground}{
        ${theme.paddingY([80, 40])};
      }
  `)}
`
export const StyledStickyElementCta = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.general.light()};
  color: ${({ theme }) => theme.colors.grayscale.white};
  ${({ theme }) => theme.paddingY(8)};
  ${({ theme }) => theme.paddingX(24)};
  gap: ${({ theme }) => theme.rem(16)};


  ${StyledButton}{
    aspect-ratio: 1;
    padding: 0;

    span{
      display: none;
    }
  }

  ${({ theme }) => theme.mediaQuery.md(`
    position: static;
    bottom: unset;
    left: unset;
    transition: opacity ease-in-out .4s;
    opacity: 0;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    border-radius: ${theme.rem(24)};
    margin-top: ${theme.rem(24)};
    ${theme.paddingX(8)};

    ${StyledButton}{
      width: 100%;
      aspect-ratio: auto;
      ${theme.paddingX(24)};

      span{
        display: inline-flex;
      }
    }
  `)}

  ${({ theme }) => theme.mediaQuery.lg(`
    border-radius: 100px;
    flex-direction: row;
    align-items: center;
    text-align: left;
    ${theme.paddingX([24, 8])};
  `)}
`

export const StyledStickyElement = styled.div`
  z-index: 10;
  position: relative;
  opacity: 1;

  ${StyledStickyElementCta}{
    opacity: 0;
  }

  &.active-mobile,
  &.active{
    ${StyledStickyElementCta}{
      opacity: 1
    }
  }
`

export const StyledStickyElementWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;


  ${({ theme }) => theme.mediaQuery.md(`
    display: table;
    height: auto;
    position: relative;
    width: 100%;
    max-width: 420px;
    left:unset;
    transform: unset;
  `)}
`

export const StyledStickyElementImage = styled.div`
  position: relative;
  margin:  0 auto;

  opacity: 1;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity ease-in-out .2s;
  ${({ theme }) => theme.size(280, true)};
  ${({ theme }) => theme.size('100%')};
  ${StyledGraphicAnimated}{
    z-index: 0;
    svg{
      opacity: 1;
      transform: scale(1);
    }
  }
  ${({ theme }) => theme.mediaQuery.md(`
    ${theme.size(360, true)};
    ${StyledGraphicAnimated}{
      svg{
        opacity: 0;
        transform: scale(0);
      }
    }
  `)}

  .active &{
    pointer-events: none;
    opacity: 0;

    ${({ theme }) => theme.mediaQuery.md(`
      opacity: 1
    `)}

    ${StyledGraphicAnimated}{
      svg{
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  > img{
    --shadow: ${({ theme }) => theme.shadows.product};
    object-fit: contain;
    position: relative;
    z-index: 1;
    filter: drop-shadow(var(--shadow));
    ${({ theme }) => theme.size('86%')}
    ${({ theme }) => theme.size(360, true)}
  }
`


export const StyledStickyElementActions = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.rem(12)};
`

