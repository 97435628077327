import styled from 'styled-components'

export const StyledParagraph = styled.div`
  ${({ theme }) => theme.mediaQuery.md(`
    ${theme.paddingX(`${theme.col(1)}`)}
  `)}

  h1{
    ${({ theme }) => theme.typo('heading1')}
  }

  h2{
    ${({ theme }) => theme.typo('heading2')}
  }

  h3{
    ${({ theme }) => theme.typo('heading3')}
  }

  h4{
    ${({ theme }) => theme.typo('heading4')}
  }

  ul, ol{
    list-style: initial;
    padding-left: ${({ theme }) => theme.rem(24)};
  }

  a{
    text-decoration-color: ${({ theme }) => theme.colors.accent()};
    text-decoration-thickness: 4px;
  }
`
