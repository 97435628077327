const ENTITIES = [
  'pages',
  'products',
  'recipes',
  'cocktail',
  'chef-secrets',
  'magazine',
]

const ENDPOINTS = {
  brands: '/wp/v2/brands',
  pages: '/wp/v2/pages',
  products: '/wp/v2/products',
  recipes: '/wp/v2/recipes',
  cocktail: '/wp/v2/cocktail',
  forms: '/wp/v2/forms',
  media: '/wp/v2/media',
  'chef-secrets': '/wp/v2/chef-secrets',
  magazine: '/wp/v2/magazine',
  options: '/api/options',
  menu: '/api/menu',
  category: '/wp/v2/{type}-categories',
  algolia: '/indexes/wp_posts_{type}/query',
  redirects: '/redirection/v1/redirect'
}

export {
  ENTITIES,
  ENDPOINTS
}
