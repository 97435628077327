import styled from 'styled-components'
import styledMap from 'styled-map'
import { helpers } from '@/atoms/Typography'

export const StyledRichtext = styled.span`
  ${helpers};
  strong,
  b {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  a {
    text-decoration: underline;
  }

  color:${({ theme }) => styledMap`
    default: inherit;
    white: ${theme.colors.grayscale.white};
    accent:${theme.colors.accent()};
    background:${theme.colors.background()};
    medium:${theme.colors.medium()};
  `};

  ${({
    theme,
    typo
  }) => typo && theme.typo(typo)};
`
