import styled from 'styled-components'

import { StyledParagraph } from '../styles'

export const StyledRecipeParagraph = styled(StyledParagraph)`
  display: grid;
  color: ${({ theme }) => theme.colors.general.light()};

  ${({ theme }) => theme.mediaQuery.md(`
    // display: flex;
  `)}
`

export const StyledRecipeParagraphContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.rem(80)};

  ${({ theme }) => theme.mediaQuery.md(`
    grid-area: content;
  `)}
`

export const StyledRecipeParagraphHeader = styled.header`
  > * + * {
    margin-block-start: ${({ theme }) => theme.rem(40)};
  }
`

export const StyledRecipeParagraphContent = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.rem(80)};
`

export const StyledRecipeParagraphAside = styled.aside`
  display: flex;
  align-items: flex-end;
  z-index: 10;
  position: fixed;
  left: 0;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.md(`
    position: relative;
    display: block;
  `)}
`

export const StyledRecipeParagraphAsideContainer = styled.div`
  ${({ theme }) => theme.mediaQuery.md(`
    ---m: ${theme.col(0.2)}
    width: calc(80% + var(---m));
    margin-left: auto;
  `)}

@media screen and (max-width: 767px) {
  .ingredients-wrapper{
    position: fixed;
    height: 100vh;
    height: 100dvh;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    z-index: 100;
    button{
      opacity: 0;
    }
    &.active{
      pointer-events: auto;
      button{
      opacity: 1;
    }
    }
  }
}

`
