import React, { useRef } from 'react'
import {
  useScroll,
  useTransform
} from 'framer-motion'

import Richtext from '@/atoms/RichText'
import Cta from '@/atoms/Cta'

import CustomImage from '@/atoms/CustomImage'
import HeadingWithMark from '@/molecules/HeadingWithMark'

import {
  StyledMediaBanner,
  StyledMediaBannerWrapper,
  StyledMediaBannerMedia,
  StyledMediaBannerContent,
  StyledMediaBannerTitle,
  StyledMediaBannerDescription,
} from './styles'

const MediaBanner = ({
  image,
  shadow = true,
  title,
  description,
  cta
}) => {
  const ref = useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'start 50vh'],
  })

  const scale = useTransform(scrollYProgress, [0, 1], [0.9, 1], { clamp: true, })

  return (
    <StyledMediaBanner ref={ref}>
      <StyledMediaBannerWrapper>
        <StyledMediaBannerMedia
          shadow={shadow}
          initial={{ scale: 0.9 }}
          style={{ scale: scale }}
        >
          <CustomImage fill data={image} />
        </StyledMediaBannerMedia>
        <StyledMediaBannerContent>
          {title ? (
            <StyledMediaBannerTitle>
              <HeadingWithMark typo="heading2" content={title} />
            </StyledMediaBannerTitle>
          ) : null}
          <StyledMediaBannerDescription>
            {
              description ?
                <Richtext typo="paragraphLarge">{description}</Richtext>
                : null
            }
            {
              cta &&
              !!cta.link &&
              <Cta white keyword={cta.keyword} {...cta.link}/>
            }
          </StyledMediaBannerDescription>
        </StyledMediaBannerContent>
      </StyledMediaBannerWrapper>
    </StyledMediaBanner>
  )
}

export default MediaBanner
