import { useRef } from 'react'
import {
  useScroll,
  useTransform
} from 'framer-motion'

import Graphics from '@/atoms/Graphics'
import BackToTopButton from '@/molecules/Buttons/BackToTopButton'
import {
  StyledSectionBackground,
  StyledSectionBackgroundButton,
  StyledSectionDecoration,
} from './styles'

const Background = ({
  withBgColor,
  showBackToTop,
  top = false,
  isLast,
  decoration,
  brand,
}) => {
  const ref = useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'start 50vh'],
  })

  const scaleX = useTransform(scrollYProgress, [0, 1], [0.9, 1], { clamp: true, })
  const opacity = useTransform(scrollYProgress, [0, 1], [0, 1], { clamp: true, })

  return (
    <>
      <StyledSectionBackground
        isLast={isLast}
        ref={ref}
        withBgColor={withBgColor}
        initial={{ scaleX: top ? 0.9 : 1 }}
        style={{ scaleX: top ? scaleX : 1 }}
      >
        {
          decoration &&
          <StyledSectionDecoration
            initial={{ opacity: 0 }}
            style={{ opacity }}>
            <Graphics id={`heading-${brand}`} width={256} height={128} fill={false} />
          </StyledSectionDecoration>
        }
      </StyledSectionBackground>

      {
        showBackToTop &&
        <StyledSectionBackgroundButton>
          <BackToTopButton/>
        </StyledSectionBackgroundButton>
      }
    </>
  )
}

export default Background
