import React, { useCallback } from 'react'
import { useField  } from 'formik'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import CustomField from './index'

const RecaptchaField = ({
  label,
  ...props
}) => {
  const [field, meta, helpers] = useField(props)

  const { setValue } = helpers
  const onChange = useCallback((token) => {
    if (!field.value) setValue(token)
  })

  if (!process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY) return null

  return (
    <CustomField
      id={props.id}
      type={props.type}
      label={label}
      field={field}
      meta={meta}
    >
      <GoogleReCaptcha onVerify={onChange} />
    </CustomField>
  )
}

export default RecaptchaField
