import React from 'react'

import Image from 'next/image'

const Graphics = ({
  id,
  format = 'svg',
  ...props
}) => {
  return (
    <Image src={`/images/illustrations/${id}.${format}`} fill {...props} />
  )
}

export default Graphics
