import React, { useContext } from 'react'
import {
  AnimatePresence,
  motion
} from 'framer-motion'

import { HeaderContext } from 'contexts/Header'

import { Label } from '@/atoms/Typography'

import Brands from '@/molecules/Brands'
import ClubButton from '@/molecules/Buttons/ClubButton'
import ShopButton from '@/molecules/Buttons/ShopButton'

import SingleItem from '../SingleItem'

import {
  StyledLateralNavigation,
  StyledLateralNavigationList,
  StyledLateralNavigationBrands,
  StyledLateralNavigationBrandsList,
  StyledLateralNavigationBrandsCta,
  StyledLateralNavigationUserCtas
} from './styles'

const LateralNavigation = () => {
  const {
    state,
    lateral,
    brands,
    isMobile,
  } = useContext(HeaderContext)
  return (
    <AnimatePresence>
      {
        state.opened &&
        (state.position === 'lateral' || isMobile && state.position === 'submenuMobile') &&
        <StyledLateralNavigation
          id="lateralMenu"
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: 0.2 }
          }}
          exit={{ opacity: 0 }}
        >

          {
            isMobile &&
            <Label className='MenuTitle' bold as="strong" typo="heading2">Menu</Label>
          }

          {
            lateral &&
            !!lateral.length &&
            <StyledLateralNavigationList>
              {
                lateral.map(lateralNav => <SingleItem key={lateralNav} {...lateralNav} />)
              }
            </StyledLateralNavigationList>
          }

          <StyledLateralNavigationBrands>
            {
              brands.label &&
              <Label as="strong" typo="labelSmall">{brands.label}</Label>
            }

            {
              brands.items &&
              !!brands.items.length &&
              <StyledLateralNavigationBrandsList>
                <Brands items={brands.items}/>
              </StyledLateralNavigationBrandsList>
            }


            {
              brands.cta &&
              !!brands.cta.link &&
              <StyledLateralNavigationBrandsCta typo="buttonRegular" keyword={brands.cta.keyword} href={brands.cta.link.href}>
                {brands.cta.link.title}
              </StyledLateralNavigationBrandsCta>
            }
          </StyledLateralNavigationBrands>

          {
            isMobile &&
            <StyledLateralNavigationUserCtas>
              <ClubButton negative small={false}/>
              <ShopButton general small={false} />
            </StyledLateralNavigationUserCtas>
          }

        </StyledLateralNavigation>
      }
    </AnimatePresence>
  )
}

export default LateralNavigation
