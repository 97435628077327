import styled, { css } from 'styled-components'
import styledMap from 'styled-map'

export const resetButton = css`
  width: auto;
  position: relative;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-align: inherit;
  color: inherit;
  border: none;
  background: transparent;
  line-height: normal;
  overflow: visible;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
`

const hoverStatus = css`
  background-color: ${({ theme }) => styledMap`
    default: ${theme.colors.medium()};
    general: #184786;
    transparent: transparent;
    negative: transparent;
    reset: transparent;
  `};

  border-color: ${({ theme }) => styledMap`
    default: ${theme.colors.medium()};
    general: #184786;
    transparent: ${theme.colors.grayscale.gray600};
    negative: ${theme.colors.general.light()};
  `};
`

export const StyledButton = styled.button`
  ${resetButton}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 0 0 0;
  transition: background-color ease-in-out 0.4s, box-shadow ease-in-out 0.4s, border-color ease-in-out 0.4s;

  padding: ${({ theme }) => styledMap`
    default: ${theme.rem(0, 24)};
    reset: 0;
  `};

  background-color: ${({ theme }) => styledMap`
    default: ${theme.colors.accent()};
    general: ${theme.colors.general.light()};
    transparent: transparent;
    negative: transparent;
    reset: transparent;
  `};

  color:  ${({ theme }) => styledMap`
    default: ${theme.colors.general.light()};
    general: ${theme.colors.grayscale.white};
    transparent: ${theme.colors.grayscale.white};
  `};

  border-radius: ${styledMap`
    default: 50px;
    boxed: 0;
    reset: 0;
    smooth: 8px;
    rounded: 50%;
  `};

  border: ${({ theme }) => styledMap`
    default: none;
    transparent: 1px solid ${theme.colors.grayscale.gray800};
    negative: 1px solid ${theme.colors.grayscale.gray200};
  `};

  height: ${({ theme }) => styledMap`
    big: ${theme.rem(56)};
    default: ${theme.rem(48)};
    small: ${theme.rem(40)};
    auto: auto;
  `};

  &:not([disabled]),
  &:not([aria-disabled="true"]) {
    // HOVER
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      &:hover {
        ${hoverStatus}
      }
    }

    // FOCUS
    &:focus {
      ${hoverStatus}
    }

    // ACTIVE
    &:active,
    &[data-active="true"] {}
  }

  // ICONS
  && i {
    font-size: ${({ theme }) => theme.rem(24)};
    > svg path {
      fill: ${({
    theme, general, transparent
  }) => (general || transparent) && `${theme.colors.grayscale.white}`};
    }
  }

  // DISABLED
  &[disabled],
  &[aria-disabled="true"] {
    opacity: 0.5;
    pointer-events: none;
  }

  // VARIANT FULL WIDTH
  ${({ fullWidth }) => fullWidth && css`
    width: 100%;
  `}
`
