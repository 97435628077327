import styled from 'styled-components'

export const StyledMenuButtonElement = styled.span`
  --standardPosition: translate(-50%, -50%);
  --offset: 3px;

  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform ease-in-out .4s, opacity ease-in-out .4s;
  background-color: ${({ theme }) => theme.colors.general.light()};
  ${({ theme }) => theme.size(['50%', 2])};
  border-radius: 2px;

  &:nth-child(1){
    transform: translate(-50%, calc(-50% - var(--offset)));

    ${({ theme }) => theme.mediaQuery.lg(`
      --offset: 5px;
    `)};
  }

  &:nth-child(2){
    opacity: 1;
    display: none;
    transform: var(--standardPosition);
    transition: transform ease-in-out .4s, width cubic-bezier(0.4, 0, 0.2, 1) .6s, opacity ease-in-out .4s;

    ${({ theme }) => theme.mediaQuery.lg(`
      display: block;
    `)};
  }

  &:nth-child(3){
    transform: translate(-50%, calc(-50% + var(--offset)));

    ${({ theme }) => theme.mediaQuery.lg(`
      --offset: 5px;
    `)};
  }

  ${({ theme }) => theme.mediaQuery.lg(`
    ${theme.size(2)};

    &::before,
    &::after{
      content: '';
      opacity: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transition: transform ease-in-out .4s, opacity ease-in-out .4s;
      background-color: ${theme.colors.general.light()};
      ${theme.size(2)};
    }

    &::after{
      transform: translate(calc(-50% + 5px), -50%);
    }

    &::before{
      transform: translate(calc(-50% + -5px), -50%);
    }
  `)}
`

export const StyledMenuButton = styled.button`
  z-index: 4;
  flex-shrink: 0;
  position: relative;
  padding: 0;
  cursor: pointer;
  border: none;
  ${({ theme }) => theme.size(32)};
  background-color: ${({ theme }) => theme.colors.grayscale.white};

  ${({ theme }) => theme.mediaQuery.lg(`
    border-radius: 50%;
    border: 1px solid ${theme.colors.grayscale.gray200};
    transition: border-color ease-in-out .4s;

    &:hover{
      border-color: ${theme.colors.general.light()};
    }
  `)}


  ${({ isOpen }) => isOpen && `
    ${StyledMenuButtonElement}{
      &:nth-child(1){
        transform: var(--standardPosition);

        &::before,
        &::after{
          opacity: 0;
          transform: var(--standardPosition);
        }
      }

      &:nth-child(2){
        transition-delay: 0s, .2s, 0s;
        transform: var(--standardPosition);
        width: 16px;
        &::before,
        &::after{
          opacity: 0;
          transform: var(--standardPosition);
        }
      }

      &:nth-child(3){
        transform: var(--standardPosition);

        &::before,
        &::after{
          opacity: 0;
          transform: var(--standardPosition);
        }
      }
    }
  `}
`
