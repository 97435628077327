import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.9642 6.67858C13.9642 6.26437 14.3 5.92858 14.7142 5.92858H20.0714C20.4856 5.92858 20.8214 6.26437 20.8214 6.67858C20.8214 7.09279 20.4856 7.42858 20.0714 7.42858H14.7142C14.3 7.42858 13.9642 7.09279 13.9642 6.67858Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.25 6.67858C3.25 6.26437 3.58579 5.92858 4 5.92858H6.14286C6.55707 5.92858 6.89286 6.26437 6.89286 6.67858C6.89286 7.09279 6.55707 7.42858 6.14286 7.42858H4C3.58579 7.42858 3.25 7.09279 3.25 6.67858Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.25 16.3214C3.25 15.9072 3.58579 15.5714 4 15.5714H9.35714C9.77136 15.5714 10.1071 15.9072 10.1071 16.3214C10.1071 16.7356 9.77136 17.0714 9.35714 17.0714H4C3.58579 17.0714 3.25 16.7356 3.25 16.3214Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.1786 16.3214C17.1786 15.9072 17.5144 15.5714 17.9286 15.5714H20.0714C20.4857 15.5714 20.8214 15.9072 20.8214 16.3214C20.8214 16.7356 20.4857 17.0714 20.0714 17.0714H17.9286C17.5144 17.0714 17.1786 16.7356 17.1786 16.3214Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.82139 4.75C7.75627 4.75 6.89282 5.61345 6.89282 6.67857C6.89282 7.74369 7.75627 8.60714 8.82139 8.60714C9.88651 8.60714 10.75 7.74369 10.75 6.67857C10.75 5.61345 9.88651 4.75 8.82139 4.75ZM5.39282 6.67857C5.39282 4.78502 6.92785 3.25 8.82139 3.25C10.7149 3.25 12.25 4.78502 12.25 6.67857C12.25 8.57212 10.7149 10.1071 8.82139 10.1071C6.92785 10.1071 5.39282 8.57212 5.39282 6.67857Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.25 14.3929C14.1849 14.3929 13.3214 15.2563 13.3214 16.3214C13.3214 17.3866 14.1849 18.25 15.25 18.25C16.3151 18.25 17.1786 17.3866 17.1786 16.3214C17.1786 15.2563 16.3151 14.3929 15.25 14.3929ZM11.8214 16.3214C11.8214 14.4279 13.3564 12.8929 15.25 12.8929C17.1435 12.8929 18.6786 14.4279 18.6786 16.3214C18.6786 18.215 17.1435 19.75 15.25 19.75C13.3564 19.75 11.8214 18.215 11.8214 16.3214Z" fill="#194074"/>
  </svg>

)
