import React, { useContext } from 'react'

import { GeneralContext } from 'contexts/General'
import { StyledShopButton } from './styles'

const ShopButton = (props) => {
  const { options = {}, } = useContext(GeneralContext)
  const { shop = {} } = options

  return (
    shop.cta &&
    !!shop.cta.link &&
    <StyledShopButton
      small
      typo='buttonRegular'
      keyword={shop.cta.keyword}
      label={shop.cta.link.title}
      href={shop.cta.link.href}
      target={shop.cta.link.target}
      iconStart="cart"
      {...props}
    />
  )
}

export default ShopButton
