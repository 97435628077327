import styled from 'styled-components'

import { InlineList } from '@/atoms/Layout'
import { StyledHeroContainer, } from '@/molecules/Heroes/styles'
import { StyledRichtext } from '@/atoms/RichText/styles'
import { StyledCta } from '@/atoms/Cta/styles'
import Link from '@/atoms/Link'

import CardMedia from '@/molecules/Card/CardMedia'
import { StyledMediaIcon, } from '@/molecules/Card/CardMedia/styles'
import { StyledBannerCardContent } from '@/molecules/Card/Cards/BannerCard/styles'

export const StyledFeaturedContent = styled.div`
  ${StyledRichtext}{
    margin-top: ${({ theme }) => theme.rem(24)};
  }

  > ${StyledCta}{
    margin-top: ${({ theme }) => theme.rem(40)};
  }
`

export const StyledFeaturedContentTitle = styled(Link)`
  &[href] {
    cursor: pointer;

    ${({ theme }) => theme.mediaQuery.md(`
      &:hover{
        text-decoration: underline;
      }
    `)}
  }
`

export const StyledFeaturedContentMedia = styled(CardMedia)`

  will-change: box-shadow, border-radius;
  transition: box-shadow ease-in-out .6s, border-radius ease-in-out .6s,  transform ease-in-out .6s;

  &[href] {
    cursor: pointer;

    ${({ theme }) => theme.mediaQuery.md(`
      &:hover{
        transform: scale(1.01);
        box-shadow: ${theme.shadows.hover};
        border-radius: ${theme.rem(24)} ${theme.rem(24)} ${theme.rem(100)};

        ${StyledMediaIcon}{
          transform: translate(-15%, 10%);
        }
      }
  `)}
  }

  ${StyledMediaIcon}{
    will-change: transform;
    transition: transform ease-in-out .6s;
  }
`
export const StyledFeaturedContentContainer = styled(StyledHeroContainer)`
  display: flex;
  flex-direction: column-reverse;
  ${({ theme }) => theme.marginY(0)};

  ${({ theme }) => theme.mediaQuery.md(`
    display: grid;
    grid-template-columns: 6fr 6fr;
  `)}
`

export const StyledFeaturedContentCategories = styled(InlineList)`
  margin-bottom: ${({ theme }) => theme.rem(16)};
`

export const StyledFeaturedContentInfos = styled(InlineList)`
  margin-top: ${({ theme }) => theme.rem(24)};
`

export const StyledFeaturedContentProduct = styled.div`
  margin-top: ${({ theme }) => theme.rem(40)};

  ${({ theme }) => theme.mediaQuery.md(`
    margin-top: ${theme.rem(80)};
  `)}

  ${StyledBannerCardContent}{
    place-self: center;
    margin-top: 0;
  }
`
