import styled from 'styled-components'
import { Label } from '@/atoms/Typography'
export const StyledFormModalContainer = styled.div`

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.rem(24)};
  background-color: ${({
    theme, success
  }) => success ? theme.colors.status.success.default : theme.colors.status.error.default };
  ${({ theme }) => theme.paddingY(`${theme.rem(24)}`)}
  ${({ theme }) => theme.paddingX(24)}

  ${({ theme }) => theme.mediaQuery.md(`
    ${theme.paddingX(`${theme.col(1)}`)};
  `)}

  .SuccessMessage{
    color: ${({ theme }) => theme.colors.accent()};
    margin: ${({ theme }) => theme.rem(40)} 0;

    strong{
      display: block;
      margin-bottom: ${({ theme }) => theme.rem(8)};
    }
  }
`

export const StyledFormModalContent = styled.div`
  color: ${({ theme }) => theme.colors.grayscale.white};

  ${Label}{
    display: block;
  }

`
