import React from 'react'
import {
  Swiper,
  SwiperSlide
} from 'swiper/react'
import SwiperCore, { Keyboard, Mousewheel } from 'swiper/core'

import Card from '@/molecules/Card'

import { StyledDefaultSlider } from './styles'

SwiperCore.use([Keyboard, Mousewheel])

const swiperProps = {
  spaceBetween: 24,
  slidesPerView: 'auto',
  breakpoints: { 768: { spaceBetween: 60, }, },
  keyboard: true,
}

const DefaultSlider = ({
  list,
  type,
  columns = 3,
}) => (
  <StyledDefaultSlider columns={columns} >
    <Swiper {...swiperProps}>
      {
        list.map((item, i) => (
          <SwiperSlide key={`${i}_${item.title}`}>
            <Card key={`${i}_${item.title}`} item={item} type={type} imageRatio={item.imageRatio} />
          </SwiperSlide>
        ))
      }
    </Swiper>
  </StyledDefaultSlider>
)

export default DefaultSlider
