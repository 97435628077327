import React from 'react'

import { StyledButtonIngredients } from './styles'

const ButtonIngredients = ({
  onClick, isOpen
}) => {
  return (
    <StyledButtonIngredients
      isOpen={isOpen}
      big
      typo="heading6"
      handleClick={onClick}
      iconStart="chevron-up"
      label="Mostra gli ingredienti"
      unlockedProportions
    />
  )
}

export default ButtonIngredients
