import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'

import Cluster from '@/molecules/Filters/Cluster'
const TabFilter = dynamic(import('@/molecules/Filters/Tab'))
const LineFilter = dynamic(import('@/molecules/Filters/Line'))

const SearchResultsHeader = ({
  filters,
  getData,
  setLoading,
  setTotalPages,
  setTotalItems,
}) => {
  const [first, setFirst] = useState(true)
  const [tabs, setTabs] = useState(filters.tabs)
  const [currentLine, setCurrentLine] = useState(filters.currentLine)


  const onTabClick = async (tabProps) => {
    const clonedTabs = structuredClone(tabs)
    const sameTab = clonedTabs.items.find(
      (item) => item.current && item.name === tabProps.name
    )

    if (sameTab) return

    let currentLineUpdated = currentLine
    clonedTabs.items.forEach((item) => {
      if (item.name !== tabProps.name) {
        item.current = false
        return
      }

      item.current = true
      currentLineUpdated = item.items
    })

    const clonedCurrentLine = structuredClone(currentLine)
    clonedCurrentLine.items = currentLineUpdated

    setTabs(clonedTabs)
    setCurrentLine(clonedCurrentLine)

    let newBrandName = tabProps.name.toLowerCase()

    if (newBrandName.includes('parmalat')) {
      newBrandName = 'parmalat'
    }

    const url = new URL(window.location.href)
    url.searchParams.set('brand', newBrandName)
    window.history.replaceState({}, '', url)
  }

  const onLineClick = (lineProps) => {
    const clonedCurrentLine = structuredClone(currentLine)
    const sameLine = clonedCurrentLine.items.find(
      (item) => item.current && item.title === lineProps.title
    )

    if (sameLine) return
    clonedCurrentLine.items.forEach((item) => {
      if (item.title !== lineProps.title) {
        item.current = false
        return
      }
      item.current = true
    })
    setCurrentLine(clonedCurrentLine)
  }

  useEffect(() => {
    const getFirstResults = async () => {
      const brand = new URLSearchParams(window.location.search).get('brand')
      const clonedTabs = structuredClone(tabs)

      let currentLineUpdated = currentLine
      clonedTabs.items.forEach((item, index) => {
        if (!brand) {
          if (index === 0) {
            item.current = true
            currentLineUpdated = item.items
          } else {
            item.current = false
          }
          return
        }

        if (['parmalat', 'parmalat-corporate'].includes(brand) && item.name.includes('Parmalat')) {
          item.current = true
          currentLineUpdated = item.items
          return
        }

        if (item.name.toLowerCase() !== brand) {
          item.current = false
          return
        }

        item.current = true
        currentLineUpdated = item.items
      })

      const clonedCurrentLine = structuredClone(currentLine)
      clonedCurrentLine.items = currentLineUpdated
      const facetFilters = clonedCurrentLine.items.find((item) => item.current).value

      setCurrentLine(clonedCurrentLine)
      setTabs(clonedTabs)

      const { nbPages, nbHits } = await getData({
        additionalBodyData: { facetFilters },
      })
      setTotalPages(nbPages)
      setTotalItems(nbHits)
      setLoading(false)

      setFirst(false)
    }

    getFirstResults()
  }, [])

  useEffect(() => {
    const getResultsOnLineChange = async () => {
      setLoading(true)

      const facetFilters = currentLine.items.find((item) => item.current).value
      const { nbPages, nbHits } = await getData({
        additionalBodyData: { facetFilters },
      })

      setTotalPages(nbPages)
      setTotalItems(nbHits)
      setLoading(false)
    }

    if (!first) {
      getResultsOnLineChange()
    }
  }, [currentLine])


  return (
    <>
      <Cluster label={tabs.label} type="Tab">
        <TabFilter
          hideImg={false}
          type="Tab"
          items={tabs.items}
          actions={{ onClick: onTabClick }}
        />
      </Cluster>

      <Cluster
        label={currentLine.label}
        // withLine
        type="Line"
      >
        <LineFilter
          type="Line"
          items={currentLine.items}
          actions={{ onClick: onLineClick }}
        />
      </Cluster>
    </>
  )
}

export default SearchResultsHeader
