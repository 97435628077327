import Button from '@/atoms/Button'
import styled from 'styled-components'

export const StyledShopButton = styled(Button)`
  ${({
    change,
    theme
  }) => change && `
    height: ${theme.rem(40)};
    aspect-ratio: 1;
    border-radius: 50%;
    padding: 0;
  `}

  ${({ theme }) => theme.mediaQuery.md(`
    aspect-ratio: auto;
    border-radius: 50px;
    ${theme.paddingX(24)};
  `)};

  span{
    ${({ change }) => change && 'display: none;'}
    ${({ theme }) => theme.mediaQuery.md(`
      display: inline-block;
    `)}
  }
`
