import React, { useRef } from 'react'
import { useInView } from 'framer-motion'

import { Heading, } from '@/atoms/Typography'
import Richtext from '@/atoms/RichText'


import { StyledHeadingWithMark, } from './styles'

const HeadingWithMark = ({
  content,
  typo = 'heading2',
  as = false,
}) => {
  const ref = useRef(null)
  const isInView = useInView(ref,  { once: true })

  return (
    <StyledHeadingWithMark
      ref={ref}
      className={isInView ? 'inView' : ''}
    >
      <Heading typo={typo}>
        <Richtext as={as} bold>{content}</Richtext>
      </Heading>
    </StyledHeadingWithMark>
  )
}

export default HeadingWithMark
