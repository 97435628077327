import Link from 'next/link'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'

import { Label } from '@/atoms/Typography'

const beforeAnimation = css`
  &::before{
    width: 100%;
  }
`

export const StyledCtaLabel = styled(Label)`
  position: relative;
  padding: ${({ theme }) => theme.rem(8, 0)};

  ${({
    theme,
    small,
    regular,
  }) => {
    if (small) return theme.typo('buttonSmall')
    if (regular) theme.typo('buttonRegular')

    return theme.typo('buttonBig')
  }};

  ${({
    detail,
    theme
  }) => detail && `
    &::before{
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 4px;
      width: 54px;
      border-radius: 8px;
      background-color: ${theme.colors.accent()};
      transform-origin: left center;
      transition: width ease-in-out .4s;
    }

    &:hover {
      ${beforeAnimation}
    }
  `}
`

export const StyledCta = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.rem(4)};

  color: ${({ theme }) => styledMap`
    default: ${theme.colors.general.light()};
    white: ${theme.colors.grayscale.white};
  `};

  &:focus{
    ${StyledCtaLabel}{
      ${beforeAnimation}
    }
  }
`
