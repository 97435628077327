import React from 'react'
import { useLenis } from '@studio-freight/react-lenis'

import Icon from '@/atoms/Icon'

import { StyledScrollDownButton } from './styles'

const ScrollDownButton = ({
  container,
  ...props
}) => {
  const lenis = useLenis()

  const onClick = () => {
    if (!container || !container.current) return
    const offsetToScroll = container.current.clientHeight

    lenis.scrollTo(offsetToScroll)
  }

  return (
    <StyledScrollDownButton
      aria-label='Esplora'
      onClick={onClick}
      {...props}
    >
      <Icon className="explore" name="explore" />
      <Icon className="arrowDown" name="arrow-down" />
    </StyledScrollDownButton>
  )
}

export default ScrollDownButton
