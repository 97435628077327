import styled from 'styled-components'
import styledMap from 'styled-map'

import Link from '@/atoms/Link'

import { currentGraphic, } from '@/organisms/Header/styles'

export const StyledSingleNavigation = styled.nav`
  margin-right: auto;
  width: 100%;
  strong{
    display: block;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.grayscale.gray700};
    margin-bottom:  ${({ theme }) => theme.rem(16)};
  }
`

export const StyledSingleNavigationList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.rem(16)};
`

export const StyledSingleNavigationListItem = styled.li``

export const StyledSingleNavigationLink = styled(Link)`
  white-space: nowrap;
  position: relative;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;

  &:not(span){
    padding-left: ${({ theme }) => styledMap`
      default: 0;
      current: ${theme.rem(8)};
    `};
    ${({ current }) => current && currentGraphic};
  }

  i{
    ${({ theme }) => theme.mediaQuery.md(`
      display: none;
    `)}
  }

  /* &::after{
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.general.light()};
    opacity: 0;
    transform: scaleX(0);
    transform-origin: left center;
    transition: transform ease-in-out .4s,opacity ease-in-out .4s ;
  } */

  &:hover{
    /* opacity: 1;
    transform: scaleX(1); */
    text-decoration: underline;
  }

`
