import React, { useEffect } from 'react'
import Router, { useRouter } from 'next/router'
import { Lenis, useLenis } from '@studio-freight/react-lenis'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

import Metadata from '@/organisms/Metadata'
import Header from '@/organisms/Header'
import Footer from '@/organisms/Footer'


const DefaultLayout = ({
  metadata,
  children
}) => {
  const router = useRouter()
  const lenis = useLenis()

  useEffect(() => {
    const onChangeStart = () => {
      lenis.scrollTo(0, { immediate: true })
    }
    Router.events.on('routeChangeStart', onChangeStart)

    return () => {
      Router.events.off('routeChangeStart', onChangeStart)
    }
  }, [lenis])
  return (
    <Lenis root>
      <Metadata {...metadata} />
      <Header key={router.asPath} />
      {children}
      <Footer key="footer" />
    </Lenis>
  )
}

export default DefaultLayout
