import styled from 'styled-components'

import { Label } from '@/atoms/Typography'
import { StyledButton } from '@/atoms/Button/styles'

export const StyledHeroContent = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.paddingY(16)};

  ${Label}{
    margin-bottom: ${({ theme }) => theme.rem(16)};
  }

  > ${StyledButton}{
    display: none;
    margin-top: ${({ theme })=> theme.rem(40)};
    align-self: start;
    ${({ theme }) => theme.mediaQuery.md(`
      display: flex;
    `)}
  }

`
