import React, {
  useLayoutEffect,
  useRef
} from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

import breakpoints from 'theme/breakpoints'

import { Label } from '@/atoms/Typography'
import Button from '@/atoms/Button'
import Richtext from '@/atoms/RichText'

import SubParagraph from '@/molecules/SubParagraph'
import Media from '@/molecules/Media'
import BannerCard from '@/molecules/Card/Cards/BannerCard'
import ShareButton from '@/molecules/Buttons/ShareButton'

import {
  StyledArticleParagraph,
  StyledArticleParagraphContent,
  StyledArticleParagraphAside,
  StyledArticleParagraphRelated,
  StyledArticleParagraphRelatedList,
  StyledArticleParagraphRelatedListItem,
  StyledArticleParagraphContentCta,
} from './styles'

const ArticleParagraph = ({
  paragraphs,
  related,
}) => {
  const sticky = useRef(null)
  const containerRef = useRef(null)

  useLayoutEffect(() => {
    const mm = gsap.matchMedia()

    if (!related) return () =>mm?.revert()

    mm.add({
      isDesktop: `(min-width: ${breakpoints.md}px)`,
      isMobile: `(max-width: ${breakpoints.md - 1}px)`,
    }, (context) => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        ScrollTrigger.create({
          trigger: containerRef.current,
          pin: sticky.current,
          pinSpacing: false,
          start: 'top top+=100',
          end: () => {
            const  list = sticky.current.querySelector('.related')
            return `bottom bottom-=${window.innerHeight - list.offsetHeight - 100}`
          },
        })
      }
    }, containerRef)

    return () =>mm?.revert()
  }, [])
  return (
    <StyledArticleParagraph ref={containerRef}>

      <StyledArticleParagraphContent>
        {
          paragraphs &&
          !!paragraphs.length &&
          paragraphs.map(({
            title,
            content,
            media
          }) => (
            <SubParagraph key={title} as='h2' title={title} typo="heading4">
              {
                content &&
                <Richtext as="div" typo="paragraphLarge">{content}</Richtext>
              }
              <Media {...media} />
            </SubParagraph>
          ))
        }
        <StyledArticleParagraphContentCta>
          <ShareButton negative iconStart="share" label="Condividi" />
        </StyledArticleParagraphContentCta>
      </StyledArticleParagraphContent>

      <StyledArticleParagraphAside ref={sticky}>
        <StyledArticleParagraphRelated className='related'>
          <Label as="h6" typo="heading6">{related.title}</Label>
          <StyledArticleParagraphRelatedList>
            {
              related.items.map((item, idx) => (
                <StyledArticleParagraphRelatedListItem key={item.title + idx}>
                  <BannerCard
                    {...item}
                    changeMobileLayout={false}
                    boxProps={{
                      noBorder: false, noRadius: false
                    }} />
                </StyledArticleParagraphRelatedListItem>
              ))
            }
          </StyledArticleParagraphRelatedList>

          {
            related.cta &&
            !!related.cta?.link &&
            <Button general  keyword={related.cta.keyword} href={related.cta.link.href} target={related.cta.link.target} label={related.cta.link.title}/>
          }
        </StyledArticleParagraphRelated>
      </StyledArticleParagraphAside>

    </StyledArticleParagraph>
  )
}

export default ArticleParagraph
