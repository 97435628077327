import styled from 'styled-components'
import styledMap from 'styled-map'

export const StyledBoxedImage = styled.div`
  width: 100%;
  overflow: hidden;
  transition: border-color ease-in-out .4s;
  position: relative;
  border-radius: ${({ theme }) => styledMap`
    default: ${theme.rem(16)};
    rounded: ${theme.rem(24)};
    noRadius: 0;
  `};
  border: ${({ theme }) => styledMap`
    default: 1px solid ${theme.colors.grayscale.gray200};
    noBorder: none;
  `};
  aspect-ratio: ${styledMap('ratio', {
    default: '93 / 56',
    1: '1',
    16: '16 / 9',
    4: '4 / 3'
  })};

  padding: ${({ theme }) => styledMap`
    default: ${theme.rem(8)};
    full: 0;
  `};

  img{
    ${({ theme }) => theme.size('100%')};

    object-fit: ${styledMap`
      default: contain;
      full: cover;
    `}
  }

  &:is(a){
    &:hover{
      border-color: ${({ theme }) => theme.colors.general.light()};
    }
  }
`
