import React from 'react'
import { useSwiper } from 'swiper/react'
import Balancer from 'react-wrap-balancer'
import Link from 'next/link'

import useIsMobile from 'hooks/useIsMobile'
import { Paragraph, } from '@/atoms/Typography'
import Button from '@/atoms/Button'
import {
  Label,
  Heading
} from '@/atoms/Typography'
import CustomImage from '@/atoms/CustomImage'
import Richtext from '@/atoms/RichText'

import { StyledCarouselSlideCta } from '../CarouselSlide/styles'

import {
  StyledCarouselPreview,
  StyledCarouselPreviewInfo,
  StyledCarouselPreviewImg,
} from './styles'


const SlideNextButton = ({ children }) => {
  const swiper = useSwiper()

  return <button onClick={() => swiper.slideNext()}>{children}</button>
}

const CarouselPreview = ({ data }) =>{
  const isMobile = useIsMobile()
  return (
    <StyledCarouselPreview>
      <SlideNextButton>
        <StyledCarouselPreviewImg>
          <CustomImage fill data={data.media.image} />
        </StyledCarouselPreviewImg>
        <StyledCarouselPreviewInfo>
          <Label className='next' typo="labelSmall">Prossimo</Label>
          {
            data.eyelet &&
          <Label
            as="span"
            className='eyelet'
            typo="labelBig"

          >
            {data.eyelet}
          </Label>
          }
          <Heading
            as={!!data.cta.link ? Link : 'span'}
            href={!!data.cta?.link?.href ? data.cta.link.href : false}
            typo='heading1'
            data-title
          >
            <Richtext bold>{`${data.previewLabel} ${isMobile ? data.title : data.thumbTitle}`}</Richtext>
          </Heading>

          {
            data.description &&
          <Paragraph>
            <Balancer>{data.description}</Balancer>
          </Paragraph>
          }


          {
            !!data.cta.link &&
          <StyledCarouselSlideCta>
            <Button big keyword={data.cta.keyword} href={data.cta.link.href}>{data.cta.link.title}</Button>
          </StyledCarouselSlideCta>
          }
        </StyledCarouselPreviewInfo>
      </SlideNextButton>
    </StyledCarouselPreview>
  )
}


export default CarouselPreview
