export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.93335 9C10.5902 9 11.9333 7.65685 11.9333 6C11.9333 4.34315 10.5902 3 8.93335 3C7.2765 3 5.93335 4.34315 5.93335 6C5.93335 7.65685 7.2765 9 8.93335 9Z" fill="#CFD4DB" className="branded-content"/>
    <path d="M17.7334 23.5689C19.3903 23.5689 20.7334 22.2258 20.7334 20.5689C20.7334 18.9121 19.3903 17.5689 17.7334 17.5689C16.0765 17.5689 14.7334 18.9121 14.7334 20.5689C14.7334 22.2258 16.0765 23.5689 17.7334 23.5689Z" fill="#CFD4DB" className="branded-content"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.0167 4.66666C15.0167 4.30768 15.3077 4.01666 15.6667 4.01666H23.0001C23.359 4.01666 23.6501 4.30768 23.6501 4.66666C23.6501 5.02565 23.359 5.31666 23.0001 5.31666H15.6667C15.3077 5.31666 15.0167 5.02565 15.0167 4.66666Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.349976 4.66666C0.349976 4.30768 0.64099 4.01666 0.999976 4.01666H3.93331C4.29229 4.01666 4.58331 4.30768 4.58331 4.66666C4.58331 5.02565 4.29229 5.31666 3.93331 5.31666H0.999976C0.64099 5.31666 0.349976 5.02565 0.349976 4.66666Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.349976 19.2356C0.349976 18.8766 0.64099 18.5856 0.999976 18.5856H8.33331C8.69229 18.5856 8.98331 18.8766 8.98331 19.2356C8.98331 19.5945 8.69229 19.8856 8.33331 19.8856H0.999976C0.64099 19.8856 0.349976 19.5945 0.349976 19.2356Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M19.4166 19.2356C19.4166 18.8766 19.7076 18.5856 20.0666 18.5856H23C23.3589 18.5856 23.65 18.8766 23.65 19.2356C23.65 19.5945 23.3589 19.8856 23 19.8856H20.0666C19.7076 19.8856 19.4166 19.5945 19.4166 19.2356Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.59999 1.65001C5.93393 1.65001 4.58333 3.00061 4.58333 4.66667C4.58333 6.33273 5.93393 7.68334 7.59999 7.68334C9.26605 7.68334 10.6167 6.33273 10.6167 4.66667C10.6167 3.00061 9.26605 1.65001 7.59999 1.65001ZM3.28333 4.66667C3.28333 2.28264 5.21596 0.350006 7.59999 0.350006C9.98402 0.350006 11.9167 2.28264 11.9167 4.66667C11.9167 7.0507 9.98402 8.98334 7.59999 8.98334C5.21596 8.98334 3.28333 7.0507 3.28333 4.66667Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.4 16.2189C14.734 16.2189 13.3834 17.5695 13.3834 19.2356C13.3834 20.9016 14.734 22.2522 16.4 22.2522C18.0661 22.2522 19.4167 20.9016 19.4167 19.2356C19.4167 17.5695 18.0661 16.2189 16.4 16.2189ZM12.0834 19.2356C12.0834 16.8516 14.016 14.9189 16.4 14.9189C18.7841 14.9189 20.7167 16.8516 20.7167 19.2356C20.7167 21.6196 18.7841 23.5522 16.4 23.5522C14.016 23.5522 12.0834 21.6196 12.0834 19.2356Z" fill="#194074"/>
  </svg>
)
