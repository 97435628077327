import styled from 'styled-components'

import { StyledBoxedImage } from '@/molecules/BoxedImage/styles'

import { InlineList } from '@/atoms/Layout'

export const StyledBannerCard = styled.article`

  display: grid;

  gap: ${({ theme }) => theme.rem(24)};
  grid-template-columns: 128px auto;
  ${({ withActions }) => withActions ?
    `
    grid-template-areas:
      "image content"
      "image actions";
    ` :
    'grid-template-areas: "image content";'
}


  ${({
    changeMobileLayout,
    theme,
    withActions,
  }) => changeMobileLayout && `
    grid-template-areas:
      "image content"
      "actions actions";

    ${theme.mediaQuery.md(`
      ${withActions
    ? `grid-template-areas:
        "image content"
        "image actions";`
    : `grid-template-areas:
        "image content";`}
    `)}
  ` };



  ${({ theme }) => theme.mediaQuery.md(`
    max-width: 500px;
    width: 100%;
  `)};
`

export const StyledBannerCardMedia = styled.div`
  grid-area: image;

  > ${StyledBoxedImage} {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    > img{
      position: relative;
      z-index: 1;
      max-width: 72%;
      max-height: 72%;
    }
  }
`

export const StyledBannerCardContent = styled.div`
  grid-area: content;
  align-self:start;
  display: grid;
  margin-top: ${({ theme }) => theme.rem(8)};

  > * + * {
    margin-block-start: ${({ theme }) => theme.rem(8)};
  }

  a:hover{
    text-decoration: underline;
  }

`

export const StyledBannerCardActions = styled(InlineList)`
  grid-area: actions;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.rem(8)};

  > a{
    width: 100%;
  }

  ${({ theme }) => theme.mediaQuery.md(`
    justify-content: flex-start;

    > a{
      width: auto;
    }
  `)}
`


export const StyledBannerCardMediaDecoration = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  img{
    object-fit: contain ;
  }
`
