import React, {
  useEffect,
  useRef,
  useContext,
  useMemo,
} from 'react'
import { useRouter } from 'next/router'

import { GeneralContext } from 'contexts/General'
import HeaderProvider, { HeaderContext } from 'contexts/Header'

import Logo from '@/atoms/Logo'
import SkipToContent from '@/atoms/SkipToContent'

import MenuButton from '@/molecules/Buttons/MenuButton'
import SearchButton from '@/molecules/Buttons/SearchButton'
import ClubButton from '@/molecules/Buttons/ClubButton'
import ShopButton from '@/molecules/Buttons/ShopButton'

import MainNavigation from './Navigation/MainNavigation'

import {
  StyledHeader,
  StyledHeaderWrapper,
  StyledHeaderContainer,
  StyledHeaderPart
} from './styles'

const HeaderSection = () => {
  const router = useRouter()
  const ref = useRef(null)

  const { options = {}, } = useContext(GeneralContext)
  const {
    state,
    brand,
    dispatch,
    isMobile
  } = useContext(HeaderContext)

  useEffect(() => {
    const detectOutsideClick = (e) => {
      if (isMobile || !state.opened) return
      if (!ref.current.contains(e.target)) dispatch({ type: 'close' })
    }

    window.addEventListener('click', detectOutsideClick)

    return () => {
      window.removeEventListener('click', detectOutsideClick)
    }
  }, [isMobile, state])

  useEffect(() => {
    const handleRouteChange = () => {
      dispatch({ type: 'close' })
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events])

  const logoHref = useMemo(() => {
    return brand && brand.isVertical && !options.isMainBrand ? `/${brand.slug}` : '/'
  }, [])

  return (
    <StyledHeader ref={ref}>
      <SkipToContent />
      <StyledHeaderWrapper
        isOpen={state.opened}
        position={state.position}
      >
        <StyledHeaderContainer>
          <StyledHeaderPart>
            <MenuButton />
            {
              brand &&
              <Logo brand={brand.title} image={brand.logoHeader} href={logoHref} fill/>
            }

            <MainNavigation />
          </StyledHeaderPart>

          <StyledHeaderPart right>
            <SearchButton />
            {
              !isMobile &&
              isMobile !== undefined &&
              <ClubButton reset />
            }
            <ShopButton general change />
          </StyledHeaderPart>
        </StyledHeaderContainer>
      </StyledHeaderWrapper>
    </StyledHeader>
  )
}


const Header = () => {
  return (
    <HeaderProvider>
      <HeaderSection />
    </HeaderProvider>
  )
}

export default Header
