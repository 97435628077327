import { ENDPOINTS } from 'api/config'

import {
  getEndpoint,
  fetchData
} from 'api'

export const getCategory = async ({
  type,
  id,
  params
}) =>{
  const endpoint = ENDPOINTS.category.replace('{type}', type)

  const apiURL = await getEndpoint({
    endpoint,
    dynamicParams: [id],
    queryString: {
      '_fields': 'id,type,name,slug,link,uri',
      ...params
    }
  })
  const data = await fetchData(apiURL)
  return data
}
