import React from 'react'

import Icon from '@/atoms/Icon'
import Button from '@/atoms/Button'

import { StyledFormSubmit, } from './styles'

const Submit = ({
  inline,
  submit,
  isValid,
  isSubmitting,
  errors
}) =>  (
  <StyledFormSubmit
    inline={inline}
  >

    <Button
      className="FormSubmit"
      disabled={!isValid || !!Object.entries(errors).length || isSubmitting}
      {...submit}
      rounded={inline}
    >
      {
        submit.icon &&
          <Icon name={submit.icon} />
      }
    </Button>
  </StyledFormSubmit>
)

export default Submit
