import styled from 'styled-components'

import { Heading } from '@/atoms/Typography'

import {
  StyledCardMedia,
  StyledMediaIcon,
} from '@/molecules/Card/CardMedia/styles'

export const StyledSearchCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${({ theme }) => theme.rem(16)};

  ${({ theme }) => theme.mediaQuery.md(`
    flex-direction: column;
    gap: ${theme.rem(24)};

    ${StyledCardMedia}{
      transition: box-shadow ease-in-out .4s;
      width: 100%;
    }
  `)}


  ${({ theme }) => theme.mediaQuery.lg(`
    ${StyledMediaIcon}{
      transition: transform ease-in-out .4s;
    }

    &:hover {
      ${StyledCardMedia}{
        border-color: transparent;
        box-shadow: ${theme.shadows.hover};

        ${StyledMediaIcon}{
          transform: translate(-15%, 10%);
        }
      }

      ${Heading}{
        text-decoration: underline;
      }
    }
  `)}

  ${StyledCardMedia}{
    flex-shrink: 0;
    ${({ theme }) => theme.size(122)};
    ${({ theme }) => theme.mediaQuery.md(`
      ${theme.size('100%')};
    `)}
  }


  ${({
    cardType, theme
  }) => cardType === 'products' && `
    ${StyledCardMedia}{
      aspect-ratio: 1;
      border-radius: ${theme.rem(24)};
      overflow: hidden;
      background-color: transparent;
      border: 1px solid ${theme.colors.grayscale.gray200};
      transition: box-shadow ${theme.timing.normal} ${theme.ease}, border-color ${theme.timing.normal} ${theme.ease};

      img{
        object-fit: contain;
      }
    }
  `}
`
