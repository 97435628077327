import React, { forwardRef } from 'react'

import { LinkLabel } from '@/atoms/Typography'

import { StyledLink } from './styles'
const SimpleLink = (props, forwardedRef) => {
  const {
    children,
    href = null,
    target = '_self',
    disabled = false,
    withLabel = true,
    id,
    title, // eslint-disable-line no-unused-vars
    'aria-label': ariaLabel = false,
    ...rest
  } = props

  const labelProps = { ...rest }

  const getRel = (attrs) => {
    if (attrs.target === '_blank') {
      return `${attrs.rel ? attrs.rel + ' ' : ''}noopener noreferrer`
    }
    return attrs.rel
  }

  const labelWrapper = withLabel ? <LinkLabel {...labelProps}>{children}</LinkLabel> : <>{children}</>

  return (
    <StyledLink
      id={id}
      {...rest}
      aria-label={ariaLabel}
      ref={forwardedRef}
      href={href}
      target={target}
      disabled={disabled}
      rel={getRel(props)}
    >
      {labelWrapper}
    </StyledLink>
  )
}

export default forwardRef(SimpleLink)
