import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M19.6003 2.25C20.0146 2.25 20.3503 2.58579 20.3503 3V20.9998C20.3503 21.414 20.0146 21.7498 19.6003 21.7498C19.1861 21.7498 18.8503 21.414 18.8503 20.9998V3C18.8503 2.58579 19.1861 2.25 19.6003 2.25Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M19.6007 3.75C18.5266 3.75 17.4965 4.17669 16.737 4.9362C15.9775 5.69572 15.5508 6.72584 15.5508 7.79995V14.2499H19.6007C20.0149 14.2499 20.3507 14.5857 20.3507 14.9999C20.3507 15.4141 20.0149 15.7499 19.6007 15.7499H14.8008C14.3866 15.7499 14.0508 15.4141 14.0508 14.9999V7.79995C14.0508 6.32802 14.6355 4.91636 15.6763 3.87554C16.7171 2.83473 18.1288 2.25 19.6007 2.25V3.75Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.59937 11.8501C8.01358 11.8501 8.34937 12.1859 8.34937 12.6001V21C8.34937 21.4142 8.01358 21.75 7.59937 21.75C7.18515 21.75 6.84937 21.4142 6.84937 21V12.6001C6.84937 12.1859 7.18515 11.8501 7.59937 11.8501Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M4 2.25C4.41421 2.25 4.75 2.58579 4.75 3V8.99994C4.75 9.7558 5.05026 10.4807 5.58474 11.0152C6.11921 11.5496 6.84411 11.8499 7.59997 11.8499C8.35582 11.8499 9.08073 11.5496 9.6152 11.0152C10.1497 10.4807 10.4499 9.7558 10.4499 8.99994V3C10.4499 2.58579 10.7857 2.25 11.1999 2.25C11.6141 2.25 11.9499 2.58579 11.9499 3V8.99994C11.9499 10.1536 11.4916 11.2601 10.6759 12.0758C9.86008 12.8916 8.75365 13.3499 7.59997 13.3499C6.44628 13.3499 5.33985 12.8916 4.52408 12.0758C3.7083 11.2601 3.25 10.1536 3.25 8.99994V3C3.25 2.58579 3.58579 2.25 4 2.25Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.59937 2.25C8.01358 2.25 8.34937 2.58579 8.34937 3V8.99994C8.34937 9.41416 8.01358 9.74994 7.59937 9.74994C7.18515 9.74994 6.84937 9.41416 6.84937 8.99994V3C6.84937 2.58579 7.18515 2.25 7.59937 2.25Z" fill="#194074"/>
  </svg>
)
