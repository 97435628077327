import React, { useMemo } from 'react'


import Button from '@/atoms/Button'
import { Label } from '@/atoms/Typography'
import CustomImage from '@/atoms/CustomImage'
import GraphicAnimated from '@/atoms/GraphicAnimated'

import {
  StyledSyncedSliderItem,
  StyledSyncedSliderItemImage
} from './styles'

const SyncedSliderItem = ({
  title,
  cta,
  image,
  link,
  brand,
}) => {
  const buttonProps = useMemo(() => {
    return !!cta.link && cta.link.href
      ? {
        keyword: cta.keyword,
        label: cta.link.title,
        href: cta.link.href,
        target: cta.link.target
      }
      : {
        label: title,
        href: link
      }
  }, [cta])

  return (
    <StyledSyncedSliderItem>
      <Label as="strong" typo="heading4" bold>{title}</Label>

      {
        image &&
        <StyledSyncedSliderItemImage>
          <CustomImage data={image} />
          <GraphicAnimated brand={brand}/>
        </StyledSyncedSliderItemImage>
      }

      <Button general big {...buttonProps}/>
    </StyledSyncedSliderItem>
  )
}

export default SyncedSliderItem
