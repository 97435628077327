import styled from 'styled-components'

import Button from '@/atoms/Button'
import { StyledButton } from '@/atoms/Button/styles'
import { InlineList } from '@/atoms/Layout'
import { currentGraphic } from '@/organisms/Header/styles'

export const StyledLineFilterWrapper = styled.div``

export const StyledLineFilterList = styled.div``

export const StyledLineFilterListMobile = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.rem(24)};

`
export const StyledLineFilterListMobileWrapper = styled.div`
  /* height: clamp(40px, 140px,80vh); */
  max-height: 80vh;
  overflow: auto;
  margin-top: ${({ theme }) => theme.rem(50)};
  padding: ${({ theme }) => theme.rem(24)};
  border: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};
  border-top: none;
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  border-bottom-left-radius: ${({ theme }) => theme.rem(8)};
  border-bottom-right-radius: ${({ theme }) => theme.rem(8)};

  ${StyledButton}{
    width: 100%;
    height: auto;
    justify-content: flex-start;

    &:not(.current){
      span{
        font-weight: 400;
      }
    }
  }
`

export const StyledLineFilterListContainer = styled(InlineList)`
  flex-wrap: wrap;
`

export const StyledLineFilter = styled(Button)`
  ${({ theme }) => theme.paddingX(16)};
  span{
    position: relative;
    padding-left: 0;

    &::before{
      z-index: -1;
    }

  ${({
    theme,
    current,
  }) => current
    ? `
      padding-left: ${theme.rem(8)};
      ${currentGraphic};
      &::before{
        ${theme.size(16)};
        background-color: ${theme.colors.accent()};
      }
    `
    : ''};
  }

  ${({
    current, theme
  }) => current ?
    `
      border-color: ${theme.colors.general.light()};
    `
    : ''}

  ${({ theme }) => theme.mediaQuery.md(`
    &:hover{
      border-color: ${theme.colors.general.light()}
    }
  `)}
`
