import styled, { css } from 'styled-components'
import styledMap from 'styled-map'

export const Spacer = styled.div`
  ${({
    theme, xs, lg
  }) => css`
    ${typeof xs === 'number' && `height: ${theme.spacing(xs)};`}

    ${typeof lg === 'number' && `
      ${theme.mediaQuery.lg(`
        height: ${theme.spacing(lg)};
      `)}
    `}
  `}
`

export const Page = styled.main`
  padding: ${({ theme }) =>theme.rem(100)};
`

export const Section = styled.section`
  color: ${({ theme }) => theme.colors.grayscale.white};
`

export const DividerWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const DividerWrapperItem = styled.div`
  &:not(:last-child) {
    margin-right: 30px;
  }
`

export const InlineList = styled.div`
  display: inline-flex;
  gap: ${({
    theme, gap
  }) => theme.rem(gap || 0)};
  flex-wrap: ${styledMap`
    default: nowrap;
    wrap: wrap;
    wrap-reverse: wrap-reverse;
  `};
`
