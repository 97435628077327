import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react'

import {
  motion,
  useInView
} from 'framer-motion'

import YouTube from 'react-youtube'

import CustomImage from '../CustomImage'

import {
  StyledVideoPlayer,
  StyledVideoPlayerPreview,
  StyledVideoPlayerContent,
  StyledVideoPlayerPreviewIcon
} from './styles'

const inlineOpts = {
  rel: 0,
  controls: 0,
  disablekb: 1,
  iv_load_policy: 3,
  modestbranding: 1,
  mute: 1,
}

const videoVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
}

const previewVariants = {
  hidden: {
    opacity: 1, zIndex: 1
  },
  visible: {
    opacity: 0, zIndex: -1
  }
}

const VideoPlayer = ({
  videoId = null,
  poster,
  active = false,
  videoOpts,
  notClickable = false,
}) => {
  const ref = useRef(null)
  const player = useRef(null)

  const isInView = useInView(ref)
  const [status, setReady] = useState('hidden')

  const opts = useMemo(() => ({
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      ...inlineOpts,
      playlist: videoId,
      ...videoOpts,
    },
  }), [videoId])

  const _onReady = (event) => {
    player.current = event.target
    if (!active || !isInView) return
    event.target.playVideo()
    setReady('visible')
  }

  const _onVideoPauseEnd = () => {
    if (videoOpts && videoOpts.loop) return
    setReady('hidden')
    player.current.stopVideo()
  }

  useEffect(() => {
    if (!isInView && player.current) {
      player.current.stopVideo()
      setReady('hidden')
    }

    if (isInView && active && player.current) {
      player.current.playVideo()
      setReady('visible')
    }
  }, [isInView, player])

  return (
    <StyledVideoPlayer ref={ref}>
      <StyledVideoPlayerPreview
        as={motion.div}
        initial="hidden"
        variants={previewVariants}
        animate={status}
      >
        {
          poster &&
          <CustomImage data={poster} fill />
        }

        <StyledVideoPlayerPreviewIcon
          tabIndex={notClickable ? -1 : 0}
          handleClick={() => {
            setReady('visible')
            player.current.playVideo()
          }}
          iconStart="play"
          rounded/>
      </StyledVideoPlayerPreview>

      <StyledVideoPlayerContent
        as={motion.div}
        initial="hidden"
        animate={status}
        variants={videoVariants}
      >
        <YouTube
          videoId={videoId}
          opts={opts}
          onReady={_onReady}
          onEnd={_onVideoPauseEnd}
          onPause={_onVideoPauseEnd}
        />
      </StyledVideoPlayerContent>

    </StyledVideoPlayer>
  )
}

export default VideoPlayer
