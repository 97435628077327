import React from 'react'

import Button from '@/atoms/Button'
import { useFormikContext } from 'formik'


import { StyledFormClear } from './styles'

const Clear = ({
  label,
  ...rest
}) =>  {
  const { resetForm } = useFormikContext()

  return (
    <StyledFormClear>
      <Button
        negative
        handleClick={() => {
          resetForm({ values: {} })
        }}
        label={label}
        {...rest}
      />
    </StyledFormClear>
  )
}

export default Clear
