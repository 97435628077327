import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.25 4.375C3.25 3.96079 3.58579 3.625 4 3.625H7.2C7.58216 3.625 7.90321 3.91235 7.94541 4.29218L8.93795 13.225H17.3261L18.9594 8.325H10.4C9.98579 8.325 9.65 7.98921 9.65 7.575C9.65 7.16079 9.98579 6.825 10.4 6.825H20C20.2411 6.825 20.4675 6.94089 20.6084 7.13647C20.7494 7.33204 20.7878 7.58346 20.7115 7.81217L18.5782 14.2122C18.4761 14.5184 18.1895 14.725 17.8667 14.725H8.26667C7.88451 14.725 7.56346 14.4376 7.52125 14.0578L6.52872 5.125H4C3.58579 5.125 3.25 4.78921 3.25 4.375Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.79995 18.875C8.85518 18.875 8.89995 18.8302 8.89995 18.775C8.89995 18.7198 8.85518 18.675 8.79995 18.675C8.74472 18.675 8.69995 18.7198 8.69995 18.775C8.69995 18.8302 8.74472 18.875 8.79995 18.875ZM10.4 18.775C10.4 19.6587 9.68361 20.375 8.79995 20.375C7.9163 20.375 7.19995 19.6587 7.19995 18.775C7.19995 17.8913 7.9163 17.175 8.79995 17.175C9.68361 17.175 10.4 17.8913 10.4 18.775Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.3333 18.875C17.3885 18.875 17.4333 18.8302 17.4333 18.775C17.4333 18.7198 17.3885 18.675 17.3333 18.675C17.278 18.675 17.2333 18.7198 17.2333 18.775C17.2333 18.8302 17.278 18.875 17.3333 18.875ZM18.9333 18.775C18.9333 19.6587 18.2169 20.375 17.3333 20.375C16.4496 20.375 15.7333 19.6587 15.7333 18.775C15.7333 17.8913 16.4496 17.175 17.3333 17.175C18.2169 17.175 18.9333 17.8913 18.9333 18.775Z" fill="#194074"/>
  </svg>

)
