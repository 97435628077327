import styled from 'styled-components'

import { StyledBannerCard } from '@/molecules/Card/Cards/BannerCard/styles'

import {
  StyledHero,
  StyledHeroContainer,
} from '../styles'

import { StyledHeroContent } from '../HeroContent/styles'

export const StyledHeroEditorial = styled(StyledHero)``

export const StyledHeroEditorialContainer = styled(StyledHeroContainer)`
  margin-top: ${({ theme }) => theme.rem(24)};
  gap: ${({ theme }) => theme.rem(24)};
  > nav{
    margin-bottom:  ${({ theme }) => theme.rem(24)};
  }

  ${StyledBannerCard}{
    max-width: 100%;
    margin-top: ${({ theme }) => theme.rem(16)};
  }

  ${({ theme }) => theme.mediaQuery.md(`
    grid-template-columns: auto;
    --paddingX:  ${theme.col(2)};
    margin-top: ${theme.rem(40)};
    gap: ${theme.rem(40)};
    ${theme.paddingX('var(--paddingX)')}

    ${StyledBannerCard}{
      margin-top: 0;
    }
  `)}

  ${({ theme }) => theme.mediaQuery.xxl(`
    --paddingX:  ${theme.col(1)};
  `)}
`

export const StyledHeroEditorialContent = styled.div`
  color: ${({ theme }) => theme.colors.general.light()};
  width: 100%;

  ${StyledHeroContent}{
    gap: ${({ theme }) => theme.rem(24)};
  }
`

export const StyledHeroEditorialProduct = styled.div`
  width: 100%;
`
