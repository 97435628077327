import React from 'react'
import { useField  } from 'formik'

import Icon from '../Icon'
import { Label } from '../Typography'
import Button from '../Button'

import CustomField from './index'
import {
  StyledInputFileLabel,
  StyledInputFileInstructions,
  StyledInputFileSelected,
} from './styles'

const FileInput = ({
  label,
  placeholder,
  dependsOn,
  instructions,
  hideLabel,
  ...props
}) => {
  const [field, meta, helpers] = useField(props)

  const onChange = (e) =>{
    if (e.currentTarget.files[0]) helpers.setValue(e.currentTarget.files[0])
  }

  const onRemove = () => {
    helpers.setValue('')
  }

  return (
    <CustomField
      id={props.id}
      type={props.type}
      label={label}
      field={field}
      meta={meta}
      dependsOn={dependsOn}
      hideLabel={hideLabel}
    >
      <StyledInputFileLabel>
        <Label  bold as="span" typo="buttonSmall"><Icon name="attachment" />{placeholder}</Label>
        <input tabIndex={0} id={field.name} {...field} {...props} title={label}  value={undefined}  onChange={onChange}/>
      </StyledInputFileLabel>

      {
        instructions &&
        <StyledInputFileInstructions
          as="p"
          typo="paragraphSmall">
          {instructions}
        </StyledInputFileInstructions>
      }

      {
        !!field.value &&
        field.value.name &&
        <StyledInputFileSelected>
          <Button
            rounded
            negative
            hideLabel
            label="Annulla selezione"
            handleClick={onRemove}
          />
          <Label bold as="span" typo="linkSmall">{field.value.name}</Label>
        </StyledInputFileSelected>
      }

    </CustomField>
  )
}

export default FileInput
