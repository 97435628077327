import React from 'react'
import Balancer from 'react-wrap-balancer'

import {
  Label,
  Paragraph,
} from '@/atoms/Typography'
import Button from '@/atoms/Button'

import Media from '@/molecules/Media'
import HeadingWithMark from '@/molecules/HeadingWithMark'

import {
  StyledCarouselSlide,
  StyledCarouselSlideImage,
  StyledCarouselSlideImageWrapper,
  StyledCarouselSlideContent,
  StyledCarouselSlideContentHeading,
  StyledCarouselSlideWrapper,
  StyledCarouselSlideCta,
} from './styles'
import Link from '@/atoms/Link'


const CarouselSlide = ({
  data,
  isActive
}) => {
  const {
    title,
    description,
    cta,
    media,
    eyelet
  } = data

  return (
    <StyledCarouselSlide>
      <StyledCarouselSlideImage>
        <StyledCarouselSlideImageWrapper>
          <Media
            {...media}
            notClickable
            active={isActive}
            videoOpts={{
              muted: true,
              volume: 0,
              loop: 1,
            }}/>
        </StyledCarouselSlideImageWrapper>
      </StyledCarouselSlideImage>

      <StyledCarouselSlideWrapper>
        <StyledCarouselSlideContent>
          {
            eyelet &&
            <Label bold as="span" typo="labelBig">{eyelet}</Label>
          }
          <StyledCarouselSlideContentHeading
            as={ !!cta.link ? Link : 'div'}
            href={!!cta.link ? cta.link.href : false}
          >
            <HeadingWithMark typo='heading1' content={title} />
          </StyledCarouselSlideContentHeading>
          {
            description &&
            <Paragraph>
              <Balancer>{description}</Balancer>
            </Paragraph>
          }

          {
            !!cta.link &&
            <StyledCarouselSlideCta>
              <Button big keyword={cta.keyword} href={cta.link.href}>{cta.link.title}</Button>
            </StyledCarouselSlideCta>
          }
        </StyledCarouselSlideContent>
      </StyledCarouselSlideWrapper>
    </StyledCarouselSlide>
  )
}
export default CarouselSlide
