import React from 'react'

import { motion } from 'framer-motion'

import Card  from '@/molecules/Card'

import { StyledGridItem } from './styles'

const GridItem = ({
  item,
  type,
  imageRatio,
  ...rest
}) => (
  <StyledGridItem
    as={motion.div}
    initial={{ opacity: 0 }}
    animate={{
      opacity: 1, transition: { duration: 0.6 }
    }}
  >
    <Card
      item={item}
      type={type}
      imageRatio={imageRatio}
      grid
      {...rest}
    />
  </StyledGridItem>
)

export default GridItem
