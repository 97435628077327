import styled, { css } from 'styled-components'
import styledMap from 'styled-map'

export const openedGraphic = css`
  &:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-color: ${({ theme }) => theme.colors.accent()};
    transform: translateY(4px);
    opacity: 0;
    transition: transform ease-in-out .4s, opacity ease-in-out .4s;
  }
`

export const openedStatus = css`
  &::after{
    transform: translateY(0);
    opacity: 1;
  }
`

export const currentGraphic = css`
  &::before{
    content: '';
    position: absolute;
    ${({ theme }) => theme.size(16)};
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.accent()};
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 21;
  height: ${({ theme }) => theme.rem(56)};
  margin-top: ${({ theme }) => theme.rem(8)};
  ${({ theme }) => theme.paddingX(8)};

  ${({ theme }) => theme.mediaQuery.lg(`
    height: ${theme.rem(80)};
    margin-top: 0;
    ${theme.paddingX(24)};
  `)}

`
export const StyledHeaderWrapper = styled.div`
  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.colors.grayscale.white};
  box-shadow: ${({
    theme, isOpen
  }) => isOpen ? 'none' : theme.shadows.low()};
  border-radius: ${({ theme }) => theme.rem(30)};
  position: relative;
  z-index: 5;

  &::before{
    content: '';
    ${({ theme }) => theme.size(['100vw', 64])};
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    transition: opacity ease-in-out .2s;

  ${({
    isOpen,
    position,
    theme
  }) => `
      opacity: ${isOpen && (position === 'lateral' || position === 'submenuMobile') ? 1 : 0};

      ${theme.mediaQuery.md(`
        opacity: ${isOpen ? 1 : 0};
      `)}

      ${theme.mediaQuery.lg(`
        display: none;
      `)}
    `}

  }

  ${({
    theme,
    position
  }) => theme.mediaQuery.md(`
    box-shadow: ${theme.shadows.low()};
    border-bottom-left-radius: ${position === 'lateral' || position === 'search' ? 0 : theme.rem(24)};
    border-bottom-right-radius: ${ position === 'search' ? 0 : theme.rem(24) };
  `)};

  ${({ theme }) => theme.mediaQuery.lg(`
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `)};

`

export const StyledHeaderContainer = styled.div`
  display: grid;
  grid-template-areas: "left right";
  grid-template-columns: 7fr 5fr;
  width: 100%;
  ${({ theme }) => theme.paddingX(8)}
  height: 100%;
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.mediaQuery.lg(`
    ${theme.paddingX(16)};
  `)}

`

export const StyledHeaderPart = styled.div`
  grid-area: ${styledMap`
    default: left;
    right: right;
  `};

  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: ${styledMap`
    default: flex-start;
    right: flex-end;
  `};

  column-gap: ${({ theme }) => theme.rem(8)};

  ${({ theme }) => theme.mediaQuery.lg(`
    column-gap: ${ theme.rem(24)};
  `)}

`
