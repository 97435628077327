import React from 'react'

import Form from '@/molecules/Form'

import { StyledContactForm } from './styles'

const ContactForm = (props) => {
  return (
    <StyledContactForm>
      <Form {...props.form} />
    </StyledContactForm>
  )
}

export default ContactForm
