const ChefSecretsCard = (item) => {
  const {
    acf = {},
    ...rest
  } = item
  const { tag } = acf

  let categories = null
  if (tag && !!tag.length) {
    categories = tag.map(({
      name, slug
    }) => ({
      keyword: name,
      label: name,
      link: { href: `/chef/segreti-da-chef/${slug}` }
    }))
  }
  const normalizeData = {
    ...rest,
    categories,
    hasVideo: true,
  }
  return normalizeData
}

export default ChefSecretsCard
