import styled from 'styled-components'

import { InlineList } from '@/atoms/Layout'

import { StyledRoundedIcon } from '@/molecules/RoundedIcon/styles'

import {
  StyledDefaultCard,
  StyledDefaultCardContentWrapper
} from '../DefaultCard/styles'

export const StyledRecipeCard = styled(StyledDefaultCard)``

export const StyledRecipeCardContentWrapper = styled(StyledDefaultCardContentWrapper)`
  ${({
    theme,
    icon
  }) => !!icon && `
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.rem(16)};
  `}

  > ${StyledRoundedIcon}{
    i{
      font-size: ${({ theme }) => theme.rem(40)}
    }
  }
`

export const StyledRecipeCardContent = styled.div`
  > * + * {
    margin-block-start: ${({ theme }) => theme.rem(16)};
  }
`

export const StyledRecipeCardLabels = styled(InlineList)`
  margin-top: ${({ theme }) =>theme.rem(8)};
  color: ${({ theme }) => theme.colors.general.light()};
  text-transform: capitalize;
`

