import { Label } from '@/atoms/Typography'
import styled from 'styled-components'
import styledMap from 'styled-map'

export const StyledFieldset = styled.div`
  all: unset;
  display: block;
  width: 100%;
  border-color: transparent;
  margin: 0;
  padding: 0;
  width: 100%;
  margin-block-end: ${({ theme }) => theme.rem(24)};

  ${({ theme }) =>
    theme.mediaQuery.md(`
    &:last-child { margin: 0 }
  `)}

  &:has(> div[data-selected-values="true"]) {
    border-color: ${({ theme }) => theme.colors.general.light()};
  }
`

export const StyledFieldsetTitle = styled.div`
  display: block;
  margin-bottom: ${({ theme }) => theme.rem(40)};
  cursor: ${({ onClick }) => (!!onClick ? 'pointer' : 'auto')};
  strong {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.general.light()};
  }

  ${({ icon, theme }) =>
    !!icon &&
    `
    display: inline-flex;
    align-items: center;
    gap: ${theme.rem(16)};
    max-height: ${theme.rem(65)};
    ${theme.size('100%')};



    i{
      font-size: ${theme.rem(24)};
    }

    strong{
      text-transform: none;
    }
  `}
`

export const StyledFieldsetContent = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  gap: ${({ theme }) => theme.rem(0)} ${({ theme }) => theme.rem(24)};

  ${({ theme }) =>
    styledMap('col', {
      100: 'flex-direction: column;',
      default: `
      flex-direction: column;
      ${theme.mediaQuery.md('flex-direction: row;')};
    `,
    })};

  > div {
    width: 100%;
  }
`

export const StyledFieldsetTitleSelected = styled(Label)`
  flex-shrink: 0;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.general.light()};
  color: ${({ theme }) => theme.colors.accent()};
  ${({ theme }) => theme.size(24)};
`
