import styled from 'styled-components'
import styledMap from 'styled-map'

export const StyledHero = styled.div`
  display: block;
  background-color: ${({ theme }) => theme.colors.background()};
  position: relative;
  z-index: 1;
  ${({ theme }) => theme.paddingY([80, 40])};
  ${({ theme }) => theme.paddingX(24)};

  ${({ theme }) => theme.mediaQuery.md(`

    --col-min: ${theme.col(0.5)};
    --col: calc((100vw - var(--max-width)) / 2);
    padding-left: clamp(var(--col-min), var(--col),var(--col));
    padding-right: clamp(var(--col-min), var(--col),var(--col));

    > nav{
      ${theme.marginY([34, 0])};
    }
  `)}
`

export const StyledHeroContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.col(1)};
  ${({ theme }) => theme.marginY([0, 34])};

  ${({ theme }) => theme.mediaQuery.md(`
    display: grid;
    grid-template-columns: 7fr 5fr;
  `)}
`

export const StyledHeroPart = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.general.light()};

  align-self: ${styledMap`
    default: auto;
    start: start;
    stretch: stretch;
    end: end;
    center: center;
  `};
`
