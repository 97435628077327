import styled from 'styled-components'

import Button from '@/atoms/Button'

import { StyledSingleItem } from '../SingleItem/styles'
import { StyledBoxedImage } from '@/molecules/BoxedImage/styles'

export const StyledLateralNavigation = styled.div`
  position: absolute;
  left:  -8px;
  top: 100%;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  padding: ${({ theme }) => theme.rem(24)};
  border-top: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};
  border-bottom-right-radius: ${({ theme }) => theme.rem(16)};
  border-bottom-left-radius: ${({ theme }) => theme.rem(16)};
  width: 100vw;
  height: calc(100vh - 56px);
  height: calc(100svh - 56px);
  overflow: auto;

  ${({ theme }) => theme.mediaQuery.md(`
    box-shadow: ${theme.shadows.low(7)};
    overflow: hidden;
    height: auto;
    width: 100%;
    max-width: 360px;
  `)};

  ${({ theme }) => theme.mediaQuery.lg(`
    left: 0;
  `)}

  .MenuTitle{
    display: block;
    color: ${({ theme }) => theme.colors.general.light()};
    margin-bottom: ${({ theme }) => theme.rem(16)};
  }
`
export const StyledLateralNavigationList = styled.ul`
  display: grid;
  gap: ${({ theme }) => theme.rem(16)};

  > ${StyledSingleItem}{

    &:last-child:has(> nav){
      padding-top: ${({ theme })=> theme.rem(24)};
      border-top: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};

      ${({ theme }) => theme.mediaQuery.lg(`
        padding-top: 0;
        border-top: none;
      `)}
    }
  }
`

export const StyledLateralNavigationBrands = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};
  margin-top: ${({ theme }) => theme.rem(24)};
  padding-top: ${({ theme }) => theme.rem(24)};

  strong{
    color: ${({ theme }) => theme.colors.grayscale.gray700};
  }
`

export const StyledLateralNavigationBrandsList = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin-top: ${({ theme }) => theme.rem(16)};
  gap: ${({ theme }) => theme.rem(16)};

  ${StyledBoxedImage}{
    border-radius: ${({ theme }) => theme.rem(8)};
  }
`

export const StyledLateralNavigationBrandsCta = styled(Button)`
  width: 100%;
  margin-top: ${({ theme }) => theme.rem(24)};
  `

export const StyledLateralNavigationUserCtas = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};
  margin-top: ${({ theme }) => theme.rem(24)};
  padding-top: ${({ theme }) => theme.rem(24)};
  gap: ${({ theme }) => theme.rem(16)};
  display: flex;
  flex-direction: column;
`
