import React from 'react'
import Link from 'next/link'

import { Heading } from '@/atoms/Typography'
import Button from '@/atoms/Button'

import { StyledCardContainer } from '../styles'

import {
  StyledShopCard,
  StyledShopCardMedia,
  StyledShopCardName,
} from './styles'

const ShopCard = ({ item }) => {
  const {
    title,
    image,
    cta,
  } = item
  return (
    <StyledShopCard
      as={!!cta?.link ? Link : 'div'}
      href={cta?.link?.href}
      target={cta?.link?.target}
    >
      <StyledCardContainer>
        {
          image &&
          <StyledShopCardMedia image={image} />
        }

        <StyledShopCardName>
          <Heading typo="heading5">{title}</Heading>
        </StyledShopCardName>

        {
          cta?.link?.title  &&
          <Button as="div">{cta.link.title}</Button>
        }
      </StyledCardContainer>
    </StyledShopCard>
  )
}

export default ShopCard
