import React from 'react'

export default () => (
  <svg width="129" height="130" viewBox="0 0 129 130" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M75.9408 32.5386C79.8764 21.008 91.4333 13.3519 104.055 14.7817C118.621 16.4318 129.089 29.5577 127.436 44.0992C126.515 52.2045 122.022 59.0389 115.703 63.2151C114.244 64.3301 112.531 65.4907 110.542 66.7002C92.195 77.8585 82.8898 96.6441 79.8432 103.162C79.335 104.249 77.3375 103.806 77.354 102.606C77.4784 93.5431 76.5775 82.4382 75.704 71.6725C74.3212 54.6286 73.0074 38.4348 75.9408 32.5386Z" fill="#FDC901"/>
    <path d="M43.4042 17.4297C46.3197 29.3281 55.8997 49.3952 60.9421 59.5203C61.6082 60.858 59.9135 62.1826 58.7765 61.2127C56.338 59.4273 40.7607 48.768 19.6558 44.6699C19.3774 44.6158 19.1523 44.5499 18.9786 44.473C11.3535 43.3797 4.53124 38.3074 1.57467 30.6178C-2.77279 19.3106 2.88448 6.62583 14.2105 2.28561C25.5366 -2.05461 38.2425 3.59324 42.5899 14.9004C42.9144 15.7444 43.1832 16.5961 43.398 17.4514L43.4042 17.4297Z" fill="#FDC901"/>
    <path d="M124.82 104.74C122.453 105.373 120.021 103.97 119.386 101.607C118.752 99.2445 120.157 96.8158 122.524 96.1826C124.891 95.5495 127.323 96.9517 127.958 99.3147C128.592 101.678 127.187 104.106 124.82 104.74Z" fill="#FDC901"/>
    <path d="M12.998 92.6924C5.46398 91.8389 0.0495089 85.0497 0.904423 77.5282C1.75934 70.0067 8.5599 64.6013 16.0939 65.4548C23.6279 66.3083 29.0424 73.0975 28.1875 80.619C27.3326 88.1404 20.532 93.5459 12.998 92.6924Z" fill="#FDC901"/>
  </svg>
)
