import React from 'react'

import DefaultCard from '@/molecules/Card/Cards/DefaultCard'

const ArticleCard = ({
  item,
  imageRatio,
  ...rest
}) => (
  <DefaultCard {...item}  ratio={imageRatio} {...rest}/>
)

export default ArticleCard
