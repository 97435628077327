import * as Models from 'models'

export const generateSlug = (attributes) => {
  if (attributes.homepage) return false

  if (!attributes.slug) return []

  if (!!attributes.parent) return [attributes.parent, ...[...attributes.slug.split('/')]]

  return [...attributes.slug.split('/')]
}

export const generateHref = (slug) => {
  if (!slug) return '/'
  return `/${slug.join('/')}`
}

export const generateNextSlug = ({ link }, isHomepage = false) => {
  if (isHomepage) return [isHomepage.slug]

  const url = new URL(link)
  const slug = url.pathname.split('/').filter(Boolean)
  return slug
}

export const getGeneralHomePages = (brands, mainBrand) => {
  const homepages = brands.map(({
    id, slug, acf
  }) => {
    const { homepage = {} } = acf
    return {
      brandId: id,
      brandHomepageId: homepage?.ID,
      isMainBrand: id === mainBrand,
      slug,
    }
  })

  return homepages
}

export const getBrandObj = (data, brands) => {
  const {
    type,
    acf
  } = data

  if (!acf[`brands_${type}`]) return null

  const id = acf[`brands_${type}`].ID
  const { acf: { isVertical } } = brands.find(item => item.id === id)

  return {
    id,
    isVertical,
    name: acf[`brands_${type}`].post_name,
    title: acf[`brands_${type}`].post_tile,
  }
}

export const normalizeDataForAlgoliaRequest = (obj, brand = null) =>{
  const algoliaSearchQuery = Object.entries(obj).map(([key, items]) => {
    const singleFilterParams = items.map(param => `'${key}':'${param}'`)

    if (singleFilterParams.length === 0) return false

    let joinOpt = ''
    if (singleFilterParams.length > 1) joinOpt = ' OR '

    return `(${singleFilterParams.join(joinOpt)})`
  }).filter(Boolean)

  if (!algoliaSearchQuery.length === 0) return null

  let algoliaJoinOpt = ''
  if (algoliaSearchQuery.length >= 1) algoliaJoinOpt = ' AND '

  if (brand && algoliaSearchQuery.length === 0) return `('brand':'${brand}')`
  if (brand && algoliaSearchQuery.length >= 0) return `${algoliaSearchQuery.join(algoliaJoinOpt)} AND ('brand':'${brand}')`

  return algoliaSearchQuery.join(algoliaJoinOpt)
}

export const getFiltersFromQuerystringForAlgolia = (brand = null) => {
  const queryString = new URLSearchParams(window.location.search)
  const keysToExclude = ['s']
  const queryObj = {}
  for (const [key, value] of queryString.entries()) {
    if (keysToExclude.includes(key)) continue
    if (!queryObj[key]) queryObj[key] = []
    queryObj[key].push(value)
  }

  return normalizeDataForAlgoliaRequest(queryObj, brand)
}


export const normalizeData = async (pageData = {}, additionalData) => {
  const { acf } = pageData
  const { sections } = acf
  const data = []

  if (sections && sections.length === 0) return data

  for (const section of sections) {
    let _section = section
    const type = section.acf_fc_layout ? section.acf_fc_layout.replace(/-/g, '') : null
    if (Models[type]) {
      _section = await Models[type](_section, {
        pageData,
        additionalData
      })
    }
    delete _section.acf_fc_layout
    _section.type = type
    _section.bgp = !!_section.bgp ? _section.bgp : false
    data.push(_section)
  }

  return data
}

export const normalizeMetadata = async (page) => {
  const { yoast_head: yoastMetadata } = page
  return { yoastMetadata }
}


export const normalizeBreadcrumbs = (items, currentLink) => {
  return items.map(({
    label,
    url
  }) => ({
    label,
    href: url,
    current: url === currentLink.replace(/\/$/, '')
  }))
}
