import React from 'react'

import Button from '@/atoms/Button'

import { StyledLineDecorationButton } from './styles'

const LineDecorationButton = ({
  cta,
  isExpanded,
  onClick
}) => (
  <StyledLineDecorationButton isExpanded={isExpanded}>
    <Button small typo="buttonSmall" negative iconEnd={cta.icon} label={cta.label} handleClick={onClick}  />
  </StyledLineDecorationButton>
)

export default LineDecorationButton
