import React, { Fragment } from 'react'
import Link from 'next/link'

const LinkWrapper = ({
  target,
  href,
  children
}) => {
  let isLink = false

  if (href) {
    if (['_blank', '_self', 'self'].includes(target)) {
      isLink = true
    }
  }

  return isLink ? (
    <Link href={href} passHref legacyBehavior target={target}>
      {children}
    </Link>
  ) : (
    <Fragment>{children}</Fragment>
  )
}

export default LinkWrapper
