import React, { useContext } from 'react'
import { GeneralContext } from 'contexts/General'
import { allowedBrands } from '@/utils'

import Breadcrumbs from '@/molecules/Breadcrumbs'

import StyledHeroWave from '../HeroWave'
import HeroContent from '../HeroContent'
import HeroBackground from '../HeroBackground'

import {
  StyledHeroContainer,
  StyledHeroPart
} from '../styles'

import { StyledHeroStandard } from './styles'

const HeroStandard = ({
  breadcrumbs,
  title,
  description
}) => {
  const { brand = 'parmalat' } = useContext(GeneralContext)
  const brandSelected = allowedBrands.find(i => i === brand) || 'parmalat'
  const graphicIds = [`${brandSelected}-standard-illustration`]

  return (
    <StyledHeroStandard brand={brand}>
      <Breadcrumbs items={breadcrumbs}/>
      <StyledHeroContainer>
        <StyledHeroPart>
          <HeroContent
            title={title}
            description={description}
          />
        </StyledHeroPart>

        <StyledHeroPart end>
          <HeroBackground
            brand={brand}
            graphicIds={graphicIds}
            backgroundProps={{
              fill: false,
              width: 540,
              height: 250
            }}/>
        </StyledHeroPart>
      </StyledHeroContainer>
      <StyledHeroWave />
    </StyledHeroStandard>
  )
}

export default HeroStandard
