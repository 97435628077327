import React from 'react'
import {
  AnimatePresence,
  motion
} from 'framer-motion'

import {
  Swiper,
  SwiperSlide,
} from 'swiper/react'

import useIsMobile from 'hooks/useIsMobile'
import { Label } from '@/atoms/Typography'

import GridItem from './GridItem'

import {
  StyledGrid,
  StyledGridContainer,
  StyledGridTotalItems,
} from './styles'


const Grid = ({
  list,
  type,
  columns = 3,
  showTotalItemLabel = false,
  totalItems = 0,
  mobileSlider,
  imageRatio,
}) => {
  const isMobile = useIsMobile()

  return (
    <StyledGridContainer
      showTotalItemLabel={showTotalItemLabel}
      isMobile={isMobile}
      mobileSlider={mobileSlider}
    >
      {
        showTotalItemLabel &&
        <StyledGridTotalItems>
          <Label as="span" typo="labelSmall">Ci sono <strong>{totalItems} risultati</strong></Label>
        </StyledGridTotalItems>
      }

      <AnimatePresence mode='wait'>
        {
          list &&
          !!list.length &&
            <>
              {
                (!!isMobile && mobileSlider)
                  ? (
                    <Swiper
                      spaceBetween={24}
                      slidesOffsetBefore={24}
                      slidesOffsetAfter={24}
                      slidesPerView={'auto'}
                    >
                      {
                        list.map((item) => (
                          <SwiperSlide key={item.id}>
                            <GridItem  item={item} type={type} imageRatio={3}/>
                          </SwiperSlide>
                        ))
                      }
                    </Swiper>
                  )
                  : (
                    <StyledGrid
                      as={motion.div}
                      transition={{ staggerChildren: 0.2 }}
                      columns={Number(columns)}>
                      {
                        list.map((item) => (
                          <GridItem  key={item.id} item={item} type={type} imageRatio={imageRatio} />
                        ))
                      }
                    </StyledGrid>
                  )
              }

            </>
        }
      </AnimatePresence>
    </StyledGridContainer>
  )
}


export default Grid
