import React from 'react'
import { useLenis } from '@studio-freight/react-lenis'

import { getFirstFocusableElement } from '@/utils'
import { StyledSkipToContent, StyledSkipToContentTrigger } from './styles'

const SkipToContent = () => {
  const lenis = useLenis()

  const handleScrollTo = () => {
    const element = document.getElementById('main-content')

    if (!!element) {
      const y = element.getBoundingClientRect().top

      lenis.scrollTo(y)

      getFirstFocusableElement(element)
    }
  }

  return (
    <StyledSkipToContent>
      <StyledSkipToContentTrigger onClick={handleScrollTo}>
        Vai al contenuto principale
      </StyledSkipToContentTrigger>
    </StyledSkipToContent>
  )
}


export default SkipToContent
