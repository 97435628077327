import React from 'react'
import Link from 'next/link'

import Tag from '@/atoms/Tag'
import { Heading } from '@/atoms/Typography'
import { InlineList } from '@/atoms/Layout'

import CardMedia from '@/molecules/Card/CardMedia'
import RoundedIcon from '@/molecules/RoundedIcon'

import {
  StyledDefaultCard,
  StyledDefaultCardContentWrapper,
  StyledDefaultCardContent,
  StyledDefaultCardContentContainer,
  StyledDefaultCardName,
} from './styles'


const DefaultCard = ({
  title,
  titleSize = 'heading4',
  link,
  hasVideo,
  categories,
  image,
  hoverIcon,
  icon = null,
  children = null,
  ratio = 4,
  row = false,
  chefPeople = false,
  ...rest
}) => (
  <StyledDefaultCard row={row} {...rest}>
    <CardMedia
      as={link ? Link : 'div'}
      href={link}
      ratio={ratio}
      image={image}
      chefPeople={chefPeople}
      aria-label={link ? title : false}
      icon={{
        name: hasVideo ? 'play' : 'arrow-diagonal',
        visible: hasVideo,
        ...hoverIcon
      }}
    />

    <StyledDefaultCardContentWrapper icon={icon}>
      {
        !!icon &&
        <RoundedIcon name={icon} size={64} useIconColor />
      }
      <StyledDefaultCardContentContainer>
        {
          categories &&
          !!categories.length &&
          <InlineList gap={16} wrap>
            {categories.map(category => <Tag key={category} {...category} />)}
          </InlineList>
        }
        <StyledDefaultCardContent
          as={link ? Link : 'div'}
          href={link}
        >
          <StyledDefaultCardName>
            <Heading as="strong" typo={titleSize}>{title}</Heading>
          </StyledDefaultCardName>

          {children}
        </StyledDefaultCardContent>
      </StyledDefaultCardContentContainer>
    </StyledDefaultCardContentWrapper>
  </StyledDefaultCard>
)

export default DefaultCard
