import styled from 'styled-components'

import { StyledFiltersResults } from '@/molecules/Filters/Results/styles'
import { StyledBoxedImage } from '@/molecules/BoxedImage/styles'
import { StyledTabFilter } from '@/molecules/Filters/Tab/styles'

export const StyledSearchResults = styled.div`
  ${StyledFiltersResults}{
    margin-bottom: 0;
  }

  ${StyledTabFilter}:hover ${StyledBoxedImage}{
    border-color: ${({ theme }) =>theme.colors.general.light()};;
  }
`

export const StyledNoResults = styled.div`
  display: block;
  text-align: center;
  > *+*{
    margin-block-start: ${({ theme }) => theme.rem(24)};
  }
`
