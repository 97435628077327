import React from 'react'
import { motion } from 'framer-motion'
import Richtext from '@/atoms/RichText'
import { Label } from '@/atoms/Typography'

import RoundedIcon from '@/molecules/RoundedIcon'

import {
  StyledAccordion,
  StyledAccordionHeader,
  StyledAccordionHeaderTrigger,
  StyledAccordionContentWrapper,
  StyledAccordionContent
} from './styles'

const accordionVariants = {
  open: {
    opacity: 1,
    height: 'auto'
  },
  collapsed: {
    opacity: 0,
    height: 0
  }
}

const Accordion = ({
  i,
  expanded,
  setExpanded,
  data,
}) => {
  const {
    title,
    icon, content
  } = data

  const isOpen = i === expanded

  return (
    <StyledAccordion>
      <StyledAccordionHeader>
        <StyledAccordionHeaderTrigger
          aria-expanded={isOpen}
          aria-controls={`sect${i}`}
          id={`accordion${i}id`}
          isOpen={isOpen}
          onClick={() => setExpanded(isOpen ? false : i)}
        >
          <RoundedIcon name={icon} size={40} />
          <Label bold typo="heading5">{title}</Label>
        </StyledAccordionHeaderTrigger>
      </StyledAccordionHeader>

      <StyledAccordionContentWrapper
        id={`sect${i}`}
        aria-labelledby={`accordion${i}id`}
        role="region"
        as={motion.div}
        initial="collapsed"
        animate={isOpen ? 'open' : 'collapsed'}
        exit="collapsed"
        variants={accordionVariants}
        transition={{
          duration: 0.8,
          ease: [0.04, 0.62, 0.23, 0.98]
        }}
      >
        <StyledAccordionContent>
          <Richtext typo="paragraphLarge">{content}</Richtext>
        </StyledAccordionContent>
      </StyledAccordionContentWrapper>
    </StyledAccordion>
  )
}

export default Accordion
