import React, {
  useEffect, useState
} from 'react'
import { useLenis } from '@studio-freight/react-lenis'

import useIsMobile from 'hooks/useIsMobile'

import { Label } from '@/atoms/Typography'
import { InlineList } from '@/atoms/Layout'
import Richtext from '@/atoms/RichText'

import CopyButton from '@/molecules/Buttons/CopyButton'
import EmailButton from '@/molecules/Buttons/EmailButton'
import IngredientsButton from '@/molecules/Buttons/IngredientsButton'

import {
  StyledIngredients,
  StyledIngredientsHeader,
  StyledIngredientsContent,
  StyledIngredientsFooter,
  StyledIngredientsList,
  StyledIngredientsListItem,
} from './styles'

const Ingredients = ({
  title,
  details,
  instructions,
  items,
}) => {
  const lenis = useLenis()
  const isMobile = useIsMobile()
  const [open, setOpen] = useState(false)

  const text = items.reduce((acc, curr) => {
    const {
      label, details: d
    } = curr

    return `${acc}\n${label} ${d}`
  }, '').trim()


  useEffect(() => {
    if (!isMobile && open) {
      lenis.start()
      setOpen(false)
    }
  }, [isMobile])

  const onClick = () =>{
    const newOpenStatus = !open
    if (newOpenStatus) {
      lenis?.stop()
    } else {
      lenis?.start()
    }

    setOpen(newOpenStatus)
  }
  return (
    <>
      <StyledIngredients className='ingredients' isOpen={open} data-lenis-prevent>
        <StyledIngredientsHeader>
          <InlineList>
            <Label as="strong" bold typo="heading3">{title}</Label>
            <Label as="span" typo="paragraphRegular" style={{ lineHeight: '100%' }}>{details}</Label>
          </InlineList>
          <Richtext typo="paragraphTiny" as="div">
            {instructions}
          </Richtext>
        </StyledIngredientsHeader>

        <StyledIngredientsContent>
          <StyledIngredientsList>
            {
              items &&
              !!items.length &&
              items.map(({
                label,
                details: itemDetails
              }, idx) => (
                <StyledIngredientsListItem key={label + idx}>
                  <input
                    tabIndex={0}
                    type='checkbox'
                    name={label + idx}
                    id={label + idx}
                  />
                  <Label tabIndex={-1} as="label" htmlFor={label + idx}>
                    <Label bold as="span"  typo="buttonRegular">{label}</Label>
                    <Label as="span" typo="paragraphRegular" >{itemDetails}</Label>
                  </Label>
                </StyledIngredientsListItem>
              ))
            }
          </StyledIngredientsList>
        </StyledIngredientsContent>

        <StyledIngredientsFooter>
          <EmailButton subject={title} textToMail={text} />
          <CopyButton textToCopy={text} />
        </StyledIngredientsFooter>
      </StyledIngredients>

      <IngredientsButton onClick={onClick} isOpen={open} />
    </>
  )
}


export default Ingredients
