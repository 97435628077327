import React, {
  useRef,
  useLayoutEffect,
} from 'react'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import breakpoints from 'theme/breakpoints'

import useIsMobile from 'hooks/useIsMobile'

import Richtext from '@/atoms/RichText'
import Ingredients from '@/molecules/Ingredients'
import Steps from '@/molecules/Steps'

import {
  StyledRecipeParagraph,
  StyledRecipeParagraphHeader,
  StyledRecipeParagraphContainer,
  StyledRecipeParagraphContent,
  StyledRecipeParagraphAside,
  StyledRecipeParagraphAsideContainer,
} from './styles'

const RecipeParagraph = ({
  abstract,
  steps,
  ingredients,
}) => {
  const containerRef1 = useRef(null)
  const isMobile = useIsMobile()

  useLayoutEffect(() => {
    const mm = gsap.matchMedia()

    if (!ingredients) return () =>mm?.revert()

    mm.add({
      isMobile: `(max-width: ${breakpoints.md - 1}px)`,
    }, (context) => {
      const {
        isMobile: gsapIsMobile,
      } = context.conditions

      if (gsapIsMobile) {
        ScrollTrigger.create({
          trigger: containerRef1.current,
          toggleClass: { targets: '.ingredients-wrapper', className: 'active' },
          start: 'top top',
          end: 'bottom bottom',
        })
      }
    }, containerRef1)

    return () =>mm?.revert()
  }, [])

  return (
    <StyledRecipeParagraph ref={containerRef1}>
      {
        ingredients &&
        <StyledRecipeParagraphAside >
          <StyledRecipeParagraphAsideContainer>
            <div className='ingredients-wrapper'>
              <Ingredients {...ingredients} />
            </div>
          </StyledRecipeParagraphAsideContainer>
        </StyledRecipeParagraphAside>
      }

      <StyledRecipeParagraphContainer>
        <StyledRecipeParagraphHeader>
          <Richtext as="div" typo="paragraphLarge">
            {abstract}
          </Richtext>
        </StyledRecipeParagraphHeader>
        {
          steps &&
        <StyledRecipeParagraphContent>
          <Steps isMobile={isMobile} {...steps} />
        </StyledRecipeParagraphContent>
        }
      </StyledRecipeParagraphContainer>
    </StyledRecipeParagraph>


  )
}

export default RecipeParagraph
