import styled from 'styled-components'

import { Paragraph } from '@/atoms/Typography'
import { StyledVideoPlayer } from '@/atoms/Video/styles'

export const StyledCarouselSlide = styled.div`
  position: relative;

  ${({ theme }) => theme.mediaQuery.md(`
    min-height: 100svh;
    min-height: 100vh;
  `)}
`

export const StyledCarouselSlideImage = styled.div`
  aspect-ratio: 1/1;
  position: relative;

  ${({ theme }) => theme.mediaQuery.md(`
      aspect-ratio: auto;
      position: initial;
  `)};

  ${({ theme }) =>
    theme.mediaQuery.md(`
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      width: 100%;
      height: 100%;
      mix-blend-mode: multiply;
      background: linear-gradient(
        73deg,
        rgba(0, 0, 0, 0.8) -0.21%,
        rgba(0, 0, 0, 0.5) 50.68%,
        rgba(0, 0, 0, 0) 79.26%
      );
    }
  `)}
`

export const StyledCarouselSlideImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  ${({ theme }) => theme.size('100%')}

  ${StyledVideoPlayer}{
    pointer-events: none;

    iframe{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100vw;

      transform: translate(-50%, -50%);
      @media (min-aspect-ratio: 16/9) {
        height: 56.25vw;
      }

      @media (max-aspect-ratio: 16/9) {
        width: 177.78vh;

        ${({ theme }) => theme.mediaQuery.md(`
          height: 100vh;
        `)}
      }
    }
  }

  > div{
    height: 100%;
    border-radius: 0;

    > div{
      height: 100%;
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

`

export const StyledCarouselSlideWrapper = styled.div`
  display: none;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: end;
  padding: ${({ theme }) => theme.rem(48)} ${({ theme }) => theme.rem(24)} ${({ theme }) => theme.rem(160)};
  border-radius: ${({ theme }) => `${theme.rem(24)} ${theme.rem(24)} 0 0`};
  overflow: hidden;
  z-index: 9;
  background-color: ${({ theme }) => theme.colors.background()};


  ${({ theme }) => theme.mediaQuery.md(`
    --col-min: ${theme.col(0.5)};
    --col: calc((100vw - var(--max-width)) / 2);

    display: flex;
    background: none;
    margin-top: 0;
    border-radius: 0;
    min-height: 100svh;
    min-height: 100vh;
    padding-left: clamp(var(--col-min), var(--col),var(--col));
    padding-right: clamp(var(--col-min), var(--col),var(--col));
    ${theme.paddingY(120)};
  `)}
`
export const StyledCarouselSlideCta = styled.div`
  a{
    width: 100%;
    ${({ theme }) => theme.mediaQuery.md(`
      width: auto;
    `)}
  }
`

export const StyledCarouselSlideContent = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQuery.md(`
    color: ${theme.colors.grayscale.white};
    width: calc(100vw / 12 * 4);
  `)}

  > span{
    margin-bottom: ${({ theme }) => theme.rem(8)};
  }

  > ${Paragraph}{
    margin-top: ${({ theme }) => theme.rem(16)};
  }

  > ${StyledCarouselSlideCta}{
    margin-top: ${({ theme }) => theme.rem(24)};
  }
`


export const StyledCarouselSlideContentHeading = styled.div`
  color: ${({ theme }) => theme.colors.grayscale.white};

  &[href]{
    cursor: pointer;

    &:hover{
      text-decoration: underline;
    }
  }

`
