export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.63994 20L6 15.0205L7.14005 10L10 19.1798L7.63994 20Z" fill="#CFD4DB" className="branded-content"/>
    <path d="M16 17.8571L13 19L10 11L16 17.8571Z" fill="#CFD4DB" className="branded-content"/>
    <path d="M4 22L4.21432 21.4742L5.19046 18L6 21.3533L4 22Z" fill="#CFD4DB" className="branded-content"/>
    <path d="M18 6C17.6044 6 17.2178 5.8827 16.8889 5.66294C16.56 5.44318 16.3036 5.13082 16.1522 4.76537C16.0009 4.39991 15.9613 3.99778 16.0384 3.60982C16.1156 3.22186 16.3061 2.86549 16.5858 2.58579C16.8655 2.30608 17.2219 2.1156 17.6098 2.03843C17.9978 1.96126 18.3999 2.00087 18.7654 2.15224C19.1308 2.30362 19.4432 2.55996 19.6629 2.88886C19.8827 3.21776 20 3.60444 20 4C20 4.53043 19.7893 5.03914 19.4142 5.41421C19.0391 5.78929 18.5304 6 18 6Z" fill="#CFD4DB" className="branded-content"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.20694 6.46471C6.39245 6.42091 6.58742 6.4763 6.72221 6.61108L17.3889 17.2778C17.5237 17.4125 17.579 17.6075 17.5353 17.793C17.4915 17.9785 17.3547 18.1282 17.1739 18.1884L1.17389 23.5218C0.976257 23.5876 0.758365 23.5362 0.611057 23.3889C0.46375 23.2416 0.412312 23.0237 0.47819 22.8261L5.81152 6.82607C5.8718 6.64523 6.02142 6.5085 6.20694 6.46471ZM6.57366 8.01817L1.86959 22.1304L15.9818 17.4263L6.57366 8.01817Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.1739 1.47823C10.4621 1.57429 10.6178 1.88577 10.5218 2.17393L9.52175 5.17393C9.4257 5.4621 9.11422 5.61784 8.82605 5.52178C8.53788 5.42573 8.38215 5.11425 8.4782 4.82608L9.4782 1.82608C9.57426 1.53791 9.88573 1.38218 10.1739 1.47823Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M22.5218 13.8261C22.6178 14.1143 22.4621 14.4257 22.1739 14.5218L19.1739 15.5218C18.8857 15.6178 18.5743 15.4621 18.4782 15.1739C18.3821 14.8858 18.5379 14.5743 18.8261 14.4782L21.8261 13.4782C22.1142 13.3822 22.4257 13.5379 22.5218 13.8261Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.5548 1.66039C17.2899 1.55064 16.9983 1.52193 16.7171 1.57787C16.4358 1.63382 16.1774 1.77192 15.9746 1.97471C15.7719 2.17749 15.6338 2.43586 15.5778 2.71713C15.5219 2.9984 15.5506 3.28995 15.6603 3.5549C15.7701 3.81986 15.9559 4.04631 16.1944 4.20564C16.4328 4.36497 16.7132 4.45001 17 4.45001C17.3845 4.45001 17.7533 4.29725 18.0253 4.02532C18.2972 3.75339 18.45 3.38458 18.45 3.00001C18.45 2.71323 18.3649 2.43289 18.2056 2.19444C18.0463 1.95598 17.8198 1.77013 17.5548 1.66039ZM16.5025 0.499011C16.9971 0.400619 17.5098 0.451117 17.9758 0.64412C18.4417 0.837124 18.84 1.16396 19.1202 1.58331C19.4004 2.00265 19.55 2.49567 19.55 3.00001C19.55 3.67631 19.2813 4.32492 18.8031 4.80313C18.3249 5.28135 17.6763 5.55001 17 5.55001C16.4956 5.55001 16.0026 5.40046 15.5832 5.12026C15.1639 4.84006 14.8371 4.44181 14.6441 3.97586C14.4511 3.5099 14.4006 2.99718 14.4989 2.50253C14.5973 2.00788 14.8402 1.55351 15.1968 1.19689C15.5535 0.840267 16.0078 0.597403 16.5025 0.499011Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M23 2.45001C23.3037 2.45001 23.55 2.69626 23.55 3.00001C23.55 4.73718 22.8599 6.4032 21.6315 7.63156C20.4031 8.85992 18.7371 9.55001 17 9.55001C16.6962 9.55001 16.45 9.30377 16.45 9.00001C16.45 8.69626 16.6962 8.45001 17 8.45001C18.4454 8.45001 19.8316 7.87582 20.8537 6.85374C21.8758 5.83167 22.45 4.44544 22.45 3.00001C22.45 2.69626 22.6962 2.45001 23 2.45001Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M5.47119 21.1511L3.47119 14.1511L4.52887 13.8489L6.52887 20.8489L5.47119 21.1511Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.4783 20.1739L6.47827 8.17393L7.52182 7.82608L11.5218 19.8261L10.4783 20.1739Z" fill="#194074"/>
  </svg>
)
