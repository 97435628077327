import styled from 'styled-components'

export const StyledWithDecorationButton = styled.div`
  margin:  ${({ theme }) => theme.rem(20)} auto 0;
  display: table;
  text-align: center;
  position: relative;

  ${({ theme }) => theme.mediaQuery.md(`
    margin:  ${theme.rem(120)} auto ${theme.rem(80)};
  `)}

  a{
    min-width: 0;

    grid-area: button;
  }
`


export const StyledWithDecoration = styled.div`
  display: none;
  grid-area: right-icon;
  position: absolute;
  left: 100%;
  margin-left: ${({ theme }) => theme.rem(40)};
  bottom: 28px;

  i{
    font-size: ${({ theme }) => theme.rem(100)};
  }

  ${({ theme }) => theme.mediaQuery.md(`
    display: inline-block;
  `)}

`
