import React from 'react'

import SyncedSlider from '@/molecules/Sliders/SyncedSlider'

const ProductSlider = ({ list }) => (
  <SyncedSlider {...list} />
)


export default ProductSlider
