import styled from 'styled-components'

import { StyledGraphicAnimated } from '@/atoms/GraphicAnimated/styles'

export const StyledSyncedCarousel = styled.div`
  width: 100%;
  display: block;
`

export const StyledSyncedSlider = styled.div`
  color: ${({ theme }) => theme.colors.general.light()};

  ${({ theme }) => theme.mediaQuery.md(`
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    padding-right: ${theme.col(1 / 2)};
  `)}
`

export const StyledSyncedSliderItem = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  ${({ theme }) => theme.mediaQuery.md(`
    row-gap: ${theme.rem(40)};
  `)};

  strong{
    order: 2;
    ${({ theme }) => theme.marginY([24, 40])};

    ${({ theme }) => theme.mediaQuery.md(`
      order: 1;
      ${theme.marginY(0)};
    `)}
  }

  a{
    order: 3;

    ${({ theme }) => theme.mediaQuery.md(`
      align-self: center;
    `)}
  }

  .swiper-slide-active &{
    ${StyledGraphicAnimated}{
      z-index: 0;
      svg{
        opacity: 1;
        transform: scale(1);
      }
    }
  }
`

export const StyledSyncedSliderItemImage = styled.div`
  order: 1;
  aspect-ratio: 1;
  display: block;
  height: 100%;
  width: 65%;
  padding-top: 65%;
  position: relative;
  margin: 0 auto;

  ${({ theme }) => theme.mediaQuery.md(`
    width: 50%;
    padding-top: 50%;
  `)}

  ${({ theme }) => theme.mediaQuery.lg(`
    order: 2;
    max-width: 320px;
    width: 100%;
  `)}

  img{
    position: absolute;
    z-index:1;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%) scale(0);
    object-fit: contain;
    filter:drop-shadow(0px 0px 22px rgba(0, 0, 0, 0.25));
    ${({ theme }) => theme.size('73%')};
    transition: transform ${({ theme }) => theme.timing.normal} ${({ theme }) => theme.ease};

    ${({ theme }) => theme.mediaQuery.md(`
      ${theme.size('100%')};
    `)}

    .swiper-slide-active &{
      transform:translate(-50%, -50%) scale(1);
    }
  }
`

export const StyledSyncedSliderNavigation = styled.nav`
  position: absolute;
  width: 100%;
  left: 0%;
  top: 20%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  pointer-events: none;

  ${({ theme }) => theme.mediaQuery.md(`
    top: 50%;
    transform: translateY(-50%);
  `)}
`

export const StyledSyncedSliderNavigationButton = styled.button`
  cursor: pointer;
  pointer-events: auto;
  flex-shrink: 0;
  display: grid;
  place-items: center;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};
  ${({ theme }) => theme.size(40)};
  color: ${({ theme }) => theme.colors.general.light()};

  ${({ theme }) => theme.mediaQuery.md(`
    ${theme.size(48)};
  `)}

  &.swiper-button-disabled{
    opacity: 0.25;
  }

  i{
    font-size: ${({ theme }) => theme.rem(24)};
  }

  ${({ theme }) => theme.mediaQuery.md(`
    transition: border-color ease-in-out .4s;
    &:not(.swiper-button-disabled):hover{
      border-color: ${theme.colors.general.light()};
    }
  `)}
`


export const StyledSyncedImages = styled.div`
  margin-top: ${({ theme }) => theme.rem(40)};

  ${({ theme }) => theme.mediaQuery.md(`
    width: 50%;
    vertical-align: middle;
    display: inline-block;
    aspect-ratio: 1;
    margin-top: 0;

    .swiper-slide{
      ${theme.paddingY(60)};
      ${theme.paddingX(`${theme.col(1 / 2)}`)};
    }
  `)}
`
export const StyledSyncedImagesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
