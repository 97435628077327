import styled from 'styled-components'

export const StyledBackToTopButton = styled.button`
  display: grid;
  place-items: center;
  cursor: pointer;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.accent()};
  background-color: ${({ theme }) => theme.colors.general.light()};
  border: 1px solid ${({ theme }) => theme.colors.accent()};

  ${({ theme }) => theme.size(48)};

  ${({ theme }) => theme.mediaQuery.md(theme.size(64))};

  i{
    font-size: ${({ theme }) => theme.rem(24)};

    ${({ theme }) => theme.mediaQuery.md(`
      font-size: ${theme.rem(32)};
    `)};
  }
`
