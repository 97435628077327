import React from 'react'

import Info from '@/molecules/Info'
import DefaultCard from '@/molecules/Card/Cards/DefaultCard'

import { StyledRecipeCardLabels } from './styles'

const RecipeCard = ({
  item,
  imageRatio,
}) => {
  const {
    badges,
    ...rest
  } = item

  return (
    <DefaultCard ratio={imageRatio} {...rest}>
      {
        badges &&
        !!badges.length &&
        <StyledRecipeCardLabels gap={24}>
          {
            badges.map((badge, idx) => (
              <Info key={badge.label + idx} className="RecipeCard_infos" label={badge.label} icon={badge.icon}/>
            ))
          }

        </StyledRecipeCardLabels>
      }

    </DefaultCard>
  )
}

export default RecipeCard
