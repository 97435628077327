export default () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5 24H6.5C5.11917 24 4 23.2837 4 22.4V18C4 16.8954 4.89543 16 6 16H19V22.4C19 23.2837 17.8808 24 16.5 24Z" fill="#CFD4DB" className="branded-content"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M18.85 8.49998C18.85 8.14099 19.141 7.84998 19.5 7.84998H23.5C23.859 7.84998 24.15 8.14099 24.15 8.49998C24.15 8.85896 23.859 9.14998 23.5 9.14998H19.5C19.141 9.14998 18.85 8.85896 18.85 8.49998Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.849976 7.84998H20.15V20.5C20.15 22.516 18.516 24.15 16.5 24.15H4.49998C2.48399 24.15 0.849976 22.516 0.849976 20.5V7.84998ZM2.14998 9.14998V20.5C2.14998 21.798 3.20196 22.85 4.49998 22.85H16.5C17.798 22.85 18.85 21.798 18.85 20.5V9.14998H2.14998Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.849976 12.85H20.15V14.15H0.849976V12.85Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.5 0.849976C10.859 0.849976 11.15 1.14099 11.15 1.49998V4.49998C11.15 4.85896 10.859 5.14998 10.5 5.14998C10.141 5.14998 9.84998 4.85896 9.84998 4.49998V1.49998C9.84998 1.14099 10.141 0.849976 10.5 0.849976Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M5.49998 0.849976C5.85896 0.849976 6.14998 1.14099 6.14998 1.49998V4.49998C6.14998 4.85896 5.85896 5.14998 5.49998 5.14998C5.14099 5.14998 4.84998 4.85896 4.84998 4.49998V1.49998C4.84998 1.14099 5.14099 0.849976 5.49998 0.849976Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.5 0.849976C15.859 0.849976 16.15 1.14099 16.15 1.49998V4.49998C16.15 4.85896 15.859 5.14998 15.5 5.14998C15.141 5.14998 14.85 4.85896 14.85 4.49998V1.49998C14.85 1.14099 15.141 0.849976 15.5 0.849976Z" fill="#194074"/>
  </svg>
)
