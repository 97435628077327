import styled from 'styled-components'

import { InlineList } from '@/atoms/Layout'

export const StyledContacts = styled(InlineList)`
  width: 100%;
  justify-content: space-between;
  align-items: stretch;
  gap: ${({ theme }) => theme.rem(24)};
  flex-direction: column;

  ${({ theme }) => theme.mediaQuery.md(`
    flex-direction: row;
    gap: ${theme.rem(30)}
  `)};

  ${({ theme }) => theme.mediaQuery.lg(`
    gap: ${theme.rem(60)}
  `)};
`
