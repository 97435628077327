import styled from 'styled-components'

import {
  StyledHero,
  StyledHeroContainer
} from '../styles'


export const StyledHeroStandard = styled(StyledHero)`
  ${({ brand, theme }) => (brand === 'chef' || brand === 'zymil' || brand === 'santal') && `
    padding-bottom: ${theme.rem(20)};

    ${theme.mediaQuery.md(`
      padding-bottom: ${theme.rem(40)};
    `)}

    ${StyledHeroContainer}{
      margin-bottom: 0;
    }
  `}
`
