import React from 'react'

export default () => (
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M33.3882 24.2976C33.1383 23.9672 32.668 23.902 32.3376 24.1519C27.9541 27.4675 24.1092 31.6379 21.0887 36.6213L21.0885 36.6215C17.7938 42.0618 15.8001 47.9206 15.0067 53.8506C14.9518 54.2611 15.24 54.6385 15.6506 54.6934C16.0612 54.7483 16.4385 54.4601 16.4934 54.0495C17.26 48.3196 19.1862 42.6584 22.3714 37.3988C25.2909 32.5822 29.006 28.5526 33.2425 25.3482C33.5729 25.0983 33.6381 24.628 33.3882 24.2976Z" fill="#98D1F1"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M41.1925 99.7402C41.3581 99.3606 41.8001 99.187 42.1798 99.3525C47.4784 101.663 53.3166 102.96 59.47 102.96C65.1023 102.96 70.4787 101.869 75.4136 99.9128C75.7987 99.7601 76.2346 99.9485 76.3872 100.334C76.5399 100.719 76.3515 101.155 75.9664 101.307C70.8613 103.331 65.2977 104.46 59.47 104.46C53.1034 104.46 47.0616 103.117 41.5803 100.727C41.2006 100.562 41.027 100.12 41.1925 99.7402Z" fill="#98D1F1"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M85.8018 24.3975C86.0517 24.0672 86.5221 24.0019 86.8524 24.2518C91.2359 27.5674 95.0808 31.7378 98.1014 36.7212L98.1015 36.7215C101.396 42.1618 103.39 48.0205 104.183 53.9505C104.238 54.3611 103.95 54.7384 103.539 54.7934C103.129 54.8483 102.752 54.56 102.697 54.1494C101.93 48.4195 100.004 42.7584 96.8186 37.4987C93.8992 32.6822 90.1841 28.6526 85.9476 25.4481C85.6172 25.1983 85.552 24.7279 85.8018 24.3975Z" fill="#98D1F1"/>
    <path d="M39.6153 19.6866L39.1726 18.6818L41.528 17.6439L41.4676 17.5067C41.3756 17.298 41.2598 17.1196 41.1202 16.9713C40.9842 16.8214 40.8269 16.7224 40.6484 16.6743C40.4735 16.6246 40.2762 16.6482 40.0566 16.7449C39.7382 16.8853 39.5646 17.1213 39.5359 17.453C39.5093 17.7795 39.6065 18.1934 39.8274 18.6949L40.0186 19.1287C40.1492 19.4251 40.296 19.6839 40.459 19.905C40.6256 20.1244 40.8217 20.2719 41.0473 20.3473C41.2749 20.4175 41.5443 20.384 41.8554 20.2469C42.119 20.1308 42.3581 19.9839 42.5727 19.8063C42.7893 19.6234 42.9877 19.4289 43.1679 19.2228C43.3261 19.2842 43.4762 19.3819 43.6184 19.516C43.7642 19.6485 43.8803 19.7831 43.9668 19.9198C43.7814 20.2332 43.5244 20.5278 43.1957 20.8037C42.8708 21.0781 42.5143 21.3007 42.1263 21.4717C41.6651 21.6749 41.2479 21.7648 40.8747 21.7412C40.5015 21.7177 40.1664 21.6119 39.8693 21.4237C39.5743 21.2302 39.3151 20.9794 39.0919 20.6713C38.8671 20.3595 38.674 20.0205 38.5127 19.6545C38.2708 19.1054 38.1373 18.5742 38.1121 18.0607C38.0869 17.5473 38.1944 17.0869 38.4345 16.6795C38.673 16.2684 39.0649 15.9427 39.6103 15.7024C40.0899 15.4911 40.5403 15.4172 40.9617 15.4807C41.3851 15.5389 41.7602 15.7058 42.0871 15.9814C42.4177 16.2553 42.6797 16.612 42.8732 17.0512C42.9668 17.2635 43.0367 17.4469 43.0829 17.6014C43.1275 17.7522 43.1668 17.9207 43.2007 18.1068L39.6153 19.6866Z" fill="#98D1F1"/>
    <path d="M45.6976 19.3151C45.6605 19.147 45.6592 18.9505 45.6937 18.7257C45.7273 18.497 45.7858 18.3072 45.8693 18.1562C46.1557 18.2197 46.4496 18.2646 46.7509 18.2909C47.0522 18.3172 47.3419 18.2928 47.6199 18.2177C47.8864 18.1457 48.0789 18.0378 48.1974 17.8938C48.3199 17.7489 48.3611 17.5948 48.3211 17.4316C48.2836 17.2926 48.1975 17.1811 48.063 17.0973C47.9324 17.0125 47.7509 16.9621 47.5187 16.9461L46.7942 16.887C46.5007 16.8586 46.2221 16.8013 45.9584 16.7151C45.6976 16.6239 45.4724 16.488 45.2827 16.3072C45.0968 16.1254 44.9632 15.8839 44.8818 15.5827C44.7806 15.2081 44.7842 14.8612 44.8927 14.5418C45.0001 14.2186 45.1962 13.9398 45.4809 13.7054C45.7656 13.4711 46.1184 13.297 46.5393 13.1833C46.8791 13.0915 47.2508 13.0408 47.6542 13.0312C48.0567 13.0178 48.3957 13.038 48.6713 13.092C48.7114 13.2096 48.7333 13.3446 48.7372 13.4968C48.741 13.6491 48.7249 13.7964 48.6888 13.9388C48.6555 14.0762 48.6057 14.1912 48.5396 14.2836C48.2885 14.2437 48.0124 14.2189 47.7115 14.209C47.4133 14.1943 47.1233 14.225 46.8414 14.3012C46.6406 14.3554 46.4958 14.4484 46.4071 14.5801C46.3212 14.7069 46.2981 14.8437 46.3377 14.9904C46.3732 15.1217 46.4493 15.2192 46.5659 15.283C46.6826 15.3468 46.8469 15.3874 47.0587 15.4047L47.8474 15.4713C48.3255 15.5078 48.7446 15.6328 49.1048 15.8463C49.4677 16.0548 49.7134 16.3966 49.8417 16.8716C49.9919 17.4276 49.8966 17.9257 49.5556 18.3659C49.2146 18.8061 48.6773 19.1253 47.9436 19.3236C47.5304 19.4352 47.1291 19.4835 46.7397 19.4686C46.3493 19.4498 46.0019 19.3986 45.6976 19.3151Z" fill="#98D1F1"/>
    <path d="M51.945 12.0534C52.2672 12.0192 52.5278 12.1061 52.7269 12.3143C52.93 12.522 53.0526 12.8247 53.0949 13.2225L53.7663 19.547C53.803 19.893 53.7656 20.1464 53.6539 20.3071C53.5422 20.4678 53.3293 20.5648 53.0151 20.5981L52.4125 20.6621L52.3267 20.5928L51.4342 12.186L51.5035 12.1002L51.945 12.0534ZM56.8468 14.5197C56.9211 15.2197 56.8716 15.8143 56.6982 16.3033C56.5289 16.7919 56.2777 17.1726 55.9446 17.4453C55.6155 17.7175 55.2461 17.8754 54.8364 17.9189C54.4386 17.9611 54.0698 17.9158 53.7299 17.7829C53.3939 17.6497 53.0832 17.5077 52.7977 17.357L52.8962 16.182C53.1463 16.3405 53.4087 16.4816 53.6833 16.6053C53.9574 16.725 54.2158 16.772 54.4585 16.7462C54.6653 16.7243 54.8345 16.6319 54.9661 16.4691C55.1012 16.3019 55.1931 16.0689 55.2418 15.7701C55.2941 15.4669 55.2979 15.1044 55.2531 14.6828C55.2083 14.2612 55.1305 13.9255 55.0195 13.6759C54.9121 13.4218 54.7844 13.2422 54.6365 13.1373C54.4882 13.0283 54.3305 12.9827 54.1634 13.0005C54.0043 13.0173 53.8321 13.0819 53.6469 13.1941C53.4657 13.3058 53.2994 13.4824 53.1481 13.7237C53.0003 13.9606 52.8936 14.2817 52.828 14.6869L52.3887 13.7319C52.4131 13.4316 52.5142 13.1333 52.6919 12.8369C52.8692 12.5365 53.1054 12.2801 53.4007 12.0678C53.6959 11.8554 54.0344 11.729 54.4163 11.6884C54.8857 11.6386 55.2894 11.7285 55.6275 11.9581C55.9692 12.1832 56.2419 12.5163 56.4456 12.9573C56.6493 13.3982 56.783 13.919 56.8468 14.5197Z" fill="#98D1F1"/>
    <path d="M61.7468 16.4519C61.7793 16.565 61.8056 16.6879 61.8255 16.8205C61.8454 16.9532 61.8534 17.0855 61.8493 17.2175C61.8476 17.2734 61.8438 17.3333 61.8378 17.3972C61.832 17.457 61.8202 17.5127 61.8026 17.5642C61.642 17.5833 61.4574 17.5996 61.2489 17.6132C61.0402 17.6308 60.836 17.6365 60.6361 17.6304C60.1683 17.616 59.8301 17.4876 59.6214 17.245C59.4169 16.9986 59.3222 16.6276 59.3375 16.1318L59.563 8.79127L59.6494 8.71588L60.2551 8.73449C60.571 8.7442 60.7886 8.82492 60.908 8.97666C61.0275 9.1244 61.0818 9.37419 61.071 9.72602L60.8822 15.8731C60.8708 16.2409 61.0311 16.43 61.363 16.4402L61.7468 16.4519Z" fill="#98D1F1"/>
    <path d="M68.68 15.5077C68.5788 16.1275 68.3891 16.6558 68.111 17.0927C67.8336 17.5256 67.4762 17.8401 67.0388 18.0361C66.6061 18.2288 66.0996 18.2778 65.5193 18.183C64.935 18.0875 64.4668 17.8773 64.1146 17.5522C63.767 17.2239 63.5326 16.8107 63.4113 16.3126C63.29 15.8145 63.2797 15.2575 63.3803 14.6417C63.481 14.0259 63.668 13.5012 63.9415 13.0676C64.219 12.6347 64.5744 12.3199 65.0078 12.1232C65.4451 11.9272 65.9539 11.8765 66.5342 11.9714C67.1185 12.0668 67.5844 12.2787 67.932 12.6071C68.2842 12.9321 68.5209 13.3437 68.6422 13.8418C68.7681 14.3365 68.7807 14.8918 68.68 15.5077ZM64.9732 14.902C64.9164 15.2494 64.8915 15.5756 64.8984 15.8807C64.9059 16.1819 64.9718 16.4358 65.0961 16.6426C65.2211 16.8454 65.4256 16.97 65.7099 17.0165C66.002 17.0642 66.2376 17.0115 66.4166 16.8584C66.6002 16.7019 66.7436 16.4822 66.8466 16.1991C66.9502 15.912 67.0304 15.5948 67.0872 15.2474C67.1433 14.904 67.1679 14.5797 67.161 14.2746C67.1541 13.9695 67.0882 13.7155 66.9633 13.5127C66.8429 13.3066 66.6367 13.1797 66.3446 13.132C66.0604 13.0855 65.8264 13.1405 65.6428 13.297C65.4598 13.4494 65.3165 13.6692 65.2129 13.9563C65.1092 14.2433 65.0293 14.5585 64.9732 14.902Z" fill="#98D1F1"/>
    <path d="M75.6116 14.4251C75.5521 14.6118 75.4488 14.8308 75.3017 15.082C75.1546 15.3332 74.9847 15.5436 74.7922 15.7132C74.7143 15.6548 74.6333 15.6059 74.5492 15.5666C74.4652 15.5273 74.3806 15.4898 74.2953 15.4543C74.1124 15.396 73.9389 15.3807 73.7748 15.4082C73.6107 15.4357 73.4553 15.4954 73.3085 15.5872C73.163 15.6752 73.0222 15.7878 72.8861 15.925C72.7501 16.0622 72.6206 16.2119 72.4975 16.3742L72.3853 16.074C72.494 15.7854 72.6256 15.5041 72.78 15.23C72.9394 14.9533 73.1228 14.7137 73.33 14.511C73.5385 14.3045 73.7769 14.1621 74.0453 14.0838C74.3175 14.0068 74.6194 14.021 74.951 14.1266C75.092 14.1715 75.2116 14.218 75.3096 14.266C75.4089 14.3102 75.5096 14.3632 75.6116 14.4251ZM71.4922 13.8755C71.5652 13.7518 71.6742 13.627 71.8192 13.501C71.9681 13.3763 72.1055 13.2941 72.2314 13.2545C72.6706 13.4573 72.9524 13.759 73.0767 14.1596C73.201 14.5602 73.2003 15.0238 73.0746 15.5505L71.826 19.4725C71.717 19.4588 71.5965 19.4351 71.4645 19.4015C71.3312 19.3717 71.1979 19.3355 71.0645 19.2931C70.9349 19.2518 70.8053 19.2042 70.6755 19.1503C70.5483 19.1014 70.4344 19.0505 70.3338 18.9974L71.5442 15.1955C71.6474 14.8715 71.6822 14.5971 71.6488 14.3724C71.6154 14.1477 71.5632 13.9821 71.4922 13.8755Z" fill="#98D1F1"/>
    <path d="M75.395 18.8948C75.5999 18.4696 75.8631 18.1679 76.1843 17.9898C76.5092 17.8133 76.8614 17.7367 77.241 17.7599C77.6242 17.7848 78.0068 17.8893 78.3887 18.0734C78.5689 18.1603 78.7798 18.2708 79.0214 18.4051C79.2685 18.5375 79.4852 18.6775 79.6715 18.8251L79.2312 19.7385C79.0773 19.6155 78.8869 19.4948 78.6597 19.3764C78.438 19.2562 78.2388 19.1535 78.0622 19.0683C77.7776 18.9311 77.5294 18.9025 77.3176 18.9825C77.1076 19.0588 76.9436 19.2195 76.8254 19.4645C76.7369 19.6483 76.7171 19.8275 76.7661 20.0021C76.8151 20.1767 76.9351 20.3101 77.1261 20.4021C77.3639 20.5168 77.6515 20.5511 77.989 20.5051C78.3301 20.4608 78.6636 20.3263 78.9895 20.1016L78.7311 21.1626C78.5177 21.2552 78.2783 21.3418 78.0129 21.4225C77.7493 21.4997 77.4653 21.536 77.1611 21.5314C76.8623 21.525 76.5525 21.4445 76.2318 21.2899C75.8679 21.1144 75.6043 20.8874 75.4411 20.6089C75.2815 20.3321 75.2021 20.0407 75.2031 19.7348C75.2077 19.4306 75.2716 19.1506 75.395 18.8948ZM79.7117 21.9885C79.6221 22.1007 79.4957 22.2063 79.3325 22.3052C79.173 22.4059 79.0281 22.467 78.8978 22.4886C78.6361 22.3313 78.4482 22.1453 78.3342 21.9305C78.2202 21.7156 78.1625 21.4946 78.161 21.2674C78.1648 21.0384 78.2002 20.8223 78.267 20.6192L79.411 18.2465C79.5465 17.9655 79.5788 17.728 79.508 17.534C79.439 17.3364 79.2748 17.1751 79.0153 17.05C78.7631 16.9284 78.4774 16.8395 78.1581 16.7833C77.8406 16.7234 77.5161 16.6735 77.1845 16.6336C77.1356 16.4679 77.1239 16.2758 77.1494 16.0571C77.1785 15.8402 77.2292 15.6382 77.3017 15.4511C77.5702 15.4652 77.8588 15.5021 78.1674 15.5621C78.4796 15.6238 78.7857 15.7026 79.0859 15.7984C79.3914 15.8924 79.663 15.9968 79.9008 16.1114C80.2359 16.273 80.5126 16.4819 80.731 16.7382C80.9494 16.9944 81.0781 17.2985 81.1172 17.6504C81.158 17.9986 81.0733 18.3908 80.8631 18.8267L79.7999 21.0318C79.7148 21.2084 79.6609 21.3756 79.6381 21.5333C79.6153 21.6911 79.6399 21.8428 79.7117 21.9885Z" fill="#98D1F1"/>
    <path d="M103.575 62.2404L104.666 62.3634L104.378 64.9212L104.527 64.938C104.753 64.9635 104.966 64.9533 105.164 64.9072C105.362 64.8651 105.527 64.779 105.658 64.6489C105.789 64.5228 105.868 64.3405 105.895 64.102C105.934 63.7562 105.817 63.4874 105.545 63.2957C105.276 63.1084 104.87 62.9841 104.325 62.9227L103.854 62.8697C103.532 62.8334 103.235 62.83 102.962 62.8596C102.688 62.8932 102.462 62.9885 102.283 63.1455C102.108 63.3069 102.002 63.5565 101.963 63.8944C101.931 64.1806 101.938 64.4611 101.983 64.7359C102.032 65.0151 102.101 65.2845 102.188 65.5439C102.055 65.6497 101.895 65.7303 101.708 65.7857C101.52 65.845 101.345 65.8776 101.184 65.8836C101.006 65.5657 100.881 65.1953 100.808 64.7725C100.734 64.3536 100.721 63.9335 100.769 63.5122C100.825 63.0113 100.957 62.6056 101.166 62.295C101.374 61.9843 101.634 61.7479 101.946 61.5859C102.262 61.4282 102.609 61.3305 102.987 61.2927C103.37 61.2553 103.76 61.259 104.157 61.3038C104.754 61.371 105.28 61.5229 105.736 61.7595C106.193 61.9961 106.536 62.3206 106.768 62.7331C107.003 63.146 107.087 63.6486 107.02 64.2409C106.962 64.7616 106.799 65.188 106.532 65.5202C106.269 65.8569 105.936 66.0971 105.533 66.2409C105.13 66.3887 104.69 66.4357 104.213 66.3819C103.982 66.356 103.789 66.3241 103.632 66.2863C103.479 66.2489 103.314 66.1981 103.136 66.1338L103.575 62.2404Z" fill="#98D1F1"/>
    <path d="M100.838 67.662C101.002 67.7147 101.172 67.8126 101.349 67.9557C101.53 68.0999 101.664 68.2461 101.753 68.3943C101.553 68.6097 101.367 68.841 101.192 69.0879C101.017 69.3349 100.893 69.5974 100.817 69.8754C100.745 70.1418 100.742 70.3625 100.806 70.5374C100.87 70.7162 100.982 70.8295 101.143 70.8772C101.282 70.9148 101.422 70.8967 101.562 70.8227C101.701 70.7526 101.836 70.6213 101.967 70.4288L102.383 69.8328C102.555 69.5935 102.745 69.3818 102.953 69.1975C103.163 69.0182 103.394 68.8921 103.645 68.8194C103.896 68.7505 104.172 68.7567 104.473 68.8382C104.848 68.9396 105.146 69.1175 105.367 69.3721C105.592 69.6278 105.734 69.9376 105.793 70.3017C105.852 70.6657 105.824 71.0582 105.71 71.479C105.618 71.8188 105.475 72.1654 105.28 72.5187C105.089 72.873 104.9 73.1557 104.715 73.3665C104.593 73.3419 104.465 73.2928 104.332 73.2194C104.199 73.146 104.079 73.0578 103.975 72.9549C103.873 72.8569 103.798 72.756 103.752 72.6523C103.913 72.4555 104.074 72.2296 104.234 71.9745C104.397 71.7244 104.516 71.4584 104.593 71.1766C104.647 70.9758 104.64 70.8039 104.57 70.6609C104.504 70.5228 104.398 70.4339 104.251 70.3942C104.12 70.3587 103.997 70.3753 103.883 70.4439C103.769 70.5125 103.652 70.634 103.53 70.8082L103.075 71.4559C102.802 71.8504 102.483 72.1494 102.117 72.353C101.754 72.5614 101.335 72.6013 100.86 72.4728C100.304 72.3224 99.9223 71.989 99.7139 71.4727C99.5055 70.9563 99.5006 70.3313 99.6991 69.5977C99.8109 69.1846 99.9713 68.8136 100.18 68.4848C100.393 68.1571 100.613 67.8828 100.838 67.662Z" fill="#98D1F1"/>
    <path d="M103.959 76.74C103.827 77.0357 103.62 77.2172 103.34 77.2845C103.059 77.3554 102.735 77.3091 102.37 77.1455L96.5671 74.5434C96.2495 74.401 96.0494 74.2412 95.9667 74.0638C95.8841 73.8865 95.9074 73.6536 96.0366 73.3653L96.2846 72.8123L96.3877 72.7731L104.102 76.2318L104.141 76.3349L103.959 76.74ZM99.3616 79.7351C98.7192 79.4471 98.2303 79.1051 97.8949 78.7093C97.5579 78.3171 97.3553 77.9084 97.2873 77.4834C97.2176 77.062 97.267 76.6633 97.4356 76.2874C97.5992 75.9224 97.824 75.6264 98.1098 75.3995C98.3941 75.1763 98.6731 74.9792 98.947 74.8083L99.9128 75.4846C99.65 75.621 99.3961 75.7768 99.151 75.9518C98.9096 76.1285 98.739 76.3282 98.6391 76.5508C98.554 76.7406 98.5487 76.9333 98.6232 77.1289C98.6997 77.3298 98.8548 77.5265 99.0886 77.7189C99.3243 77.9167 99.6356 78.1023 100.022 78.2758C100.409 78.4492 100.739 78.5508 101.01 78.5806C101.284 78.6156 101.503 78.5956 101.668 78.5206C101.837 78.4472 101.956 78.3339 102.025 78.1806C102.09 78.0346 102.121 77.8534 102.117 77.6369C102.112 77.424 102.043 77.1915 101.911 76.9393C101.78 76.6923 101.556 76.4385 101.239 76.178L102.286 76.2789C102.533 76.4511 102.74 76.6885 102.906 76.9913C103.077 77.2956 103.179 77.6288 103.214 77.9908C103.249 78.3527 103.188 78.7089 103.031 79.0593C102.838 79.49 102.557 79.7937 102.189 79.9704C101.822 80.1524 101.397 80.2204 100.914 80.1746C100.43 80.1287 99.9127 79.9822 99.3616 79.7351Z" fill="#98D1F1"/>
    <path d="M95.2194 83.0057C95.1053 82.9771 94.9859 82.9381 94.8611 82.8887C94.7364 82.8394 94.618 82.7798 94.5059 82.71C94.4584 82.6804 94.4085 82.647 94.3563 82.6098C94.3074 82.5747 94.2652 82.5366 94.2295 82.4956C94.2937 82.3471 94.3722 82.1792 94.4651 81.9921C94.5547 81.8028 94.6523 81.6233 94.758 81.4535C95.0053 81.0562 95.2862 80.8282 95.6007 80.7695C95.9165 80.7163 96.2849 80.8208 96.706 81.0829L102.941 84.9638L102.963 85.0763L102.642 85.5908C102.475 85.8591 102.296 86.0067 102.105 86.0338C101.917 86.063 101.674 85.9845 101.375 85.7985L96.1541 82.5486C95.8417 82.3542 95.5978 82.3979 95.4223 82.6797L95.2194 83.0057Z" fill="#98D1F1"/>
    <path d="M92.5716 89.4606C92.0861 89.0623 91.7239 88.6334 91.4853 88.1738C91.2497 87.7167 91.1567 87.2498 91.2063 86.7731C91.2564 86.3021 91.4679 85.8392 91.8408 85.3846C92.2162 84.9269 92.6329 84.6271 93.0907 84.4853C93.549 84.349 94.0241 84.3533 94.5159 84.498C95.0076 84.6427 95.4948 84.9129 95.9772 85.3086C96.4597 85.7044 96.82 86.1292 97.0581 86.5832C97.2936 87.0403 97.3879 87.5056 97.3409 87.9792C97.2913 88.4559 97.0801 88.9216 96.7072 89.3762C96.3317 89.8339 95.9148 90.1309 95.4565 90.2671C94.9987 90.4089 94.5239 90.4075 94.0321 90.2628C93.5409 90.1237 93.0541 89.8563 92.5716 89.4606ZM94.9536 86.5565C94.6815 86.3333 94.4117 86.1482 94.1442 86.0013C93.8798 85.8568 93.627 85.7866 93.3858 85.7905C93.1477 85.797 92.9373 85.9115 92.7547 86.1342C92.567 86.3631 92.4945 86.5933 92.5373 86.825C92.5806 87.0623 92.699 87.2965 92.8923 87.5275C93.0887 87.7611 93.323 87.9895 93.5952 88.2127C93.8643 88.4334 94.1325 88.6172 94.4 88.7642C94.6675 88.9111 94.9202 88.9814 95.1584 88.9749C95.397 88.9741 95.6102 88.8592 95.7979 88.6304C95.9806 88.4077 96.0502 88.1777 96.0069 87.9404C95.9667 87.7056 95.8483 87.4715 95.6519 87.2379C95.4554 87.0044 95.2227 86.7772 94.9536 86.5565Z" fill="#98D1F1"/>
    <path d="M90.0313 96.0016C89.8997 95.8564 89.7622 95.6571 89.6188 95.4037C89.4755 95.1503 89.3788 94.8978 89.3288 94.6462C89.4184 94.6081 89.5014 94.5626 89.5776 94.5097C89.6538 94.4568 89.7287 94.4024 89.8022 94.3465C89.9444 94.2175 90.0449 94.0752 90.1034 93.9195C90.162 93.7638 90.1885 93.5994 90.1828 93.4264C90.1798 93.2563 90.1531 93.078 90.1027 92.8915C90.0524 92.705 89.9879 92.5177 89.9093 92.3298L90.2253 92.3835C90.4203 92.6224 90.5976 92.8775 90.7571 93.1486C90.9163 93.4254 91.0315 93.7043 91.1027 93.9853C91.1766 94.2693 91.18 94.547 91.1129 94.8184C91.0429 95.0925 90.8789 95.3464 90.6211 95.5802C90.5115 95.6796 90.4113 95.7596 90.3206 95.8203C90.2325 95.884 90.1361 95.9444 90.0313 96.0016ZM92.575 92.7151C92.6454 92.8403 92.6986 92.9972 92.7347 93.1859C92.7678 93.3772 92.7699 93.5373 92.7409 93.6662C92.345 93.9442 91.9426 94.0363 91.5338 93.9427C91.1249 93.8491 90.7243 93.6157 90.3319 93.2425L87.5671 90.1934C87.6337 90.106 87.7147 90.0137 87.8101 89.9164C87.9028 89.8162 88.001 89.719 88.1047 89.625C88.2054 89.5336 88.3117 89.4454 88.4234 89.3602C88.5296 89.2748 88.6308 89.2019 88.7272 89.1415L91.4074 92.0973C91.6358 92.3491 91.8555 92.517 92.0667 92.601C92.2778 92.685 92.4472 92.723 92.575 92.7151Z" fill="#98D1F1"/>
    <path d="M86.2757 93.5718C86.5397 93.963 86.6677 94.3423 86.6598 94.7096C86.6486 95.0791 86.5373 95.4219 86.3261 95.7382C86.1115 96.0567 85.8285 96.3345 85.477 96.5717C85.3112 96.6835 85.1095 96.81 84.8717 96.9511C84.6329 97.0978 84.4028 97.2145 84.1814 97.3011L83.6143 96.4606C83.798 96.3897 83.9982 96.2859 84.2149 96.1494C84.4305 96.0184 84.6196 95.898 84.782 95.7884C85.044 95.6117 85.1938 95.4117 85.2313 95.1885C85.2712 94.9686 85.215 94.7459 85.0629 94.5205C84.9488 94.3513 84.8039 94.244 84.6284 94.1984C84.4528 94.1528 84.2772 94.1892 84.1015 94.3078C83.8826 94.4555 83.7081 94.6867 83.5778 95.0014C83.4442 95.3183 83.3924 95.6741 83.4224 96.0689L82.636 95.3111C82.6635 95.0802 82.7093 94.8298 82.7732 94.5599C82.8394 94.2932 82.9511 94.0297 83.1083 93.7692C83.2644 93.5143 83.49 93.2872 83.7851 93.0881C84.12 92.8621 84.4489 92.7488 84.7717 92.7481C85.0912 92.7497 85.3829 92.8279 85.6467 92.9829C85.9072 93.1401 86.1168 93.3364 86.2757 93.5718ZM81.4286 95.7422C81.3768 95.6083 81.3493 95.4459 81.346 95.2551C81.3394 95.0666 81.3596 94.9106 81.4066 94.7872C81.6743 94.6403 81.9296 94.5718 82.1726 94.5815C82.4156 94.5913 82.6356 94.6527 82.8326 94.7659C83.0286 94.8846 83.1974 95.024 83.3392 95.1841L84.8125 97.3675C84.987 97.6261 85.1759 97.7737 85.3791 97.8103C85.5846 97.8502 85.8067 97.7896 86.0454 97.6285C86.2775 97.4719 86.4983 97.2699 86.7077 97.0224C86.9193 96.7783 87.1259 96.5231 87.3274 96.2568C87.4952 96.298 87.6671 96.3847 87.8431 96.5169C88.0158 96.6512 88.1647 96.7969 88.2899 96.9537C88.1425 97.1786 87.9651 97.4093 87.7579 97.6456C87.5473 97.8842 87.325 98.109 87.091 98.32C86.8559 98.5365 86.6289 98.7186 86.4101 98.8663C86.1017 99.0744 85.7819 99.2082 85.4505 99.2677C85.1191 99.3272 84.7916 99.2853 84.4679 99.1417C84.1465 99.0015 83.8505 98.7308 83.5798 98.3296L82.2105 96.3004C82.1009 96.1379 81.9836 96.0071 81.8588 95.9079C81.734 95.8088 81.5906 95.7535 81.4286 95.7422Z" fill="#98D1F1"/>
    <path d="M34.602 96.1164L33.9481 96.9985L31.8803 95.4656L31.791 95.5861C31.6552 95.7693 31.5574 95.9582 31.4977 96.153C31.4348 96.3453 31.4266 96.531 31.4732 96.7099C31.5166 96.8865 31.6347 97.0462 31.8275 97.1891C32.1071 97.3964 32.3981 97.4304 32.7005 97.2911C32.9973 97.1526 33.3089 96.8633 33.6353 96.423L33.9175 96.0423C34.1105 95.782 34.2627 95.5263 34.3741 95.2753C34.4822 95.0219 34.5133 94.7785 34.4672 94.5452C34.4155 94.3126 34.2531 94.0952 33.9799 93.8927C33.7486 93.7212 33.5026 93.5861 33.2421 93.4876C32.9759 93.3899 32.7087 93.3138 32.4404 93.2593C32.4154 93.0914 32.426 92.9125 32.4722 92.7227C32.5151 92.5304 32.5746 92.3629 32.6506 92.2201C33.0146 92.226 33.3979 92.3035 33.8003 92.4525C34.1995 92.599 34.5694 92.7986 34.9101 93.0511C35.3149 93.3512 35.5995 93.6692 35.7638 94.0051C35.9281 94.341 36.002 94.6846 35.9856 95.0359C35.9635 95.388 35.8739 95.7373 35.7166 96.0838C35.5569 96.4335 35.358 96.769 35.1198 97.0904C34.7625 97.5724 34.367 97.9514 33.9333 98.2274C33.4997 98.5034 33.0464 98.638 32.5736 98.631C32.0983 98.6273 31.6213 98.4479 31.1425 98.093C30.7216 97.7809 30.4344 97.4261 30.281 97.0286C30.122 96.6319 30.0813 96.2233 30.1589 95.8028C30.2334 95.38 30.4135 94.9758 30.6994 94.5902C30.8375 94.4038 30.9623 94.2523 31.0736 94.1357C31.1826 94.0223 31.3096 93.9048 31.4545 93.7832L34.602 96.1164Z" fill="#98D1F1"/>
    <path d="M31.2955 91.03C31.1676 91.1453 30.9974 91.2435 30.7849 91.3244C30.5695 91.4082 30.3755 91.451 30.203 91.453C30.1167 91.1725 30.0106 90.8948 29.8847 90.6198C29.7589 90.3448 29.5946 90.105 29.392 89.9003C29.1978 89.7041 29.0089 89.59 28.8252 89.558C28.6387 89.5231 28.4843 89.5634 28.3621 89.6787C28.2598 89.78 28.2054 89.9098 28.1989 90.0682C28.1897 90.2237 28.2354 90.4064 28.3362 90.6162L28.6426 91.2754C28.7628 91.5447 28.8505 91.8152 28.9057 92.0871C28.9553 92.3588 28.9483 92.6219 28.8848 92.8761C28.8184 93.1275 28.6744 93.3629 28.4527 93.5824C28.1769 93.8554 27.8735 94.0236 27.5422 94.087C27.2081 94.1531 26.8689 94.1203 26.5245 93.9885C26.18 93.8566 25.8545 93.6358 25.5477 93.3259C25.3001 93.0757 25.0725 92.7776 24.865 92.4315C24.6546 92.0882 24.5048 91.7834 24.4156 91.517C24.4981 91.4241 24.6046 91.3384 24.7351 91.2598C24.8656 91.1813 25.0017 91.1226 25.1433 91.0837C25.2793 91.0448 25.4038 91.0313 25.5169 91.0432C25.6062 91.2812 25.7209 91.5335 25.8609 91.8001C25.9954 92.0667 26.1653 92.3037 26.3707 92.5112C26.517 92.659 26.6694 92.739 26.8277 92.7511C26.9804 92.7632 27.1107 92.7158 27.2188 92.6089C27.3154 92.5132 27.3627 92.3989 27.3605 92.2659C27.3583 92.133 27.3125 91.9701 27.223 91.7773L26.8914 91.0586C26.6871 90.6248 26.5889 90.1986 26.5967 89.7801C26.5988 89.3615 26.7747 88.9791 27.1243 88.633C27.5337 88.2277 28.0139 88.0647 28.5651 88.1439C29.1162 88.223 29.6591 88.5327 30.1938 89.0728C30.4949 89.377 30.7351 89.702 30.9144 90.048C31.0908 90.3968 31.2178 90.7241 31.2955 91.03Z" fill="#98D1F1"/>
    <path d="M21.8758 89.1549C21.6873 88.8914 21.6345 88.6217 21.7175 88.3459C21.7981 88.0669 22.0011 87.8109 22.3264 87.5781L27.4983 83.8767C27.7813 83.6742 28.0202 83.5819 28.2151 83.5999C28.4099 83.6178 28.5993 83.7553 28.7832 84.0123L29.1359 84.5051L29.1179 84.6139L22.2431 89.534L22.1342 89.5159L21.8758 89.1549ZM21.6058 83.6743C22.1783 83.2645 22.72 83.0146 23.2309 82.9244C23.7396 82.8309 24.1946 82.8619 24.596 83.0173C24.9951 83.1694 25.3145 83.413 25.5543 83.7481C25.7871 84.0734 25.9295 84.4166 25.9814 84.7779C26.031 85.1359 26.0606 85.4762 26.0702 85.7989L24.9994 86.2923C25.014 85.9966 25.0074 85.6988 24.9797 85.3989C24.9487 85.1013 24.8623 84.8533 24.7203 84.6549C24.5992 84.4857 24.4354 84.384 24.2289 84.3498C24.0169 84.3147 23.7688 84.3495 23.4848 84.4544C23.1952 84.5584 22.878 84.7337 22.5332 84.9805C22.1884 85.2273 21.9348 85.4605 21.7722 85.6801C21.6041 85.8988 21.5108 86.0983 21.4924 86.2788C21.4707 86.4615 21.5087 86.6212 21.6065 86.7578C21.6996 86.888 21.8406 87.0059 22.0295 87.1118C22.2161 87.2144 22.4516 87.2721 22.7362 87.2848C23.0152 87.2967 23.3472 87.2312 23.732 87.0886L23.1176 87.9415C22.8443 88.0682 22.5349 88.1273 22.1895 88.1188C21.8407 88.1126 21.5012 88.0334 21.1709 87.8812C20.8406 87.729 20.5637 87.4967 20.3403 87.1845C20.0656 86.8006 19.9448 86.405 19.9779 85.9977C20.0054 85.5894 20.1608 85.188 20.4441 84.7934C20.7274 84.3988 21.1146 84.0258 21.6058 83.6743Z" fill="#98D1F1"/>
    <path d="M20.8781 78.4669C20.9603 78.3828 21.0542 78.2994 21.1598 78.2166C21.2654 78.1338 21.3765 78.0616 21.4933 78C21.5428 77.9739 21.5968 77.9476 21.6553 77.9213C21.7102 77.8969 21.7644 77.8796 21.8179 77.8695C21.9137 77.9998 22.019 78.1523 22.1338 78.3269C22.252 78.4997 22.3578 78.6745 22.4511 78.8514C22.6695 79.2654 22.7247 79.6229 22.6167 79.9241C22.5033 80.2236 22.2273 80.489 21.7886 80.7204L15.2928 84.1467L15.1846 84.1088L14.9019 83.5728C14.7545 83.2933 14.7173 83.0642 14.7903 82.8854C14.8599 82.7086 15.0503 82.538 15.3616 82.3738L20.8013 79.5046C21.1268 79.3329 21.2121 79.1002 21.0572 78.8066L20.8781 78.4669Z" fill="#98D1F1"/>
    <path d="M16.6297 72.9306C17.2183 72.7115 17.7712 72.6145 18.2884 72.6396C18.8019 72.6661 19.2522 72.8208 19.6392 73.1035C20.021 73.3839 20.3145 73.7996 20.5196 74.3507C20.7262 74.9055 20.7755 75.4164 20.6677 75.8834C20.5547 76.348 20.3119 76.7563 19.9393 77.1085C19.5668 77.4606 19.0881 77.7455 18.5033 77.9632C17.9185 78.1809 17.3701 78.2784 16.858 78.2556C16.3444 78.2291 15.8949 78.0764 15.5093 77.7974C15.1224 77.5146 14.8263 77.0977 14.6211 76.5467C14.4146 75.9919 14.3678 75.4822 14.4808 75.0175C14.5887 74.5506 14.8289 74.141 15.2014 73.7889C15.5688 73.4344 16.0449 73.1484 16.6297 72.9306ZM17.9402 76.4506C18.2701 76.3278 18.5658 76.1878 18.8274 76.0307C19.0853 75.8749 19.2732 75.6918 19.3912 75.4814C19.5055 75.2725 19.5124 75.033 19.4119 74.7631C19.3086 74.4857 19.1461 74.3072 18.9244 74.2275C18.6975 74.1455 18.4356 74.1299 18.1387 74.1807C17.838 74.2329 17.5227 74.3204 17.1929 74.4432C16.8667 74.5646 16.5728 74.7039 16.3112 74.8611C16.0496 75.0182 15.8617 75.2013 15.7474 75.4103C15.628 75.6169 15.62 75.859 15.7232 76.1364C15.8237 76.4063 15.9874 76.5822 16.2143 76.6642C16.4374 76.7476 16.6993 76.7632 17 76.711C17.3007 76.6588 17.6141 76.572 17.9402 76.4506Z" fill="#98D1F1"/>
    <path d="M12.2521 67.4372C12.4437 67.3963 12.6851 67.3775 12.9762 67.3808C13.2673 67.3841 13.5342 67.4273 13.777 67.5105C13.7649 67.6071 13.7626 67.7017 13.77 67.7942C13.7775 67.8866 13.7869 67.9787 13.7983 68.0703C13.8384 68.2581 13.911 68.4164 14.0163 68.5453C14.1215 68.6742 14.2503 68.7796 14.4028 68.8616C14.5514 68.9444 14.7189 69.0109 14.9055 69.061C15.0921 69.1112 15.2864 69.1495 15.4883 69.1759L15.2832 69.4221C14.9787 69.4708 14.6691 69.496 14.3545 69.4977C14.0352 69.4963 13.7362 69.4558 13.4574 69.3763C13.1748 69.2975 12.9329 69.161 12.7319 68.9667C12.5301 68.7685 12.3929 68.4992 12.3202 68.1589C12.2893 68.0141 12.2704 67.8873 12.2635 67.7783C12.2527 67.6702 12.2489 67.5565 12.2521 67.4372ZM13.8163 71.2875C13.6727 71.2854 13.5103 71.2526 13.329 71.189C13.1469 71.1215 13.0075 71.0429 12.9106 70.9532C12.8691 70.4712 12.9915 70.077 13.2778 69.7705C13.5641 69.4639 13.9671 69.2347 14.4869 69.0829L18.5122 68.2236C18.5543 68.3251 18.5934 68.4415 18.6297 68.5728C18.6698 68.7033 18.7045 68.837 18.7337 68.9739C18.7621 69.1069 18.785 69.2431 18.8025 69.3825C18.8231 69.5172 18.8353 69.6414 18.8391 69.7551L14.937 70.5881C14.6045 70.6591 14.3489 70.7647 14.1703 70.9051C13.9917 71.0455 13.8737 71.173 13.8163 71.2875Z" fill="#98D1F1"/>
    <path d="M16.2404 65.4107C15.7695 65.4426 15.3772 65.363 15.0635 65.1718C14.7496 64.9766 14.5089 64.7082 14.3414 64.3668C14.1736 64.0214 14.0754 63.6371 14.0467 63.2141C14.0332 63.0146 14.0251 62.7766 14.0224 62.5001C14.0154 62.2199 14.03 61.9624 14.0662 61.7274L15.0778 61.6588C15.0469 61.8533 15.0362 62.0785 15.0455 62.3345C15.0506 62.5867 15.0597 62.8106 15.073 63.0062C15.0944 63.3214 15.1922 63.5514 15.3664 63.6959C15.5365 63.8407 15.7573 63.9039 16.0287 63.8855C16.2322 63.8717 16.3978 63.8003 16.5254 63.6714C16.6529 63.5425 16.7095 63.3723 16.6952 63.1608C16.6773 62.8974 16.565 62.6303 16.3582 62.3597C16.1512 62.0851 15.8694 61.8617 15.5129 61.6894L16.563 61.3896C16.7489 61.5294 16.9425 61.6947 17.1439 61.8855C17.3413 62.0765 17.5132 62.3054 17.6596 62.5721C17.8017 62.8351 17.8848 63.1442 17.9089 63.4994C17.9362 63.9025 17.8692 64.2438 17.7077 64.5234C17.546 64.7989 17.3319 65.0119 17.0655 65.1623C16.7988 65.3087 16.5237 65.3915 16.2404 65.4107ZM16.7963 60.129C16.9381 60.1514 17.0924 60.2091 17.259 60.302C17.4254 60.391 17.5502 60.4868 17.6333 60.5894C17.626 60.8946 17.5571 61.1498 17.4267 61.3551C17.2963 61.5604 17.1327 61.7198 16.9359 61.8334C16.7349 61.9433 16.5296 62.0193 16.32 62.0616L13.692 62.2399C13.3807 62.261 13.1582 62.3502 13.0246 62.5076C12.8869 62.6653 12.8279 62.8878 12.8473 63.1752C12.8663 63.4545 12.9302 63.7469 13.0391 64.0522C13.144 64.3578 13.2611 64.6646 13.3902 64.9725C13.2704 65.0969 13.1091 65.2021 12.9064 65.288C12.7035 65.3699 12.5028 65.4256 12.3044 65.4551C12.1838 65.2147 12.0734 64.9456 11.973 64.6477C11.8723 64.3458 11.7895 64.0407 11.7245 63.7324C11.6552 63.4204 11.6116 63.1327 11.5938 62.8693C11.5686 62.4981 11.6134 62.1543 11.7283 61.8378C11.8431 61.5213 12.0438 61.2591 12.3304 61.0513C12.613 60.8437 12.9958 60.7235 13.4787 60.6907L15.9211 60.5251C16.1166 60.5118 16.2886 60.4761 16.437 60.4179C16.5854 60.3598 16.7052 60.2634 16.7963 60.129Z" fill="#98D1F1"/>
  </svg>


)
