import React, {
  useContext, useMemo
} from 'react'
import { HeaderContext } from 'contexts/Header'


import SingleNavigation from '../SingleNavigation'
import SecondaryNavigation from '../SecondaryNavigation'

import { isCurrent } from '../utils'

import {
  StyledSingleItem,
  StyledSingleItemLink,
} from './styles'

const SingleItem = ({
  title,
  href,
  target,
  children: list = [],
  main = false,
  mainCta,
}) => {
  const {
    state,
    dispatch,
    isMobile,
  } = useContext(HeaderContext)


  const type = useMemo(() => {
    return isMobile ? 'submenuMobile' : 'submenu'
  }, [state, isMobile])

  const navLinkProps = useMemo(() => {
    const current = isCurrent(state.current, href)
    const objProps = {
      current,
      label: title,
      'aria-label': title
    }

    return (
      main &&
      list &&
      !!list.length
        ? Object.assign(objProps, {
          'aria-haspopup': 'menu',
          'aria-expanded': state.opened && state.id === title,
          opened: state.id === title,
          onClick: () => {
            const typeValue = state.opened && state.id === title ? 'close' : type
            dispatch({ id: title, type: typeValue })
          }
        })
        : Object.assign(objProps, {
          href,
          target,
        })
    )
  }, [list, type, state])

  return (
    <StyledSingleItem>
      {
        !main && list
          ? <SingleNavigation title={title} list={list} />
          : (
            <StyledSingleItemLink
              reset
              typo="link2"
              iconEnd="chevron-right"
              {...navLinkProps}
            />
          )
      }

      {
        main &&
        list && !!list.length &&
        <SecondaryNavigation data={list} type={type} {...mainCta} id={title}/>
      }
    </StyledSingleItem>
  )
}

export default SingleItem
