import styled from 'styled-components'
import { Paragraph } from '@/atoms/Typography'
import { StyledCarouselSlideCta } from '../CarouselSlide/styles'

export const StyledHeroSlideCta = styled.div``

export const StyledCarouselPreview = styled.div`
  display: flex;
  height: 100%;
  cursor: pointer;

  > button {
    pointer-events: none;

    a{
      pointer-events: auto;
    }

  ${({ theme }) => theme.mediaQuery.md(`
      pointer-events: auto;

      a{
        pointer-events: none;
      }
    `)}
  }

  ${({ theme }) => theme.mediaQuery.md(`
    visibility: visible;
    width: ${theme.rem(330)};
    right: ${theme.rem(60)};
  `)}

  &:hover {
    &:after {
      opacity: 0.9;
    }
  }

  button {
    cursor: pointer;
    appearance: none;
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    display: flex;
    width: 100%;
    gap: ${({ theme }) =>theme.rem(16)};
    padding: ${({ theme }) =>`${theme.rem(56)} ${theme.rem(16)} ${theme.rem(40)}`};

    ${({ theme }) => theme.mediaQuery.md(`
      padding: ${theme.rem(16)} ${theme.rem(16)} ${theme.rem(34)};
    `)}
  }
`

export const StyledCarouselPreviewInfo = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
  text-align: left;
  height: 100%;

  ${({ theme }) => theme.mediaQuery.md(`
    justify-content: space-between;
  `)};

  .next{
    display: none;

    ${({ theme }) => theme.mediaQuery.md(`
      display: block;
    `)}
  }

  .eyelet,
  ${Paragraph},
  ${StyledCarouselSlideCta}{
    display:block;
    color: ${({ theme }) =>theme.colors.general.light()};
    ${({ theme }) => theme.mediaQuery.md(`
      display: none;
    `)}
  }

  .eyelet{
    margin-bottom: ${({ theme }) => theme.rem(8)};
  }

  > ${Paragraph}{
    margin-top: ${({ theme }) => theme.rem(16)};
  }
  > ${StyledCarouselSlideCta}{
    margin-top: ${({ theme }) => theme.rem(24)};
  }

  [data-title] {
    opacity: 0;
    transition: all 0.4s ease;
    translate: 0  ${({ theme }) =>theme.rem(30)};
    color: ${({ theme }) =>theme.colors.general.light()};

    ${({ theme }) => theme.mediaQuery.md(`
      color: ${theme.colors.grayscale.white};
      ${theme.typo('heading6')}
    `)}

    .swiper-slide-active & {
      opacity: 1;
      translate: 0 0rem;
    }
  }

  mark{
    all: unset;
  }
`

export const StyledCarouselPreviewImg = styled.div`
  aspect-ratio: 1/1;
  border-radius: 8px;
  overflow: hidden;
  min-width:  ${({ theme }) =>theme.rem(88)};
  position: relative;
  z-index: 1;
  display: none;

  ${({ theme }) => theme.mediaQuery.md(`
    display: block;
  `)}

  img {
    object-fit: cover;
    width: 100%;
    display: block;
  }
`
