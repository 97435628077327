import React from 'react'

import Button from '@/atoms/Button'
import Cta from '@/atoms/Cta'
import CustomImage from '@/atoms/CustomImage'

import {
  StyledEditorialMedia,
  StyledEditorialMediaMedia,
  StyledEditorialMediaEditorial,
  StyledEditorialButtons,
  StyledEditorialMediaLogo,
  StyledEditorialMediaEditorialParagraph,
  StyledEditorialMediaEditorialHeading,
} from './styles'

const EditorialMedia = ({
  image_position: imagePosition,
  title,
  title_position: titlePosition = 'bottom',
  description,
  dx_cta: dxCta,
  sx_cta: sxCta,
  image,
  logo,
}) => {
  return (
    <StyledEditorialMedia imagePosition={imagePosition}>
      <StyledEditorialMediaMedia>
        {
          image &&
          <CustomImage data={image} fill/>
        }
      </StyledEditorialMediaMedia>
      <StyledEditorialMediaEditorial  position={titlePosition}>

        {
          logo &&
          <StyledEditorialMediaLogo>
            <CustomImage data={logo}/>
          </StyledEditorialMediaLogo>
        }

        {
          title &&
          titlePosition === 'top' &&
          <StyledEditorialMediaEditorialHeading typo="heading2" content={title}/>
        }

        {
          description &&
          <StyledEditorialMediaEditorialParagraph typo="paragraphLarge">
            {description}
          </StyledEditorialMediaEditorialParagraph>
        }

        {
          title &&
          titlePosition === 'bottom' &&
          <StyledEditorialMediaEditorialHeading typo="heading2" content={title} />
        }

        {
          (!!sxCta.link || !!dxCta.link) &&
          <StyledEditorialButtons>
            {
              !!sxCta.link &&
              <Button big keyword={sxCta.keyword} general {...sxCta.link}>{sxCta.link.title}</Button>
            }
            {
              !!dxCta.link &&
              <Cta keyword={dxCta.keyword} {...dxCta.link}/>
            }
          </StyledEditorialButtons>
        }
      </StyledEditorialMediaEditorial>
    </StyledEditorialMedia>
  )
}

export default EditorialMedia
