import React, { useContext } from 'react'

import { HeaderContext } from 'contexts/Header'
import Search from '@/organisms/Header/Search'
import { StyledSearchButton } from './styles'

const SearchButton = () => {
  const {
    state,
    dispatch
  } = useContext(HeaderContext)

  const isOpen = ()=> state.opened && state.id === 'search'

  const onClick = () => {
    const type = isOpen() ? 'close' : 'search'
    dispatch({ type })
  }

  return (
    <>
      <StyledSearchButton
        reset
        small
        useIconColor
        label="Cerca"
        iconStart="search"
        typo="link2"
        opened={isOpen()}
        onClick={onClick}
      />
      <Search/>
    </>
  )
}

export default SearchButton
