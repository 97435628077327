import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.6933 7.19258C14.9128 7.54386 14.8059 8.00656 14.4547 8.22605L9.94147 11.0461C9.59019 11.2655 9.12749 11.1587 8.908 10.8074C8.68851 10.4562 8.79534 9.99345 9.14662 9.77396L13.6598 6.95396C14.0111 6.73447 14.4738 6.8413 14.6933 7.19258Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.908 13.1926C9.12749 12.8413 9.59019 12.7345 9.94147 12.954L14.4547 15.774C14.8059 15.9934 14.9128 16.4562 14.6933 16.8074C14.4738 17.1587 14.0111 17.2655 13.6598 17.0461L9.14662 14.2261C8.79534 14.0066 8.68851 13.5439 8.908 13.1926Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.6001 3.75C15.3575 3.75 14.3501 4.75736 14.3501 6C14.3501 7.24264 15.3575 8.25 16.6001 8.25C17.8427 8.25 18.8501 7.24264 18.8501 6C18.8501 4.75736 17.8427 3.75 16.6001 3.75ZM12.8501 6C12.8501 3.92893 14.529 2.25 16.6001 2.25C18.6712 2.25 20.3501 3.92893 20.3501 6C20.3501 8.07107 18.6712 9.75 16.6001 9.75C14.529 9.75 12.8501 8.07107 12.8501 6Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.6001 15.75C15.3575 15.75 14.3501 16.7574 14.3501 18C14.3501 19.2426 15.3575 20.25 16.6001 20.25C17.8427 20.25 18.8501 19.2426 18.8501 18C18.8501 16.7574 17.8427 15.75 16.6001 15.75ZM12.8501 18C12.8501 15.9289 14.529 14.25 16.6001 14.25C18.6712 14.25 20.3501 15.9289 20.3501 18C20.3501 20.0711 18.6712 21.75 16.6001 21.75C14.529 21.75 12.8501 20.0711 12.8501 18Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7 9.75C5.75736 9.75 4.75 10.7574 4.75 12C4.75 13.2426 5.75736 14.25 7 14.25C8.24264 14.25 9.25 13.2426 9.25 12C9.25 10.7574 8.24264 9.75 7 9.75ZM3.25 12C3.25 9.92893 4.92893 8.25 7 8.25C9.07107 8.25 10.75 9.92893 10.75 12C10.75 14.0711 9.07107 15.75 7 15.75C4.92893 15.75 3.25 14.0711 3.25 12Z" fill="#194074"/>
  </svg>
)
