import React, { useContext } from 'react'

import { HeaderContext } from 'contexts/Header'

import SingleItem from '../SingleItem'

import {
  StyledMainNavigation,
  StyledMainNavigationList,
} from './styles'

const MainNavigation = () => {
  const { main, isMobile } = useContext(HeaderContext)

  return (
    main &&
    <StyledMainNavigation style={{ display: isMobile === undefined ? 'none' : 'block' }}>
      <StyledMainNavigationList>
        {
          main.map(props => (
            <SingleItem
              main
              key={props.id}
              {...props}
            />
          ))
        }
      </StyledMainNavigationList>
    </StyledMainNavigation>

  )
}

export default MainNavigation
