import React from 'react'

import Cta from '@/atoms/Cta'
import Richtext from '@/atoms/RichText'

import HeadingWithMark from '@/molecules/HeadingWithMark'

import {
  StyledEditorial,
  StyledEditorialTitle,
  StyledEditorialCta,
} from './styles'

const Editorial = ({
  title,
  description,
  position,
  cta
}) => (
  <StyledEditorial data-position={position}>
    <StyledEditorialTitle>
      <HeadingWithMark typo="heading2" content={title} />

    </StyledEditorialTitle>

    <StyledEditorialTitle>
      <Richtext typo="paragraphLarge">

        {description}
      </Richtext>
      {
        cta &&
        !!cta.link &&
        <StyledEditorialCta>
          <Cta keyword={cta.keyword} {...cta.link} />
        </StyledEditorialCta>
      }
    </StyledEditorialTitle>
  </StyledEditorial>
)

export default Editorial
