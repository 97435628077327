import React, { useMemo } from 'react'
import { useFormikContext } from 'formik'

import {
  StyledField,
  StyledFieldContainer,
  StyledFieldLabel
} from './styles'

import FieldError from './Error'
import { Label } from '../Typography'

const Field = ({
  id = null,
  type,
  label,
  field,
  meta,
  children,
  dependsOn,
  hideLabel = false,
}) => {
  const { values } = useFormikContext()
  const foundMatch = useMemo(() => {
    if (!dependsOn) return null

    const keys = Object.keys(dependsOn)
    const found = keys.filter(k => values[k] === dependsOn[k])

    return !!found.length
  }, [dependsOn, values])

  return (
    (!dependsOn || foundMatch) &&
    <StyledField
      type={type}
      data-type={type}
      disabled={field.disabled}
    >
      <StyledFieldContainer
        type={type}
        className={meta.touched && !!meta.error ? 'has-error' : ''}
        error={meta.touched && !!meta.error}
        withValue={!!field.value.length}>

        {children}

        {
          label &&
          <StyledFieldLabel hidden={hideLabel} tabIndex={-1} htmlFor={id || field.name}>
            <Label as="span" dangerouslySetInnerHTML={{ __html: label }}></Label>
          </StyledFieldLabel>
        }
      </StyledFieldContainer>

      <FieldError {...meta} />
    </StyledField>
  )
}

export default Field
