import styled, { keyframes } from 'styled-components'

import ScrollDownButton from '@/molecules/Buttons/ScrollDownButton'
import HeroWave from '@/molecules/Heroes/HeroWave'

export const StyledHeroCarousel = styled.div`
  position: relative;
`

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`

export const StyledHeroCarouselWave = styled(HeroWave)`
  /* position: relative; */
  height:${({ theme }) => theme.rem(48)};

  ${({ theme }) => theme.mediaQuery.md(`
    position: absolute;
    height: ${theme.rem(60)};
  `)}
`

export const StyledHeroCarouselCta = styled(ScrollDownButton)`
  position: absolute !important;
  border: none;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  .explore{
    animation: ${rotate} 30s linear infinite;
  }
`
