import React, {
  useEffect, useState
} from 'react'

import Form from '@/molecules/Form'

import { StyledSearch } from './styles'

const Search = (props) => {
  const [formData, setFormData] = useState(props.form)

  useEffect(() => {
    if (window.location.search) {
      const formCloned = structuredClone(props.form)
      const { form } = formCloned
      const { initialValues } = form


      for (const [key, value] of new URLSearchParams(window.location.search).entries()) {
        if (!(key in initialValues)) continue
        initialValues[key] = value
      }

      setFormData(formCloned)
    }
  }, [])

  return (
    <StyledSearch>
      <Form {...formData} />
    </StyledSearch>
  )
}

export default Search
