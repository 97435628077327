import styled from 'styled-components'

import { motion } from 'framer-motion'

export const StyledSectionBackground = styled(motion.div)`
  --b-radius-size: ${({ theme }) =>theme.rem(24)};

  ${({ theme }) => theme.mediaQuery.md(`
    --b-radius-size: ${theme.rem(40)};
  `)}

  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background-color: ${({
    theme,
    withBgColor
  }) => !withBgColor ? theme.colors.grayscale.white : theme.colors.background()};

  [data-bg-top] & {
    scale: 1 1;
    border-top-left-radius: var(--b-radius-size);
    border-top-right-radius: var(--b-radius-size);
  }

  [data-bg-center] & {
    scale: 1 1;
  }

  [data-bg-bottom] &{
    scale: 1 1;
    border-bottom-left-radius: var(--b-radius-size);
    border-bottom-right-radius: var(--b-radius-size);
  }

  ${({
    showBackToTop,
    isLast,
  }) => (showBackToTop || isLast) && `
    scale: 1 1;
    border-bottom-left-radius: var(--b-radius-size);
    border-bottom-right-radius: var(--b-radius-size);
  `}
`

export const StyledSectionBackgroundButton = styled.div`
  position: absolute;
  z-index: 3;

  bottom: ${({ theme }) => theme.rem(24)};
  right: ${({ theme }) => theme.rem(24)};
`

export const StyledSectionDecoration = styled(motion.div)`
  position: absolute;
  top:0;
  right: 0;
  max-width: 135px;
  height: auto;
  border-top-right-radius: ${({ theme }) => theme.rem(40)};
  pointer-events: none;
  overflow: hidden;

  ${({ theme }) => theme.mediaQuery.md(`
    max-width: 256px;
  `)}

  img{
    ${({ theme }) => theme.size('100%')};
    object-fit: contain;
  }
`
