import React from 'react'
import { useSwiperSlide } from 'swiper/react'

import { Label } from '@/atoms/Typography'
import Icon from '@/atoms/Icon'
import BoxedImage from '@/molecules/BoxedImage'

import {
  StyledSyncedImage,
  StyledSyncedImageWrapper,
  StyledSyncedImageCtaWrapper,
  StyledSyncedImageCta,
  StyledSyncedImageCtaText,
  StyledSyncedImageCtaIcon,
} from './styles'

const SyncedImage = ({
  image,
  cta,
  ctaEyelet,
}) => {
  const { isActive } = useSwiperSlide()

  return (
    <StyledSyncedImage>
      <StyledSyncedImageWrapper isActive={isActive}>
        {
          image &&
          <BoxedImage
            image={image}
            ratio={1}
            noBorder
            noRadius
            rounded
            full
          />
        }
      </StyledSyncedImageWrapper>

      {
        isActive &&
        <StyledSyncedImageCtaWrapper>
          <StyledSyncedImageCta
            withLabel={false}
            href={!!cta.link && cta.link.href ? cta.link.href : '#'}
          >
            <StyledSyncedImageCtaText>
              <Label typo="labelSmall">{ctaEyelet}</Label>
              {
                !!cta.link && cta.link.title &&
              <Label typo="heading6" bold>{cta.link.title}</Label>
              }
            </StyledSyncedImageCtaText>
            <StyledSyncedImageCtaIcon>
              <Icon name="arrow-right" />
            </StyledSyncedImageCtaIcon>
          </StyledSyncedImageCta>
        </StyledSyncedImageCtaWrapper>
      }

    </StyledSyncedImage>
  )
}


export default SyncedImage
