import styled from 'styled-components'
import styledMap from 'styled-map'

import { Label } from '../Typography'

export const StyledInputFileLabel = styled.div`
  text-indent: ${({ theme }) => theme.rem(4)};
  position: relative;

  ${Label}{
    z-index: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    height: ${({ theme }) => theme.rem(40)};
    ${({ theme }) => theme.paddingY(8)};
    ${({ theme }) => theme.paddingX(24)};
    gap: ${({ theme }) => theme.rem(8)};
    border: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};

    i{
      font-size: ${({ theme }) => theme.rem(24)};
    }
  }

  input {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    /* opacity: 0; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 0 !important;
  }

`

export const StyledField = styled.div`
  position: relative;

  &[type="checkbox"],
  &[type="radio"] {
    width: auto;

    > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  ${styledMap('type', {
    default: `
      label {
        pointer-events: none;
        width: 100%;
      }
    `,
    hidden: 'margin-bottom: 0;',
    radio: `
      min-width: 150px;

      label {
        pointer-events: auto;
        width: auto;
      }
    `,
    checkbox: `
      label {
        pointer-events: auto;
        width: auto;
      }
    `,
  })}
`

export const StyledFieldContainer = styled.div`
  position: relative;
  width: 100%;

  label[hidden]{
    color: transparent;
    userSelect: none;
  }


  input:not([type="checkbox"]):not([type="radio"]):not([type="file"]),
  select,
  textarea {
    border: none;
    border-radius: 8px;
    height: ${({ theme }) =>theme.rem(64)};
    text-indent: ${({ theme }) =>theme.rem(24)};
    border: 1px solid ${({ theme }) =>theme.colors.grayscale.gray200};
    background-color: ${({ theme }) =>theme.colors.grayscale.white};
    font-family: ${({ theme }) => theme.fonts.main};
    color: ${({ theme }) => theme.colors.general.light()};
    ${({ theme }) => theme.typo('inputPlaceholder')};
  }


  input:not([type="checkbox"]):not([type="radio"]),
  textarea {
    width: 100%;
    padding: 0;

    &::placeholder {
      font-family: ${({ theme }) => theme.fonts.main};
      color: ${({ theme }) => theme.colors.general.light()};
      ${({ theme }) => theme.typo('inputPlaceholder')};

      + label{
        color: transparent;
        userSelect: none;
      }
    }

    &:focus::placeholder{
      color: transparent;
    }
  }

  input:not([type="checkbox"]):not([type="radio"]){
    transition: padding-top ease-in-out .3s;

    &:focus,
    &:not(:placeholder-shown)  {
      font-weight: bold;
      padding-top: ${({ theme }) => theme.rem(16)};

      + label:not([hidden]){
        transform: translate(3px, -130%) scale(0.875);
        color: inherit;
      }

      + label[hidden]{
        color: transparent;
      }
    }

    &:placeholder-shown + label,
    &:placeholder-shown + label[hidden]{
      color: transparent;
      user-select: none;
    }
  }

  textarea {
    width: 100%;
    display: block;
    padding: ${({ theme }) => theme.rem(24)} 0;
    min-height: ${({ theme }) => theme.rem(180)};
    resize: none;

    + label[hidden]{
      color: transparent;
      userSelect: none;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    + label{
      display: flex;
      align-items: flex-start;
      margin: 0;
      position: relative;
      top: unset;
      transform: none;
      cursor: pointer;
      text-align: left;

      &:before {
        content: '';
        top: -4px;
        right: 100%;
        position: absolute;
        flex-shrink: 0;
        ${({ theme }) => theme.size(24)};
        transition: background-color ease-in-out .3s, border-color ease-in-out .3s, border-width ease-in-out .2s;
        border: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};
      }



      &:hover:before {
        border-color: ${({ theme }) => theme.colors.accent()};
      }
    }

    &:checked + label {
      &:before {
        border-color: inherit;
        background-color: ${({ theme }) => theme.colors.general.light()};
      }
    }
  }

  input[type="radio"]{
    ${({ theme }) => theme.size(24)};
    padding: 0;
    margin: 0;
    opacity: 0;

    + label {
      padding-left: ${({ theme }) => theme.rem(16)};
      &:before { border-radius: 50%; }
    }

    &:checked + label{
      &::before{
        background-color: ${({ theme }) => theme.colors.accent()};
        border: 7px solid ${({ theme }) => theme.colors.general.light()};
      }
    }
  }

  input[type="checkbox"] {
    all: unset;
    display: block;
    border-radius: 2px;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 1;
    border: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};
    ${({ theme }) => theme.size(22)};

    &:focus + label{
      border-color: ${({ theme }) => theme.colors.general.light()};
      outline: 1px dotted #212121;
      outline: 5px auto -webkit-focus-ring-color;
    }

    + label {
      display: flex;
      align-items: flex-start;
      padding-left: 0;
      ${Label}{
        ${({ theme }) => theme.typo('paragraphRegular')};
      }

      &:before{
        position: relative;
        background-color: ${({ theme }) => theme.colors.grayscale.white};
        margin-right: ${({ theme }) => theme.rem(16)};
        right: unset;
        z-index: 1;
        top: 0;
      }


      &::after{
        content: '';
        z-index: 2;
        position: absolute;
        left: 5px;
        top: 10px;
        opacity: 0;
        transition: opacity ease-in-out .4s;
        transform: translateY(-50%) rotate(-45deg);
        transform-origin: center center;
        width: 13px;
        height: 8px;
        border-left: 3px solid ${({ theme }) => theme.colors.accent()};;
        border-bottom: 3px solid ${({ theme }) => theme.colors.accent()};;
      }
    }
  }

  input[type="checkbox"]:checked + label {
    &:after{
      opacity: 1;
      border-color: ${({ theme }) => theme.colors.accent()};
    }
  }


  input[type="date"] {
    text-indent: 10px !important;
  }

  input[type="file"] {
    /* order: 1; */
    /* opacity: 0; */
    color: transparent;

    &:not(:placeholder-shown) + label {
      all: unset;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) scale(1) !important;
      color: ${({ theme }) => theme.colors.grayscale.gray200};
      display: inline-block;
    }

    &::-webkit-file-upload-button { display: none }
  }

  select {
    padding: 0 ${({ theme }) => theme.rem(32)} 0 0;
    margin: 0;
    max-width: 100%;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8 6.78148L1.53432 0L0 1.60926L8 10L16 1.60926L14.4657 0L8 6.78148Z" fill="currentColor"/></svg>');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 24px);
    background-position-y: 25px;

    option {
      color: ${({ theme }) => theme.colors.accent()};
      padding: 0;
    }

    + label[hidden]{
      color: transparent;
      userSelect: none;
    }
  }

  ${styledMap('type', {
    checkbox: `
      display: flex;
      align-items: flex-start;
    `,
    radio: `
      display: flex;
      align-items: center;
    `,
    file: `
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    `,
    default: `
      display: flex;
      align-items: flex-start;

      flex-direction: column-reverse;
    `
  })}

  ${styledMap('error', {
    default: '',
    true: `
      input:not([type="checkbox"]):not([type="radio"]),
      textarea {
        border: 1px solid red;

        &:focus,
        &:not(:placeholder-shown) {
          border-color: red;
        }
      }

      select { border: 1px solid red; }

      input[type="checkbox"] + label,
      input[type="radio"] + label {
        color: red;
        &:before { border-color: red; }
      }

      input[type="file"] + span {
        border-color: red;
        color: red;
      }
    `
  })}
`

export const StyledFieldLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform-origin: top left;
  transform: translate(0,-50%) scale(1,1);
  transition: transform .3s ease-in-out, color .3s ease-in-out;
  padding-left: ${({ theme }) =>theme.rem(24)};
`

export const StyledFieldError = styled.div`
  color: red;
  margin-top: ${({ theme }) => theme.rem(4)};

  ${({ theme }) => theme.typo('paragraphSmall')}
`

export const StyledGroup = styled.div`
  display: block;
  overflow: auto;
  padding-bottom: ${({ theme }) => theme.rem(16)};

  &:last-of-type{
    padding-bottom:0;
  }

  &::-webkit-scrollbar { height: 1px; }

  &::-webkit-scrollbar-track { background: ${({ theme }) => theme.colors.grayscale.white}; };
  &::-webkit-scrollbar-thumb { background-color: ${({ theme }) => theme.colors.accent()};};

  strong {
    display: block;
    margin-bottom: ${({ theme }) => theme.rem(16)};
  }
`

export const StyledGroupContainer = styled.div`
  display: inline-flex;
  // width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: ${({ theme }) => theme.rem(16)};
  row-gap: ${({ theme }) => theme.rem(16)};

  [data-type="radio"],
  [data-type="checkbox"] {
    position: relative;

    label {
      color: ${({ theme }) => theme.colors.grayscale.black};
      padding-top: ${({ theme }) => theme.rem(4)} !important;
      padding-left: ${({ theme }) => theme.rem(32)}!important;
      text-align: center;
      transition: background-color ease-in-out .4s, border-color ease-in-out .4s;

      &::before{
        top: 0 !important;
      }
    }

    input {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  [data-type="radio"] {
    min-width: 0;

    label {
      &:before {
        right: unset;
        left: 0
      }
    }
  }

  [data-type="checkbox"] {
    label {
      padding-left: 0 !important;
      display: flex !important;
      align-items: center;
      justify-content: center;

      &:before {
        border-radius: 2px;
        ${({ theme }) => theme.size(18)};
        right: 0 !important;
        position: relative !important
      }
    }
  }
`

export const StyledInputFileInstructions = styled(Label)`
`


export const StyledInputFileSelected = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  gap: 8px;

  button{
    position: relative;
    padding: 0;
    ${({ theme }) => theme.size(24)};

    &::before,
    &::after{
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;

      ${({ theme }) => theme.size(['40%', 1])};
      background-color: ${({ theme }) => theme.colors.general.light()};
    }

    &::before{
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after{
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
`
