import { getListItemData } from 'api/page'

const Grid = async (props) => {
  const {
    data, ...rest
  } = props
  if (!data) return {}

  const {
    list,
    showResultsNumber: showTotalItemLabel,
    resultsNumber: totalItems = null,
    columns,
    disposition,
    ...restOfData
  } = data

  const normalizedList = []

  if (!list || list.length === 0) return props
  for (const item of list) {
    const {
      badges = null,
      featuredBadges = null,
      lines = null,
    } = item

    const singleItemData = await getListItemData(item)

    const itemData = {
      badges,
      featuredBadges,
      lines,
      ...singleItemData,
    }
    normalizedList.push(itemData)
  }

  let imageRatio = Number(columns) === 2 ? 1 : 4

  if (disposition === 'lines') imageRatio = 3

  const normalizedData = {
    ...rest,
    data: {
      ...restOfData,
      columns,
      imageRatio,
      totalItems,
      showTotalItemLabel,
      list: normalizedList
    }
  }

  return normalizedData
}


export const GridAlgolia = (data) => {
  const {
    hits,
    nbPages: totalPages,
    page: currentPage,
  } = data


  const list = hits.map(({
    post_title: title,
    post_type: cardType,
    images,
    permalink: link,
    brand,
    post_id: id,
    user_defined: chefPeople,
  }) => {
    const hasVideo = cardType === 'chef-secrets' // TODO: ??

    const icon = cardType === 'products'
      ? false
      : {
        name: hasVideo ? 'play' : 'arrow-diagonal',
        visible: hasVideo,
        big: true
      }

    return {
      id,
      brand,
      hasVideo,
      title,
      titleSize: 'heading4',
      cardType,
      link,
      image: images['icon-360x360'] || images.thumbnail || { url: '/images/placeholder-page.jpg', },
      icon,
      chefPeople,
    }
  }
  )

  const normalizedData = {
    list,
    totalPages,
    currentPage: currentPage + 1,
  }

  return normalizedData
}

export default Grid
