import React from 'react'

export default () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12275_21345)">
      <path d="M12.4631 7.68094C11.2954 7.65922 10.1668 8.10209 9.32557 8.91219C8.48431 9.72228 7.99917 10.8333 7.97681 12.001C7.99921 13.1686 8.48437 14.2796 9.32563 15.0897C10.1669 15.8997 11.2954 16.3426 12.4631 16.3208C13.6305 16.3422 14.7587 15.8993 15.5998 15.0892C16.4408 14.2792 16.9258 13.1684 16.9483 12.001C16.9259 10.8335 16.4409 9.72267 15.5998 8.91261C14.7588 8.10255 13.6306 7.65954 12.4631 7.68094Z" fill="#194074"/>
      <path d="M19.4059 0H5.51474C4.05216 4.59651e-05 2.64949 0.581077 1.61528 1.61528C0.581077 2.64949 4.59651e-05 4.05216 0 5.51474L0 18.4853C0 19.9479 0.581016 21.3506 1.61523 22.3848C2.64945 23.419 4.05214 24 5.51474 24H19.4059C20.1301 24 20.8472 23.8574 21.5163 23.5802C22.1854 23.3031 22.7933 22.8969 23.3054 22.3848C23.8175 21.8727 24.2237 21.2647 24.5008 20.5956C24.7779 19.9266 24.9205 19.2095 24.9205 18.4853V5.51474C24.9205 4.05217 24.3395 2.6495 23.3053 1.61529C22.2712 0.581082 20.8685 4.59668e-05 19.4059 0ZM12.4644 18.2416C10.778 18.2727 9.14814 17.633 7.93315 16.463C6.71817 15.293 6.01743 13.6884 5.98496 12.002C6.01743 10.3156 6.71817 8.71102 7.93315 7.54102C9.14814 6.37101 10.778 5.73128 12.4644 5.76242C14.1509 5.73128 15.7807 6.37101 16.9957 7.54102C18.2107 8.71102 18.9114 10.3156 18.9439 12.002C18.9114 13.6884 18.2107 15.293 16.9957 16.463C15.7807 17.633 14.1509 18.2727 12.4644 18.2416ZM19.6872 6.72158C19.2332 6.72993 18.7945 6.55766 18.4674 6.24263C18.1403 5.9276 17.9518 5.4956 17.9431 5.04157C17.9605 4.59059 18.1519 4.16387 18.4772 3.85095C18.8024 3.53803 19.2362 3.36324 19.6875 3.36324C20.1388 3.36324 20.5726 3.53803 20.8978 3.85095C21.2231 4.16387 21.4145 4.59059 21.4319 5.04157C21.4232 5.49566 21.2345 5.92769 20.9073 6.24271C20.5802 6.55774 20.1413 6.72998 19.6872 6.72158Z" fill="#194074"/>
    </g>
    <defs>
      <clipPath id="clip0_12275_21345">
        <rect width="24.9205" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>

)
