import React, {
  useEffect,
  useState,
  useContext
} from 'react'

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

import ChefSecretsCardModels from 'models/ChefSecretsCard'
import ArticleCardModels from 'models/ArticleCard'
import BrandCardModels from 'models/BrandCard'
import RecipeCardModels from 'models/RecipeCard'

import ArticleCard  from '@/molecules/Card/Cards/ArticleCard'
import BannerCard  from '@/molecules/Card/Cards/BannerCard'
import BrandCard  from '@/molecules/Card/Cards/BrandCard'
import CategoryCard  from '@/molecules/Card/Cards/CategoryCard'
import ChefSecretsCard  from '@/molecules/Card/Cards/ChefSecretsCard'
import CocktailCard  from '@/molecules/Card/Cards/CocktailCard'
import ProductCard from '@/molecules/Card/Cards/ProductCard'
import RecipeCard from '@/molecules/Card/Cards/RecipeCard'
import SearchCard from '@/molecules/Card/Cards/SearchCard'
import ShopCard from '@/molecules/Card/Cards/ShopCard'

import { GeneralContext } from 'contexts/General'

const CardModels = {
  ChefSecretsCard: ChefSecretsCardModels,
  ArticleCard: ArticleCardModels,
  BrandCard: BrandCardModels,
  RecipeCard: RecipeCardModels,
}

const CardsType = {
  ArticleCard,
  BannerCard,
  BrandCard,
  CategoryCard,
  ChefSecretsCard,
  CocktailCard,
  ProductCard,
  RecipeCard,
  SearchCard,
  ShopCard,
}

const Card = ({
  item,
  type,
  grid = false,
  imageRatio,
}) => {
  const [itemData, setItemData] = useState(null)

  const {
    options,
    brand,
  } = useContext(GeneralContext)

  const componentName = capitalize(type)
  const fallBack = process.env.NODE_ENV === 'development' ? <>{componentName} not found</> : <></>
  const Component = CardsType[componentName] ? CardsType[componentName] : fallBack

  useEffect(() => {
    // TODO: refactor and move to model of every single component that uses Card item
    const runModel = async () => {
      const d = await CardModels[componentName](item, options?.mainBrandId)
      setItemData(d)
    }
    if (!CardModels[componentName]) {
      setItemData(item)
    } else {
      runModel()
    }
  }, [componentName, item.id])

  return (
    itemData &&
    <Component
      brand={brand}
      imageRatio={imageRatio}
      item={itemData}
      grid={grid}
    />
  )
}

export default Card
