import styled from 'styled-components'

import {
  openedGraphic,
  openedStatus
} from '@/organisms/Header/styles'

import Button from '@/atoms/Button'


export const StyledSearchButton = styled(Button)`

  height: 100% !important;

  ${openedGraphic};

  ${({
    theme,
    opened
  }) => theme.mediaQuery.md(`
    &:hover{
      ${openedStatus};
    }

    ${opened ? openedStatus : ''};
  `)}

  span{
    display: none;

    ${({ theme }) => theme.mediaQuery.md(`
      display: inline-block;
    `)}
  }
`
