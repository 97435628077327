import Accordion from './Accordion'
import ArticleParagraph from './ArticleParagraph'
import Contacts from './Contacts'
import Editorial from './Editorial'
import EditorialMedia from './EditorialMedia'
import Grid from './Grid'
import Hero from './Hero'
import HeroCarousel from './HeroCarousel'
import HeroEditorial from './HeroEditorial'
import HeroProduct from './HeroProduct'
import HeroRecipe from './HeroRecipe'
import MediaBanner from './MediaBanner'
import Metadata from './Metadata'
import MosaicRecipe from './MosaicRecipe'
import ProductParagraph from './ProductParagraph'
import ProductSlider from './ProductSlider'
import RecipeParagraph from './RecipeParagraph'
import SeoParagraph from './SeoParagraph'
import SectionCta from './SectionCta'
import Slider from './Slider'
import Filters from './Filters'
import FeaturedContent from './FeaturedContent'
import NotFound from './NotFound'
import Search from './Search'
import SearchResults from './SearchResults'
import ContactForm from './ContactForm'
import Paragraph from './Paragraph'
import Cookiebot from './Cookiebot'


export default {
  Accordion,
  ArticleParagraph,
  Contacts,
  ContactForm,
  Cookiebot,
  Editorial,
  EditorialMedia,
  Grid,
  Hero,
  HeroCarousel,
  HeroEditorial,
  HeroProduct,
  HeroRecipe,
  MediaBanner,
  Metadata,
  MosaicRecipe,
  ProductParagraph,
  ProductSlider,
  RecipeParagraph,
  SeoParagraph,
  SectionCta,
  Slider,
  Filters,
  FeaturedContent,
  NotFound,
  Explorer: Filters,
  Search,
  SearchResults,
  HeroCocktail: HeroRecipe,
  CocktailParagraph: RecipeParagraph,
  Paragraph,
}
