import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.3333 5.75C7.9802 5.75 6.8833 6.8469 6.8833 8.2C6.8833 9.5531 7.9802 10.65 9.3333 10.65C10.6864 10.65 11.7833 9.5531 11.7833 8.2C11.7833 6.8469 10.6864 5.75 9.3333 5.75ZM5.3833 8.2C5.3833 6.01848 7.15178 4.25 9.3333 4.25C11.5148 4.25 13.2833 6.01848 13.2833 8.2C13.2833 10.3815 11.5148 12.15 9.3333 12.15C7.15178 12.15 5.3833 10.3815 5.3833 8.2Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.8 6.81667C16.036 6.81667 15.4166 7.43601 15.4166 8.2C15.4166 8.96399 16.036 9.58333 16.8 9.58333C17.564 9.58333 18.1833 8.96399 18.1833 8.2C18.1833 7.43601 17.564 6.81667 16.8 6.81667ZM13.9166 8.2C13.9166 6.60758 15.2075 5.31667 16.8 5.31667C18.3924 5.31667 19.6833 6.60758 19.6833 8.2C19.6833 9.79242 18.3924 11.0833 16.8 11.0833C15.2075 11.0833 13.9166 9.79242 13.9166 8.2Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M5.03177 14.5651C6.17261 13.4243 7.71993 12.7833 9.33333 12.7833C10.9467 12.7833 12.4941 13.4243 13.6349 14.5651C14.7757 15.7059 15.4167 17.2533 15.4167 18.8667C15.4167 19.0656 15.3376 19.2563 15.197 19.397C15.0563 19.5376 14.8656 19.6167 14.6667 19.6167H4C3.80109 19.6167 3.61032 19.5376 3.46967 19.397C3.32902 19.2563 3.25 19.0656 3.25 18.8667C3.25 17.2533 3.89092 15.7059 5.03177 14.5651ZM9.33333 14.2833C8.11776 14.2833 6.95197 14.7662 6.09243 15.6258C5.41173 16.3065 4.96726 17.1792 4.81176 18.1167H13.8549C13.6994 17.1792 13.2549 16.3065 12.5742 15.6258C11.7147 14.7662 10.5489 14.2833 9.33333 14.2833Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.7987 13.2167C16.3174 13.2159 15.8466 13.3578 15.4458 13.6244C15.101 13.8539 14.6354 13.7603 14.4059 13.4155C14.1765 13.0706 14.27 12.605 14.6149 12.3756C15.2623 11.9448 16.0229 11.7155 16.8006 11.7167C17.848 11.7168 18.8524 12.133 19.593 12.8736C20.3338 13.6144 20.75 14.6191 20.75 15.6667V16.7333C20.75 17.1476 20.4142 17.4833 20 17.4833H16.8C16.3857 17.4833 16.05 17.1476 16.05 16.7333C16.05 16.3191 16.3857 15.9833 16.8 15.9833H19.25V15.6667C19.25 15.0169 18.9918 14.3937 18.5324 13.9343C18.0729 13.4748 17.4497 13.2167 16.8 13.2167H16.7987V13.2167ZM16.8006 11.7167C16.8008 11.7167 16.801 11.7167 16.8012 11.7167L16.8 12.4667V11.7167C16.8002 11.7167 16.8004 11.7167 16.8006 11.7167Z" fill="#194074"/>
  </svg>
)
