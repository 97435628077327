import React from 'react'
import BoxedImage from '@/molecules/BoxedImage'

const Brands = ({ items }) => (
  items &&
  !!items.length &&
  <>
    {
      items.map(({
        href = '#',
        title,
        logoHeader
      }) => (
        <BoxedImage
          key={title}
          title={title}
          href={href}
          image={logoHeader}
        />
      ))
    }
  </>
)


export default Brands
