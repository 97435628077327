import {
  useState,
  useEffect
} from 'react'

import theme from 'theme/config'

const useIsMobile = (breakpoint = 'md') => {
  const [isMobile, setIsMobile] = useState(undefined)

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth < theme.breakpoints[breakpoint])
  }

  useEffect(() => {
    checkIsMobile()
    window.addEventListener('resize', checkIsMobile)

    return () => {
      window.removeEventListener('resize', checkIsMobile)
    }
  }, [])

  return isMobile
}

export default useIsMobile
