import styled from 'styled-components'


export const StyledScrollDownButton = styled.button`
  cursor: pointer;
  padding: 0;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.size(96)};
  background-color: ${({ theme }) => theme.colors.general.light()};
  color: ${({ theme }) => theme.colors.accent()};

  ${({ theme }) => theme.mediaQuery.md(`
    ${theme.size(120)};
  `)}

  .explore{
    font-size: ${({ theme }) =>theme.rem(96)};

    ${({ theme }) => theme.mediaQuery.md(`
      font-size: ${theme.rem(120)};
    `)}
  }

  .arrowDown{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: ${({ theme }) =>theme.rem(40)};
  }
`
