import React from 'react'
import Link from 'next/link'

import {
  StyledTag,
  StyledTagLabel
} from './styles'

const Tag = ({
  label,
  link = null,
  selected = false
}) => (

  <StyledTag
    selected={selected}
    as={link ? Link : 'div'}
    {...link}
  >
    <StyledTagLabel
      selected={selected}
      typo="labelSmall">
      <span>{label}</span>
    </StyledTagLabel>
  </StyledTag>
)

export default Tag
