import React, {
  useContext, useEffect, useMemo, useRef
} from 'react'
import { GeneralContext } from 'contexts/General'

import ShopButton from '@/molecules/Buttons/ShopButton'
import ClubButton from '@/molecules/Buttons/ClubButton'
import Socials from '@/molecules/Socials'
import SimpleNavigation from '@/molecules/SimpleNavigation'

import {
  StyledFooter,
  StyledFooterRow,
  StyledFooterCol,
  StyledFooterColWrapper,
} from './styles'

import Logo from '@/atoms/Logo'
import Richtext from '@/atoms/RichText'
import useIsMobile from 'hooks/useIsMobile'

const Footer = () =>{
  const ref = useRef(null)
  const isMobile = useIsMobile()
  const {
    options = {},
    menus = {},
  } = useContext(GeneralContext)
  const {
    brand = {},
    companyLogo,
    companyMenu,
    companyText,
  } = options

  const { footerMenu } = menus

  const logoHref = useMemo(() => {
    return brand && brand.isVertical && !options.isMainBrand ? `/${brand.slug}` : '/'
  }, [brand])


  useEffect(() => {
    const updateOffset = () => {
      const main = document.querySelector('#__next > main')
      const footerHeight = ref.current.clientHeight
      const marginBottom = isMobile ? 0 : footerHeight

      main.style.marginBottom = `${marginBottom}px`
    }

    updateOffset()

    window.addEventListener('resize', updateOffset)
    return () => window.removeEventListener('resize', updateOffset)
  }, [ref, isMobile])


  return (
    <StyledFooter ref={ref}>
      <StyledFooterRow>
        <StyledFooterCol border={false} noPaddingTop>
          {
            brand &&
            <Logo brand={brand.title} image={brand.logoFooter} href={logoHref}/>
          }
        </StyledFooterCol>
        <StyledFooterCol right>
          <StyledFooterColWrapper>
            {
              footerMenu &&
              footerMenu.subMenus &&
              !!footerMenu.subMenus.length &&
              footerMenu.subMenus.map((submenu, idx) => (
                <SimpleNavigation
                  key={submenu.id}
                  end
                  typo="labelSmall"
                  items={submenu.items}
                  bold={idx === 0}
                />
              ))
            }
          </StyledFooterColWrapper>
        </StyledFooterCol>
      </StyledFooterRow>
      <StyledFooterRow>
        <StyledFooterCol>
          <Socials items={brand.socials} />
        </StyledFooterCol>
        <StyledFooterCol expand right>
          <ClubButton transparent typo='buttonSmall' small />
          <ShopButton typo='buttonSmall' small />
        </StyledFooterCol>
      </StyledFooterRow>
      <StyledFooterRow>
        <StyledFooterCol expand border={false}>
          <Logo
            brand="Parmalat Corporate"
            className="LogoCorporate"
            href="/"
            image={companyLogo}
          />
          <Richtext
            typo="paragraphTiny"
            white
          >{companyText}</Richtext>
        </StyledFooterCol>
        <StyledFooterCol right border={false} noPaddingTop>
          <SimpleNavigation
            items={companyMenu}
            typo="buttonTiny"
            end
            uppercase
          />
        </StyledFooterCol>
      </StyledFooterRow>
    </StyledFooter>
  )
}

export default Footer
