import React, { useMemo } from 'react'
import { useFormikContext } from 'formik'

import { Label } from '@/atoms/Typography'
import Icon from '@/atoms/Icon'

import {
  StyledFieldsetTitle,
  StyledFieldsetTitleSelected
} from './styles'

const FieldsetTitle = ({
  id,
  title = null,
  icon = null,
  isExpanded = true,
  expandable = false,
  onClick = false
}) => {
  const { values } = useFormikContext()

  const selectedValues = useMemo(() => {
    return values[id] ? values[id].length : 0
  }, [values])

  return (
    title &&
    <StyledFieldsetTitle icon={icon} onClick={expandable ? onClick : false} data-selected-values={!isExpanded && expandable && !!selectedValues}>
      {
        (((icon && isExpanded) || selectedValues === 0)) &&
        expandable &&
        <Icon name={icon} useIconColor />
      }

      {
        (!isExpanded && expandable && !!selectedValues) &&
        <StyledFieldsetTitleSelected bold as="span">{selectedValues}</StyledFieldsetTitleSelected>
      }
      <Label as="strong" typo="h5" $title bold>{title}</Label>
    </StyledFieldsetTitle>

  )
}

export default FieldsetTitle
