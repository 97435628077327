import styled from 'styled-components'
export const StyledGridContainer = styled.div`
  padding-top: ${({ theme }) => theme.rem(32)};

  ${({ theme }) => theme.mediaQuery.md(`
    padding-top: ${theme.rem(64)}
  `)};

  ${({ showTotalItemLabel, theme }) => showTotalItemLabel && `
    padding-top: ${theme.rem(24)};

    ${theme.mediaQuery.md(`
      padding-top: ${theme.rem(40)}
    `)}
  `};


  ${({ mobileSlider, isMobile }) => isMobile && mobileSlider && `
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;

    .swiper-slide{
      max-width: calc(100vw / 12 * 9);
    }
  `}
`

export const StyledGridTotalItems = styled.div`
  margin-bottom: ${({ theme }) => theme.rem(32)};

  ${({ theme }) => theme.mediaQuery.md(`
    margin-bottom: ${theme.rem(64)};
  `)}
`

export const StyledGrid = styled.div`
  --gap:  calc(100vw / 12 * 1);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--gap);

  ${({ theme, columns }) =>theme.mediaQuery.md(`
    grid-gap: ${columns === 2 ? 'var(--gap)' : '60px'};
    grid-template-columns: repeat(${columns}, 1fr);
  `)}
`

export const StyledGridItem = styled.div``
