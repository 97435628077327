import React from 'react'

import SingleSlider from '@/molecules/Sliders/SingleSlider'
import DefaultSlider from '@/molecules/Sliders/DefaultSlider'

const SliderTypes = {
  SingleSlider,
  DefaultSlider
}

const Slider = ({
  listExist,
  sliderType,
  sliderProps,
}) => {
  const SliderToRender = SliderTypes[sliderType]

  return (
    listExist &&
    <SliderToRender {...sliderProps} />
  )
}

export default Slider
