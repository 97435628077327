import React from 'react'

export default () => (
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M37.5866 38V14.6201Z" fill="#194074"/>
    <path d="M14 37.5177L37.5177 14Z" fill="#194074"/>
    <path d="M28.1933 14.5972L14.1837 14.5971Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M38.7554 14.1759C38.8085 13.8003 38.6906 13.4051 38.4016 13.1161C37.9135 12.628 37.122 12.628 36.6339 13.1161L13.1161 36.6339C12.628 37.122 12.628 37.9135 13.1161 38.4016C13.6043 38.8898 14.3957 38.8898 14.8839 38.4016L36.3366 16.9489V38C36.3366 38.6904 36.8962 39.25 37.5866 39.25C38.277 39.25 38.8366 38.6904 38.8366 38V14.6201C38.8366 14.4637 38.8079 14.314 38.7554 14.1759ZM12.9337 14.5971C12.9337 13.9068 13.4934 13.3471 14.1837 13.3471L28.1933 13.3472C28.8836 13.3472 29.4433 13.9068 29.4433 14.5972C29.4433 15.2875 28.8836 15.8472 28.1933 15.8472L14.1837 15.8471C13.4934 15.8471 12.9337 15.2875 12.9337 14.5971Z" fill="#194074"/>
  </svg>
)
