import React from 'react'

import Image from 'next/image'
import { StyledChefPeopleLogo } from './styles'

const ChefPeopleLogo = () => (
  <StyledChefPeopleLogo>
    <Image src={'/images/popolodichef.png'} width={72} height={72} alt='Popolo di chef' />
  </StyledChefPeopleLogo>
)

export default ChefPeopleLogo
