import styled from 'styled-components'

export const StyledMosaicRecipe = styled.div``

export const StyledMosaicRecipeCols = styled.div`
  > * +*{
    margin-block-start: ${({ theme }) => theme.rem(80)};
  }

  &:last-child{
    padding-top: ${({ theme }) => theme.rem(80)};
  }
`

export const StyledMosaicRecipeWrapper = styled.div`
  display: block;
  width: 100%;

  .swiper-slide{
    max-width: calc(100vw / 12 * 9);
  }

  ${({ theme }) => theme.mediaQuery.md(`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 ${ theme.rem(120)};
  `)}
`


export const StyledMosaicRecipeCta = styled.div`
  > div{
    width: 100%;
    padding: ${({ theme }) => theme.rem(24)};

    ${({ theme }) => theme.mediaQuery.md(`
      padding: 0;
      width: auto;
    `)}

    a{
      width: 100%;

      ${({ theme }) => theme.mediaQuery.md(`
        width: auto;
      `)}
    }
  }
`
