import React, {
  useContext, useMemo
} from 'react'
import { GeneralContext } from 'contexts/General'
import Background from './Background'

const SectionBackground = ({
  bgp,
  withBgColor,
  decoration,
  isLast
}) => {
  const {
    page,
    brand,
  } = useContext(GeneralContext)

  const bgpProps = useMemo(() => {
    if (bgp.length === 0 || !bgp) return false

    const props = {}
    if (bgp && !!bgp.length) bgp.forEach(element => Object.assign(props, { [element]: true }))
    return props
  }, [])

  return (
    (bgpProps || isLast) &&
    <Background
      {...bgpProps}
      decoration={decoration}
      brand={brand}
      withBgColor={(isLast && !bgpProps) ? false : withBgColor}
      isLast={isLast}
      showBackToTop={isLast && page.showBackToTop}
    />
  )
}

export default SectionBackground
