import React, {
  useEffect,
  useRef
} from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

import breakpoints from 'theme/breakpoints'
import useIsMobile from 'hooks/useIsMobile'

import CustomImage from '@/atoms/CustomImage'
import { Label } from '@/atoms/Typography'
import Button from '@/atoms/Button'
import GraphicAnimated from '@/atoms/GraphicAnimated'

import ShareButton from '@/molecules/Buttons/ShareButton'

import {
  StyledStickyElement,
  StyledStickyElementWrapper,
  StyledStickyElementImage,
  StyledStickyElementCta,
  StyledStickyElementActions
} from './styles'

const StickyElement = ({
  containerRef,
  productName,
  cta,
  image,
  imageProps = {}
}) => {
  const isMobile = useIsMobile()
  const sticky = useRef(null)

  useEffect(() => {
    const mm = gsap.matchMedia()

    mm.add({
      isDesktop: `(min-width: ${breakpoints.md}px)`,
      isMobile: `(max-width: ${breakpoints.md - 1}px)`,
    }, (context) => {
      const calculateEnd = () =>  {
        const {
          y, height
        } = sticky.current.getBoundingClientRect()
        return `bottom bottom-=${window.innerHeight - (y + height)}`
      }
      const {
        isMobile: gsapIsMobile, isDesktop
      } = context.conditions

      if (gsapIsMobile) {
        const endTrigger = document.querySelector('[data-product-paragraph]')
        ScrollTrigger.create({
          trigger: containerRef.current,
          start: 'top top+=10',
          endTrigger,
          end: 'bottom bottom',
          toggleClass: {
            targets: sticky.current,
            className: 'active-mobile'
          }
        })
      }

      if (isDesktop) {
        const endTrigger = document.querySelector('[data-product-paragraph]')

        ScrollTrigger.create({
          trigger: containerRef.current,
          pin: sticky.current,
          pinSpacing: false,
          start: 'top top',
          endTrigger,
          end: calculateEnd,
        })

        ScrollTrigger.create({
          trigger: endTrigger,
          start: 'top center',
          end: calculateEnd,
          onLeaveBack: () => {
            sticky.current.classList.remove('active')
          },
          onEnter: () => {
            sticky.current.classList.add('active')
          }
        })
      }
    }, containerRef)

    return () =>mm?.revert()
  }, [])

  return (
    <StyledStickyElement
      ref={sticky}>
      <StyledStickyElementWrapper>
        {
          image &&
          <StyledStickyElementImage>
            <CustomImage data={image} {...imageProps}/>
            <GraphicAnimated />
          </StyledStickyElementImage>
        }

        {
          !!cta.link &&
          <StyledStickyElementCta>
            <Label typo="heading6" bold>
              {productName}
            </Label>
            <StyledStickyElementActions>
              {
                isMobile &&
                <ShareButton  general small iconStart="share" />
              }

              <Button
                small
                className="cta-sticky-buy"
                keyword={cta.keyword}
                href={cta.link?.href}
                iconStart="cart"
                label={cta.link?.title}
              />
            </StyledStickyElementActions>
          </StyledStickyElementCta>
        }


      </StyledStickyElementWrapper>
    </StyledStickyElement>
  )
}

export default StickyElement
