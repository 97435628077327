import styled from 'styled-components'
import styledMap from 'styled-map'
import { Label } from '@/atoms/Typography'

export const StyledTag = styled.div`
  flex-shrink: 0;
  transition: border ease-in-out .6s;
  padding:  ${({ theme }) => theme.rem(6, 12)};
  border-radius: ${({ theme }) => theme.rem(100)};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => styledMap`
    default: ${theme.colors.grayscale.gray200};
    selected: ${theme.colors.general.light()};
  `};

  &[href]{
    cursor: pointer;
  }

  &:hover{
    border-color: ${({ theme }) => theme.colors.general.light()};
  }
`

export const StyledTagLabel = styled(Label)`
  position: relative;

  ${({
    theme,
    selected
  }) => selected && `
    span{
      position: relative;
      z-index:1;
    }

    &:before{
      content: '';
      position: absolute;
      top: 50%;
      transform: translate(-6px, -50%);
      z-index: 0;
      border-radius: 50%;
      ${theme.size(16)};
      background-color: ${theme.colors.accent()};
    }
  `}
`
