import styled from 'styled-components'
import styledMap from 'styled-map'

export const StyledHeroBackground = styled.div`
  z-index: 0;
  bottom: 0;
  height: 100%;
  position: ${styledMap`
    default: static;
    absolute: absolute;
  `};
  width: ${({ cols = 12 }) => `calc(100% / 12 *  ${cols})` };

  ${({ right, theme }) => right
    ? `
      right: 0;
      ${theme.paddingX([0, 60])};
    `
    : ''}

  ${({ left, theme }) => left
    ? `
      left: 0;
      ${theme.paddingX([60, 0])};
    `
    : ''}
`

export const StyledHeroBackgroundImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  ${({ theme }) => theme.size('100%')};

  > img{
    object-fit: contain;
    --max-height: ${styledMap`
      default: 250px;
      full: 100%;
    `};

    ${({ length, theme }) => length === 1
    ? `
        margin: auto;
        max-height: var(--max-height);
        ${theme.size('100%')};
      `
    : `
      width: 100%;
      margin: 0 auto;
      height: auto;
    `}

  }
`
