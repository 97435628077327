import styled from 'styled-components'

export const StyledCarousel = styled.div``


export const StyledCarouselThumbs = styled.div`
  width: 100%;
  z-index: 5;
  display: block;
  position: relative;
  overflow: hidden;
  margin-top: calc(100vw / 12 * -1);
  padding-bottom: ${({ theme }) =>theme.rem(96)};
  background-color: ${({ theme }) =>theme.colors.background()};
  border-top-right-radius: ${({ theme }) => theme.rem(24)};
  border-top-left-radius: ${({ theme }) => theme.rem(24)};

  ${({ theme }) => theme.mediaQuery.md(`
   --col-min: ${theme.col(0.5)};
    --col: calc((100vw - var(--max-width)) / 2);

    width: 325px;
    margin-top: 0;
    padding-bottom: 0;
    display: block;
    position: absolute;
    bottom: ${theme.rem(120)};
    right: clamp(var(--col-min), var(--col),var(--col));
    border-radius: ${theme.rem(16)};
    background-color: ${theme.colors.general.light(0.75)};
  `)}


    .swiper-slide{
      height: auto;
      z-index: 0;

      &.swiper-slide-active{
        z-index: 1;
      }
    }

  .swiper-pagination {
    width: 100%;
    height: 2px;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    position: absolute;
    left: 0;
    overflow: hidden;
    top: ${({ theme }) => theme.rem(32)};
    ${({ theme }) => theme.paddingX(16)};
    gap: ${({ theme }) => theme.rem(8)};

    ${({ theme }) => theme.mediaQuery.md(`
      top: unset;
      bottom: ${theme.rem(16)};
    `)}

    .swiper-pagination-bullet {
      height: 100%;
      width: 100%;
      position: relative;
      background-color: ${({ theme }) => theme.colors.grayscale.gray500};
      overflow: hidden;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        transform-origin: 0 0;
        height: 100%;
        background-color: ${({ theme }) => theme.colors.accent()};
        scale: 1;
      }

      &.swiper-pagination-bullet-active ~ span{
        &:after {
          scale: 0 1;
        }
      }

      &.swiper-pagination-bullet-active {
        &:after {
          scale: var(--slide-progress) 1;
        }
      }
    }
  }
`
