import React from 'react'

import Cta from '@/atoms/Cta'
import Tag from '@/atoms/Tag'
import Richtext from '@/atoms/RichText'
import Link from '@/atoms/Link'

import HeadingWithMark from '@/molecules/HeadingWithMark'
import { StyledHeroPart } from '@/molecules/Heroes/styles'
import Info from '@/molecules/Info'
import BannerCard from '@/molecules/Card/Cards/BannerCard'

import {
  StyledFeaturedContentContainer,
  StyledFeaturedContent,
  StyledFeaturedContentCategories,
  StyledFeaturedContentTitle,
  StyledFeaturedContentMedia,
  StyledFeaturedContentInfos,
  StyledFeaturedContentProduct,
} from './styles'


const FeaturedContent = ({
  categories,
  link,
  title,
  description,
  infos,
  product,
  cta,
  ratio,
  media,
}) => {
  return (
    <StyledFeaturedContentContainer>
      <StyledHeroPart start>
        <StyledFeaturedContent>
          {
            categories &&
            !!categories.length &&
            <StyledFeaturedContentCategories gap={16} wrap>
              {
                categories.map(category => typeof category.link === 'object' ? <Tag key={category} label={category.link.title} {...category} /> : <Tag key={category} label={category} />)
              }
            </StyledFeaturedContentCategories>
          }

          <StyledFeaturedContentTitle as={link ? Link : 'div'} href={link}>
            <HeadingWithMark as='h2' typo='heading2' bold content={title} />
          </StyledFeaturedContentTitle>

          {
            description &&
            <Richtext as="div" typo="paragraphLarge">
              {description}
            </Richtext>
          }
          {
            infos &&
            !!infos.length &&
            <StyledFeaturedContentInfos gap={24} wrap>
              {
                infos.map(info => <Info key={info.label} {...info} iconSize={24}/>)
              }
            </StyledFeaturedContentInfos>
          }

          {
            product &&
            <StyledFeaturedContentProduct>
              <BannerCard withDecoration {...product} />
            </StyledFeaturedContentProduct>
          }

          {
            cta &&
            !!cta.link &&
            <Cta keyword={cta.keyword} {...cta.link} />
          }
        </StyledFeaturedContent>
      </StyledHeroPart>

      <StyledHeroPart>
        <StyledFeaturedContentMedia
          aria-label={title}
          href={link}
          ratio={ratio}
          image={media.image}
          icon={{
            big: true,
            name: media.type !== 'image' ? 'play' : 'arrow-diagonal',
            visible: media.type !== 'image',
          }}
        />
      </StyledHeroPart>

    </StyledFeaturedContentContainer>
  )
}

export default FeaturedContent
