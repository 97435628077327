import styled from 'styled-components'

import Link from '@/atoms/Link'
import styledMap from 'styled-map'

export const StyledSimpleNavigation = styled.nav`
width: 100%;
`

export const StyledSimpleNavigationList = styled.ul`
  display: inline-flex;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.rem(24)};
  justify-content: ${styledMap`
    default: flex-start;
    end: flex-end;
    center: center;
    expanded: space-between;
  `};
`

export const StyledSimpleNavigationListItem = styled.li``

export const StyledSimpleNavigationLink = styled(Link)`
  color: ${({ theme }) => theme.colors.grayscale.white};
  font-weight: ${styledMap`
    default: 300;
    bold: 600;
  `};
`
