import React from 'react'

import RecipeCard from '@/molecules/Card/Cards/RecipeCard'
import SubParagraph from '@/molecules/SubParagraph'

import {
  StyledRelated,
  StyledRelatedList,
} from './styles'

const Related = ({
  title,
  items
}) => (
  <StyledRelated>
    <SubParagraph title={title}>
      {
        items &&
        !!items.length &&
        <StyledRelatedList>
          {
            items.map((item, idx) => (
              <RecipeCard
                key={item + idx}
                imageRatio={1}
                item={{
                  ...item,
                  titleSize: 'heading5',
                  row: true
                }} />
            ))
          }
        </StyledRelatedList>
      }
    </SubParagraph>
  </StyledRelated>
)

export default Related
