import styled from 'styled-components'

import Button from '@/atoms/Button'

export const StyledDropTrigger = styled(Button)`
  justify-content: space-between;
  height: ${({ theme }) => theme.rem(50)};
  z-index: 1;
`

export const StyledDropListContainer = styled.div`
  z-index: 0;
  content: '';
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  ${({ theme }) => theme.size('100%')}
`

export const StyledDropDown = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;

  ${({ isOpen }) => isOpen && `
    ${StyledDropTrigger}{
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  `}
`
