import React from 'react'
import { Label } from '../Typography'
import {
  StyledTable,
  StyledTableTitle,
  StyledTableRowGroupContainer,
  StyledTableRowGroup,
  StyledTableRow,
} from './styles'

const Table = ({
  title,
  items,
  outro,
}) => (
  <StyledTable
    role="table"
    aria-label={title}
    aria-describedby="semantic_elements_table_desc"
  >
    <StyledTableTitle id="semantic_elements_table_desc">
      <Label bold typo="heading5">{title}</Label>
    </StyledTableTitle>
    <StyledTableRowGroupContainer>
      {
        items &&
        !!items.length &&
        items.map((group, idx) => (
          <StyledTableRowGroup
            hide={idx === items.length - 1}
            last={idx === items.length - 1}
            key={group + idx} role="rowgroup"
          >
            {
              group &&
              !!group.length &&
              group.map(({
                label, detail
              }, i)=> (
                <StyledTableRow key={i + title} role="row" aria-rowindex={10 + idx }>
                  <span role="cell">{label}</span>
                  <span role="cell">{detail}</span>
                </StyledTableRow>
              ))
            }
          </StyledTableRowGroup>
        ))
      }

      {
        outro &&
        items &&
        !!items.length &&
        <StyledTableRowGroup last hide role="rowgroup">
          <StyledTableRow role="row" aria-rowindex={10 +  items.length}>
            <Label as="span" typo="paragraphSmall" roll="cell">{outro}</Label>
            <span role="cell"></span>
          </StyledTableRow>
        </StyledTableRowGroup>
      }
    </StyledTableRowGroupContainer>
  </StyledTable>
)

export default Table
