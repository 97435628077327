import React from 'react'
import { object } from 'yup'

import { createValidationSchema } from 'models/Form'

import CustomForm from '@/atoms/CustomForm'

const Form = ({
  form,
  settings,
  ...rest
}) => {
  if (!form) return <></>
  let validationSchema = {}

  form.originalFieldset.forEach(({ fields: f }) => {
    const fieldsetValidationSchema = f.reduce(createValidationSchema, {})
    validationSchema = {
      ...validationSchema,
      ...fieldsetValidationSchema
    }
  })

  return (
    <CustomForm
      validateOnMount
      validationSchema={object().shape(validationSchema)}
      {...rest}
      {...form}
      {...settings}
    />
  )
}


export default Form
