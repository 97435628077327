import React from 'react'

import { motion } from 'framer-motion'

import Icon from '@/atoms/Icon'

import { StyledTimer } from './styles'

const ButtonTimer = ({
  delay = 1,
  duration = 5,
  onClick,
  onAnimationComplete,
}) => {
  return (
    <StyledTimer onClick={onClick}>
      <motion.svg
        width="56"
        height="56"
        fill="transparent"
        viewBox="0 0 56 56"
      >
        <motion.circle
          cx="28"
          cy="28"
          r="26"
          stroke="white"
          strokeWidth={1}
          initial={{
            pathLength: 1,
            opacity: 1
          }}
          animate={{
            pathLength: 0,
            opacity: 0
          }}
          transition={{
            delay,
            duration,
            ease: 'linear',
          }}
          onAnimationComplete={onAnimationComplete}
        />
      </motion.svg>
      <Icon name="check" />
    </StyledTimer>
  )
}


export default ButtonTimer
