import React from 'react'
import { Label } from '@/atoms/Typography'
import {
  StyledFilterCluster,
  StyledFilterClusterWrapper,
  StyledFilterClusterHeader,
  StyledFilterClusterHeaderContainer,
  StyledFilterClusterContent,
} from './styles'

const Cluster = ({
  label,
  type,
  withLine = false,
  children,
  isLast,
}) => (
  <StyledFilterCluster>

    {
      label &&
      <StyledFilterClusterHeader>
        <StyledFilterClusterHeaderContainer>
          <Label as="span" typo="labelSmall">{label}</Label>
        </StyledFilterClusterHeaderContainer>
      </StyledFilterClusterHeader>
    }

    <StyledFilterClusterWrapper type={type} withLine={withLine} isLast={isLast}>
      {
        children &&
        <StyledFilterClusterContent>
          {children}
        </StyledFilterClusterContent>
      }
    </StyledFilterClusterWrapper>
  </StyledFilterCluster>
)

export default Cluster
