import dynamic from 'next/dynamic'
import { useState } from 'react'

import { fetchAlgoliaData } from 'api'
import { GridAlgolia } from 'models/Grid'

import Cluster from '@/molecules/Filters/Cluster'
const FiltersResults = dynamic(import('@/molecules/Filters/Results'))

import SearchResultsHeader from './SearchResultsHeader'

import { StyledSearchResults } from './styles'

const SearchResults = ({ filters, endpoint, noResult }) => {
  const [loading, setLoading] = useState(true)
  const [results, setResults] = useState(undefined)
  const [error, setError] = useState(null)
  const [totalItems, setTotalItems] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(0)
  const [fetchBody, setFetchBody] = useState({})

  const getData = async ({
    additionalBodyData = {},
    page = 0,
    replace = true,
  }) => {
    const body = {
      ...fetchBody,
      ...additionalBodyData,
    }

    const query = new URLSearchParams(window.location.search).get('s')

    if (!query) {
      setFetchBody({})
      setResults([])
      setTotalItems(0)
      setTotalPages(1)
      return false
    }

    body.query = query
    body.page = page

    const { data } = await fetchAlgoliaData({
      body,
      customEndpoint: endpoint,
    })

    const { list } = GridAlgolia(data)

    if (!replace) {
      setResults((oldItems) => [...oldItems, ...list])
    } else {
      setResults(list)
    }

    setCurrentPage(data.page)
    setFetchBody(body)

    return data
  }

  return (
    <StyledSearchResults>
      <SearchResultsHeader
        filters={filters}
        getData={getData}
        setLoading={setLoading}
        setTotalPages={setTotalPages}
        setTotalItems={setTotalItems}
        setError={setError}
      />
      {!loading && (
        <Cluster type="results">
          <FiltersResults
            results={results}
            error={error}
            getData={getData}
            currentPage={currentPage}
            totalItems={totalItems}
            totalPages={totalPages}
            noResult={noResult}
            loading={loading}
          />
        </Cluster>
      )}
    </StyledSearchResults>
  )
}

export default SearchResults
