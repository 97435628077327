import styled from 'styled-components'

import {
  StyledCard,
  StyledCardContainer,
  commonRoundedCardStyle
} from '@/molecules/Card/Cards/styles'

import { StyledButton } from '@/atoms/Button/styles'

import CardMedia from '@/molecules/Card/CardMedia'

export const StyledShopCard = styled(StyledCard)`
  ${commonRoundedCardStyle};
  height: 100%;
  box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.1);
  transition: box-shadow ease-in-out .4s;

  ${({ theme }) => theme.mediaQuery.lg(`
    &:hover {
      box-shadow: ${theme.shadows.hover};

      ${StyledButton}{
        background-color: ${theme.colors.medium()};
      }
    }
  `)};

  ${StyledCardContainer}{
    height: 100%;
  }

  ${StyledButton}{
    margin-top: auto;
  }
`

export const StyledShopCardMedia = styled(CardMedia)`
  border-radius: ${({ theme }) =>theme.rem(16)};
`

export const StyledShopCardName = styled.div`
  padding: 0 ${({ theme }) =>theme.rem(8)};
  color: ${({ theme }) => theme.colors.general.light()};
  text-align: left;
`
