import styled from 'styled-components'
import styledMap from 'styled-map'

import Button from '@/atoms/Button'

import {
  openedStatus,
  openedGraphic,
  currentGraphic,
} from '@/organisms/Header/styles'


export const StyledMainNavigation = styled.nav`
  ${({ theme }) => theme.mediaQuery.md(`
    height: 100%;
  `)}
`

export const StyledMainNavigationList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: ${({ theme }) => theme.rem(24)};

  ${({ theme }) => theme.mediaQuery.md(`
    flex-direction: row;
    height: 100%;
  `)}
`

export const StyledMainNavigationListItem = styled.li`

  position: relative;
  ${({ theme }) => theme.mediaQuery.md(`
    height: 100%;
  `)}
`

export const StyledMainNavigationLink = styled(Button)`

  position: relative;
  background-color: transparent;
  padding:0;
  border: none;
  height: 100%;
  padding-left: ${({ theme }) => styledMap`
    default: 0;
    current: ${theme.rem(12)};
  `};

  ${openedGraphic};

  &:hover{
    ${openedStatus}
  }

  ${({ opened }) => opened && openedStatus}

  ${({ current }) => current && currentGraphic};

  ${({ theme }) => theme.mediaQuery.md(`
    display: grid;
    place-items: center;
  `)}

`
