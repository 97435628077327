import React, { useContext } from 'react'

import { GeneralContext } from 'contexts/General'

import Button from '@/atoms/Button'


const ClubButton = (props) => {
  const { options = {}, } = useContext(GeneralContext)
  const { club = {} } = options

  return (
    club.cta &&
    !!club.cta.link &&
    <Button
      useIconColor
      small
      iconStart="buon-per-me"
      keyword={club.cta.keyword}
      label={club.cta.link.title}
      href={club.cta.link.href}
      target={club.cta.link.target}
      typo="link2"
      {...props}
    />
  )
}

export default ClubButton
