import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9901 19.9219L19.9432 11.9688Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20.5796 11.3324C20.9311 11.6838 20.9311 12.2537 20.5796 12.6052L12.6265 20.5583C12.275 20.9097 11.7052 20.9097 11.3537 20.5583C11.0022 20.2068 11.0022 19.6369 11.3537 19.2855L19.3068 11.3324C19.6583 10.9809 20.2281 10.9809 20.5796 11.3324Z" fill="#194074"/>
    <path d="M4 11.6151L20 11.615Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20 12.515L3.99998 12.5151C3.50292 12.5151 3.09998 12.1121 3.09998 11.6151C3.09997 11.118 3.50292 10.7151 3.99997 10.7151L20 10.715C20.497 10.715 20.9 11.118 20.9 11.615C20.9 12.1121 20.497 12.515 20 12.515Z" fill="#194074"/>
    <path d="M16.7557 7.76561L11.9901 4Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.284 3.44203C11.5921 3.05203 12.1581 2.98569 12.5481 3.29386L17.3137 7.05947C17.7037 7.36763 17.77 7.93361 17.4619 8.32361C17.1537 8.71361 16.5877 8.77995 16.1977 8.47178L11.4321 4.70617C11.0421 4.398 10.9758 3.83203 11.284 3.44203Z" fill="#194074"/>
  </svg>

)
