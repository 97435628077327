import styled from 'styled-components'

import DefaultCard from '@/molecules/Card/Cards/DefaultCard'
import Richtext from '@/atoms/RichText'

import { StyledRoundedIcon } from '@/molecules/RoundedIcon/styles'
import { StyledDefaultCardName } from '../DefaultCard/styles'

export const StyledCategoryCard = styled(DefaultCard)`
  ${StyledDefaultCardName}{
    order: 2;
  }

  ${({ theme }) => theme.mediaQuery.md(`
    ${StyledRoundedIcon}{
      transition: border-color ease-in-out .4s;
    }

    &:hover{
      ${StyledRoundedIcon}{
        border-color: ${theme.colors.general.light()};
      }
    }
  `)}
`

export const StyledCategoryCardDescription = styled(Richtext)`
  order: 1;
  display: block;
  color: ${({ theme }) => theme.colors.general.light()};
`
