export const getErrors = (errors) => {
  const { details: { errors: errorsArray }  } = errors
  return errorsArray.reduce((acc, curr) => {
    const {
      path, message
    } = curr

    acc[path[0]] = message
    return acc
  }, {})
}


export const createFetchBody = (data) => {
  const formData = new FormData()

  for (const val in data) {
    formData.append(val, data[val])
  }

  return formData
}
