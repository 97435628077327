const RecipeCard = (item) => {
  const {
    featuredBadges = [],
    ...rest
  } = item
  const categories = featuredBadges.map(({
    keyword,
    link
  }) => ({
    keyword,
    label: link.title,
    link: { ...link }
  }))


  return {
    ...rest,
    categories
  }
}

export default RecipeCard
