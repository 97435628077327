import React from 'react'

import { InlineList } from '@/atoms/Layout'
import Tag from '@/atoms/Tag'

import Media from '@/molecules/Media'
import Breadcrumbs from '@/molecules/Breadcrumbs'
import BannerCard from '@/molecules/Card/Cards/BannerCard'

import HeroContent from '../HeroContent'
import StyledHeroWave from '../HeroWave'

import {
  StyledHeroEditorial,
  StyledHeroEditorialContainer,
  StyledHeroEditorialContent,
  StyledHeroEditorialProduct
} from './styles'

const HeroEditorial = ({
  breadcrumbs,
  media,
  category: categories,
  title,
  description,
  product
}) => (
  <StyledHeroEditorial>
    <Breadcrumbs items={breadcrumbs}/>
    <StyledHeroEditorialContainer>
      <Media {...media} />
      <StyledHeroEditorialContent>
        <InlineList gap={16} wrap>
          {
            categories.map(category => <Tag key={category} label={category} />)
          }
        </InlineList>

        <HeroContent  title={title} description={description}/>
      </StyledHeroEditorialContent>
      {
        product &&
        <StyledHeroEditorialProduct>
          <BannerCard withDecoration {...product} />
        </StyledHeroEditorialProduct>
      }
    </StyledHeroEditorialContainer>
    <StyledHeroWave />
  </StyledHeroEditorial>
)


export default HeroEditorial
