import React, {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'

import {
  AnimatePresence,
  motion
} from 'framer-motion'

import { HeaderContext } from 'contexts/Header'

import Button from '@/atoms/Button'
import { Label } from '@/atoms/Typography'

import SingleItem from '../SingleItem'

import {
  StyledSecondaryNavigation,
  StyledSecondaryNavigationList,
  StyledSecondaryNavigationCta,
  StyledSecondaryNavigationHeader,
} from './styles'

const SecondaryNavigation = ({ type: position,
  data,
  id,
  cta
}) => {
  const ref = useRef(null)
  const [offsetX, setOffsetX] = useState(0)

  const {
    state,
    dispatch,
    isMobile
  } = useContext(HeaderContext)

  useEffect(() => {
    const updateOffset = () => {
      const { x } = ref.current.getBoundingClientRect()
      const w = window.innerWidth - x * 2
      setOffsetX(w)
    }
    updateOffset()
    window.addEventListener('resize', updateOffset)
    return () => window.removeEventListener('resize', updateOffset)
  }, [ref])

  return (
    <AnimatePresence>
      {
        (position === 'submenu' || position === 'submenuMobile') &&
        <StyledSecondaryNavigation
          data-lenis-prevent
          ref={ref}
          layout
          as={motion.div}
          initial={{ opacity: 0, display: 'none' }}
          animate={{
            opacity: ~~(state.opened && id === state.id),
            transition: { duration: 0.2 },
            transitionEnd: {
              display: state.opened && id === state.id ? 'block' : 'none',
            },

          }}
          exit={{ opacity: 0 }}
        >

          {
            isMobile &&
            <StyledSecondaryNavigationHeader>
              <Button
                bold
                reset
                auto
                typo="labelSmall"
                iconStart="chevron-left"
                label="Torna indietro"
                handleClick={() => dispatch({ type: 'lateral' })}
              />

              <Label as="strong" bold typo="heading3">
                {id}
              </Label>
            </StyledSecondaryNavigationHeader>
          }
          {
            data &&
            !!data.length &&
            <StyledSecondaryNavigationList
              x={offsetX}
              cols={data.length}
            >
              {
                data.map(item => (
                  <SingleItem key={item.id} {...item} />
                ))
              }
            </StyledSecondaryNavigationList>
          }

          {
            cta &&
            cta.link &&
            <StyledSecondaryNavigationCta>
              <Button
                keyword={cta.keyword}
                href={cta.link.href}
                label={cta.link.title}
              />
            </StyledSecondaryNavigationCta>
          }

        </StyledSecondaryNavigation>
      }
    </AnimatePresence>

  )
}
export default SecondaryNavigation
