export default () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.1999 17.4C16.194 17.4 16.9999 16.5941 16.9999 15.6C16.9999 14.6059 16.194 13.8 15.1999 13.8C14.2058 13.8 13.3999 14.6059 13.3999 15.6C13.3999 16.5941 14.2058 17.4 15.1999 17.4Z" fill="#CFD4DB" className="branded-content"/>
    <path d="M7.99995 15.6C8.99406 15.6 9.79995 14.7941 9.79995 13.8C9.79995 12.8059 8.99406 12 7.99995 12C7.00584 12 6.19995 12.8059 6.19995 13.8C6.19995 14.7941 7.00584 15.6 7.99995 15.6Z" fill="#CFD4DB" className="branded-content"/>
    <path d="M21.8 9.60002C21.8 10.5941 20.9941 11.4 20 11.4C19.0058 11.4 18.2 10.5941 18.2 9.60002L20 9.60001L21.8 9.60002Z" fill="#CFD4DB" className="branded-content"/>
    <path d="M11.4243 4.79999L22.398 9.42049H4.3999C4.50699 8.49718 4.94817 7.44968 5.87831 6.5874C6.94768 5.59607 8.70018 4.81682 11.4243 4.79999Z" fill="#CFD4DB" className="branded-content"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M22.6999 9.63813H1.99988V8.73813H22.6999V9.63813Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.34289 5.24505C4.62621 4.05537 6.64236 3.21738 9.59098 3.21738H9.68185L23.1528 8.88935V19.2995H1.54993V18.8495V9.18814C1.54993 7.93315 2.06366 6.43094 3.34289 5.24505ZM2.44993 18.3995H22.2528V9.48693L9.50077 4.11765C6.77663 4.13449 5.02412 4.91373 3.95475 5.90507C2.86955 6.9111 2.44993 8.16927 2.44993 9.18814V18.3995Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M18.0499 9.60001C18.0499 10.0142 18.3857 10.35 18.7999 10.35C19.2141 10.35 19.5499 10.0142 19.5499 9.60001H20.4499C20.4499 10.5113 19.7112 11.25 18.7999 11.25C17.8886 11.25 17.1499 10.5113 17.1499 9.60001H18.0499Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14 15.9C14.497 15.9 14.9 15.4971 14.9 15C14.9 14.503 14.497 14.1 14 14.1C13.5029 14.1 13.1 14.503 13.1 15C13.1 15.4971 13.5029 15.9 14 15.9ZM14 16.8C14.9941 16.8 15.8 15.9941 15.8 15C15.8 14.0059 14.9941 13.2 14 13.2C13.0058 13.2 12.2 14.0059 12.2 15C12.2 15.9941 13.0058 16.8 14 16.8Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.8 14.1C7.29706 14.1 7.7 13.6971 7.7 13.2C7.7 12.7029 7.29706 12.3 6.8 12.3C6.30294 12.3 5.9 12.7029 5.9 13.2C5.9 13.6971 6.30294 14.1 6.8 14.1ZM6.8 15C7.79411 15 8.6 14.1941 8.6 13.2C8.6 12.2059 7.79411 11.4 6.8 11.4C5.80589 11.4 5 12.2059 5 13.2C5 14.1941 5.80589 15 6.8 15Z" fill="#194074"/>
  </svg>
)
