import styled from 'styled-components'

export const StyledFilters = styled.div`
  .NoResults{
    ${({ theme }) => theme.marginY([64, 0])};
    ${({ theme }) => theme.mediaQuery.md(`
      margin-top: ${theme.rem(128)};
    `)};
  }

`

export const StyledFiltersItems = styled.div``


