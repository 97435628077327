import styled, { css } from 'styled-components'

export const commonRoundedCardStyle = css`
  border-radius: ${({ theme }) =>theme.rem(24)};
`

export const StyledCard = styled.article`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  gap: ${({ theme }) =>theme.rem(16)};

  ${({ theme }) => theme.mediaQuery.lg(`
    gap: ${theme.rem(24)};
  `)}
`

export const StyledCardContainer = styled.div`
  padding: ${({ theme }) =>theme.rem(16)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) =>theme.rem(16)};

  ${({ theme }) => theme.mediaQuery.lg(`
    gap: ${theme.rem(24)};
  `)}
`
