import Icon from '@/atoms/Icon'
import React from 'react'

import {
  StyledSocials,
  StyledSocialsItem,
  StyledSocialsItemLink,
} from './styles'

import { Label } from '@/atoms/Typography'

const Socials = ({ items }) => (
  items &&
  !!items.length &&
  <StyledSocials>
    {
      items.map(({
        icon,
        link
      }, idx) => (
        <StyledSocialsItem key={icon + idx}>
          <StyledSocialsItemLink
            white
            withLabel={false}
            aria-label={link.cta.keyword}
            href={!!link.cta.link && link.cta.link.href ? link.cta.link.href : '#'}
            target={link.cta.link.target}>
            <Icon name={icon} />
            <Label as="span" typo="paragraphSmall" >{link.cta.link.title}</Label>
          </StyledSocialsItemLink>
        </StyledSocialsItem>
      ))
    }

  </StyledSocials>
)


export default Socials
