import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
} from 'react'

import { generateHref } from 'api/utils'
import useIsMobile from 'hooks/useIsMobile'

export const HeaderContext = createContext({})

const reducer = (state, action) => {
  switch (action.type) {
    case 'lateral': {
      return {
        ...state,
        opened: true,
        position: 'lateral',
        id: 'lateral',
        data: null
      }
    }

    case 'submenuMobile':
    case 'submenu': {
      return {
        ...state,
        opened: true,
        position: action.type,
        id: action.id,
      }
    }
    case 'search': {
      return {
        ...state,
        opened: true,
        position: 'search',
        id: 'search',
        data: null
      }
    }

    case 'close': {
      return {
        ...state,
        position: null,
        data: null,
        opened: false,
        id: null
      }
    }
    default:
      throw Error('Unknown action: ' + action.type)
  }
}

import { GeneralContext } from './General'

const HeaderProvider = ({ children }) => {
  const isMobile = useIsMobile('lg')
  const {
    options = {},
    menus = {},
    page = {}
  } = useContext(GeneralContext)

  const {
    otherBrands,
    brand,
    searchForm,
  } = options
  const { headerMenu = {} } = menus
  const { currentPageSlug } = page

  const initialState = {
    position: null,
    opened: false,
    data: null,
    id: null,
    current: generateHref(currentPageSlug),
  }

  const {
    data = {},
    items: main
  } = headerMenu || {}

  const menusByViewport = useMemo(() => {
    if (!isMobile) {
      return {
        main,
        lateral: data.sideMenu?.items
      }
    }

    const mainNavMobile = main ? structuredClone(main).map(item => ({
      main: true,
      ...item
    })) : []

    const items = data.sideMenu?.items || []

    return {
      main: null,
      lateral: [...mainNavMobile, ...items]
    }
  }, [isMobile])

  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <HeaderContext.Provider
      value={{
        brand,
        isMobile,
        state,
        searchForm,
        dispatch,
        ...menusByViewport,
        brands: {
          items: otherBrands,
          ...data.allBrands
        }
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export default HeaderProvider
