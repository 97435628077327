import React from 'react'

import Icon from '@/atoms/Icon'

import {
  StyledCta,
  StyledCtaLabel
} from './styles'

const Cta = ({
  href,
  target = '_self',
  title,
  icon = 'chevron-right',
  detail = true,
  small = false,
  big = false,
  white = false,
  keyword = false,
  className = false,
}) => (
  <StyledCta
    className={className}
    title={keyword}
    href={href}
    target={target}
    white={white}>

    <StyledCtaLabel
      as="span"
      bold
      detail={detail}
      small={small}
      big={big}
    >
      {title}
    </StyledCtaLabel>

    <Icon name={icon} />
  </StyledCta>
)

export default Cta
