import React, { useState } from 'react'

import Button from '@/atoms/Button'

const CopyButton = ({ textToCopy }) => {
  const [label, setLabel] = useState('Copia')

  const onCopyClick = () => {
    navigator.clipboard.writeText(textToCopy)
    setLabel('Copiato')

    setTimeout(() => {setLabel('Copia')}, 3000)
  }

  return (
    <Button
      negative
      typo="buttonRegular"
      iconStart="copy"
      label={label}
      handleClick={onCopyClick}
    />
  )
}

export default CopyButton
