import React from 'react'
import Link from 'next/link'

import CardMedia from '@/molecules/Card/CardMedia'
import { Heading } from '@/atoms/Typography'

import {
  StyledDefaultCardContent,
  StyledDefaultCardName
} from '../DefaultCard/styles'

import { StyledSearchCard } from './styles'

const SearchCard = ({ item }) => {
  const {
    link,
    image,
    title,
    titleSize,
    cardType,
    icon,
    chefPeople
  } = item

  return (
    <StyledSearchCard cardType={cardType}>
      <CardMedia
        as={Link}
        href={link}
        ratio={1}
        image={image}
        aria-label={title}
        icon={icon}
        chefPeople={chefPeople}
      />

      <StyledDefaultCardContent
        as={Link}
        href={link}
      >
        <StyledDefaultCardName>
          <Heading as="strong" typo={titleSize}>{title}</Heading>
        </StyledDefaultCardName>

      </StyledDefaultCardContent>
    </StyledSearchCard>
  )
}

export default SearchCard
