import styled from 'styled-components'

export const StyledSectionBuilder = styled.main`
  z-index: 1;
  position: relative;

  ${({ theme }) => theme.mediaQuery.md(`
    &::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100%);
      background-color: white;
      border-bottom-left-radius: ${theme.rem(40)};
      border-bottom-right-radius: ${theme.rem(40)};
    }
  `)}
`
