import React from 'react'

import { Label } from '@/atoms/Typography'
import Button from '@/atoms/Button'
import Richtext from '@/atoms/RichText'
import { StyledNotFound } from './styles'

const NotFound = () => {
  return (
    <StyledNotFound>
      <Label bold as="h1" typo="heading1">404</Label>
      <Label bold as="h2" typo="heading2">Qualcosa è andato storto</Label>
      <Richtext as="p" typo="paragraphRegular">
        La pagina che cerchi non esiste o è stata rimossa.
      </Richtext>

      <Button href="/" big general label="Torna alla home" />
    </StyledNotFound>
  )
}

export default NotFound
