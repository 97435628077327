import React from 'react'
import { useLenis } from '@studio-freight/react-lenis'

import Icon from '@/atoms/Icon'

import { StyledBackToTopButton } from './styles'

const BackToTopButton = () => {
  const lenis = useLenis()
  const onClick = () => {
    lenis.scrollTo(0)
  }

  return (
    <StyledBackToTopButton
      aria-label='Torna su'
      onClick={onClick}
    >
      <Icon name="arrow-up" />
    </StyledBackToTopButton>
  )
}

export default BackToTopButton
