import styled from 'styled-components'
import Link from '@/atoms/Link'
import { Label } from '@/atoms/Typography'

export const StyledBreadcrumbsList = styled.ol`
  display: none;

  ${({ theme }) => theme.mediaQuery.md(`
    display: flex;
    gap: ${theme.rem(4)};
  `)}
`

export const StyledBreadcrumbsListItem = styled.li`
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.rem(4)};

  i{
    font-size: ${({ theme }) => theme.rem(16)}
  }
`

export const StyledBreadcrumbsItem = styled(Label)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.general.light()};

  ${({ current }) => current && `
    font-weight: 300;
  ` }
`

export const StyledBreadcrumbsBack = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.rem(4)};

  i{
    font-size: ${({ theme }) => theme.rem(10)}
  }

  ${({ theme }) => theme.mediaQuery.md(`
    display: none;
  `)}
`
