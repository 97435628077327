import styled from 'styled-components'

export const StyledSearch = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 10;
  padding: ${({ theme }) => theme.rem(4)};

  ${({ theme }) =>
    theme.mediaQuery.md(`
    top: 100%;
    height: auto;
    padding: ${theme.rem(24)};
    z-index: 1;
  `)}

  > div {
    position: relative;
    z-index: 1;
  }

  &::before {
    content: "";
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.colors.grayscale.white};
    border-radius: ${({ theme }) => theme.rem(30)};
    box-shadow: ${({ theme }) => theme.shadows.low(5)};
    width: 100%;

    ${({ theme }) =>
    theme.mediaQuery.md(`
    width: 100vw;
      border-top: 1px solid ${theme.colors.grayscale.gray200};
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: ${theme.rem(24)};
      border-bottom-left-radius: ${theme.rem(24)};
      `)}
    ${({ theme }) =>
    theme.mediaQuery.lg(`
      width: 100%;
      `)}
  }
`

export const StyledSearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  gap: ${({ theme }) => theme.rem(4)};

  ${({ theme }) =>
    theme.mediaQuery.md(`
    gap: ${theme.rem(16)};
    justify-content: center;
    max-width: 540px;
    `)}

  ${({ theme }) =>
    theme.mediaQuery.lg(`
  `)}
`

export const StyledSearchClose = styled.button`
  border-radius: 50%;
  position: relative;
  background-color: transparent;
  border: none;
  flex-shrink: 0;
  ${({ theme }) => theme.size(32)};

  ${({ theme }) =>
    theme.mediaQuery.md(`
    border: 1px solid ${theme.colors.grayscale.gray200};
  `)}

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;

    ${({ theme }) => theme.size(['40%', 2])};
    background-color: ${({ theme }) => theme.colors.general.light()};
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`
