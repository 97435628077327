import styled from 'styled-components'

import { InlineList } from '@/atoms/Layout'

export const StyledIngredients = styled.div`
  opacity: 0;
  height: 100%;
  overflow: auto;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity ease-in-out .4s;
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.background()};
  ${({ theme }) => theme.paddingX(24)}
  color: ${({ theme }) => theme.colors.general.light()};

  ${({ isOpen }) => isOpen && `
    opacity: 1;
    pointer-events: auto;
  `}

  ${InlineList}{
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    gap: ${({ theme }) => theme.rem(24)};
  }

  ${({ theme }) => theme.mediaQuery.md(`
    pointer-events: auto;
    opacity: 1;
    height: auto;
    width: 100%;
    left: unset;
    transform: none;
    padding: 0;
    border-radius: ${theme.rem(24)};
  `)}
`

export const StyledIngredientsHeader = styled.header`
  z-index: 1;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.background()};
  ${({ theme }) =>theme.paddingY([80, 8])};

  ${({ theme }) => theme.mediaQuery.md(`
    position: static;
    ${theme.paddingX(24)};
    ${theme.paddingY(24)};
  `)};

  ${({ theme }) => theme.mediaQuery.lg(`
    position: sticky;
    ${theme.paddingX(40)};
    ${theme.paddingY([40, 16])};
  `)};

  > * + *{
    margin-block-start: ${({ theme }) => theme.rem(8)};
  }
`

export const StyledIngredientsContent = styled.div`
  ${({ theme }) => theme.mediaQuery.md(`
    ${theme.paddingX(24)};
    ${theme.paddingY(0)};
  `)};

  ${({ theme }) => theme.mediaQuery.lg(`
    ${theme.paddingX(40)};
  `)};
`

export const StyledIngredientsFooter = styled.footer`
  display: inline-flex;
  width: 100%;
  bottom: 0;
  gap: ${({ theme }) => theme.rem(16)};
  background-color: ${({ theme }) => theme.colors.background()};
  ${({ theme }) =>theme.paddingY([16, 80])};

  ${({ theme }) => theme.mediaQuery.md(`
    position: sticky;
    flex-direction: column;
    ${theme.paddingX(24)};
    ${theme.paddingY([16, 24])};
  `)};

  ${({ theme }) => theme.mediaQuery.lg(`
    flex-direction: row;
    ${theme.paddingX(40)};
    ${theme.paddingY([16, 40])};
  `)};

  button{
    width: 100%;
  }
`

export const StyledIngredientsList = styled.ol`
  display: grid;
  gap: ${({ theme }) => theme.rem(16)};
`

export const StyledIngredientsListItem = styled.li`
  display: inline-flex;
  align-items: flex-start;
  position: relative;

  input {
    padding: 0;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    ${({ theme }) => theme.size(24)};
  }

  input:focus +label{
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }

  label{
    cursor: pointer;
    display: inline-flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: ${({ theme }) => theme.rem(16)};

    > span:first-child{
      margin-right: auto;
      margin-top: ${({ theme }) => theme.rem(4)};
    }
    > span:last-child{
      margin-top: ${({ theme }) => theme.rem(4)};
      flex-shrink: 0;
      line-height: 100%;
    }

    &::before{
      content: '';
      flex-shrink: 0;
      border-radius: 2px;
      transition: background-color ease-in-out .4s;
      ${({ theme }) => theme.size(24)};
      color: ${({ theme }) => theme.colors.accent()};
      background-color: ${({ theme }) => theme.colors.grayscale.white};
      border: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};
    }
    &::after{
      content: '';
      position: absolute;
      left: 5px;
      top: 11px;
      opacity: 0;
      transition: opacity ease-in-out .4s;
      transform: translateY(-50%) rotate(-45deg);
      transform-origin: center center;
      ${({ theme }) => theme.size([13, 8])};
      border-left: 3px solid ${({ theme }) => theme.colors.accent()};
      border-bottom: 3px solid ${({ theme }) => theme.colors.accent()};
    }
  }


  input:checked + label {
    > span:first-child{
      text-decoration: line-through;
    }

    &::before{
      background-color: ${({ theme }) => theme.colors.general.light()};
    }

    &::after{
      opacity: 1;
    }
  }
`
