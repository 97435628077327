import styled from 'styled-components'

export const StyledHeadingWithMark = styled.div`

  mark {
    background: none;
    display: inline;
    padding: 0  5px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    z-index: 1;
    opacity: 0;
    transition: opacity ease-in-out .4s,  color ease-in-out .4s;
    color: ${({ theme }) => theme.colors.grayscale.white};
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.accent()};
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: 8px;
      transition: transform ease-in-out .4s;
      transform: matrix(1, -0.01, 0.01, 1, 0, 0) scaleX(0);
      transform-origin: left center;
    }
  }

  &.inView{
    mark{
      opacity: 1;
      color: ${({ theme }) => theme.colors.general.light()};

      &::after{
        transform: matrix(1, -0.01, 0.01, 1, 0, 0) scaleX(1.03);
      }
    }
  }

`
