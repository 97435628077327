import styled from 'styled-components'

export const StyledRoundedIcon = styled.div`
  border-radius: 50%;
  flex-shrink: 0;
  display: grid;
  place-items: center;
  border: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};

  ${({
    size = 32,
    theme
  }) => theme.size(size)};
`
