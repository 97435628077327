import styled from 'styled-components'

import Link from '@/atoms/Link'
import { Label } from '@/atoms/Typography'

export const StyledSocials = styled.ul`
  display: inline-flex;
  margin: 0;
  padding: 0;
  width: 100%;
  justify-content: space-between;
  gap: ${({ theme }) => theme.rem(16)};

  ${({ theme }) => theme.mediaQuery.md(`
      width: auto;
  `)}
`

export const StyledSocialsItem = styled.li`
  width: 50%;
  ${({ theme }) => theme.mediaQuery.md(`
      width: auto;
  `)}
`

export const StyledSocialsItemLink = styled(Link)`
  display: inline-flex;
  gap: ${({ theme }) => theme.rem(16)};

  i{
    font-size: ${({ theme }) => theme.rem(24)};
  }

  ${Label}{
    ${({ theme }) => theme.mediaQuery.md(`
      display: none;
    `)}
  }

  ${({ theme }) => theme.mediaQuery.md(`
    opacity: 1;
    transition: opacity ease-in-out .4s;
    &:hover{
      opacity: 0.7
    }
  `)}
`
