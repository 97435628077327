import React, {
  useMemo, useState
} from 'react'

import {
  Swiper,
  SwiperSlide,
} from 'swiper/react'
import SwiperCore, { Keyboard, Mousewheel } from 'swiper/core'

import {
  Autoplay,
  EffectFade,
  Pagination, Controller
} from 'swiper'

import useIsMobile from 'hooks/useIsMobile'

import CarouselSlide from './CarouselSlide'
import CarouselPreview from './CarouselPreview'

import {
  StyledCarousel,
  StyledCarouselThumbs
} from './styles'

SwiperCore.use([Keyboard, Mousewheel])
const Carousel = ({ list }) => {
  const isMobile = useIsMobile()
  const listThumbs = useMemo(() => {
    const clonedList = [...list]
    const firstEl = clonedList.shift()
    clonedList.push(firstEl)

    clonedList.forEach(el => {
      const regex = /(<([^>]+)>)/ig
      const body = el.title
      const result = body.replace(regex, '')

      el.thumbTitle = result
    })

    return isMobile ? list : clonedList
  }, [list, isMobile])

  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)

  return (
    <StyledCarousel>
      <Swiper
        modules={[EffectFade, Autoplay, Controller]}
        onSwiper={setFirstSwiper}
        keyboard={true}
        controller={{ control: secondSwiper }}
        fadeEffect={ { crossFade: true } }
        effect="fade"
        preventInteractionOnTransition
        spaceBetween={0}
        slidesPerView={1}
        watchSlidesProgress
        watchOverflow
        loop={true}
        autoplay={{ disableOnInteraction: false, }}
        onAutoplayTimeLeft={(s, time, progress) => {
          if (progress >= 0 && secondSwiper  && secondSwiper.el) {
            secondSwiper.el.style.setProperty('--slide-progress', 1 - progress)
          }
        }}
        breakpoints={{
          768: { allowTouchMove: false }
        }}
      >
        {
          list.map((el, i) =>
            <SwiperSlide
              key={`main_${i}`}
              data-swiper-autoplay={el.duration * 1000}
            >
              {({ isActive }) => (
                <CarouselSlide data={el} isActive={isActive} />
              )}
            </SwiperSlide>
          )
        }
      </Swiper>

      <StyledCarouselThumbs>
        <Swiper
          watchSlidesProgress
          modules={[EffectFade, Pagination, Controller]}
          pagination={{ clickable: false }}
          allowTouchMove={false}
          onSwiper={(instance) => {
            instance.el.style.setProperty('--slide-progress', 0)
            setSecondSwiper(instance)
          }}
          controller={{ control: firstSwiper }}
          fadeEffect={ { crossFade: true } }
          effect="fade"
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
        >
          {
            listThumbs.map((el, i) =>
              <SwiperSlide key={`preview_${i}`}>
                <CarouselPreview data={el} />
              </SwiperSlide>
            )
          }
        </Swiper>
      </StyledCarouselThumbs>


    </StyledCarousel>
  )
}

export default Carousel
