import styled from 'styled-components'

import { StyledRichtext } from '@/atoms/RichText/styles'

export const StyledParagraph = styled.article`
  position: relative;

  ${({ theme }) => theme.mediaQuery.md(`
    display: grid;
    grid-template-columns: 7fr 5fr;
    grid-template-areas: "content sidebar";
    column-gap: ${theme.col(1)};
  `)}

  ${StyledRichtext}{
    a{
      text-decoration-color: ${({ theme }) => theme.colors.accent()};
      text-decoration-thickness: 4px;
    }
  }
`
