import React, { useContext, useCallback, useMemo } from 'react'

import LateralNavigation from '@/organisms/Header/Navigation/LateralNavigation'
import { HeaderContext } from 'contexts/Header'

import {
  StyledMenuButton,
  StyledMenuButtonElement
} from './styles'

const MenuButton = () => {
  const {
    state,
    dispatch
  } = useContext(HeaderContext)

  const isOpen = () => {
    return state && state.opened && (state.position === 'lateral' || state.position === 'submenuMobile')
  }
  const onClick = useCallback(() => {
    const type = isOpen() ? 'close' : 'lateral'

    dispatch({ type })
  }, [dispatch, isOpen])

  const buttonProps = useMemo(() => isOpen() ? { 'aria-controls': 'lateralMenu' } : {}, [isOpen, state])

  return (
    <>
      <StyledMenuButton
        aria-label='Menu laterale'
        aria-haspopup="true"
        {...buttonProps}
        isOpen={isOpen()}
        onClick={onClick}
      >
        <StyledMenuButtonElement />
        <StyledMenuButtonElement />
        <StyledMenuButtonElement />
      </StyledMenuButton>

      <LateralNavigation />
    </>

  )
}

export default MenuButton
