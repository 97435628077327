import styled from 'styled-components'

export const StyledNotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1{
    color:${({ theme }) => theme.colors.accent()};
    font-size: clamp(80px,18vw, 140px);
    line-height: clamp(80px,18vw, 140px);
  }

  h2{
    margin-bottom: ${({ theme }) => theme.rem(16)};
  }

  p{
    margin-bottom: ${({ theme }) => theme.rem(40)};
  }
`
