import styled, { css } from 'styled-components'
import styledMap from 'styled-map'

export const StyledIcon = styled.i`
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => styledMap`
    huge: ${theme.rem(40)};
    big: ${theme.rem(20)};
    default: ${theme.rem(16)};
  `};
  /* cursor: ${({ onClick }) => onClick ? 'pointer' : 'default'}; */

  svg {
    width: 1em;
    height: 1em;

    ${({ unlockedProportions }) => unlockedProportions && css`
        height: auto;
        width: auto;
    `}

    .branded-content{
      fill: ${({ theme }) => styledMap`
        default: ${theme.colors.accent()};
        general: ${theme.colors.general.light()};
      `}
    }
  }

  path {
    ${({
    useIconColor, color = 'currentColor'
  }) => !useIconColor && css`
      fill: ${color};
    `}
  }

  ${({
    round, theme
  }) => round && css`
    padding: ${theme.rem(8)};
    background-color: ${theme.colors.white};
    box-shadow: ${theme.commons.boxShadow};
    border-radius: 50%;
  `}
`
