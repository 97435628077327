import React, {
  useMemo, useState
} from 'react'
import dynamic from 'next/dynamic'

import { GoogleReCaptchaProvider, } from 'react-google-recaptcha-v3'
import { Formik } from 'formik'

import { fetchData } from 'api'

import {
  getErrors,
  createFetchBody
} from './helpers'

import RichText from '@/atoms/RichText'
import ButtonTimer from '@/molecules/Buttons/TimerButton'

import Fieldset from './Fieldset'
import Submit from './Submit'
import Clear from './Clear'

import {
  StyledForm,
  StyledFormContent,
  StyledFormFooter,
  StyledFormActions
} from './styles'

import { StyledFormModalContent } from '@/molecules/Modals/FormModal/styles'
import { Label } from '../Typography'

const FormModal = dynamic(() => import('@/molecules/Modals/FormModal'), { ssr: false })

const CustomForm = ({
  endpoint,
  successMessage,
  initialValues,
  fieldsets = [],
  validationSchema = {},
  validateOnMount = false,
  submit = null,
  onSubmit = null,
  onFormChange = null,
  className = null,
  method = 'POST',
  hideSuccessMessage,
  footer,
  inline = false,
  expandable = false,
  isExpanded = true,
  column = false,
  clear = false,
  otherActions = null,
  enableReinitialize = false,
  forceReset = false,
  avoidReset = false,
  onFieldsetClick = false,
  recaptcha = false,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [message, setMessage] = useState(null)

  const onFormSubmit = async (values, {
    resetForm,
    setErrors,
  }) => {
    if (endpoint && method === 'POST') {
      const urlEndpoint = new URL(endpoint)

      const body = createFetchBody(values)

      const response = await fetchData(urlEndpoint, {
        method: method,
        mode: 'cors',
        body
      })

      if (response.error) {
        const errors = await getErrors(response.error)
        await setErrors(errors)
        return
      }

      if (response.code) {
        let errorMessage = 'Qualcosa è andato storto, riprova  più tardi!'
        if (response.code === 500) errorMessage = response.message

        setMessage(errorMessage)
      }
    }
    if (!avoidReset) {
      const resetFormOpts = forceReset ? { values: {} } : null
      await resetForm(resetFormOpts)
    }

    if (!hideSuccessMessage) setIsOpen(true)

    if (onSubmit) await onSubmit(values)
  }

  const formProps = {
    method,
    action: endpoint,
    expandable,
  }

  const WrapperComponent = useMemo(() => ({ children }) => recaptcha ? <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY}>{children}</GoogleReCaptchaProvider> : <>{children}</>, [recaptcha])

  return (
    <WrapperComponent>
      <Formik
        enableReinitialize={enableReinitialize}
        validateOnMount={validateOnMount}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onFormSubmit}
      >
        {({
          isValid,
          isSubmitting,
          handleSubmit,
          errors,
          values
        }) => {
          if (onFormChange) onFormChange(values)

          if (method === 'POST') {
            Object.assign(formProps, {
              action: '#',
              onSubmit: handleSubmit
            })
          }
          return (
            <StyledForm
              className={className}
              inline={inline}
              {...formProps}
            >
              <StyledFormContent column={column} isExpanded={isExpanded}>
                {
                  fieldsets &&
                  !!fieldsets.length &&
                  fieldsets.map((fieldset, idx) => <Fieldset key={idx} {...fieldset} isExpanded={isExpanded} expandable={expandable} onFieldsetClick={onFieldsetClick} />)
                }
              </StyledFormContent>

              {
                footer &&
                footer.length &&
                <StyledFormFooter>
                  {
                    footer.map(item => (
                      <RichText key={item}>
                        {item}
                      </RichText>
                    ))
                  }
                </StyledFormFooter>
              }

              {
                isExpanded &&
                <StyledFormActions>
                  {otherActions && otherActions.map(action => action)}
                  {
                    clear &&
                    <Clear {...clear} />
                  }
                  {
                    submit &&
                    <Submit
                      inline={inline}
                      submit={submit}
                      isValid={isValid}
                      isSubmitting={isSubmitting}
                      errors={errors}
                    />
                  }
                </StyledFormActions>
              }

            </StyledForm>
          )
        }}
      </Formik>

      {
        !hideSuccessMessage &&
        <FormModal
          isOpen={isOpen}
          modalHandler={() => setIsOpen(false)}
          success={!message}
        >
          <ButtonTimer
            onClick={() => setIsOpen(false)}
            onAnimationComplete={() => {
              setMessage(null)
              if (isOpen) setIsOpen(false)
            }}
          />
          <StyledFormModalContent>
            {
              !!message
                ? <Label bold as="span" typo="heading6">{message}</Label>
                : <>
                  {
                    successMessage
                      ? successMessage
                      : <>
                        <Label bold as="span" typo="heading6">Messaggio inviato</Label>
                        <Label as="span" typo="paragraphRegular">Ti ricontatteremo al più presto ai recapiti che ci hai lasciato.</Label>
                      </>
                  }
                </>
            }

          </StyledFormModalContent>
        </FormModal>
      }

    </WrapperComponent>
  )
}

export default CustomForm
