import styled from 'styled-components'

import { StyledDefaultCardContentContainer } from '@/molecules/Card/Cards/DefaultCard/styles'
import { StyledMediaIcon } from '@/molecules/Card/CardMedia/styles'

export const StyledSlider = styled.div`
  .swiper{
    ${({ theme }) => theme.paddingY([32, 64])};
    ${({ theme }) => theme.mediaQuery.md(`
      ${theme.paddingY([64, 128])}
    `)};
  }
`

export const StyledDefaultSlider = styled(StyledSlider)`
  --columns: ${({ columns }) => columns};


  .swiper-slide {
    height: auto;
    width: calc(100vw / 12 * 9);
    ${({ theme }) => theme.mediaQuery.md(`
      --lateral-padding-min: calc(calc(1 / 12 * 100vw) * 2);
      --lateral-padding: calc((100vw - var(--max-width)));
      --lp: clamp(
          var(--lateral-padding-min),
          var(--lateral-padding),
          var(--lateral-padding)
        );
      --width: calc(calc(100vw - var(--lp)) / 3 );

      width: clamp(
        calc(100vw / 12),
        calc(var(--width) - 40px),
        calc(calc(1440px / 12 * 3))
      );
    `)}
    ${({ theme }) => theme.mediaQuery.xxl(`
      width: clamp(
        calc(calc(100vw / 12 * 3) - 40px),
        calc(calc(100vw / 12 * 3)),
        calc(calc(1440px / var(--columns)) - 40px)
      )
    `)}
  }

  .swiper{
    ${({ theme }) => theme.paddingY([32, 64])};
    ${({ theme }) => theme.paddingX(24)};

    ${({ theme }) => theme.mediaQuery.md(`
      --col-min: ${theme.col(1)};
      --col: calc((100vw - var(--max-width)) / 2);
      padding-left: clamp(var(--col-min), var(--col),var(--col));
      padding-right: clamp(var(--col-min), var(--col),var(--col));
    `)};

    [data-bg-lastsection="true"] &{
      ${({ theme }) => theme.paddingY([32, 128])};
    }

    ${({ theme }) => theme.mediaQuery.md(`
      ${theme.paddingY([64, 128])}
    `)};
  }
`


export const StyledSingleSlider = styled(StyledSlider)`
  ${({ theme }) => theme.mediaQuery.md(`
    ${theme.paddingY([64, 128])}
  `)};

  .swiper{
    [data-bg-bottom] &,
    [data-bg-lastsection="true"] &{
      ${({ theme }) => theme.mediaQuery.md(`
        ${theme.paddingY(0)};
    `)}
    }
  }

  .swiper-slide {
    width: calc(100vw / 12 * 10);

    ${({ theme }) => theme.mediaQuery.md(`
      width: 880px;
    `)}

    ${StyledDefaultCardContentContainer}{
      transition: opacity ease-in-out .6s;
      opacity: 0;
    }

    &.swiper-slide-active{
      ${StyledDefaultCardContentContainer}{
        opacity: 1;
      }
    }
  }
`

export const StyledSingleSliderPagination = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  max-width: 720px;
  width: 100%;
  margin: 0 auto ${({ theme }) => theme.rem(-40)};
  color:${({ theme })=> theme.colors.general.light()};

  ${({ theme }) => theme.mediaQuery.md(`
    display: flex;
  `)}
`

export const StyledSingleSliderPaginationIndicators = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 90px;
  color:${({ theme })=> theme.colors.general.light()};

  &:before{
    content:'';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 1px;
    width: 50%;
    background-color: ${({ theme }) => theme.colors.general.light()};
  }

  span {
    font-weight: 600;
    ${({ theme }) => theme.typo('labelBig')};
  }
`

export const StyledSingleSliderSlide = styled.div`
    padding: 4vw;

    ${({ theme }) => theme.mediaQuery.md(`
      padding: ${theme.rem(80)} ${theme.rem(80)} 0;

      .swiper-slide-active &{
        ${StyledMediaIcon}{
          transform: translate(-15%, 10%);
        }
      }
    `)}
  `
