import styled from 'styled-components'
import { Label } from '@/atoms/Typography'

export const StyledAccordionList = styled.div`
  width: 100%;
  > *+*{
    margin-block-start: ${({ theme }) => theme.rem(24)};
  }
`

export const StyledAccordion = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};
  border-radius: ${({ theme }) => theme.rem(8)};
  color: ${({ theme }) => theme.colors.general.light()};
`

export const StyledAccordionHeader = styled.header`
`

export const StyledAccordionHeaderTrigger = styled.button`
  cursor: pointer;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border: none;
  color: ${({ theme }) => theme.colors.general.light()};
  gap: ${({ theme }) => theme.rem(24)};
  padding: ${({ theme }) => theme.rem(16, 24)};

  svg{
    transition: transform ease-in-out .4s;

    ${({ isOpen }) => isOpen && `
      transform: rotate(180deg);
      transform-origin: center center;
    `}
  }

  ${Label}{
    text-align: left;
  }
`
export const StyledAccordionContentWrapper = styled.div`
  overflow: hidden;
  border-top: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};
`

export const StyledAccordionContent = styled.div`
  padding: ${({ theme }) => theme.rem(16)};

  ${({ theme }) => theme.mediaQuery.md(`
    ${theme.paddingY(24)};
    ${theme.paddingX([88, 24])};
  `)}
`
