import styled from 'styled-components'

export const StyledTable = styled.div`
  background-color: ${({ theme }) => theme.colors.background()};
  padding: ${({ theme }) => theme.rem(40, 24)};
  border-radius: ${({ theme }) => theme.rem(16)};
  color: ${({ theme }) => theme.colors.general.light()};

  ${({ theme }) => theme.mediaQuery.md(`
    border-radius: ${theme.rem(24)};
    padding: ${theme.rem(40)};
  `)}
`

export const StyledTableTitle = styled.div`
  margin-bottom: ${({ theme }) => theme.rem(24)};
`

export const StyledTableRowGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(16)};
`

export const StyledTableRowGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(16)};
  border-bottom: ${({
    theme, hide
  }) => !hide && `1px solid ${theme.colors.grayscale.gray200};`};

  ${({
    last,
    theme
  }) => !last && `
    padding-bottom: ${theme.rem(16)};
  `}
`

export const StyledTableRow = styled.div`
  display: inline-flex;
  justify-content: space-between;
`

