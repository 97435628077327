import React from 'react'

export default () => (
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1563 25.7187L26.0625 41.625Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M26.9464 42.5088C26.4582 42.997 25.6668 42.997 25.1786 42.5088L9.27242 26.6026C8.78426 26.1145 8.78426 25.323 9.27242 24.8349C9.76057 24.3467 10.552 24.3467 11.0402 24.8349L26.9464 40.7411C27.4345 41.2292 27.4345 42.0207 26.9464 42.5088Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M25.2813 42L25.2812 10C25.2812 9.30964 25.8409 8.75 26.5312 8.75C27.2216 8.75 27.7812 9.30964 27.7812 10L27.7813 42C27.7813 42.6904 27.2216 43.25 26.5313 43.25C25.8409 43.25 25.2813 42.6904 25.2813 42Z" fill="#194074"/>
    <path d="M32.4688 35.2499L42 25.7188Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M42.8839 24.8349C43.372 25.323 43.372 26.1145 42.8839 26.6026L33.3527 36.1338C32.8645 36.622 32.073 36.622 31.5849 36.1338C31.0967 35.6457 31.0967 34.8542 31.5849 34.3661L41.1161 24.8349C41.6043 24.3467 42.3957 24.3467 42.8839 24.8349Z" fill="#194074"/>
  </svg>

)
