import styled from 'styled-components'

import Button from '@/atoms/Button'

export const StyledButtonIngredients = styled(Button)`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100vw;
  transform: translateX(-50%);
  border-radius: 0;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  box-shadow: ${({ theme }) => theme.shadows.high};
  ${({ theme }) => theme.paddingY(8)};

  &:focus{
    background-color: ${({ theme }) => theme.colors.grayscale.white} !important;
    border-color: ${({ theme }) => theme.colors.grayscale.white} !important;
  }

  > span{
    margin-left: ${({ theme }) => theme.rem(20)};
  }

  i{
    border: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};
    border-radius: 50%;
    ${({ theme }) => theme.size(40)};

    svg{
      ${({ theme }) => theme.size(24)};
      transition: transform ease-in-out .4s;

      ${({ isOpen }) => isOpen && `
        transform: rotate(180deg);
        transform-origin: center center;
      `}
    }
  }

  ${({ theme }) => theme.mediaQuery.md(`
    display: none;
  `)}
`
