import styled from 'styled-components'
const StyledHeroWave = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  height: ${({ theme }) =>theme.rem(20)};
  width: 100%;
  z-index: 9;
  transform: translate(-50%,0%);
  border-radius:  ${({ theme }) =>`${theme.rem(40)} ${theme.rem(40)} 0 0`};

  ${({ theme }) => theme.mediaQuery.md(`
      height: ${theme.rem(40)};
  `)}
`

export default StyledHeroWave
