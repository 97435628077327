export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.8913 11.0443L12.1151 15.3038M5.68169 16.143C3.3088 15.1938 2.56124 14.6472 2.55994 13.4787C2.55946 13.0483 2.87491 12.6941 3.27861 12.5448L7.91923 10.8282M16.7373 7.68756L15.4718 8.03093" stroke="#194074" strokeWidth="0.9" strokeLinejoin="round"/>
    <path d="M19.5968 10.0512C20.5493 10.5378 21.4607 10.8415 22.1549 10.9483C22.5045 11.0021 22.7733 11.0019 22.9552 10.9666C23.1033 10.9379 23.146 10.8972 23.1541 10.8877C23.157 10.8756 23.1649 10.8171 23.1014 10.6804C23.0234 10.5122 22.8661 10.2943 22.6176 10.0426C22.1241 9.54273 21.3439 8.98232 20.3914 8.49574C19.4389 8.00916 18.5276 7.70545 17.8334 7.59862C17.4838 7.54481 17.215 7.54504 17.033 7.58035C16.885 7.60907 16.8423 7.64974 16.8342 7.65922C16.8312 7.67135 16.8233 7.72979 16.8868 7.86655C16.9649 8.03469 17.1222 8.25259 17.3707 8.50433C17.8641 9.00421 18.6443 9.56462 19.5968 10.0512Z" stroke="#194074" strokeWidth="0.9"/>
    <path d="M17.8775 6.68881L17.9507 6.52179C17.9507 6.57135 17.9254 6.62763 17.8775 6.68881L12.8509 18.1506C11.8721 20.3826 10.129 22.3643 7.73614 22.8272C6.92017 22.985 6.19683 23.0047 5.63563 22.8167C5.02258 22.6114 4.96747 21.8555 5.23842 21.2685L11.3702 7.98392C13.7435 8.76106 17.323 7.39671 17.8775 6.68881Z" fill="#CFD4DB" className="branded-content"/>
    <path d="M17.8131 6.41153C18.0382 7.10685 16.7553 8.14496 14.9477 8.7302C13.14 9.31545 11.4921 9.22622 11.267 8.5309C11.0419 7.83558 12.3248 6.79747 14.1324 6.21222C15.9401 5.62698 17.588 5.71621 17.8131 6.41153Z" fill="#CFD4DB" className="branded-content"/>
    <path d="M17.5769 2.93237L12.4771 14.5612C11.4983 16.7932 9.75519 18.7749 7.36236 19.2377C6.54958 19.3949 5.82871 19.4151 5.26843 19.2295C4.65122 19.025 4.59714 18.2642 4.873 17.6754L10.8632 4.89058" stroke="#194074" strokeWidth="0.9" strokeLinejoin="round"/>
    <path d="M14.4927 4.69422C15.5102 4.36477 16.3694 3.93511 16.9358 3.51976C17.2211 3.3106 17.411 3.12039 17.5147 2.96675C17.599 2.84177 17.6005 2.78281 17.5995 2.77037C17.593 2.75972 17.5573 2.7128 17.4157 2.66099C17.2416 2.59728 16.9763 2.55445 16.6226 2.55216C15.9202 2.54761 14.9722 2.70302 13.9546 3.03247C12.9371 3.36192 12.0779 3.79158 11.5115 4.20693C11.2262 4.41609 11.0363 4.6063 10.9326 4.75995C10.8483 4.88492 10.8468 4.94388 10.8478 4.95632C10.8543 4.96697 10.89 5.01389 11.0316 5.0657C11.2057 5.12941 11.471 5.17224 11.8247 5.17453C12.5271 5.17908 13.4751 5.02367 14.4927 4.69422Z" stroke="#194074" strokeWidth="0.9"/>
  </svg>
)
