import React from 'react'

import { Label } from '@/atoms/Typography'

import {
  StyledSubParagraph,
  StyledSubParagraphContent
} from './styles'

const SubParagraph = ({
  title,
  typo = 'heading3',
  as = 'div',
  children
}) => (
  <StyledSubParagraph>
    {
      title &&
      <Label typo={typo} as={as} bold>{title}</Label>
    }
    {
      children &&
      <StyledSubParagraphContent>
        {children}
      </StyledSubParagraphContent>
    }
  </StyledSubParagraph>
)

export default SubParagraph
