import styled from 'styled-components'

import { Label } from '@/atoms/Typography'
import { StyledButton } from '@/atoms/Button/styles'
import { StyledCtaLabel } from '@/atoms/Cta/styles'
import { StyledParagraph } from '../styles'

import {
  StyledSubParagraph,
  StyledSubParagraphContent
} from '@/molecules/SubParagraph/styles'

export const StyledArticleParagraph = styled(StyledParagraph)``

export const StyledArticleParagraphContent = styled.div`
  display: grid;
  grid-area: content;

  > * + *{
    margin-block-start: ${({ theme }) => theme.rem(40)};

    ${({ theme }) => theme.mediaQuery.md(`
      margin-block-start: ${theme.rem(80)};
    `)}
  }

  ${StyledSubParagraph}{
    > * + *{
      margin-block-start: ${({ theme }) => theme.rem(24)};
    }
  }
  ${StyledSubParagraphContent}{
    > * + *{
      margin-block-start: ${({ theme }) => theme.rem(24)};
      ${({ theme }) => theme.mediaQuery.md(`
        margin-block-start: ${theme.rem(40)};
      `)}
    }
  }
`

export const StyledArticleParagraphAside = styled.aside`
  grid-area: sidebar;
  margin-top: ${({ theme }) => theme.rem(40)};
  ${({ theme }) => theme.mediaQuery.md(`
    margin-top:0;
  `)}
`

export const StyledArticleParagraphRelated = styled.div`
  display: grid;

  ${Label}{
    color: ${({ theme }) => theme.colors.general.light()};
  }

  ${StyledButton}{
    ${({ theme }) => theme.mediaQuery.md(`
      margin: 0 auto;
      place-self: center;
    `)}
  }
`
export const StyledArticleParagraphContentCta = styled.div`
  display: grid;

  ${({ theme }) => theme.mediaQuery.md(`
    display: none;
  `)}
`
export const StyledArticleParagraphRelatedList = styled.ul`
  ${({ theme }) => theme.marginY([24, 40])};

  > *+* {
    margin-block-start: ${({ theme }) => theme.rem(24)};
  }
`
export const StyledArticleParagraphRelatedListItem = styled.li`
  ${StyledCtaLabel}{
    &::before{
      width: 30px;
    }

    &:hover{
      &::before{
        width: 100%;
      }
    }
  }
`
