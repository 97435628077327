export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7185 18.5235H18.8991C20.7204 18.5235 22.1969 17.0471 22.1969 15.2257C22.1969 13.4044 20.7204 11.928 18.8991 11.928H10.978" stroke="#194074" strokeWidth="0.9"/>
    <path d="M7.07519 15.2051C7.06296 17.0397 8.54024 18.5368 10.3748 18.549C12.2093 18.5612 13.7064 17.084 13.7186 15.2494C13.7309 13.4149 12.2536 11.9178 10.4191 11.9056C8.58452 11.8933 7.08742 13.3706 7.07519 15.2051Z" stroke="#194074" strokeWidth="0.9"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.00203 14.2307C5.94703 14.2982 5.8974 14.3717 5.85412 14.4509C5.54684 15.0129 5.65286 15.6897 6.06976 16.1317C5.64129 16.6578 4.88639 16.8227 4.26745 16.4883C3.56692 16.1099 3.30583 15.2352 3.68428 14.5346C4.06274 13.8341 4.93743 13.573 5.63796 13.9515C5.7772 14.0267 5.89908 14.1215 6.00203 14.2307Z" fill="#CFD4DB" className="branded-content"/>
    <path d="M5.5702 18.1083C3.98414 18.1057 2.7005 16.8178 2.7031 15.2318C2.7057 13.6457 3.99356 12.3621 5.57961 12.3647C6.33285 12.3659 7.01789 12.657 7.52933 13.1323C7.70012 12.8838 7.8999 12.6568 8.12376 12.4562C7.45401 11.8418 6.56154 11.4663 5.58109 11.4647C3.49798 11.4613 1.80652 13.1472 1.80311 15.2303C1.79969 17.3134 3.48561 19.0048 5.56872 19.0083C6.53786 19.0099 7.42223 18.6458 8.09128 18.0462C7.86976 17.843 7.67264 17.6137 7.50475 17.3632C6.99372 17.8272 6.31482 18.1095 5.5702 18.1083Z" fill="#194074"/>
    <path d="M9.27595 12.1106L18.4226 12.1967C20.2351 12.2138 21.7132 10.7573 21.7228 8.94476C21.7324 7.12272 20.255 5.63748 18.433 5.63748H8.93964" stroke="#194074" strokeWidth="0.9"/>
    <path d="M4.65533 8.75056C4.6431 10.5851 6.12038 12.0822 7.95491 12.0944C9.78945 12.1067 11.2866 10.6294 11.2988 8.79484C11.311 6.9603 9.83374 5.4632 7.9992 5.45097C6.16466 5.43874 4.66756 6.91602 4.65533 8.75056Z" stroke="#194074" strokeWidth="0.9"/>
    <path d="M6.18832 8.57749C6.18206 9.51646 6.93817 10.2827 7.87715 10.289C8.81612 10.2952 9.58238 9.53913 9.58864 8.60016C9.5949 7.66118 8.83879 6.89492 7.89981 6.88866C6.96084 6.8824 6.19458 7.63852 6.18832 8.57749Z" fill="#CFD4DB" className="branded-content"/>
    <path d="M8.64822 15.123C8.64196 16.0619 9.39807 16.8282 10.337 16.8345C11.276 16.8407 12.0423 16.0846 12.0485 15.1456C12.0548 14.2067 11.2987 13.4404 10.3597 13.4341C9.42074 13.4279 8.65448 14.184 8.64822 15.123Z" fill="#CFD4DB" className="branded-content"/>
  </svg>
)
