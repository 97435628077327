export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.21655 6.31693H8.04989V7.41693H6.21655V6.31693Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.88293 6.31693H17.583V7.41693H9.88293V6.31693Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.21655 11.4498H8.04989V12.5498H6.21655V11.4498Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.88293 11.4498H17.583V12.5498H9.88293V11.4498Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.21655 16.5828H8.04989V17.6828H6.21655V16.5828Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.88293 16.5828H13.1829V17.6828H9.88293V16.5828Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.90393 8.37943H8.73727V9.47943H6.90393V8.37943Z" fill="#CFD4DB" className="branded-content"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.5706 8.37943H18.2706V9.47943H10.5706V8.37943Z" fill="#CFD4DB" className="branded-content"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.90393 13.5124H8.73727V14.6124H6.90393V13.5124Z" fill="#CFD4DB" className="branded-content"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.5706 13.5124H18.2706V14.6124H10.5706V13.5124Z" fill="#CFD4DB" className="branded-content"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.90393 18.6451H8.73727V19.7451H6.90393V18.6451Z" fill="#CFD4DB" className="branded-content"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.5706 18.6451H13.8706V19.7451H10.5706V18.6451Z" fill="#CFD4DB" className="branded-content"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.0165 16.5828H21.4332V17.6828H16.1165V22.9994H15.0165V16.5828Z" fill="#194074"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1.81665 0.44989H21.9834V17.361L15.7945 23.5499H1.81665V0.44989ZM2.91665 1.54989V22.4499H15.3389L20.8834 16.9054V1.54989H2.91665Z" fill="#194074"/>
  </svg>
)
