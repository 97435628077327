import React from 'react'

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" fill="none">
    <rect width="24.515" height="24.515" x=".515" y=".485" fill="#F5F9FE" rx="12.257"/>
    <g fill="#00305C" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
      <path d="M8.872 6.753a1.532 1.532 0 1 0 0 3.065 1.532 1.532 0 0 0 0-3.065Zm-.696 1.532a.696.696 0 1 1 1.392 0 .696.696 0 0 1-1.392 0ZM7.34 10.514c0-.23.187-.418.418-.418h2.228c.231 0 .418.187.418.418v7.243c0 .23-.187.418-.418.418H7.758a.418.418 0 0 1-.418-.418v-7.243Zm.836.418v6.407h1.392v-6.407H8.176ZM11.24 10.514c0-.23.187-.418.418-.418h2.229c.23 0 .417.187.417.418v.242l.243-.104c.417-.179.863-.29 1.314-.332 1.546-.14 2.9 1.074 2.9 2.634v4.803c0 .23-.186.418-.417.418h-2.229a.418.418 0 0 1-.418-.418v-3.9a.697.697 0 0 0-1.393 0v3.9c0 .23-.187.418-.418.418h-2.228a.418.418 0 0 1-.418-.418v-7.243Zm.836.418v6.407h1.393v-3.482a1.532 1.532 0 1 1 3.064 0v3.482h1.393v-4.385a1.822 1.822 0 0 0-1.99-1.802 3.505 3.505 0 0 0-1.06.268l-.825.354a.418.418 0 0 1-.582-.384v-.458h-1.393Z"/>
    </g>
    <defs><clipPath id="a"><path fill="#fff" d="M6.086 6.057h13.372v13.372H6.086z"/></clipPath></defs>
  </svg>
)
