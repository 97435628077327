import {
  useEffect,
  useLayoutEffect
} from 'react'

export const useIsomorphicLayoutEffect = typeof window !== 'undefined' && typeof window.document !== 'undefined' && typeof window.document.createElement !== 'undefined' ? useLayoutEffect : useEffect

export const allowedBrands = ['chef', 'santal', 'zymil']

export const getFirstFocusableElement = (element) => {
  const focusable = element.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
  if (focusable && focusable.length > 0) {
    focusable[0].focus({ preventScroll: true })
  }
}
