import React, { createContext } from 'react'

export const DropDownCtx = createContext({
  setOpen: null,
  isOpen: null
})

const DropDownProvider = ({
  setOpen,
  isOpen,
  children,
}) => (
  <DropDownCtx.Provider
    value={{
      setOpen,
      isOpen,
    }}
  >
    {children}
  </DropDownCtx.Provider>
)

export default DropDownProvider
