import React from 'react'
import Icon from '@/atoms/Icon'

import { StyledRoundedIcon } from './styles'

const RoundedIcon = ({
  size,
  ...props
}) => (
  <StyledRoundedIcon size={size}>
    <Icon {...props} />
  </StyledRoundedIcon>
)

export default RoundedIcon
