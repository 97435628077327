import React from 'react'
import Icon from '@/atoms/Icon'

import { StyledInfo } from './styles'

const Info = ({
  typo = 'labelBig',
  className,
  label,
  icon,
  iconSize = 16
}) => (
  <StyledInfo className={className} typo={typo} iconSize={iconSize}>
    {
      !!icon &&
      <Icon name={icon} />
    }
    <span>{label}</span>
  </StyledInfo>
)
export default Info
