import React, { useMemo } from 'react'

import useIsMobile from 'hooks/useIsMobile'

import BoxedImage from '@/molecules/BoxedImage'
import VideoPlayer from '@/atoms/Video'

const Media = ({
  image,
  mobileImage = false,
  video,
  active = false,
  videoOpts,
  notClickable = false,
}) => {
  const isMobile = useIsMobile()

  const imageData = useMemo(() => {
    if (isMobile && mobileImage) return mobileImage

    return image
  }, [isMobile, image, mobileImage])

  return (
    video
      ? <VideoPlayer poster={imageData} videoId={video} videoOpts={videoOpts} active={active} notClickable={notClickable}/>
      : <BoxedImage rounded image={imageData} full />
  )
}

export default Media
