import styled from 'styled-components'

import { StyledButton } from '@/atoms/Button/styles'

export const StyledLineDecorationButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &::before,
  &::after{
    content: '';
    width: 100%;
    height: 1px;
    z-index: 0;
    background-color: ${({ theme }) => theme.colors.grayscale.gray200};
  }

  ${StyledButton}{
    flex-shrink: 0;
    position: relative;
    z-index: 1;
  }

  i{
    font-size: ${({ theme }) => theme.rem(16)} !important;
    transition: transform ease-in-out .2s;
    ${({ isExpanded }) => isExpanded && `
      transform: rotateX(180deg);
    `}
  }
`
