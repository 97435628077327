import styled from 'styled-components'

export const StyledSkipToContent = styled.div`
  height: 0;
  opacity: 0;
  color: #fff;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: height 0.3ms, opacity 0.3ms;
  ${({ theme }) => theme.paddingX(16)};
  background-color: ${({ theme }) => theme.colors.general.light()};

  :has(button:focus) {
    height: 24px;
    opacity: 1;
  }
`

export const StyledSkipToContentTrigger = styled.button`
  background-color: transparent;
  border: none;
  text-decoration: underline;
  padding: 0;
  ${({ theme }) => theme.typo('buttonTiny')};
  color: ${({ theme }) => theme.colors.grayscale.white};

  &:focus{
    border:none;
  }
`
