import styled from 'styled-components'

import { StyledButton } from '@/atoms/Button/styles'

export const StyledContact = styled.div`
  width: 100%;
`

export const StyledContactWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  padding: ${({ theme }) => theme.rem(24)};
  border-radius: ${({ theme }) => theme.rem(24)};
  border: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};
  gap: ${({ theme }) => theme.rem(16)};

  ${({ theme }) => theme.mediaQuery.md(`
    grid-template-rows: 54px auto 70px;
  `)}

  ${({ theme }) => theme.mediaQuery.lg(`
    padding: ${theme.rem(40)}
  `)}
  `

export const StyledContactHeader = styled.div`
  i{
    font-size: ${({ theme }) => theme.rem(32)};
  }
`

export const StyledContactContent = styled.div`
  align-self: stretch;
  color: ${({ theme }) => theme.colors.general.light()};

  > *+*{
    margin-block-start: ${({ theme }) => theme.rem(8)};
  }
`

export const StyledContactFooter = styled.div`
  align-self: end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: ${({ theme }) => theme.rem(16)};

  ${StyledButton}{
    margin-left: auto;
    width: auto;
  }

  ${({ theme }) => theme.mediaQuery.md(`
    flex-direction: column;
    align-items: flex-start;

    ${StyledButton}{
      width: 100%;
    }
  `)};

  ${({ theme }) => theme.mediaQuery.xl(`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    ${StyledButton}{
      width: auto;
    }
  `)}
`

export const StyledContactFooterDetails = styled.div`
  color: ${({ theme }) => theme.colors.status.success.default};
`
