import React from 'react'
import Head from 'next/head'
import parse from 'html-react-parser'

const faviconSchemaLinks = [
  {
    rel: 'apple-touch-icon-precomposed', sizes: '57x57'
  },
  {
    rel: 'apple-touch-icon-precomposed', sizes: '60x60'
  },
  {
    rel: 'apple-touch-icon-precomposed', sizes: '72x72'
  },
  {
    rel: 'apple-touch-icon-precomposed', sizes: '76x76'
  },
  {
    rel: 'apple-touch-icon-precomposed', sizes: '114x114'
  },
  {
    rel: 'apple-touch-icon-precomposed', sizes: '120x120'
  },
  {
    rel: 'apple-touch-icon-precomposed', sizes: '144x144'
  },
  {
    rel: 'apple-touch-icon-precomposed', sizes: '152x152'
  },
  {
    rel: 'icon', type: 'image/png', sizes: '16x16'
  },
  {
    rel: 'icon', type: 'image/png', sizes: '32x32'
  },
  {
    rel: 'icon', type: 'image/png', sizes: '96x96'
  },
  {
    rel: 'icon', type: 'image/png', sizes: '128x128'
  },
  {
    rel: 'icon', type: 'image/png', sizes: '196x196'
  },
]


const faviconSchemaMeta = [
  {
    name: 'msapplication-TileColor', sizes: '#FFFFFF'
  },
  {
    name: 'msapplication-square70x70logo', sizes: '70x70'
  },
  {
    name: 'msapplication-TileImage', sizes: '144x144'
  },
  {
    name: 'msapplication-square150x150logo', sizes: '150x150'
  },
  {
    name: 'msapplication-wide310x150logo', sizes: '310x150'
  },
  {
    name: 'msapplication-square310x310logo', sizes: '310x310'
  },
]
const Metadata = ({
  yoastMetadata = '',
  favicon = null,
  title = null
}) => {
  const fullHead = parse(yoastMetadata)

  return (
    <Head>
      {
        title &&
        <title>{title}</title>
      }

      {fullHead}
      {
        favicon &&
        faviconSchemaLinks.map(props => (
          favicon.sizes[`icon-${props.sizes}`] &&
          <link
            key={props.sizes}
            {...props}
            href={favicon.sizes[`icon-${props.sizes}`]}
          />
        ))
      }

      {
        favicon &&
        faviconSchemaMeta.map(({
          name, sizes
        }) => (
          favicon.sizes[`icon-${sizes}`] &&
          <meta
            key={sizes}
            name={name}
            content={ favicon.sizes[`icon-${sizes}`]}
          />
        ))
      }

      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta id="seodata-metatag" httpEquiv="content-language" content="it" />
      <meta name="theme-color" content="#ffffff" />
    </Head>
  )
}

export default Metadata
