import { getCategory } from 'api/category'

const ArticleCard = async (item, mainBrandId) => {
  const data = []
  const { acf } = item
  if (!acf) return item

  const { brands_magazine: brandMagazine } = acf

  for (const category of item['magazine-categories']) {
    if (!category) continue

    const catData = await getCategory({
      type: item.type,
      id: category
    })

    if (!catData) continue

    const {
      id,
      name,
      uri
    } = catData

    data.push({
      id,
      keyword: name,
      label: name,
      link: { href: brandMagazine.ID === mainBrandId ? '' : `/${brandMagazine.post_name}/magazine/${uri}` }
    })
  }


  return {
    ...item,
    categories: data,
  }
}

export default ArticleCard
