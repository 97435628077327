import React from 'react'

import Button from '@/atoms/Button'

const EmailButton = ({
  subject,
  textToMail
}) => {
  const onMailClick = () => {
    window.location.href = `mailto:?subject=${subject}&body=${encodeURIComponent(textToMail)}`
  }

  return (
    <Button
      general
      typo="buttonRegular"
      iconStart="mail"
      label="Mail"
      handleClick={onMailClick}
    />
  )
}

export default EmailButton
