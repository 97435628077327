import React from 'react'
import {
  EffectCreative,
  Pagination
} from 'swiper'

import {
  Swiper,
  SwiperSlide
} from 'swiper/react'

import { Label } from '@/atoms/Typography'
import Card from '@/molecules/Card'

import {
  StyledSingleSlider,
  StyledSingleSliderSlide,
  StyledSingleSliderPagination,
  StyledSingleSliderPaginationIndicators,
} from './styles'

const swiperProps = {
  centeredSlides: true,
  slidesPerView: 'auto',
  effect: 'creative',
}

const SingleSlider = ({
  list,
  type,
  label,
}) => (
  list &&
  !!list.length &&
  <StyledSingleSlider>
    <StyledSingleSliderPagination>
      <Label typo="labelBig">{label}</Label>
      <StyledSingleSliderPaginationIndicators className='single-slider-custom-pagination' />
    </StyledSingleSliderPagination>
    <Swiper
      {...swiperProps}
      modules={[EffectCreative, Pagination]}
      pagination={{
        type: 'custom',
        el: '.single-slider-custom-pagination',
        renderCustom: (_, current, total) => {
          return `
            <span>${current}</span>
            <span>${total}</span>
          `
        }
      }}
      creativeEffect={{
        progressMultiplier: 1,
        limitProgress: 2,
        prev: {
          translate: ['-100%', 0, 0],
          scale: 0.75,
          origin: 'center right',
        },
        next: {
          translate: ['100%', 0, 0],
          scale: 0.75,
          origin: 'center left',
        },
      }}
      breakpoints={{
        768: {
          longSwipes: false,
          followFinger: true,

          creativeEffect: {
            progressMultiplier: 1,
            limitProgress: 2,
            prev: {
              translate: ['-100%', 0, 0],
              scale: 0.75,
              // origin: 'center right',
            },
            next: {
              translate: ['100%', 0, 0],
              scale: 0.75,
              // origin: 'center left',
            },
          }
        }
      }}
    >
      {
        list.map((item, i) => (
          <SwiperSlide key={i}>
            <StyledSingleSliderSlide>
              <Card
                type={type}
                item={{
                  ...item,
                  hoverIcon: {
                    big: true,
                    visible: false }
                }}
              />
            </StyledSingleSliderSlide>
          </SwiperSlide>
        ))
      }
    </Swiper>
  </StyledSingleSlider>
)

export default SingleSlider
