import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
/**
 * Accessibility specifications
 *
 * @url Accessibility https://www.w3.org/WAI/ARIA/apg/patterns/button/
 * @url Docs https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button
 *
 * TODO:
 * - button type props management => 'submit' value in props 'type' in case of <button type="submit">
 * - 'aria-pressed' props management
 * - probably 'aria-hidden="true"' and 'focusable="false"' on <Icon> components
 */


import Icon from '@/atoms/Icon'
import { ButtonLabel } from '@/atoms/Typography'

import LinkWrapper from './LinkWrapper'
import { StyledButton } from './styles'

const Button = ({
  children,
  href,
  handleClick = null,
  hideLabel = false,
  useIconColor,
  unlockedProportions = false,
  type = 'button',
  innerRef,
  ...props
}) => {
  const {
    typo,
    label,
    iconStart,
    iconEnd,
    uppercase,
    huge,
    small,
    as,
    keyword = false
  } = props

  // Get rel props for link
  const getRel = (linkProps) => {
    if (linkProps.target === '_blank') {
      return `${linkProps.rel ? linkProps.rel + ' ' : ''}noopener noreferrer`
    }
    return linkProps.rel
  }

  // Button label props
  const buttonLabelProps = useMemo(() => {
    return Object.assign(
      {},
      typo ? { typo } : null,
      iconStart ? { iconStart } : null,
      iconEnd ? { iconEnd } : null,
      uppercase ? { uppercase } : null,
      huge ? { huge } : null,
      small ? { small } : null,
      { withIcon: iconStart || iconEnd }
    )
  }, [])

  const buttonProps = useMemo(() => {
    const btnPrps = {
      ref: innerRef, ...props
    }

    if (btnPrps.disabled) {
      btnPrps['aria-disabled'] = true
      btnPrps.tabIndex = '-1'
    }

    btnPrps['aria-label'] = label ? label : iconStart || iconEnd

    if (!href && !as) {
      btnPrps.role = btnPrps.role || 'button'
      btnPrps.type =  type
      btnPrps.label = false
    } else {
      btnPrps.label = false
      btnPrps.keyword = false
      if (!!keyword) btnPrps.title = keyword
      btnPrps.rel = getRel(btnPrps)
    }

    return btnPrps
  }, [props])


  return (
    <LinkWrapper href={href} target={buttonProps.target}>
      <StyledButton
        href={href}
        as={href ? Link : 'button'}
        onClick={handleClick}
        {...buttonProps}
      >
        {iconStart && <Icon name={iconStart} useIconColor={useIconColor} unlockedProportions={unlockedProportions} />}
        {(!hideLabel && label || children) && (
          <ButtonLabel bold center {...buttonLabelProps}>
            {!hideLabel && label && label}
            {children && children}
          </ButtonLabel>
        )}
        {iconEnd && <Icon name={iconEnd} useIconColor={useIconColor} unlockedProportions={unlockedProportions} />}
      </StyledButton>
    </LinkWrapper>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  label: PropTypes.string,
  iconStart: PropTypes.string,
  iconEnd: PropTypes.string,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
}

export default Button
