import styled from 'styled-components'

import {
  StyledGroup,
  StyledGroupContainer,
  StyledFieldLabel,
} from '../Fields/styles'

import {
  StyledFieldset,
  StyledFieldsetTitle,
  StyledFieldsetContent,
} from './Fieldset/styles'

import { StyledHeaderPart } from '@/organisms/Header/styles'

export const StyledFormClear = styled.div`
  > * {
    width: 100%;

    ${({ theme }) =>
    theme.mediaQuery.lg(`
      width: auto;
    `)}
  }
`

export const StyledFormActions = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: ${({ theme }) => theme.rem(16)};
  margin-top: ${({ theme }) => theme.rem(40)};

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    justify-content: flex-start;
    gap: ${theme.rem(24)};
  `)}
`

export const StyledFormSubmit = styled.div`
  button {
    width: 100%;

    ${({ theme, inline }) =>
    inline &&
      `
      aspect-ratio: 1;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${theme.colors.accent()};
      color: ${theme.colors.general.light()};
      ${theme.size(46)};

      ${StyledHeaderPart} &{
        ${theme.size(40)};
      }

      ${theme.mediaQuery.md(theme.size(56))};

      i{
        font-size: ${theme.rem(24)};
      }

      span{
        line-height: 100%;
      }

      &:hover i{
        color: ${theme.colors.grayscale.white};
      }
    `}
  }
`

export const StyledFormContent = styled.div`
  width: 100%;

  ${({ theme, column, isExpanded }) =>
    column &&
    `

    > * + * {
      margin-block-start: ${theme.rem(24)};

      ${theme.mediaQuery.lg(`
        margin-block-start: 0;
      `)};
    }

    ${theme.mediaQuery.lg(`
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: ${theme.rem(16)};
    `)};

    ${StyledFieldsetTitle}{
      ${
  isExpanded
    ? `
        border-bottom: 1px solid ${theme.colors.grayscale.gray200};
      `
    : ''
};

      margin-bottom: 0;
      padding: ${theme.rem(16)}  ${theme.rem(24)};
    }

    ${StyledFieldsetContent},
    ${StyledGroupContainer}{
      flex-direction: column;
    }

    input[type="checkbox"]{
      + ${StyledFieldLabel}{
        padding-top: 0 !important;
        color: ${theme.colors.general.light()};

        span{
          margin-top: ${theme.rem(4)};
          line-height: ${theme.rem(19.7)};
        }
      }

      &:hover,
      &:checked{
        + ${StyledFieldLabel}{
          &::before{
            border-color: ${theme.colors.general.light()};
          }

          span{
            font-weight: bold;
          }
        }
      }
    }

    ${StyledFieldsetContent}{
      width: 96%;
      max-height: 240px;
      overflow: auto;
      margin: ${theme.rem(24)} 0;
      padding: 0 ${theme.rem(24)};
      &::-webkit-scrollbar { width: 1px;}
      &::-webkit-scrollbar-track { background: ${
  theme.colors.grayscale.white
}; }
      &::-webkit-scrollbar-thumb { background-color: ${theme.colors.accent()}; }
    }

    ${StyledGroup}{
      overflow: hidden;
    }

    ${StyledFieldset}{
      margin-block-end: 0;
      overflow: hidden;
      border: 1px solid ${theme.colors.grayscale.gray200};
      border-radius: ${theme.rem(8)};
    }

    + ${StyledFormActions}{
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 21;
      box-shadow: ${theme.shadows.low(0)};
      background-color: ${theme.colors.grayscale.white};
      ${theme.paddingY(8)};
      ${theme.paddingX(24)};

      ${theme.mediaQuery.lg(`
        box-shadow: none;
        justify-content: center;
        background-color: transparent;
        position: static;
        padding: 0;
      `)}

      >div  {
        width: 100%;
        ${theme.mediaQuery.lg(`
          width: auto;
        `)}
    }
  `}
`

export const StyledFormFooter = styled.div`
  margin-bottom: ${({ theme }) => theme.rem(24)};
  color: ${({ theme }) => theme.colors.grayscale.gray300};
`

export const StyledForm = styled.form`
  width: 100%;

  ${({ theme, inline }) =>
    inline &&
    `
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 100px;
    transition: border-color ease-in-out .3s, color ease-in-out .3s;
    background-color: ${theme.colors.background()};
    border: 1px solid ${theme.colors.grayscale.gray200};
    gap: ${theme.rem(16)};
    ${theme.paddingY(4)};
    ${theme.paddingX([24, 4])};

    .error{
      display: none;
    }

    &:has( div.has-error) {
      border-color: red;

      input::placeholder {
        color: red !important;
      }
    }

    ${StyledFormContent}{
      margin-bottom: 0;
    }
    ${StyledFormActions}{
      width: auto;
      margin-top: 0;
    }
    ${StyledFieldset}{
      margin-bottom: 0;

      input:not([type="checkbox"]):not([type="radio"]), select, textarea{
        background-color: transparent;
        border: none;
        padding-top: 0;
        height: 100%;
        text-indent: 0;

        &:not(:placeholder-shown)  {
          padding-top: 0 !important;
        }
    }

    .error{
      position: absolute;
      top: 120%;
      left: 0;
    }
  `};
`
