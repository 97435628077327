import React from 'react'

import {
  StyledCategoryCard,
  StyledCategoryCardDescription
} from './styles'

const CategoryCard = ({
  item,
  imageRatio,
  ...rest
}) => {
  const {
    title,
    image,
    cta,
    description,
    icon,
    hoverIcon,
  } = item

  return (
    <StyledCategoryCard
      title={title}
      icon={icon}
      link={cta?.link?.href}
      target={cta?.link?.target}
      image={image}
      ratio={imageRatio}
      hoverIcon={{ big: true, ...hoverIcon }}
      {...rest}>
      <StyledCategoryCardDescription as="div">
        {description}
      </StyledCategoryCardDescription>
    </StyledCategoryCard>
  )
}

export default CategoryCard
