import styled from 'styled-components'

export const StyledCookiebot = styled.div`
  ${({ theme }) => theme.mediaQuery.md(`
    ${theme.paddingX(`${theme.col(1)}`)}
  `)}

  h1{
    ${({ theme }) => theme.typo('heading1')}
  }

  h2{
    ${({ theme }) => theme.typo('heading2')}
  }

  h3{
    ${({ theme }) => theme.typo('heading3')}
  }

  h4{
    ${({ theme }) => theme.typo('heading4')}
  }

  ul, ol{
    list-style: initial;
    padding-left: ${({ theme }) => theme.rem(24)};
  }

  a{
    text-decoration-color: ${({ theme }) => theme.colors.accent()};
    text-decoration-thickness: 4px;
  }

  p{
    ${({ theme }) => theme.typo('paragraphLarge')};
  }

  .CookieDeclarationTableCell{
    ${({ theme }) => theme.typo('paragraphRegular')};
  }

  .CookieDeclarationTableHeader{
    ${({ theme }) => theme.typo('paragraphSmall')};
  }

  .CookieDeclarationTableHeader,
  .CookieDeclarationTableCell{
    ${({ theme }) => theme.paddingY(16)};
    border-color: ${({ theme }) => theme.colors.grayscale.gray200};
  }

  .CookieDeclarationType{
    border: none;
    border-radius: ${({ theme }) => theme.rem(24)};
    margin: ${({ theme }) => theme.rem(40, 0)};
    padding: ${({ theme }) => theme.rem(40)};
    background-color: ${({ theme }) => theme.colors.background()}
  }
`
