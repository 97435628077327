import React, { useContext } from 'react'

import { GeneralContext } from 'contexts/General'

import { Label } from '@/atoms/Typography'
import { InlineList } from '@/atoms/Layout'
import Cta from '@/atoms/Cta'
import Button from '@/atoms/Button'
import Link from '@/atoms/Link'
import Graphics from '@/atoms/Graphics'

import BoxedImage from '@/molecules/BoxedImage'

import {
  StyledBannerCard,
  StyledBannerCardMedia,
  StyledBannerCardContent,
  StyledBannerCardActions,
  StyledBannerCardMediaDecoration
} from './styles'

const BannerCard = ({
  label,
  title,
  link,
  image,
  shopCta,
  generalCta,
  categories = null,
  changeMobileLayout = true,
  imageProps = {},
  boxProps = {},
  withDecoration = false
}) => {
  const { brand } = useContext(GeneralContext)

  return (
    <StyledBannerCard
      withActions={(shopCta || generalCta)}
      changeMobileLayout={(shopCta && generalCta) || changeMobileLayout}>
      <StyledBannerCardMedia>
        <BoxedImage
          noRadius
          noBorder
          ratio={1}
          image={image}
          {...boxProps}
          {...imageProps}
        >
          {
            withDecoration &&
            <StyledBannerCardMediaDecoration>
              <Graphics
                id={`${brand}-banner-decoration`}
                alt={`${brand} grafica prodotto`}
                format='png'
              />
            </StyledBannerCardMediaDecoration>
          }
        </BoxedImage>
      </StyledBannerCardMedia>


      <StyledBannerCardContent>
        {
          categories &&
          !!categories.length &&
          <InlineList gap={18}>
            {categories.map(cat => <Label as="span" key={cat} typo="labelSmall">{cat}</Label>)}
          </InlineList>
        }

        {
          label &&
          <Label as="span" typo="labelSmall">{label}</Label>
        }
        {
          link
            ? <Link href={link} bold typo="heading4">{title}</Link>
            : <Label bold typo="heading4">{title}</Label>
        }
      </StyledBannerCardContent>

      {
        (shopCta || generalCta) &&
        <StyledBannerCardActions gap={24}>
          {
            shopCta &&
            shopCta.link &&
            <Button small general
              typo="buttonSmall"
              className="cta-buy-now"
              keyword={shopCta.keyword}
              {...shopCta.link}
              label={shopCta.link.title}
            />
          }
          {
            generalCta &&
            generalCta.link &&
            <Cta small
              className={generalCta.className}
              keyword={generalCta.keyword} {...generalCta.link} />
          }
        </StyledBannerCardActions>
      }

    </StyledBannerCard>
  )
}

export default BannerCard
