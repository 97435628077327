import React from 'react'

import {
  StyledSimpleNavigation,
  StyledSimpleNavigationList,
  StyledSimpleNavigationListItem,
  StyledSimpleNavigationLink,
} from './styles'

const SimpleNavigation = ({
  items,
  bold = false,
  typo = null,
  uppercase = null,
  ...props
}) =>(
  items &&
  !!items.length &&
  <StyledSimpleNavigation>
    <StyledSimpleNavigationList
      {...props}
    >
      {
        items.map(item => (
          <StyledSimpleNavigationListItem
            key={item.id + item.title}
          >
            <StyledSimpleNavigationLink
              bold={bold}
              uppercase={uppercase}
              typo={typo}
              aria-label={item.title}
              {...item}
            >
              {item.title}
            </StyledSimpleNavigationLink>
          </StyledSimpleNavigationListItem>
        ))
      }
    </StyledSimpleNavigationList>
  </StyledSimpleNavigation>
)

export default SimpleNavigation
