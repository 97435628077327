import React from 'react'

export default () => (
  <svg width="128" height="129" viewBox="0 0 128 129" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M106.927 25.7587V25.7587C102.344 30.064 102.664 37.4057 105.91 42.7916C122.2 69.8259 104.543 116.294 54.3312 108.034C41.1078 105.859 29.438 97.5438 22.9375 85.8249C16.947 75.0252 12.4084 60.93 18.1356 47.091C27.8979 23.503 51.8649 22.1127 65.301 31.2207C89.4887 47.6169 81.171 97.0136 18.1354 97.0136L2.8618 97.0136" stroke="#FF8D4A" strokeWidth="1.5" strokeLinecap="round" strokeDasharray="4.16 4.16"/>
    <g clipPath="url(#clip0_12760_24808)">
      <path d="M113.649 26.543C111.279 28.0779 110.015 27.0448 109.498 25.9718C109.297 25.5534 109.261 25.0781 109.289 24.6144C109.914 14.4392 123.951 6.23473 126.79 5.02156C127.272 4.81561 128.127 5.0091 127.983 5.89119C127.983 6.45399 127.592 7.02099 127.062 7.56748C125.393 9.2852 124.25 11.279 123.187 13.4248C122.523 14.7636 121.625 15.8883 120.242 16.7637C119.718 17.0951 119.357 17.6493 119.357 18.269C119.357 18.8625 119.245 19.4517 118.981 19.9836C118.042 21.8795 116.118 24.9437 113.649 26.543Z" fill="#FF8D4A"/>
      <path d="M112.91 15.5045C112.91 9.46233 108.632 3.75708 105.872 1.37064C103.935 -0.586006 102.131 0.246691 102.586 2.3365C103.354 5.85797 102.077 7.56702 101.058 9.98641C100.535 11.2296 101.386 12.2286 102.163 12.8226C102.454 13.0446 102.419 13.5439 102.094 13.7102C100.784 14.379 98.8338 15.9256 99.5262 19.4729C100.412 24.0093 102.756 24.1554 104.497 24.198C105.42 24.2206 112.91 22.1632 112.91 15.5045Z" fill="#FF8D4A"/>
    </g>
    <defs>
      <clipPath id="clip0_12760_24808">
        <rect width="128" height="128" fill="white" transform="translate(0 0.00244141)"/>
      </clipPath>
    </defs>
  </svg>

)
