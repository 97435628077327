import React from 'react'

import Tag from '@/atoms/Tag'
import { InlineList } from '@/atoms/Layout'
import ChefPeopleLogo from '@/atoms/ChefPeopleLogo'

import Info from '@/molecules/Info'
import Breadcrumbs from '@/molecules/Breadcrumbs'
import BoxedImage from '@/molecules/BoxedImage'
import BannerCard from '@/molecules/Card/Cards/BannerCard'

import HeroContent from '../HeroContent'
import StyledHeroWave from '../HeroWave'

import {
  StyledHeroContainer,
  StyledHeroPart
} from '../styles'

import {
  StyledHeroRecipe,
  StyledHeroRecipeProduct
} from './styles'

const HeroRecipe = ({
  title,
  breadcrumbs,
  mainImage,
  product,
  featuredBadges,
  badges,
  chefPeople = false,
}) => (
  <StyledHeroRecipe>
    <Breadcrumbs items={breadcrumbs}/>
    <StyledHeroContainer>
      <StyledHeroPart start>
        {
          featuredBadges &&
          !!featuredBadges.length &&
          <InlineList gap={16} wrap>
            {
              featuredBadges.map(category => <Tag key={category} label={category.link.title} {...category} />)
            }
          </InlineList>
        }

        <HeroContent
          title={title}
        />

        {
          badges &&
          !!badges.length &&
          <InlineList gap={24} wrap>
            {
              badges.map(info => <Info key={info.label} {...info} iconSize={24}/>)
            }
          </InlineList>
        }

        {
          product &&
          <StyledHeroRecipeProduct>
            <BannerCard withDecoration imageProps={{ className: 'img-product' }} {...product} />
          </StyledHeroRecipeProduct>
        }

      </StyledHeroPart>

      <StyledHeroPart>
        <BoxedImage imageProps={{ className: 'main-image' }} noBorder rounded image={mainImage} ratio={1} full>
          {
            chefPeople &&
            <ChefPeopleLogo/>
          }
        </BoxedImage>

      </StyledHeroPart>
    </StyledHeroContainer>
    <StyledHeroWave />
  </StyledHeroRecipe>
)

export default HeroRecipe
