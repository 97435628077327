import styled from 'styled-components'
import styledMap from 'styled-map'
import { motion } from 'framer-motion'

import {
  StyledSimpleNavigation, StyledSimpleNavigationList
} from '@/molecules/SimpleNavigation/styles'

export const StyledFooter = styled(motion.footer)`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.general.dark()};
  padding-top: ${({ theme })=> theme.rem(80)};
  ${({ theme }) => theme.paddingX(24)};

  ${({ theme }) => theme.mediaQuery.md(`
    padding-top: ${theme.rem(64)};
    ${theme.paddingX(80)};
    position: fixed;
    bottom: 0;
    left: 0;
  `)};
`

export const StyledFooterRow = styled.div`
  display: inline-flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 0;

  ${({ theme }) => theme.mediaQuery.md(`
    flex-direction: row;
    border-bottom: 1px solid ${theme.colors.grayscale.gray800};
    ${theme.paddingY(40)};
  `)};

  &:last-child{
    border: none;
  }
`

export const StyledFooterCol = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: ${styledMap`
    noPaddingTop: 0;
    default: ${({ theme }) => theme.rem(24)};
  `};

  padding-bottom:${({ theme }) => theme.rem(24)};
  flex-direction: ${({ expand }) => expand ? 'column' : 'row'};
  gap: ${({ theme }) => theme.rem(16)};
  border-bottom: ${({
    theme,
    border = true
  }) => border ? ` 1px solid ${theme.colors.grayscale.gray800};` : 'none'};

  ${({
    expand, theme
  }) => expand ? `
    > * {
      width: 100%;

      ${theme.mediaQuery.md(`
        width: auto;
      `)}
    }
  ` : ''}

  .LogoCorporate{
    place-self: start;
    max-width: 80px;
  }

  ${StyledSimpleNavigation}{
    &:not(:first-child){
      border-top: 1px solid ${({ theme }) =>theme.colors.grayscale.gray800};
      margin-top: ${({ theme }) => theme.rem(24)};
      padding-top: ${({ theme }) => theme.rem(24)};

      ${({ theme }) => theme.mediaQuery.md(`
        border-top: none;
        margin-top: 0;
        padding-top: 0;
      `)}
    }

    ${StyledSimpleNavigationList}{
      display: grid;
      width: 100%;
      grid-template-columns: 6fr 6fr;

      li{
        width: 100%;
      }

      ${({ theme }) => theme.mediaQuery.md(`
        display: inline-flex;
        flex-wrap: wrap;

        li{
          width: auto;

          a:hover{
            text-decoration: underline;
          }
        }
      `)}
    }
  }

  ${({
    theme,
    right,
    expand
  }) => theme.mediaQuery.md(`
    flex-direction: row;
    justify-content: ${right ? 'flex-end' : 'flex-start'};
    width: 50%;
    border: none;
    padding: 0;
    ${expand ? `
      > * {
        width: auto;
      }
    ` : ''};
  `)};
`

export const StyledFooterColWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: 1fr;

  ${({ theme }) => theme.mediaQuery.md(`
    gap: ${theme.rem(24)};
  `)}
`
