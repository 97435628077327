import React from 'react'
import PropTypes from 'prop-types'
import glyphs from './glyphs'

import { StyledIcon } from './styles'

const Icon = ({
  name,
  ...props
}) => {
  const Glyph = glyphs[name]

  const fallBack = process.env.NODE_ENV === 'development' ? <>{name} not found</> : <></>

  return (
    <StyledIcon {...props}>
      {Glyph ? <Glyph /> : fallBack}
    </StyledIcon>
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string
}

export default Icon
