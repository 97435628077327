import React from 'react'

import Richtext from '@/atoms/RichText'
import { StyledParagraph } from './styles'

const Paragraph = ({ content }) => {
  return (
    <StyledParagraph>
      <Richtext as="div" typo="paragraphLarge">
        {content}
      </Richtext>
    </StyledParagraph>
  )
}

export default Paragraph
