import React from 'react'


import { Label } from '@/atoms/Typography'
import Richtext from '@/atoms/RichText'
import Button from '@/atoms/Button'
import { WithSelectedTheme } from 'contexts/Theme'

import RoundedIcon from '@/molecules/RoundedIcon'

import {
  StyledContact,
  StyledContactWrapper,
  StyledContactHeader,
  StyledContactContent,
  StyledContactFooter,
  StyledContactFooterDetails,
} from './styles'

const Contact = ({
  icon,
  title,
  content,
  details,
  cta,
}) => (
  <StyledContact>
    <StyledContactWrapper>
      <StyledContactHeader>
        <WithSelectedTheme theme="parmalat">
          <RoundedIcon name={icon} useIconColor size={54} />
        </WithSelectedTheme>
      </StyledContactHeader>

      <StyledContactContent>
        <Label bold as="strong" typo="heading4">{title}</Label>
        <Richtext as="div" typo="paragraphRegular">
          {content}
        </Richtext>
      </StyledContactContent>

      <StyledContactFooter>
        {
          details &&
          <StyledContactFooterDetails>
            <Label bold typo="link3">{details.eyelet}</Label>
            <Label bold typo="heading4">{details.content}</Label>
          </StyledContactFooterDetails>
        }

        <Button general small  keyword={cta.keyword} label={cta.link.title} href={cta.link.href} target={cta.link.target} />
      </StyledContactFooter>

    </StyledContactWrapper>
  </StyledContact>
)

export default Contact
