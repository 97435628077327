import styled from 'styled-components'

export const StyledSearch = styled.div`
  width: 100%;
  display: block;
  margin: 0 auto;

  ${({ theme }) => theme.mediaQuery.md(`
    --min: 480px;
    --ideal: ${theme.col(4)};
    --max:  ${theme.col(6)};

    max-width: clamp(var(--min), var(--ideal), var(--max));
  `)}
`
