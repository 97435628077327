import React from 'react'
import Button from '@/atoms/Button'
import Icon from '@/atoms/Icon'
import {
  StyledWithDecorationButton, StyledWithDecoration
} from './styles'

const WithDecorationButton = (props) => {
  return (
    <StyledWithDecorationButton>
      <Button big {...props} />
      <StyledWithDecoration>
        <Icon name="button-decoration" useIconColor unlockedProportions />
      </StyledWithDecoration>
    </StyledWithDecorationButton>
  )
}

export default WithDecorationButton
