import styled from 'styled-components'

import Button from '@/atoms/Button'
import { Label } from '@/atoms/Typography'

export const StyledCheckboxFilter = styled.div``

export const StyledCheckboxFilterCta = styled(Button)`
  width: 100%;
  gap: ${({ theme }) => theme.rem(16)};
  justify-content: flex-start;
  border-color: ${({
    theme, withValues
  }) => withValues ? theme.colors.general.light() : theme.colors.grayscale.gray200};

  > span{
    display: inline-flex;
    gap: ${({ theme }) => theme.rem(16)};
  }
`

export const StyledCheckboxFilterCtaCounter = styled(Label)`
  flex-shrink: 0;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.general.light()};
  color: ${({ theme }) => theme.colors.accent()};
  ${({ theme }) => theme.size(24)};
`


export const StyledCheckboxFilterClose = styled(Button)`
  position: relative;
  /* flex-shrink: 0; */
  padding: 0;
  width: 100%;
  /* width: ${({ theme }) => theme.rem(40)}; */

  /* &::before,
  &::after{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;

    ${({ theme }) => theme.size(['40%', 2])};
    background-color: ${({ theme }) => theme.colors.general.light()};
  }

  &::before{
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after{
    transform: translate(-50%, -50%) rotate(-45deg);
  } */
`

export const StyledCheckboxFilterMain = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 25;
  overflow: auto;
  width: 100%;
  height: 100vh;
  height: 100svh;
  ${({ theme }) => theme.paddingX(24)};
  ${({ theme }) => theme.paddingY([88, 80])};

  background-color: ${({ theme }) => theme.colors.grayscale.white};

  ${({ isOpen }) => isOpen ? 'display: block' : ''};

  ${({ theme }) => theme.mediaQuery.lg(`
    ${theme.paddingY([24, 80])};
    display: block;
    position: static;
    top: unset;
    left: unset;
    padding: 0;
    z-index: 1;
    overflow: visible;
    width: auto;
    height: auto;
    background-color:transparent;
  `)};

  > * +*{
    margin-block-start: ${({ theme }) => theme.rem(40)};

    ${({ theme }) => theme.mediaQuery.lg(`
      margin-block-start:0;
    `)}
  }
`

export const StyledCheckboxFilterExpand = styled.div`
  ${({ theme }) => theme.mediaQuery.md(`
    margin-top: ${theme.rem(40)};
  `)}
`
