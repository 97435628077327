import styled from 'styled-components'
import { motion } from 'framer-motion'

import Link from '@/atoms/Link'
import styledMap from 'styled-map'

export const StyledSyncedImage = styled.div`
  display: flex;
  align-items: stretch;
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  border: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};
  border-radius: ${({ theme }) => theme.rem(16)};
  padding: ${({ theme }) => theme.rem(16)};
  gap: ${({ theme }) => theme.rem(16)};

  ${({ theme }) => theme.mediaQuery.md(`
    position: relative;
    background-color: transparent;

    border: none;
    border-radius: 0;
    border-radius: ${theme.rem(24)};
    padding: 0;
    overflow:hidden;

    .swiper-slide-active &{
      box-shadow: 10px 10px 20px 0px rgba(0,0,0,0.15);
    }
  `)}
`

export const StyledSyncedImageWrapper = styled(motion.div)`
  ${({ theme }) => theme.size(104)};
  position: relative;
  flex-shrink: 0;

  &:before{
    display: none;

    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity ease-in-out .4s;
    pointer-events: none;
    z-index: 1;
    ${({ theme }) => theme.size('100%')};
    background-color:  ${({ theme }) => theme.colors.general.light(0.8)};
    opacity: ${styledMap('isActive', {
    default: 0,
    false: 1
  })};

    ${({ theme }) => theme.mediaQuery.md(`
      display: block;
    `)}
  }

  > div{
    border-radius: ${({ theme }) => theme.rem(8)};
  }

  ${({ theme }) => theme.mediaQuery.md(`
    ${theme.size('100%')};

    > div{
      border-radius: 0};
    }
  `)};

  img{pointer-events: none;}
`


export const StyledSyncedImageCtaWrapper = styled.div`
  ${({ theme }) => theme.mediaQuery.md(`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    ${theme.paddingX(16)};
    ${theme.paddingY(16)};
  `)}
`
export const StyledSyncedImageCta = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.general.light()};

  ${({ theme }) => theme.mediaQuery.md(`
    height: auto;
    padding: ${theme.rem(16)};
    background-color: ${theme.colors.grayscale.white};
    border-radius: ${theme.rem(16)};
    gap: ${theme.rem(16)};
  `)}
`
export const StyledSyncedImageCtaText = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.rem(4)};
  justify-content: space-between;
  height: 100%;

  ${({ theme }) => theme.mediaQuery.md(`
    height: auto;
    justify-content: flex-start;
  `)}
`

export const StyledSyncedImageCtaIcon = styled.div`
  display: none;
  aspect-ratio: 1;

  align-items: center;
  border-radius: 50%;
  ${({ theme }) => theme.size(48)};
  background-color: ${({ theme }) => theme.colors.accent()};
  color: ${({ theme }) => theme.colors.general.light()};

  ${({ theme }) => theme.mediaQuery.md(`
    display: grid;
  `)}

  i{
    font-size: ${({ theme }) => theme.rem(24)};
  }
`
