import { ENDPOINTS } from './config'

export const getApiUrl = (lang = '') => {
  return `${process.env.NEXT_PUBLIC_API_URL}${lang}`
}

export const getAlgoliaEndpoint = ({
  type,
  customEndpoint = null
}) => {
  if (!type && !customEndpoint) throw new Error('No type set!')

  const algoliaUrl = process.env.NEXT_PUBLIC_ALGOLIA_URL
  if (!algoliaUrl) throw new Error('Algolia url not found!')

  if (customEndpoint) return `${algoliaUrl}${customEndpoint}`
  const algoliaPath = ENDPOINTS.algolia.replace('{type}', type)

  return `${algoliaUrl}${algoliaPath}`
}

export const getEndpoint = ({
  base = getApiUrl(),
  endpoint = null,
  dynamicParams = null,
  queryString = null,
}) => {
  if (!endpoint) throw new Error('Not endpoint value found!!')

  let apiURL = `${base}${endpoint}`

  if (dynamicParams && !!dynamicParams.length) {
    const dynamicParamsList = dynamicParams.join('/')
    apiURL = `${apiURL}/${dynamicParamsList}`
  }

  if (queryString && !!Object.keys(queryString).length) {
    const queryStringList = new URLSearchParams(queryString).toString()

    apiURL = `${apiURL}?${queryStringList}`
  }

  return apiURL
}

/*
 A wrapper for fetch. It applies a memoization to the fetch call.
 Data will be store on file system.
*/
const fetchDataMemo = (originalFunction) => {
  return async (...args) => {
    const [url, opt, raw] = args
    if (typeof window === 'undefined') {
      const crypto = require('crypto')
      const fs = require('fs')
      const path = require('path')
      const hash = crypto.createHash('sha256')
      hash.update(url)

      if (!fs.existsSync('./api-cache/')) {
        fs.mkdirSync('./api-cache/')
      }

      const filePath = path.resolve(`./api-cache/${hash.digest('hex')}.json`)
      if (fs.existsSync(filePath)) {
        const data = JSON.parse(fs.readFileSync(filePath, 'utf8'))
        return data
      }
      const data = await originalFunction(url, opt, raw)
      fs.writeFileSync(filePath, JSON.stringify(data), 'utf8')
      return data
    }
    return await originalFunction(url, opt, raw)
  }
}


export const fetchData = fetchDataMemo(async (url, opt = {}, raw = false) => {
  let response

  if (!!process.env.NODE_ENV && process.env.NODE_ENV === 'development') console.log('Fetching data', url)
  try {
    const res = await fetch(url, opt)
    response = res

    if (!raw) response = await res.json()
  } catch (err) {
    console.log('ERROR url', url)
    console.error(err)
  }

  return response
})


export const fetchAlgoliaData = async ({
  body,
  opts,
  type,
  customEndpoint = null,
} = {}) => {
  const endpoint = getAlgoliaEndpoint({
    type,
    customEndpoint
  })

  const myHeaders = new Headers()
  myHeaders.append('X-Algolia-Application-Id', process.env.NEXT_PUBLIC_ALGOLIA_APP_ID)
  myHeaders.append('X-Algolia-API-Key', process.env.NEXT_PUBLIC_ALGOLIA_API_KEY)
  myHeaders.append('Content-Type', 'application/json')

  const raw = JSON.stringify(body)

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    ...opts,
  }

  try {
    const data = await fetchData(endpoint, requestOptions)

    return {
      success: true,
      data
    }
  } catch (error) {
    return {
      success: false,
      data: error
    }
  }
}


