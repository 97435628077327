import styled from 'styled-components'
import { motion } from 'framer-motion'

export const StyledMediaBanner = styled.div`
  position: relative;
`

export const StyledMediaBannerWrapper = styled.div`
  width: 100%;

  ${({ theme }) =>
    theme.mediaQuery.md(`
      aspect-ratio: 16/10;
    `)}

  ${({ theme }) =>
    theme.mediaQuery.xxl(`
      height: 95vh;
      height: 95svh;
    `)}
`

export const StyledMediaBannerMedia = styled(motion.div)`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  left: 0;
  border-radius: ${({ theme }) =>theme.rem(24)};


  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 50.06%, rgba(0, 0, 0, 0.00) 99.99%), radial-gradient(52.11% 52.11% at 50% 50%, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.58) 100%);
  }

  img {
    object-fit: cover;
  }
`

export const StyledMediaBannerContent = styled.div`
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: ${({ theme }) => theme.rem(64, 24)};
  display: flex;
  flex-direction: column;
  gap: calc(100vw / 12 * 10);

  ${({ theme }) => theme.mediaQuery.md(`
    --col: ${theme.col(1)};
    display: block;
    max-width: var(--max-width);
    position: absolute;
  `)}

  ${({ theme }) => theme.mediaQuery.xxl(`
    max-width: 100%;
  `)}
`

export const StyledMediaBannerTitle = styled.div`
  position: relative;
  color: #fff;

  ${({ theme }) => theme.mediaQuery.md(`
    --col: ${theme.col(1)};
    --left: clamp(24px,  var(--col),  var(--col));
    left: var(--left);
    max-width: ${theme.rem(440)};
    position: absolute;

    top: calc(100vw / 12 * 1);
  `)}

  ${({ theme }) =>theme.mediaQuery.xxl(`
    top: calc(100vh / 12 * 2);
  `)}
`

export const StyledMediaBannerDescription = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) =>theme.rem(24)};
  position: relative;

  ${({ theme }) => theme.mediaQuery.md(`
    --col: ${theme.col(1)};
    --right: clamp(24px,  var(--col),  var(--col));
    right: var(--right);
    width: calc(45% - 100vw / 12 * 1);
    position: absolute;
    bottom: calc(100vw / 12 * 1);
  `)}

  ${({ theme }) => theme.mediaQuery.xxl(`

    bottom: calc(100vh / 12 * 2);
  `)}
`
