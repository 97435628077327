const BrandCard = (item) => {
  const {
    cta,
    image
  } = item

  const normalizeData = {
    ratio: 1,
    image,
    href: cta.link.href,
    rounded: true
  }

  return normalizeData
}

export default BrandCard
