import styled from 'styled-components'

import { StyledRichtext } from '@/atoms/RichText/styles'
import { StyledChefPeopleLogo } from '@/atoms/ChefPeopleLogo/styles'

import { StyledCard } from '@/molecules/Card/Cards/styles'
import { StyledCardMedia } from '@/molecules/Card/CardMedia/styles'
import { InlineList } from '@/atoms/Layout'
import { StyledParagraph } from '../styles'

export const StyledProductParagraph = styled(StyledParagraph)`
  color: ${({ theme }) => theme.colors.general.light()};
`

export const StyledProductParagraphContainer = styled.div`
  grid-area: content;
  display: grid;
  gap: ${({ theme }) => theme.rem(80)};
`

export const StyledProductParagraphHeader = styled.header`
  > * + * {
    margin-block-start: ${({ theme }) => theme.rem(40)};
  }
`

export const StyledProductParagraphContent = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.rem(80)};
`


export const StyledSpecs = styled.div``

export const StyledSpecsList = styled.div`
  margin-bottom: ${({ theme }) => theme.rem(40)};

  > * + * {
    margin-block-start: ${({ theme }) => theme.rem(24)};
  }
`
export const StyledSpecsItem = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${({ theme }) => theme.rem(16)};

  ${StyledRichtext}{
    padding-top: ${({ theme }) => theme.rem(12)};

  }
`

export const StyledSpecsItemIcon = styled.div`
  flex-shrink: 0;
  i{
    font-size: ${({ theme }) => theme.rem(30)};
  }
`


export const StyledRelated = styled.div``

export const StyledRelatedList = styled.div`
  ${StyledCard}{
    align-items: center;
  }

  ${StyledCardMedia}{
    border-radius: ${({ theme }) => theme.rem(16)};
    flex-shrink: 0;
  }

  ${StyledChefPeopleLogo}{
    top:  ${({ theme }) => theme.rem(8)};
    left:  ${({ theme }) => theme.rem(8)};
  }

  > *+*{
    margin-block-start: ${({ theme }) => theme.rem(24)};
  }

  @media screen and (max-width: 767px) {
    ${InlineList}{
      display: none;
    }
  }
`
