import styled from 'styled-components'
export const StyledSectionContent = styled.div`
  width: 100%;
  max-width: ${({ fill }) => (fill ? '100%' : 'var(--max-width)')};
  margin: 0 auto;

  ${({ theme }) => theme.mediaQuery.md(`
    position: relative;
    &[fill="md"]{
      max-width: var(--max-width);
    }
  `)}
`
export const StyledSection = styled.section`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  width: 100%;
  margin-bottom: ${({
    theme, isLast
  }) => isLast ? theme.rem(-40) : 0};

  ${({ theme }) => theme.paddingX(24)};

  &[data-fill="true"],
  &[data-fill="md"]{
    ${({ theme }) => theme.paddingX(0)};
  }

  ${({ theme, }) => theme.mediaQuery.md(`
    padding-left: ${theme.col(1)};
    padding-right: ${theme.col(1)};

    &[data-fill="true"] {
      ${theme.paddingX(0)};
    }

    &[data-fill="md"] {
      padding-left: ${theme.col(1)};
      padding-right: ${theme.col(1)};
    }
  `)}

  &:first-of-type {}

  &[data-bg-lastsection="true"]{
    background-color: transparent;
  }

  &[data-bg] {
    position: relative;
    z-index: 1;

    ${StyledSectionContent}{
      /* position: relative;
      z-index: 1; */
    }


    ${({ theme }) => theme.paddingX(24)};

    &[data-bg-top]{}

    &[data-bg-bottom]{}


    ${({ theme }) => theme.mediaQuery.md(`
      padding-left: ${theme.col(1)};
      padding-right: ${theme.col(1)};
    `)};

  }

  ${({ isLast }) => isLast && `
    position: relative;
    z-index: 1;


    ${StyledSectionContent}{
      position: relative;
      z-index: 1;
    }
  `}

  &[data-fill="true"] {
    ${({ theme }) => theme.paddingX(0)};
  }

  &[data-section="NotFound"]{
    ${({ theme }) => theme.paddingY(32)};
    ${({ theme }) => theme.mediaQuery.md(`
      ${theme.paddingY(64)}
      `)};
  }

  &[data-section="Editorial"]{
    ${({ theme }) => theme.paddingY([64, 20])};
    ${({ theme }) => theme.mediaQuery.md(`
      ${theme.paddingY([128, 40])}
    `)};
  }

  &[data-section="Accordion"]{
    ${({ theme }) => theme.paddingY([32, 64])};
    ${({ theme }) => theme.mediaQuery.md(`
      ${theme.paddingY([64, 128])}
    `)};
  }

  &[data-section="MosaicRecipe"]{
    ${({ theme }) => theme.paddingY(20)};
    ${({ theme }) => theme.mediaQuery.md(`
      ${theme.paddingY(40)}
      `)};
  }

  &[data-section="ProductSlider"]{
    ${({ theme }) => theme.paddingY([32, 64])};
    ${({ theme }) => theme.mediaQuery.md(`
      ${theme.paddingY([4, 68])}
    `)};
  }


  &[data-section="ArticleParagraph"],
  &[data-section="RecipeParagraph"],
  &[data-section="ProductParagraph"],
  &[data-section="CocktailParagraph"],
  &[data-section="Paragraph"],
  &[data-section="Cookiebot"],
  &[data-section="SeoParagraph"],
  &[data-section="FeaturedContent"],
  &[data-section="EditorialMedia"]{
    ${({ theme }) => theme.paddingY(64)};
    ${({ theme }) => theme.mediaQuery.md(`
      ${theme.paddingY(128)}
      `)};
  }

  &[data-section="Search"]{
    ${({ theme }) => theme.paddingY(24)};
    ${({ theme }) => theme.mediaQuery.md(`
      ${theme.paddingY(40)}
      `)};
  }

  &[data-section="Explorer"]{
    ${({ theme }) => theme.paddingY(0)};
    ${({ theme }) => theme.mediaQuery.md(`
      ${theme.paddingY([40, 0])}
    `)};
  }

  &[data-section="Contacts"]{
    ${({ theme }) => theme.paddingY([24, 64])};
    ${({ theme }) => theme.mediaQuery.md(`
      ${theme.paddingY([32, 128])}
    `)};
  }

  &[data-section="Grid"]{
    ${({ theme }) => theme.paddingY([0, 64])};
    ${({ theme }) => theme.mediaQuery.md(`
      ${theme.paddingY([0, 128])}
    `)};
  }

  &[data-bg-lastsection="true"]:not([data-section="Slider"]){
    padding-bottom: ${({ theme }) => theme.rem(64)};
    ${({ theme }) => theme.mediaQuery.md(`
      padding-bottom:${theme.rem(128)}
    `)};
  }
`


