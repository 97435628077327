import brandedAddress from './branded-address'
import brandedAgrumi from './branded-agrumi'
import brandedCannelloni from './branded-cannelloni'
import brandedCrepes from './branded-crepes'
import brandedDolci from './branded-dolci'
import brandedEsotici from './branded-esotici'
import brandedFaq from './branded-faq'
import brandedFilters from './branded-filters'
import brandedFormaggi from './branded-formaggi'
import brandedFormats from './branded-formats'
import brandedFreschi from './branded-freschi'
import brandedFruttaClassici from './branded-frutta-classici'
import brandedFrutta from './branded-frutta'
import brandedFruttiRossi from './branded-frutti-rossi'
import brandedIngredients from './branded-ingredients'
import brandedLasagna from './branded-lasagna'
import brandedLatte from './branded-latte'
import brandedMail from './branded-mail'
import brandedModerni from './branded-moderni'
import brandedOccasione from './branded-occasione'
import brandedOriginali from './branded-originali'
import brandedPhone from './branded-phone'
import brandedPortata from './branded-portata'
import brandedProdotto from './branded-prodotto'
import brandedSalati from './branded-salati'
import brandedStrumenti from './branded-strumenti'
import brandedTipologia from './branded-tipologia'
import brandedTortellini from './branded-tortellini'
import brandedYogurt from './branded-yogurt'
import buonPerMe from './buon-per-me'
import buttonDecoration from './button-decoration'
import arrowDiagonal from './arrow-diagonal'
import arrowDown from './arrow-down'
import arrowLeft from './arrow-left'
import arrowRight from './arrow-right'
import arrowUp from './arrow-up'
import chevronDown from './chevron-down'
import chevronLeft from './chevron-left'
import chevronRight from './chevron-right'
import chevronUp from './chevron-up'
import difficultyBeverage from './difficulty-beverage'
import difficultyFood from './difficulty-food'
import mapLocation from './map-location'
import searchBig from './search-big'
import attachment from './attachment'
import cart from './cart'
import check from './check'
import copy from './copy'
import exclamation from './exclamation'
import explore from './explore'
import facebook from './facebook'
import filters from './filters'
import instagram from './instagram'
import linkedin from './linkedin'
import mail from './mail'
import people from './people'
import play from './play'
import plus from './plus'
import preparation from './preparation'
import print from './print'
import search from './search'
import share from './share'
import twitter from './twitter'
import user from './user'
import noResults from './no-results'
import chefDecoration from './chef-decoration'
import santalDecoration from './santal-decoration'
import zymilDecoration from './zymil-decoration'
import parmalatDecoration from './parmalat-decoration'


export default {
  'branded-address': brandedAddress,
  'branded-agrumi': brandedAgrumi,
  'branded-cannelloni': brandedCannelloni,
  'branded-crepes': brandedCrepes,
  'branded-dolci': brandedDolci,
  'branded-esotici': brandedEsotici,
  'branded-faq': brandedFaq,
  'branded-filters': brandedFilters,
  'branded-formaggi': brandedFormaggi,
  'branded-formats': brandedFormats,
  'branded-freschi': brandedFreschi,
  'branded-frutta-classici': brandedFruttaClassici,
  'branded-frutta': brandedFrutta,
  'branded-frutti-rossi': brandedFruttiRossi,
  'branded-ingredients': brandedIngredients,
  'branded-lasagna': brandedLasagna,
  'branded-latte': brandedLatte,
  'branded-mail': brandedMail,
  'branded-moderni': brandedModerni,
  'branded-occasione': brandedOccasione,
  'branded-originali': brandedOriginali,
  'branded-phone': brandedPhone,
  'branded-portata': brandedPortata,
  'branded-prodotto': brandedProdotto,
  'branded-salati': brandedSalati,
  'branded-strumenti': brandedStrumenti,
  'branded-tipologia': brandedTipologia,
  'branded-tortellini': brandedTortellini,
  'branded-yogurt': brandedYogurt,
  'buon-per-me': buonPerMe,
  'button-decoration': buttonDecoration,
  'arrow-diagonal': arrowDiagonal,
  'arrow-down': arrowDown,
  'arrow-left': arrowLeft,
  'arrow-right': arrowRight,
  'arrow-up': arrowUp,
  'chevron-down': chevronDown,
  'chevron-left': chevronLeft,
  'chevron-right': chevronRight,
  'chevron-up': chevronUp,
  'difficulty-beverage': difficultyBeverage,
  'difficulty-food': difficultyFood,
  'map-location': mapLocation,
  'no-results': noResults,
  'search-big': searchBig,
  attachment,
  cart,
  check,
  copy,
  exclamation,
  explore,
  facebook,
  filters,
  instagram,
  linkedin,
  mail,
  people,
  play,
  plus,
  preparation,
  print,
  search,
  share,
  twitter,
  user,
  'chef-decoration': chefDecoration,
  'santal-decoration': santalDecoration,
  'zymil-decoration': zymilDecoration,
  'parmalat-decoration': parmalatDecoration,
}
