import styled from 'styled-components'
import styledMap from 'styled-map'

export const StyledMediaIcon = styled.div`
  --width: ${styledMap`
      big: 140px;
      default: 100px;
      small: 64px;
  `};

  display: grid;
  place-items: center;
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  aspect-ratio: 1;
  border-radius: 50%;
  width:  84px;
  background-color: ${({ theme }) => theme.colors.accent()};
  transform: ${styledMap`
    default: translate(-100%, 100%);
    visible: translate(-14%, 10%);
  `};

  ${({ theme }) => theme.mediaQuery.md(`
    width: var(--width);
  `)};

  i {
    --svg-width: ${styledMap`
      big: 40px;
      default: 30px;
      small: 24px;
    `};

    position: relative;
    z-index: 2;
    transform: translate(15%, -10%);
    width: 24px;

    ${({ theme }) => theme.mediaQuery.md(`
      width: var(--svg-width);
    `)}

    svg{
      transform: translateZ(0) scale(1);
      transform-origin: 50% 50%;
      backface-visibility: hidden;
    }
  }
`

export const StyledCardMedia = styled.div`
  position: relative;
  display: block;
  aspect-ratio: ${styledMap('ratio', {
    default: 1,
    auto: 'auto',
    3: '3 / 4',
    4: '4 / 3',
    16: '16 / 9',
  })};

  overflow: hidden;
  border-radius: ${({
    theme, noRadius = false
  }) => !noRadius ? theme.rem(16) : 0};

  ${({
    theme, noRadius
  }) => theme.mediaQuery.md(`
    border-radius: ${!noRadius ? theme.rem(24) : 0};
  `)}

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
`
