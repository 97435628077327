import React, { useState } from 'react'

import {
  Swiper,
  SwiperSlide
} from 'swiper/react'

import {
  Navigation,
  EffectFade,
  Controller,
  EffectCreative
} from 'swiper'

import SyncedSliderItem from './Slide'
import SyncedImage from './Image'
import Icon from '@/atoms/Icon'

import {
  StyledSyncedCarousel,
  StyledSyncedSlider,
  StyledSyncedSliderNavigation,
  StyledSyncedSliderNavigationButton,
  StyledSyncedImages,
  StyledSyncedImagesWrapper,
} from './styles'

const SyncedSlider = ({
  slider,
  images
}) => {
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)

  return (
    <StyledSyncedCarousel>
      {
        slider &&
        !!slider.length &&
        <StyledSyncedSlider>
          <Swiper
            modules={[Navigation, EffectFade, Controller]}
            onSwiper={setFirstSwiper}
            speed={1000}
            controller={{ control: secondSwiper }}
            navigation={{
              nextEl: '.synced-slider-button-next',
              prevEl: '.synced-slider-button-prev',
            }}
            slidesPerView={1}
            effect='fade'
            fadeEffect={ { crossFade: true }}
          >
            {
              slider.map(item => (
                <SwiperSlide key={`slide_${item.id}`}>
                  <SyncedSliderItem {...item} />
                </SwiperSlide>
              ))
            }

            <StyledSyncedSliderNavigation>
              <StyledSyncedSliderNavigationButton aria-label='prev slide' className='synced-slider-button-prev'><Icon name="chevron-left" /></StyledSyncedSliderNavigationButton>
              <StyledSyncedSliderNavigationButton aria-label='next slide' className='synced-slider-button-next'><Icon name="chevron-right" /></StyledSyncedSliderNavigationButton>
            </StyledSyncedSliderNavigation>
          </Swiper>
        </StyledSyncedSlider>
      }

      {
        images &&
        !!images.length &&
        <StyledSyncedImages>
          <StyledSyncedImagesWrapper>
            <Swiper
              modules={[Controller, EffectCreative]}
              onSwiper={setSecondSwiper}
              controller={{ control: firstSwiper }}
              slidesPerView={1}
              effect={'creative'}
              speed={1000}
              creativeEffect={{
                prev: {
                  origin: 'right center',
                  translate: ['100%', 0, -400],
                  rotate: [0, 180, 0],
                  opacity: 0,
                  scale: 0.5,
                },
                next: {
                  origin: 'right center',
                  scale: 0.85,
                  translate: ['10%', 0, -100],
                },
              }}
            >
              {
                images.map((item) => (
                  <SwiperSlide key={`image_${item.slideID}`}>
                    <SyncedImage {...item} />
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </StyledSyncedImagesWrapper>
        </StyledSyncedImages>
      }

    </StyledSyncedCarousel>
  )
}

export default SyncedSlider
