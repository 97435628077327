import React from 'react'

import { Label } from '@/atoms/Typography'
import BoxedImage from '@/molecules/BoxedImage'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Keyboard, Mousewheel } from 'swiper/core'

import {
  StyledTabFilter,
  StyledTabFilterList,
  StyledTabFilterTitle,
} from './styles'

SwiperCore.use([Keyboard, Mousewheel])

const swiperProps = {
  spaceBetween: 8,
  slidesPerView: 'auto',
  keyboard: true,
}

const TabFilter = ({ items, type, actions, hideImg = true }) => {
  return (
    items &&
    !!items.length && (
      <StyledTabFilterList gap={8} type={type}>
        <Swiper {...swiperProps}>
          {items.map((props, i) => {
            const {
              title,
              image,
              href,
              current = false,
              noBorder = true,
              full = true,
              small = false,
            } = props

            const tabProps = {}

            if (actions && actions.onClick) {
              Object.assign(tabProps, {
                onClick: () => actions.onClick(props),
              })
            }

            return (
              <SwiperSlide key={`${i}_${!!title ? title : props.name}`}>
                <StyledTabFilter
                  key={title}
                  small={small}
                  current={current}
                  hideImg={hideImg}
                  {...tabProps}
                >
                  <BoxedImage
                    noBorder={noBorder}
                    full={full}
                    image={image}
                    href={href}
                  >
                    {title && (
                      <StyledTabFilterTitle>
                        <Label bold as="span" typo="buttonRegular">
                          {title}
                        </Label>
                      </StyledTabFilterTitle>
                    )}
                  </BoxedImage>
                </StyledTabFilter>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </StyledTabFilterList>
    )
  )
}
export default TabFilter
