import React, { useMemo } from 'react'
import {
  motion,
  AnimatePresence
} from 'framer-motion'

import Controller from '../Controller'

import FieldsetTitle from './Title'

import {
  StyledFieldset,
  StyledFieldsetContent
} from './styles'

const expandableAnimationProps = {
  layout: true,
  initial: {
    height: 0,
    opacity: 0,
    margin: 0,
  },
  exit: {
    height: 0,
    opacity: 0,
    margin: 0,
  },
  animate: {
    display: 'block',
    height: 'auto',
    opacity: 1,
    margin: '24px 0'
  },
}

const Fieldset = ({
  id = null,
  title = null,
  fields = null,
  layout = 100,
  icon = null,
  isExpanded = true,
  expandable = false,
  onFieldsetClick = false
}) => {
  const contentProps = useMemo(() => {
    return expandable ? expandableAnimationProps : {}
  }, [expandable])

  return (
    <StyledFieldset>

      <FieldsetTitle
        onClick={onFieldsetClick}
        id={id}
        title={title}
        icon={icon}
        isExpanded={isExpanded}
        expandable={expandable}
      />

      <AnimatePresence initial={false}>
        {
          fields &&
          !!fields.length &&
          isExpanded &&
          <StyledFieldsetContent
            data-lenis-prevent
            {...contentProps}
            as={motion.div}
            col={layout}
            isExpanded={isExpanded}
          >
            {
              fields.map((field, idx) => <Controller key={idx} {...field} />)
            }
          </StyledFieldsetContent>
        }
      </AnimatePresence>
    </StyledFieldset>
  )
}

export default Fieldset
