import styled from 'styled-components'

export const StyledGraphicAnimated = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.size('100%')};

  i{
    padding: ${({ theme }) => theme.rem(8)};

    ${({ theme }) => theme.mediaQuery.md(`
      padding: ${theme.rem(32)}
    `)};
  }

  i,
  svg{
    ${({ theme }) => theme.size('100%')};
  }

  svg{
    transition: transform ${({ theme }) => theme.timing.normal} ${({ theme }) => theme.ease}, opacity ${({ theme }) => theme.timing.normal} ${({ theme }) => theme.ease};
    transform-origin: center center;
    opacity: 0;
    transform: scale(0);
  }
`
