import styled from 'styled-components'
import { StyledBoxedImage } from '@/molecules/BoxedImage/styles'
export const StyledBrandCard = styled.div`
  ${StyledBoxedImage}{
    display: grid;
    place-items: center;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
    transition: border-color ease-in-out .4s, box-shadow ease-in-out .4s;

    ${({ theme }) => theme.mediaQuery.lg(`
      &:hover {
        border-color: transparent;
        box-shadow: ${theme.shadows.hover};
      }
    `)};

    img{
      max-width: 73%;
      ${({ theme }) => theme.size('auto')}
    }
  }
`
