import React from 'react'

import { StyledBoxedImage } from './styles'

import CustomImage from '@/atoms/CustomImage'
import Link from '@/atoms/Link'

const BoxedImage = ({
  image,
  href = null,
  children,
  imageProps = {},
  ...props
}) => (
  image &&
  <StyledBoxedImage
    withLabel={false}
    href={href}
    as={href ? Link : 'div'}
    {...props}
  >
    <CustomImage data={image} {...imageProps}/>
    {children && children}
  </StyledBoxedImage>
)


export default BoxedImage
