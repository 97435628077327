import React from 'react'

import { Label } from '@/atoms/Typography'
import Richtext from '@/atoms/RichText'

import BoxedImage from '@/molecules/BoxedImage'
import ShareButton from '@/molecules/Buttons/ShareButton'

import {
  StyledSteps,
  StyledStepsHeader,
  StyledStepsContent,
  StyledStepsList,
  StyledStepsListItem,
  StyledStepsListItemNumber,
  StyledStepsListItemContent,
} from './styles'

const Steps = ({
  title,
  steps,
  isMobile,
}) =>(
  <StyledSteps>
    {
      title &&
      <StyledStepsHeader>
        <Label bold as="h2" typo="heading3">{title}</Label>
      </StyledStepsHeader>
    }

    <StyledStepsContent>
      <StyledStepsList>
        {
          steps &&
          !!steps.length &&
          steps.map(({
            text,
            image
          }, idx) => (
            <StyledStepsListItem key={image}>
              <StyledStepsListItemNumber>
                <Label bold typo="heading5">{idx + 1}</Label>

              </StyledStepsListItemNumber>
              <StyledStepsListItemContent>
                <Richtext as="div" typo="paragraphLarge">
                  {text}
                </Richtext>
                <BoxedImage image={image} full />
              </StyledStepsListItemContent>
            </StyledStepsListItem>
          ))
        }
      </StyledStepsList>
      {
        isMobile &&
        <ShareButton negative iconStart="share" label="Condividi" />
      }
    </StyledStepsContent>
  </StyledSteps>
)


export default Steps
