import React from 'react'

import HeadingWithMark from '@/molecules/HeadingWithMark'
import Richtext from '@/atoms/RichText'
import Specs from './Specs'
import Related from './Related'

import {
  StyledProductParagraph,
  StyledProductParagraphHeader,
  StyledProductParagraphContainer,
  StyledProductParagraphContent
} from './styles'

const ProductParagraph = ({
  title,
  abstract,
  specs = null,
  related = null
}) => {
  return (
    <StyledProductParagraph data-product-paragraph>
      <StyledProductParagraphContainer>
        <StyledProductParagraphHeader>
          <HeadingWithMark content={title} />
          <Richtext as="div" typo="paragraphLarge">
            {abstract}
          </Richtext>
        </StyledProductParagraphHeader>

        <StyledProductParagraphContent>
          <Specs {...specs} />
          <Related {...related} />
        </StyledProductParagraphContent>
      </StyledProductParagraphContainer>
    </StyledProductParagraph>
  )
}

export default ProductParagraph
