import React, { useContext } from 'react'
import { GeneralContext } from 'contexts/General'

import Icon from '@/atoms/Icon'

import { StyledGraphicAnimated } from './styles'

import { allowedBrands } from '@/utils'

const GraphicAnimated = ({ brand: forceBrand = null }) => {
  const { brand } = useContext(GeneralContext)
  const brandSelected = forceBrand || allowedBrands.find(i => i === brand) || 'parmalat'
  const iconToAnimate = `${brandSelected}-decoration`

  return (
    <StyledGraphicAnimated>
      <Icon name={iconToAnimate} useIconColor unlockedProportions />
    </StyledGraphicAnimated>
  )
}

export default GraphicAnimated
