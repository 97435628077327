import styled from 'styled-components'
import { Label } from '@/atoms/Typography'

import { StyledFiltersResults } from '../Results/styles'
export const StyledFilterClusterContent = styled.div`
  max-width: var(--max-width);
  margin-top:${({ theme }) => theme.rem(24)};
  padding-bottom:${({ theme }) => theme.rem(24)};
  ${({ theme }) =>theme.marginX('auto')}
  ${({ theme }) =>theme.paddingX(0)};

  ${({ theme }) => theme.mediaQuery.md(`
    padding-bottom:${theme.rem(40)};
  `)}
`

export const StyledFilterCluster = styled.div`
  &:last-of-type:not([type="results"]){
    margin-bottom:0;
  }

  &:first-of-type{
    ${StyledFilterClusterContent}{
      margin-top: 0;

      ${({ theme }) => theme.mediaQuery.md(`
        margin-top: ${theme.rem(24)};
      `) }
    }
  }
`

export const StyledFilterClusterWrapper = styled.div`
  display: block;
  width: 100%;
  ${({ theme }) => theme.paddingX(24)};
  ${({ theme }) => theme.mediaQuery.md(`
    --col: ${theme.col(1)};
    ${theme.paddingX('var(--col)')}
  `)}

  ${({
    theme,
    type
  }) => type === 'Tab' && `
    ${theme.paddingX(0)};

    ${StyledFilterClusterContent}{
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      ${theme.paddingX(24)};

      [data-section="SearchResults"] & {
        ${theme.paddingX(0)};
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    ${theme.mediaQuery.md(`
      ${theme.paddingX(0)};

      ${StyledFilterClusterContent}{
        max-width: none;
        ${theme.paddingX(24)};
        --col-min: ${theme.col(1)};
        --col: calc((100vw - var(--max-width)) / 2);
        padding-left: clamp(var(--col-min), var(--col),var(--col));
        padding-right: clamp(var(--col-min), var(--col),var(--col));
      }
    `)};
  `
}

  &[type="Checkbox"] ${StyledFilterClusterContent}{
    ${({ theme }) => theme.paddingY(24)};
    margin-top: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayscale.gray200};

    &:has( >${StyledFiltersResults}){
      border-bottom:none;
    }

    ${({ theme }) => theme.mediaQuery.md(`
      border-top: 0;`
  )};

    ${({ theme }) => theme.mediaQuery.lg(`
      border-top: 0;
      border-bottom: 0;
      ${theme.marginY([24, 0])}
      ${theme.paddingY(0)}
    `)}
  }

  ${({
    withLine,
    theme,
    isLast
  }) => withLine && `
    &:not([type="Checkbox"]) ${StyledFilterClusterContent}{
      position:relative;

      &::after{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        content: '';
        width:100%;
        height: 1px;
        background-color: ${theme.colors.grayscale.gray200};

      }
    }

    &[type="Tab"] ${StyledFilterClusterContent}{
      ${theme.paddingX(0)};

      &::after{
        --w: ${theme.rem(48)};
        width: calc(100% - var(--w));

        ${theme.mediaQuery.md(`
          display: ${isLast ? 'block' : 'none'};
          --col-min: ${theme.col(2)};
          --col: calc(100vw - var(--max-width));
          --w: clamp(var(--col-min), var(--col),var(--col));
        `)}
      }
    }
  `}

`

export const StyledFilterClusterHeader = styled.div`
  position: relative;
  ${({ theme }) =>theme.paddingX(24)};

  ${({ theme }) => theme.mediaQuery.md(`
    --col: ${theme.col(1)};

    ${theme.paddingX('var(--col)')}
  `)}

`

export const StyledFilterClusterHeaderContainer = styled.div`
  display: none;
  max-width: var(--max-width);
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  ${({ theme }) =>theme.marginX('auto')};
  gap: ${({ theme }) => theme.rem(24)};

  ${Label}{
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.grayscale.gray700};
  }

  &::after{
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.grayscale.gray200};
  }

  ${({ theme }) => theme.mediaQuery.md(`
    display: flex;
  `)};
`

