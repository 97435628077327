import React from 'react'

import Button from '@/atoms/Button'

import { StyledSectionCta } from './styles'

const SectionCta = ({ cta }) => {
  return (
    <StyledSectionCta>
      {cta && !!cta.link ? <Button {...cta}>{cta.link.title}</Button> : null}
    </StyledSectionCta>
  )
}

export default SectionCta
